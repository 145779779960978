function toCurrency(value: number | string) {
    value = Number(value || 0);

    return Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'USD',
    }).format(value);
};

const patterns = 
{ 
    positiveNumberUpToTwoDecimals: '^\\d+(\\.\\d{1,2})?$',
    anyNonZeroUpToTwoDecimals: '^-?(?!0+(\\.0+)?$)\\d+(\\.\\d{1,2})?$'
}

let usableFunctionsAndPatterns = 
{
    toCurrency,
    patterns
}

export function useNumberFormats(): typeof usableFunctionsAndPatterns
{
    return usableFunctionsAndPatterns;
}