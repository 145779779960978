import { defineStore } from 'pinia';
import { ApiRequestConfig, IApiClientError } from '@cyber-range/cyber-range-api-client';
import { AuthenticationApiClient } from '@cyber-range/cyber-range-api-authentication-client';
import { CompetitionApiClient } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { EntitlementApiClient } from '@cyber-range/cyber-range-api-entitlement-client';
import { IUserApiClient, UserApiClient } from '@cyber-range/cyber-range-api-user-client';
import { CatalogApiClient, CatalogFamilyApiClient, CatalogFamilySearchApiClient } from '@cyber-range/cyber-range-api-catalog-client';
import { ExerciseApiClient, IExerciseApiClient } from '@cyber-range/cyber-range-api-exercise-client';
import { IInvitationApiClient, InvitationApiClient } from '@cyber-range/cyber-range-api-invitation-client';
import { OrganizationApiClient } from '@cyber-range/cyber-range-api-organization-client';
import { UsageApiClient } from '@cyber-range/cyber-range-api-usage-client';
import { SubscriptionApiClient } from '@cyber-range/cyber-range-api-subscription-client';
import { InvoiceApiClient } from '@cyber-range/cyber-range-api-invoice-client';
import { AgreementApiClient } from '@cyber-range/cyber-range-api-agreement-client';
import { BusinessUnitApiClient } from '@cyber-range/cyber-range-api-business-unit-client';
import { BulkApiClient, IJobApiClient, JobApiClient } from '@cyber-range/cyber-range-api-job-client';
import { CreditApiClient } from '@cyber-range/cyber-range-api-credit-client';
import { SyncApiClient } from '@cyber-range/cyber-range-api-sync-client';
import { PaymentApiClient } from '@cyber-range/cyber-range-api-payment-client';
import { RosterApiClient } from '@cyber-range/cyber-range-api-roster-client';
import { CredentialApiClient, ICredentialApiClient } from '@cyber-range/cyber-range-api-credential-client';
import { FileApiClient } from '@cyber-range/cyber-range-api-file-client';
import { SearchApiClient } from '@cyber-range/cyber-range-api-search-client';
import Config from '../config';
import { useSessionStore } from './sessionStore';
import { useErrorStore } from './errorStore';
import { TrackJS } from 'trackjs';
import { useAnalyticStore } from './analyticStore';
import { BalanceApiClient } from '@cyber-range/cyber-range-api-balance-client';
import { ProductApiClient } from '@cyber-range/cyber-range-api-subscription-client';
import { VendibleApiClient } from '@cyber-range/cyber-range-api-payment-client';
import { AuditApiClient } from '@cyber-range/cyber-range-api-audit-client';

function customApiBackgroundErrorHandler(e:IApiClientError) 
{
    TrackJS.track(e);
    console.error(e);
    useAnalyticStore().trackError(e.message, e.statusCode);

    if(e.statusCode === 403 || e.statusCode === 422 || (e.statusCode === 400 && e.message === 'jwt expired'))
    {
        return useSessionStore().logout();
    }
}
function customApiErrorHandler(e:IApiClientError) 
{
    TrackJS.track(e);
    useErrorStore().setError(e);
}
function apiCallingHandler() 
{
    useApiClientStore().loadingBegin()
}
function apiCalledHandler() 
{
    useApiClientStore().loadingEnd()
}

function createApiClient<T>(T:{new(...args: any[]):T}, baseUrl:string): T
{
    let token = useSessionStore().token;

    let config = new ApiRequestConfig(
                            { "authorization": `Bearer ${token}`, 'User-Agent': undefined },
                            undefined,
                            customApiErrorHandler,
                            apiCallingHandler,
                            apiCalledHandler);

    return new T(baseUrl, config);
}

function createBackgroundApiClient<T>(T:{new(...args: any[]):T}, baseUrl:string): T
{
    let token = useSessionStore().token;

    let config = new ApiRequestConfig(
                            { "authorization": `Bearer ${token}`, 'User-Agent': undefined },
                            undefined,
                            customApiBackgroundErrorHandler,
                            undefined,
                            undefined);

    return new T(baseUrl, config);
}

function createBulkApiClient<T>(T:{new(...args: any[]):T}, baseUrl:string, jobClient: IJobApiClient): T
{
    return new T(undefined, undefined, new BulkApiClient(baseUrl, undefined, jobClient));
}

export const useApiClientStore = defineStore('apiClientStore', 
{
    state: () =>
    ({
        loading: 0,
        _authenticationApiClient: undefined,
        _entitlementApiClient: undefined,
        _userApiClient: undefined,
        _catalogApiClient: undefined,
        _backgroundCatalogApiClient: undefined,
        _catalogFamilyApiClient: undefined,
        _catalogFamilySearchApiClient: undefined,
        _invitationApiClient: undefined,
        _organizationApiClient: undefined,
        _rosterApiClient: undefined,
        _agreementApiClient: undefined,
        _backgroundAgreementApiClient: undefined,
        _businessUnitApiClient: undefined,
        _subscriptionApiClient: undefined,
        _backgroundSubscriptionApiClient: undefined,
        _productApiClient: undefined,
        _backgroundProductApiClient: undefined,
        _backgroundUserApiClient: undefined,
        _backgroundInvitationApiClient: undefined,
        _backgroundOrganizationApiClient: undefined,
        _backgroundBusinessUnitApiClient: undefined,
        _backgroundRosterApiClient: undefined,
        _exerciseApiClient: undefined,
        _backgroundExerciseApiClient: undefined,
        _credentialApiClient: undefined,
        _backgroundCredentialApiClient: undefined,
        _fileApiClient: undefined,
        _backgroundFileApiClient: undefined,
        _competitionApiClient: undefined,
        _backgroundCompetitionApiClient: undefined,
        _jobApiClient: undefined,
        _backgroundJobApiClient: undefined,
        _syncApiClient: undefined,
        _backgroundSyncApiClient: undefined,
        _searchApiClient: undefined,
        _backgroundSearchApiClient: undefined,
        _balanceApiClient: undefined,
        _backgroundBalanceApiClient: undefined,
        _paymentApiClient: undefined,
        _vendibleApiClient: undefined,
        _usageApiClient: undefined,
        _backgroundUsageApiClient: undefined,
        _invoiceApiClient: undefined,
        _auditApiClient: undefined,
        _backgroundAuditApiClient: undefined,
    }),
    getters: 
    {
        isLoading: (state) => state.loading > 0,
        authenticationApiClient: (state) => state._authenticationApiClient || createApiClient(AuthenticationApiClient, Config.AUTHENTICATION_API_BASE_URL),
        backgroundAuthenticationApiClient: (state) => createBackgroundApiClient(AuthenticationApiClient, Config.AUTHENTICATION_API_BASE_URL),
        entitlementApiClient: (state) => state._entitlementApiClient || createApiClient(EntitlementApiClient, Config.ENTITLEMENT_API_BASE_URL),
        backgroundEntitlementApiClient: (state) => createBackgroundApiClient(EntitlementApiClient, Config.ENTITLEMENT_API_BASE_URL),
        userApiClient: (state) => state._userApiClient || createApiClient(UserApiClient, Config.USER_API_BASE_URL),
        backgroundUserApiClient: (state) => state._backgroundUserApiClient || createBackgroundApiClient(UserApiClient, Config.USER_API_BASE_URL),
        catalogApiClient: (state) => state._catalogApiClient || createApiClient(CatalogApiClient, Config.CATALOG_API_BASE_URL),
        backgroundCatalogApiClient: (state) => state._backgroundCatalogApiClient || createBackgroundApiClient(CatalogApiClient, Config.CATALOG_API_BASE_URL),
        catalogFamilyApiClient: (state) => state._catalogFamilyApiClient || createApiClient(CatalogFamilyApiClient, Config.CATALOG_API_BASE_URL),
        backgroundCatalogFamilyApiClient: (state) => createBackgroundApiClient(CatalogFamilyApiClient, Config.CATALOG_API_BASE_URL),
        catalogFamilySearchApiClient: (state) => state._catalogFamilySearchApiClient || createApiClient(CatalogFamilySearchApiClient, Config.CATALOG_FAMILY_SEARCH_API_BASE_URL),
        backgroundCatalogFamilySearchApiClient: (state) => createBackgroundApiClient(CatalogFamilySearchApiClient, Config.CATALOG_FAMILY_SEARCH_API_BASE_URL),
        exerciseApiClient: (state) => state._exerciseApiClient || createApiClient(ExerciseApiClient, Config.EXERCISE_API_BASE_URL),
        backgroundExerciseApiClient: (state) => state._backgroundExerciseApiClient || createBackgroundApiClient(ExerciseApiClient, Config.EXERCISE_API_BASE_URL),
        invitationApiClient: (state) => state._invitationApiClient || createApiClient(InvitationApiClient, Config.INVITATION_API_BASE_URL),
        backgroundInvitationApiClient: (state) => state._backgroundInvitationApiClient || createBackgroundApiClient(InvitationApiClient, Config.INVITATION_API_BASE_URL),
        organizationApiClient: (state) => state._organizationApiClient || createApiClient(OrganizationApiClient, Config.ORGANIZATION_API_BASE_URL),
        backgroundOrganizationApiClient: (state) => state._backgroundOrganizationApiClient || createBackgroundApiClient(OrganizationApiClient, Config.ORGANIZATION_API_BASE_URL),
        usageApiClient: (state) => state._usageApiClient || createApiClient(UsageApiClient, Config.USAGE_API_BASE_URL),
        backgroundUsageApiClient: (state) => state._backgroundUsageApiClient || createBackgroundApiClient(UsageApiClient, Config.USAGE_API_BASE_URL),
        subscriptionApiClient: (state) => state._subscriptionApiClient || createApiClient(SubscriptionApiClient, Config.SUBSCRIPTION_API_BASE_URL),
        backgroundSubscriptionApiClient: (state) => state._backgroundSubscriptionApiClient || createBackgroundApiClient(SubscriptionApiClient, Config.SUBSCRIPTION_API_BASE_URL),
        productApiClient: (state) => state._productApiClient || createApiClient(ProductApiClient, Config.SUBSCRIPTION_API_BASE_URL),
        backgroundProductApiClient: (state) => state._backgroundProductApiClient || createBackgroundApiClient(ProductApiClient, Config.SUBSCRIPTION_API_BASE_URL),
        invoiceApiClient: (state) => state._invoiceApiClient ||createApiClient(InvoiceApiClient, Config.INVOICE_API_BASE_URL),
        backgroundInvoiceApiClient: (state) => createBackgroundApiClient(InvoiceApiClient, Config.INVOICE_API_BASE_URL),
        agreementApiClient: (state) => state._agreementApiClient || createApiClient(AgreementApiClient, Config.AGREEMENT_API_BASE_URL),
        backgroundAgreementApiClient: (state) => state._backgroundAgreementApiClient || createBackgroundApiClient(AgreementApiClient, Config.AGREEMENT_API_BASE_URL),
        businessUnitApiClient: (state) => state._businessUnitApiClient || createApiClient(BusinessUnitApiClient, Config.BUSINESS_UNIT_API_BASE_URL),
        backgroundBusinessUnitApiClient: (state) => state._backgroundBusinessUnitApiClient || createBackgroundApiClient(BusinessUnitApiClient, Config.BUSINESS_UNIT_API_BASE_URL),
        syncApiClient: (state) => state._syncApiClient || createApiClient(SyncApiClient, Config.SYNC_API_BASE_URL),
        backgroundSyncApiClient: (state) => state._backgroundSyncApiClient || createBackgroundApiClient(SyncApiClient, Config.SYNC_API_BASE_URL),
        jobApiClient: (state) => state._jobApiClient || createApiClient(JobApiClient, Config.JOB_API_BASE_URL),
        backgroundJobApiClient: (state) => state._backgroundJobApiClient || createBackgroundApiClient(JobApiClient, Config.JOB_API_BASE_URL),
        creditApiClient: (state) => createApiClient(CreditApiClient, Config.CREDIT_API_BASE_URL),
        backgroundCreditApiClient: (state) => createBackgroundApiClient(CreditApiClient, Config.CREDIT_API_BASE_URL),
        paymentApiClient: (state) => state._paymentApiClient || createApiClient(PaymentApiClient, Config.PAYMENT_API_BASE_URL),
        backgroundPaymentApiClient: (state) => createBackgroundApiClient(PaymentApiClient, Config.PAYMENT_API_BASE_URL),
        vendibleApiClient: (state) => state._vendibleApiClient || createApiClient(VendibleApiClient, Config.PAYMENT_API_BASE_URL),
        backgroundVendibleApiClient: (state) => createBackgroundApiClient(VendibleApiClient, Config.PAYMENT_API_BASE_URL),
        rosterApiClient: (state) => state._rosterApiClient ||  new RosterApiClient(state._userApiClient || createApiClient(UserApiClient, Config.USER_API_BASE_URL)),
        backgroundRosterApiClient: (state) => state._backgroundRosterApiClient ||  new RosterApiClient(state._backgroundUserApiClient || createBackgroundApiClient(UserApiClient, Config.USER_API_BASE_URL)),
        credentialApiClient: (state) => state._credentialApiClient ||  createApiClient(CredentialApiClient, Config.CREDENTIAL_API_BASE_URL),
        backgroundCredentialApiClient: (state) => state._backgroundCredentialApiClient ||  createBackgroundApiClient(CredentialApiClient, Config.CREDENTIAL_API_BASE_URL),
        fileApiClient: (state) => state._fileApiClient || createApiClient(FileApiClient, Config.FILE_API_BASE_URL),
        backgroundFileApiClient: (state) => state._backgroundFileApiClient || createBackgroundApiClient(FileApiClient, Config.FILE_API_BASE_URL),
        competitionApiClient: (state) => state._competitionApiClient || createApiClient(CompetitionApiClient, Config.COMPETITION_API_BASE_URL),
        backgroundCompetitionApiClient: (state) => state._backgroundCompetitionApiClient || createBackgroundApiClient(CompetitionApiClient, Config.COMPETITION_API_BASE_URL),
        searchApiClient: (state) => state._searchApiClient || createApiClient(SearchApiClient, Config.SEARCH_API_BASE_URL),
        backgroundSearchApiClient: (state) => state._backgroundSearchApiClient || createBackgroundApiClient(SearchApiClient, Config.SEARCH_API_BASE_URL),
        balanceApiClient: (state) => state._balanceApiClient || createApiClient(BalanceApiClient, Config.BALANCE_API_BASE_URL),
        backgroundBalanceApiClient: (state) => state._backgroundBalanceApiClient || createBackgroundApiClient(BalanceApiClient, Config.BALANCE_API_BASE_URL),
        auditApiClient: (state) => state._auditApiClient || createApiClient(AuditApiClient, Config.AUDIT_API_BASE_URL),
        backgroundAuditApiClient: (state) => state._backgroundAuditApiClient || createBackgroundApiClient(AuditApiClient, Config.AUDIT_API_BASE_URL),
        // Bulk Clients
        bulkExerciseApiClient(): IExerciseApiClient { return createBulkApiClient(ExerciseApiClient, Config.EXERCISE_API_BASE_URL, this.jobApiClient) },
        bulkUserApiClient(): IUserApiClient { return createBulkApiClient(UserApiClient, Config.USER_API_BASE_URL, this.jobApiClient) },
        bulkInvitationApiClient(): IInvitationApiClient { return createBulkApiClient(InvitationApiClient, Config.INVITATION_API_BASE_URL, this.jobApiClient) },
        bulkCredentialApiClient(): ICredentialApiClient { return createBulkApiClient(CredentialApiClient, Config.CREDENTIAL_API_BASE_URL, this.jobApiClient) }
    },
    actions:
    {
        loadingBegin(): number
        {
            return ++this.loading;
        },
        loadingEnd(): number
        {
            return --this.loading;
        }
    }
});
