import { defineStore } from 'pinia';
import { RouteLocationNormalized } from 'vue-router';
import { TrackJS } from 'trackjs';
import ISession from '../interfaces/iSession';

/**
 * Matomo uses window._paq as a tracking client.
 * Since we are doing a strong-typing in this project, the following code extend _paq to the window object.
 */
declare global {
    interface Window {
        _paq: { push: (params: (string|number|undefined)[]) => void };
    }
}

// Doc: https://developer.matomo.org/guides/tracking-javascript-guide
export const useAnalyticStore = defineStore('analyticStore', 
{
    actions:
    {
        setUserId(session:ISession): void
        {
            // Matomo
            // Always use the user who is actively engaged in the web application or platform, 
            // regardless of whether they are impersonating someone else.
            window._paq.push(['setUserId', session.impersonator || session.userId]);

            // Trackjs
            // Always use the current user regardless of whether the user is being impersonated.
            TrackJS.configure({
                userId: session.userId
            });

            if(session.userName)
            {
                TrackJS.addMetadata('User Name', session.userName);
            }
            else
            {
                TrackJS.removeMetadata('User Name');
            }

            if(session.impersonator)
            {
                TrackJS.addMetadata('Impersonator', session.impersonator);
            }
            else
            {
                TrackJS.removeMetadata('Impersonator');
            }

            if(session.impersonatorName)
            {
                TrackJS.addMetadata('Impersonator Name', session.impersonatorName);
            }
            else
            {
                TrackJS.removeMetadata('Impersonator Name');
            }
        },

        setPageTitle(route: RouteLocationNormalized): void
        {
            route.meta.title = route.name;
        },

        trackSearchKeyword(keyword:string = '', filter: string|(string[]) = [], resultCount: number = 0): void
        {
            window._paq.push(['trackSiteSearch', keyword, Array.isArray(filter) ? [...filter].sort().join(',') : filter, resultCount]);
        },

        /**
         * @param category A string describing the category of the event. This is often used to group related events. For example, "Button Clicks" or "Downloads."
         * @param action A string describing the action that the user took. For example, "Click," "Submit," or "Download."
         * @param name A string providing more details about the event. This can be used to distinguish between similar events within the same category and action.
         * @param value A numeric value associated with the event. This can be used for numerical data, like revenue or duration.
         */
        trackEvent(category: string, action:string, name:string = '', value:number = 0): void
        {
            if(name && value)
            {
                window._paq.push(['trackEvent', category, action, name, value]);
            }
            else if(name)
            {
                window._paq.push(['trackEvent', category, action, name]);
            }
            else if(value)
            {
                window._paq.push(['trackEvent', category, action, undefined, value]);
            }
            else
            {
                window._paq.push(['trackEvent', category, action]);
            }
        },

        trackError(errorMessage:string, errorCode:number)
        {
            this.trackEvent('Error', `${errorCode}`, errorMessage);
        },

        trackImpersonationBegin(impersonatedUserId:string)
        {
            this.trackEvent('Impersonation', 'Begin', impersonatedUserId);
        },

        trackImpersonationEnd(impersonatedUserId:string)
        {
            this.trackEvent('Impersonation', 'End', impersonatedUserId);
        },
    }
});