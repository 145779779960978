<template>
    <cr-delete-confirmation-dialog id="deleteOrganizationMembershipDialog" @confirm="onConfirmed" :disabled="isLoading" data-testing="delete-organization-membership-dialog">
        <cr-html :value="t('PROFILE_DELETE_ORGANIZATION_MEMBERSHIP_DIALOG_MESSAGE', {userName: user?.name, organizationName: membership?.name})" />
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import IProfileMembership from '../../../interfaces/iProfileMembership';
import { IUser } from '@cyber-range/cyber-range-api-user-client';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useI18n } from 'vue-i18n';
import { useOrganizationStore } from '../../../stores/organizationStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    user?: IUser,
    membership?: IProfileMembership
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    await useOrganizationStore().deleteOrganizationUser(props.membership?.organizationId as string, props.user?.id as string);
    emit('confirm');
}
</script>