<template>
    <cr-confirmation-dialog :title="t('EDIT_INVOICE_DIALOG_TITLE')" @confirm="onConfirmed" @shown="onShown" :disabled="!ready" :form-instruction="true" data-testing="edit-invoice-dialog">
        <form-section>
            <cr-text-field v-model="displayId" :disabled="!ready" :label="t('EDIT_INVOICE_DIALOG_DISPLAY_ID')" type="text" required :max-length="100" data-testing="edit-invoice-invoice-id"/>
            <cr-select v-model="status" :disabled="!ready" :label="t('EDIT_INVOICE_DIALOG_INVOICE_STATUS')" :items="statuses" required data-testing="edit-invoice-status-select"/>
        </form-section>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import FormSection from '../../layouts/sections/FormSection.vue';
import { useI18n } from 'vue-i18n';
import {useInvoiceStore} from '../../../stores/invoiceStore';
import { IInvoice, InvoiceStatus } from '@cyber-range/cyber-range-api-invoice-client';
import { useEnum } from '../../../composables/useEnum';

const props = defineProps<{
    invoiceId?: string
}>();

const emit = defineEmits<{
    (name: 'confirm', updated: boolean): void
}>();


const { t } = useI18n();
const ready = ref(false);

const invoice = ref<IInvoice>();
const displayId = ref<string>("");
const status = ref<InvoiceStatus|undefined>();
const statuses = useEnum().toSelectItems(InvoiceStatus, [InvoiceStatus.Scheduled, InvoiceStatus.Billed, InvoiceStatus.Canceled]);

const populateData = async () =>
{
    if (props.invoiceId)
    {
        invoice.value = await useInvoiceStore().getInvoice(props.invoiceId);
        displayId.value = invoice.value.displayId;
        status.value = invoice.value.status;
        ready.value = true;
    }
}

const onConfirmed = async () =>
{
    if (!invoice.value)
    {
        return;
    }

    const promises =  [];

    if (displayId.value !== invoice.value.displayId)
    {
        promises.push(useInvoiceStore().updateDisplayId(invoice.value.id, displayId.value));
    }

    if (status.value && status.value !== invoice.value.status)
    {
        promises.push(useInvoiceStore().updateStatus(invoice.value.id, status.value));
    }

    const updated = promises.length > 0;
    await Promise.all(promises);

    emit('confirm', updated);
}

const onShown = () =>
{
    populateData();
}
</script>