<template>
    <form-layout :title="t('BUSINESS_UNITS_CREATE_BUSINESS_UNIT_PAGE_TITLE')" :breadcrumbs="breadcrumbs" @confirm="onConfirm" @cancel="onCancel" :ready="isReady">
        <template #form>
            <business-unit-form v-model="businessUnit" />
        </template>
    </form-layout>
</template>

<script setup lang="ts">
import BusinessUnitForm from './BusinessUnitForm.vue';
import FormLayout from '../layouts/FormLayout.vue';
import Route from '../../routers/route';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { BusinessUnit } from '@cyber-range/cyber-range-api-business-unit-client';
import { IOrganization, Organization } from '@cyber-range/cyber-range-api-organization-client';
import { computed, onMounted, ref } from 'vue';
import { useBusinessUnitStore } from '../../stores/businessUnitStore';
import { useI18n } from 'vue-i18n';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useRawObject } from '../../composables/useRawObject';
import { useRouter } from 'vue-router';

const props = defineProps<
{
    organizationId:string;    
}>();

const { t } = useI18n();
const router = useRouter();

const organization = ref<IOrganization>(new Organization());
const isReady = ref(false);

const breadcrumbs = computed(()=>[ 
                        new BreadcrumbItem(Route.Organizations), 
                        new BreadcrumbItem({...Route.Organization, text: organization.value?.name, params: {organizationId: props.organizationId}}), 
                        new BreadcrumbItem({...Route.BusinessUnits, params: {organizationId: props.organizationId}}),
                        new BreadcrumbItem({...Route.CreateBusinessUnit, params: {organizationId: props.organizationId}})]);

let businessUnit = new BusinessUnit();

const onConfirm = async () => 
{
    let payload = useRawObject(businessUnit, {excludes: ['createdTimestamp']});
    
    payload.organizationId = organization?.value.id;

    let businessUnitId = await useBusinessUnitStore().create(payload);

    router.push({ name: Route.BusinessUnit.name, params: {organizationId: props.organizationId, businessUnitId}});
}

const onCancel = () => 
{
    router.push({ name: Route.BusinessUnits.name, params: {organizationId: props.organizationId}});
}

onMounted(async ()=>
{
    try
    {
        organization.value = await useOrganizationStore().getOrganization(props.organizationId);
    }
    finally
    {
        isReady.value = true;
    }
});
</script>