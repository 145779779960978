export default {
    en: {
        PROFILE_TITLE: "Profile",
        PROFILE_DETAILS: "Details",
        PROFILE_DETAILS_LAST_LOGIN_WITH: "Last login with",
        PROFILE_DETAILS_LAST_LOGIN: "Last login",
        PROFILE_DETAILS_CREATED_TIMESTAMP: "User since",
        PROFILE_DETAILS_ID: "User ID",
        PROFILE_DETAILS_TOKEN: "Token",
        PROFILE_IMPERSONATE_BUTTON_LABEL: 'Impersonate',
        PROFILE_VIEW_LOGS_BUTTON_LABEL: 'View Logs',
        PROFILE_EDIT_COURSE_MEMBERSHIP_ROLE_BUTTON: 'Edit Role',
        PROFILE_EDIT_COURSE_MEMBERSHIP_ROLE_DIALOG_TITLE: 'Edit Course Role',
        PROFILE_COURSE_MEMBERSHIP_ROLE_FORM_ROLE_SECTION: 'Role',
        PROFILE_COURSE_MEMBERSHIP_ROLE_FORM_ROLE_SELECT_LABEL: 'Role',
        PROFILE_DELETE_COURSE_MEMBERSHIP_BUTTON: 'Delete',
        PROFILE_DELETE_COURSE_MEMBERSHIP_DIALOG_MESSAGE: 'Are you sure you want to delete <b>{userName}</b> from <b>{courseName}</b>?',
        PROFILE_EDIT_ORGANIZATION_MEMBERSHIP_ROLES_BUTTON: 'Edit Roles',
        PROFILE_DELETE_ORGANIZATION_MEMBERSHIP_BUTTON: 'Delete',
        PROFILE_EDIT_ORGANIZATION_MEMBERSHIP_ROLE_DIALOG_TITLE: 'Edit Organization Roles',
        PROFILE_ORGANIZATION_MEMBERSHIP_ROLE_FORM_ROLES_PICKER_LABEL: 'Roles',
        PROFILE_DELETE_ORGANIZATION_MEMBERSHIP_DIALOG_MESSAGE: 'Are you sure you want to delete <b>{userName}</b> from <b>{organizationName}</b>?',
    }
}