<template>
    <cr-delete-confirmation-dialog id="deleteCourseApplicationDialog" @confirm="onConfirmed" :disabled="isLoading" data-testing="delete-course-application-dialog">
        <cr-html :value="t('COURSE_APPLICATIONS_DELETE_DIALOG_MESSAGE', {applicationId: application?.id})" />
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useCourseStore } from '../../../stores/courseStore';
import { storeToRefs } from 'pinia';
import { ICourseApplication } from '@cyber-range/cyber-range-api-user-client';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    courseId?: string;
    application?: ICourseApplication;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    if(props.courseId && props.application)
    {
        await useCourseStore().deleteCourseApplication(props.courseId, props.application.id);
        emit('confirm');
    }
}
</script>