import { defineStore } from 'pinia';
import { ApiPageResponse, IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import { useApiClientStore } from './apiClientStore';
import { CourseFilter, CourseStatus, ICourse, ICourseApplication, ICourseApplicationFilter, ICourseCodeOptions, ICourseFilter, ICreateCourseResponse } from '@cyber-range/cyber-range-api-user-client';
import { useEntitlementStore } from './entitlementStore';
import { useSubscriptionStore } from './subscriptionStore';
import { Products } from '@cyber-range/cyber-range-api-subscription-client';

function populateCourseImage(course:ICourse): ICourse
{
    let charCode = course.id.charCodeAt(course.id.length - 1);

    let images = ['/courses/guy_pressing_buttons_for_computer.svg', '/courses/hero_busting_into_hacker.svg', '/courses/person_stealing_the_password.svg', '/courses/spam_mail.svg', '/courses/trojan_horse.svg']

    //@ts-ignore - Ignore a complain that 'image' is not a property of ICourse
    course['image'] = images[charCode % images.length];

    return course;
}

export const useCourseStore = defineStore('courseStore', 
{
    state: () => 
    ({
        myCourses: <ICourse[]> [],
        pendingCourses: <ICourse[]> [],
        courses: <ICourse[]> [],
    }),

    actions: 
    {
        async getCourse(id:string): Promise<ICourse>
        {
            let userApiClient = useApiClientStore().userApiClient;

            return populateCourseImage(await userApiClient.getCourse(id));
        },

        async listCourses(filter?:ICourseFilter, options?: {background?:boolean}): Promise<IApiPageResponse<ICourse>>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            let page = await userApiClient.getCourses(filter);

            for(let course of page.items)
            {
                populateCourseImage(course);
            }

            return page;
        },

        async listMyCourses(filter?:ICourseFilter, options?: {background?:boolean}): Promise<IApiPageResponse<ICourse>>
        {
            let result = new ApiPageResponse<ICourse>({items: []});

            let clonedFilter = new CourseFilter(filter);
            let expectedNumberOfItems = clonedFilter.limit;

            let entitlementStore = useEntitlementStore();

            do
            {
                let page = await this.listCourses(clonedFilter, options);
                result.items = result.items.concat(page.items.filter(course => entitlementStore.myCourseIds.has(course.id)));

                result.nextPageToken = clonedFilter.token = page.nextPageToken;
                clonedFilter.limit = expectedNumberOfItems - result.items.length;
            }
            while(clonedFilter.token && clonedFilter.limit > 0)

            return result;
        },

        async fetchMyCourses(): Promise<ICourse[]>
        {
            return this.myCourses = (await this.listMyCourses(new CourseFilter({limit:Number.MAX_SAFE_INTEGER}))).items;
        },

        async fetchPendingCourses(options?: {background?:boolean}): Promise<ICourse[]>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;
            return this.pendingCourses = await userApiClient.getAllCourses(new CourseFilter({ statuses: [CourseStatus.PendingApproval] }));
        },

        async fetchAllCourses(options?: {background?:boolean}): Promise<ICourse[]>
        {
            const result: ICourse[] = [];

            const filter = new CourseFilter();

            do
            {
                let page = await this.listCourses(filter, options);

                result.push(...page.items);
                filter.token = page.nextPageToken;
            }
            while(filter.token)

            this.courses = result;

            return result;
        },

        async create(course:ICourse): Promise<ICreateCourseResponse>
        {
            let userApiClient = useApiClientStore().userApiClient;

            return await userApiClient.createCourse(course);
        },

        async update(course:ICourse): Promise<void>
        {
            let userApiClient = useApiClientStore().userApiClient;

            await userApiClient.updateCourse(course);
        },

        async delete(id:string): Promise<void>
        {
            let userApiClient = useApiClientStore().userApiClient;

            await userApiClient.deleteCourse(id);
        },

        async createCourseCode(id:string, options?:Partial<ICourseCodeOptions>): Promise<void>
        {
            let userApiClient = useApiClientStore().userApiClient;

            await userApiClient.createCourseCode(id, options);
        },

        async deleteCourseCode(id:string): Promise<void>
        {
            let userApiClient = useApiClientStore().userApiClient;

            await userApiClient.deleteCourseCode(id);
        },

        async listAllCourseApplications(filter?:ICourseApplicationFilter, options?: {background?:boolean}): Promise<ICourseApplication[]>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            let page = await userApiClient.getAllCourseApplications(filter);

            return page;
        },

        async deleteCourseApplication(courseId: string, applicationId:string): Promise<void>
        {
            let userApiClient = useApiClientStore().userApiClient;

            await userApiClient.deleteCourseApplication(courseId, applicationId);
        },

        async courseIsAnonymous(course: ICourse): Promise<boolean>
        {
            if (!course.organizationId)
            {
                return false
            }
            const subscriptionStore = useSubscriptionStore();
            await subscriptionStore.fetchOrganizationSubscribedProducts(course.organizationId);
            return subscriptionStore.isSubscribedTo(course.organizationId, Products.AnonymousStudentsAndTasFeatures);
        },

        async approve(courseId: string, options?: {background?:boolean}): Promise<void>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            await userApiClient.approveCourse(courseId);
        },

        async reject(courseId: string, options?: {background?:boolean}): Promise<void>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            await userApiClient.rejectCourse(courseId);
        },
    }
})