<template>
    <cr-error-dialog v-model="showNoAltIdDialog" :title="t('UPFRONT_PAYMENT_LINK_DIALOG_TITLE')" @confirm="closeDialog" data-testing="payment-link-no-alt-id-dialog">
        {{ t('UPFRONT_PAYMENT_LINK_NO_ALT_ID') }}
    </cr-error-dialog>
    <cr-confirmation-dialog v-model="showCreateLinkDialog" :title="t('UPFRONT_PAYMENT_LINK_DIALOG_TITLE')"  @cancel="closeDialog" @confirm="onCreateLinkDialogConfirmed" data-testing="payment-link-create-link-dialog">
        <cr-text-field v-model="paymentAmount" :label="t('UPFRONT_PAYMENT_LINK_AMOUNT_LABEL')" type="text" :pattern="patterns.positiveNumberUpToTwoDecimals" prefix="$" data-testing="payment-link-create-link-amount-field"/>
    </cr-confirmation-dialog>
    <cr-confirmation-dialog v-model="showViewLinkDialog" :title="t('UPFRONT_PAYMENT_LINK_DIALOG_TITLE')" :cancel="false" @confirm="closeDialog" data-testing="payment-link-view-link-dialog">
        <p>{{ t('UPFRONT_PAYMENT_LINK_VIEW_LINK_TEXT') }}</p>
        <cr-selectable-text :value="vendibleUrl"/>
        <cr-clipboard-copy :value="vendibleUrl" data-testing="vendible-url-clipboard-copy"/>
    </cr-confirmation-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useNumberFormats } from '../../../composables/useNumberFormats';
import { watch } from 'vue';
import { computed } from 'vue';
import { usePaymentStore } from '../../../stores/paymentStore';
import { Currency, Price, VendibleType } from '@cyber-range/cyber-range-api-payment-client';
import { useOrganizationStore } from '../../../stores/organizationStore';
import Config from '../../../config';

const { t } = useI18n();
const { patterns } = useNumberFormats();

const model = defineModel<boolean>({required: true});
const props = defineProps<
{
    organizationId: string;
    alternateIds?: string[];
}>();


const showNoAltIdDialog = ref(false);
const showCreateLinkDialog = ref(false);
const showViewLinkDialog = ref(false);
const paymentAmount = ref<string>();
const vendibleUrl = ref<string>(""); 
const hasAlternateId = computed(() => !!props.alternateIds?.length );

watch(model, () => 
{
    if (!model.value)
    {
        return; // do nothing when closing the dialog
    }
    
    if (!hasAlternateId.value)
    {
        showNoAltIdDialog.value = true;
    }
    else if (!vendibleUrl.value)
    {
        showCreateLinkDialog.value = true
    }
})

const closeDialog = () =>
{
    paymentAmount.value = undefined;
    vendibleUrl.value = "";
    model.value = false;
} 

const onCreateLinkDialogConfirmed = async () =>
{
    if (!paymentAmount.value) 
    {
        return;
    }

    const numberAmount = Number(paymentAmount.value);
    const vendibleId = await usePaymentStore().createVendible({
        type: VendibleType.OrganizationBalance,
        referenceId: props.organizationId,
        organizationId: props.organizationId,
        name: useOrganizationStore().currentOrganizationName,
        price: new Price({amount: numberAmount, currency: Currency.USD})
    });
    vendibleUrl.value = new URL(`/vendibles/${vendibleId}/pay`, Config.LOGIN_UI_BASE_URL).href;
    showViewLinkDialog.value = true;
}
</script>