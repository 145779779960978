<template>
    <read-section :label="label || t('READ_LAYOUT_READ_DETAIL_SECTION_LABEL')" class="details">
        <cr-row tag="dl">
            <slot />
        </cr-row>
    </read-section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import ReadSection from './ReadSection.vue';

const props = defineProps<
{
    /**
     * A label for this section.
     * @default t('READ_LAYOUT_READ_DETAIL_SECTION_LABEL')
     */
    label?:string
}>()

const { t } = useI18n()
</script>