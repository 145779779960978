<template>
    <cr-sidebar-item tag="span" :to="Route.CourseApprovals">
        <cr-badge :number="pendingCourses.length" icon="bi-journal-check" :alt="t('SIDEBAR_COURSE_APPROVALS_NOTIFICATION', pendingCourses.length)"/>
        <span class="sidebar-item-text" data-testing="sidebar-course-approvals-text">
            {{ t('SIDEBAR_COURSE_APPROVALS') }}
        </span>
    </cr-sidebar-item>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import Route from '../../../../routers/route';
import { useCourseStore } from '../../../../stores/courseStore';


const { t } = useI18n(); 

const courseStore = useCourseStore()
const { pendingCourses } = storeToRefs(courseStore);

onMounted(async () =>
{
    await courseStore.fetchPendingCourses();
});
</script>
