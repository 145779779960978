<template>
    <cr-card :background-color="backgroundColor" text-color="cr-balance-tile-text-color" class="cr-pt2" data-testing="balance-tile">
        <cr-container>
            <cr-row justify="center" data-testing="balance-title-balance-text">{{ t('BILLING_PAGE_CURRENT_BALANCE') }}</cr-row>
            <cr-row justify="center" class="balance" data-testing="balance-title-balance-value">{{ toCurrency(balance) }}</cr-row>
        </cr-container>
    </cr-card>
</template>

<style scoped>
.balance
{
    font-size: var(--cr-balance-tile-value-font-size);
}
</style>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useNumberFormats } from "../../composables/useNumberFormats";
import { computed } from "vue";

const {toCurrency} = useNumberFormats();
const { t } = useI18n();

const props = defineProps<
{
    balance: number;
}>();

const backgroundColor = computed(() => props.balance >= 0 ? 'cr-balance-tile-positive-color' : 'cr-balance-tile-negative-color');
</script>