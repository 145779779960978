export default {
    en: {
        //Create Credentials
        CREATE_CREDENTIALS_PAGE_TITLE: 'Create Anonymous Credentials',
        CREATE_CREDENTIALS_INFORMATION_TEXT: 'Anonymous credentials allow you to add users to this course without sharing students or TAs identifiable information with our platform. Once anonymous credentials are created you can download and distribute them to your students or TAs at your preferred time and method.',
        CREATE_CREDENTIALS_ROLE_LABEL: 'Role',
        CREATE_CREDENTIALS_NUMBER_CREDENTIALS_LABEL: 'Number of anonymous credentials',
        DOWNLOAD_CREDENTIALS_DIALOG_TITLE: 'Create Anonymous Credentials',
        DOWNLOAD_CREDENTIALS_CONFIRM_TEXT: 'Download',
        DOWNLOAD_CREDENTIALS_ARIA_LABEL: 'Credential Download Progress'
    }
};