<template>
    <cr-confirmation-dialog :title="t('COURSE_USERS_RENAME_TEAM_DIALOG_TITLE')" @confirm="onConfirmed" @cancel="onCanceled" :disabled="isLoading" :form-instruction="true" data-testing="rename-team-dialog">
        <p data-testing="rename-team-dialog-message">{{ t('COURSE_USERS_RENAME_TEAM_DIALOG_MESSAGE') }}</p>
        <cr-text-field v-model="team.name" :label="t('COURSE_USERS_RENAME_TEAM_DIALOG_LABEL')" required :max-length="256" :disabled="isLoading" data-testing="rename-team-dialog-team-name" />
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { ITeam, Team } from '@cyber-range/cyber-range-api-user-client';
import { reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useTeamStore } from '../../../stores/teamStore';
import { useRosterStore } from '../../../stores/rosterStore';
import { storeToRefs } from 'pinia';
import { IRosterEntry } from '@cyber-range/cyber-range-api-roster-client';
import { nextTick } from 'vue';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    courseId: string;
    team?: IRosterEntry;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
    (name: 'cancel'): void
}>();

const team = reactive<ITeam>(new Team());

watch(()=>props.team, ()=> 
{
    if(props.team) 
    {
        team.name = props.team.name
    }
}, { immediate: true });

const onConfirmed = async ()=>
{
    if(props.team)
    {
        await useTeamStore().update(props.courseId, props.team.teamId, team);
        useRosterStore().clearCache();
        emit('confirm');
    }
}
async function onCanceled()
{
    await nextTick();
    team.name = props.team?.name || '';
    emit('cancel');
}
</script>