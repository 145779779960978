function downloadFromBlob(blob: Blob, fileName: string, anchor?: HTMLAnchorElement) 
{
    const url = URL.createObjectURL(blob);
    const link = anchor || document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function downloadFromCsv(data: string, fileName: string, anchor?: HTMLAnchorElement) 
{
    downloadFromBlob(new Blob([data], { type: 'text/csv;charset=utf-8;' }), fileName, anchor);
}

let usableFunctions =
{
    downloadFromBlob,
    downloadFromCsv
}

export function useDownload(): typeof usableFunctions
{
    return usableFunctions;
}