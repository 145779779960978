<template>
    <!-- Edit -->
    <read-control-item v-if="canEditCourseExerciseGroup">
        <cr-button :to="editExerciseGroupRoute" outlined data-testing="exercise-group-control-edit">
            <cr-icon>bi-pencil</cr-icon>
            {{ t('EXERCISE_GROUP_EDIT_BUTTON') }}
        </cr-button>
    </read-control-item>   

    <!-- Delete -->
    <template v-if="canDeleteCourseExerciseGroup">
        <read-control-item>
            <cr-button dialog="#deleteExerciseGroupDialog" outlined data-testing="exercise-group-control-delete">
                <cr-icon>bi-trash</cr-icon>
                {{ t('EXERCISE_GROUP_DELETE_BUTTON') }}
            </cr-button>
        </read-control-item>
        <delete-exercise-group-dialog :exerciseGroup="exerciseGroup" @confirm="onDeleteButtonClicked"/>
    </template>
</template>

<script setup lang="ts">
import { IExerciseGroup } from '@cyber-range/cyber-range-api-user-client';
import { computed } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import Route from '../../routers/route';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import DeleteExerciseGroupDialog from './dialogs/DeleteExerciseGroupDialog.vue';
import ReadControlItem from '../layouts/sections/ReadControlItem.vue';

const props = defineProps<
{
    exerciseGroup: IExerciseGroup;
}>();

const { t } = useI18n();
const router = useRouter();
const authorizationStore = useAuthorizationStore();

const canEditCourseExerciseGroup = computed(() => (
    authorizationStore.canEditExerciseGroup(props.exerciseGroup.courseId, props.exerciseGroup.organizationId)
));

const canDeleteCourseExerciseGroup = computed(() => (
    authorizationStore.canDeleteExerciseGroup(props.exerciseGroup.courseId, props.exerciseGroup.organizationId)
));

const editExerciseGroupRoute = computed(() => ({ ...Route.EditExerciseGroup, props: { courseId: props.exerciseGroup.courseId, exerciseGroupId: props.exerciseGroup.id }}));

function onDeleteButtonClicked()
{
    router.push({ name: Route.Course.name, params: { courseId: props.exerciseGroup.courseId }});
}
</script>