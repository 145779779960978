import { defineStore } from 'pinia';

export const useSelectionStore = defineStore('selectionStore', 
{
    state: () => 
    ({
        _selected: new Map<string, string[]>(),
        _maxItems: new Map<string, number>()
    }),

    getters:
    {
        getSelected: (state) => (entityId: string) =>
        {
            const selected = state._selected.get(entityId) || [];
            return [...selected];
        },
        getMaxItems: (state) => (entityId: string) =>
        {
            return state._maxItems.get(entityId);
        }
    },

    actions: 
    {
        setSelected(id: string, selected: string[])
        {
            this.$state._selected.set(id, [...selected])
        },

        setMaxItems(id: string, maxItems: number)
        {
            this.$state._maxItems.set(id, maxItems);
        }
    }
})