import { IInvitation } from "@cyber-range/cyber-range-api-invitation-client";
import { Ref, computed, ref } from "vue";
import { useAuthorizationStore } from "../stores/authorizationStore";
import { useInvitationStore } from "../stores/invitationStore";

export function useAccountApprovals(organizationId?: Ref<string|undefined>)
{
    const canApproveInvitations = computed(() => useAuthorizationStore().canApproveInvitations(organizationId?.value));

    const showAccountApprovedDialog = ref(false);
    async function onApproveAccountClicked(invitation: IInvitation)
    {
        await useInvitationStore().approve(invitation.id);
        showAccountApprovedDialog.value = true;
    }

    const showAccountRejectedDialog = ref(false);
    async function onRejectAccountClicked(invitation: IInvitation)
    {
        await useInvitationStore().reject(invitation.id);
        showAccountRejectedDialog.value = true;
    }

    return {
        canApproveInvitations,
        showAccountApprovedDialog,
        onApproveAccountClicked,
        showAccountRejectedDialog,
        onRejectAccountClicked,
    };
}
