import { IAuditEventFilter } from "@cyber-range/cyber-range-api-audit-client";
import { h } from "vue";
import { RouterLink, useRoute } from "vue-router";

const messageLinkRegularExpression = /\[([^\]]+)]\(([^)]+)\)/;

export default function AuditMessage(props: { message: string, filter?: Partial<IAuditEventFilter> })
{
    const route = useRoute()

    let message = props.message;
    let match
    const nodes = [];
    while(match = messageLinkRegularExpression.exec(message))
    {
        nodes.push(message.slice(0, match.index));

        const [_, name, id] = match;
        nodes.push(h(
            RouterLink,
            { to: { query: { ...props.filter, token: '' , objectId: id } }},
            () => name
        ));

        message = message.slice(match.index + match[0].length);
    }
    nodes.push(message);

    return nodes;
}

AuditMessage.props = {
    message: {
        type: String,
        required: true
    },
    filter: {
        type: Object,
        required: false
    }
}
