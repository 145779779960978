<template>
    <read-section :label="t('PLAN_LIMITS')" data-testing="limits-section">
        <cr-row tag="dl">
            <template v-for="limit of organizationLimits">
                <cr-col tag='dt' xs12 sm9 md9 lg6 xl6 xxl4 label="" :data-testing="`limits-section-${limit}`">
                    {{ capitalCase(limit) }}
                </cr-col>
                <cr-col tag='dd' xs12 sm3 md3 lg6 xl6 xxl2>
                    <div>{{ limits?.[limit] }}</div>
                </cr-col>
            </template>
        </cr-row>
        <cr-row tag="dl">
            <template v-for="limit of courseLimits">
                <cr-col tag='dt' xs12 sm9 md9 lg6 xl6 xxl4 label="" :data-testing="`limits-section-${limit}`">
                    {{ capitalCase(limit) }}
                </cr-col>
                <cr-col tag='dd' xs12 sm3 md3 lg6 xl6 xxl2>
                    <div>{{ limits?.[limit] }}</div>
                </cr-col>
            </template>
        </cr-row>
        <cr-row tag="dl">
            <template v-for="limit of otherLimits">
                <cr-col tag='dt' xs12 sm9 md9 lg6 xl6 xxl4 label="" :data-testing="`limits-section-${limit}`">
                    {{ capitalCase(limit) }}
                </cr-col>
                <cr-col tag='dd' xs12 sm3 md3 lg6 xl6 xxl2>
                    <div>{{ limits?.[limit] }}</div>
                </cr-col>
            </template>
        </cr-row>
    </read-section>
</template>

<style scoped>
dt
{
    font-weight: unset;
    
}
dd
{
    margin-bottom: unset;
}
@media (min-width: 576px)  /* Larger than xs */
{
    dd > div
    {
        /* Align numbers to the right for better readability */
        width: 3em;
        text-align: right;
    }
}
</style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import ReadSection from '../../layouts/sections/ReadSection.vue';
import { IProductLimit, ProductLimit } from "@cyber-range/cyber-range-api-subscription-client";
import { capitalCase } from 'change-case';

const props = defineProps<
{
    limits?:IProductLimit;
}>();

const { t } = useI18n();

const organizationLimits:(keyof IProductLimit)[] = [];
const courseLimits:(keyof IProductLimit)[] = [];
const otherLimits:(keyof IProductLimit)[] = [];

for(let key of Object.keys(new ProductLimit({})))
{
    let limit = key as keyof IProductLimit;

    if(limit.includes('PerOrganization'))
    {
        organizationLimits.push(limit)
    }
    else if(limit.includes('PerCourse'))
    {
        courseLimits.push(limit)
    }
    else
    {
        otherLimits.push(limit)
    }
}
</script>
