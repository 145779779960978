import { Ref } from "vue";
import Session from "../../entities/session";
import ISession from "../../interfaces/iSession";

export default class SessionStack
{
    static readonly LocalStorageKey = 'sessions';

    sessionReactivityKey: number;
    
    constructor() 
    {
        this.sessionReactivityKey = 0; // Makes localStorage I/O reactive
    }
    /**
     * Get all sessions.
     * @returns All sessions -- older ones first. 
     */
    list(): ISession[]
    {
        let items = JSON.parse(localStorage.getItem(SessionStack.LocalStorageKey) || '[]');

        let result = [];

        for(let item of items)
        {
            result.push(new Session(item));
        }

        this.sessionReactivityKey++;

        return result;
    }

    /**
     * Get the current session.
     * @returns The current session or undefined if not logged in.
     */
    peek(): ISession | undefined
    {
        let sessions = this.list();

        return sessions.length === 0 ? undefined : sessions[sessions.length - 1];
    }

    /**
     * Remove and return the current session
     * @returns the current session or undefined if there is no currently no session.
     */
    pop(): ISession | undefined
    {
        let sessions = this.list();
        let currentSession = sessions.pop();
        this.save(sessions);
        
        return currentSession;
    }
    
    /**
     * Upsert the session to localStorage
     * @param The session to upsert 
     * @returns oldSession (in case of an update) or undefined  (in case of an insert)
     */
    upsert(session:ISession): ISession | undefined
    {
        let sessions = this.list();

        for(let i=0; i<sessions.length; i++)
        {
            if(sessions[i].userId === session.userId)
            {
                let oldSession = sessions[i];
    
                sessions[i] = session;
                this.save(sessions);
                
                return oldSession;
            }
        }

        if (session.impersonator && sessions.at(-1)?.userId === session.impersonator)
        {
            sessions.push(session);
        }
        else
        {
            sessions = [session]
        }

        this.save(sessions);
        
        return undefined;
    }
    
    /**
     * Save the given sessions into localStorage
     */
    save(sessions:ISession[]): void
    {
        localStorage.setItem(SessionStack.LocalStorageKey, JSON.stringify(sessions));
        this.sessionReactivityKey++;
    }

    /**
     * Delete all sessions from localStorage
     */
    clear(): void
    {
        localStorage.removeItem(SessionStack.LocalStorageKey);
        this.sessionReactivityKey++;
    }
}
