<template>
    <cr-delete-confirmation-dialog id="deleteOrganizationApplicationDialog" @confirm="onConfirmed" :disabled="isLoading" data-testing="delete-organization-application-dialog">
        <cr-html :value="t('ORGANIZATION_APPLICATIONS_DELETE_DIALOG_MESSAGE', {applicationName: application?.name})" />
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useOrganizationStore } from '../../../stores/organizationStore';
import { storeToRefs } from 'pinia';
import { IOrganizationApplication } from '@cyber-range/cyber-range-api-organization-client';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    organizationId?: string;
    application?: IOrganizationApplication;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    if(props.organizationId && props.application)
    {
        await useOrganizationStore().deleteOrganizationApplication(props.organizationId, props.application.id);
        emit('confirm');
    }
}
</script>