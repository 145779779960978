import { defineStore } from 'pinia';
import { useAuthorizationStore } from './authorizationStore';
import { SupportTopic } from '../interfaces/supportTopic';
import { ISupportRecipient } from '../interfaces/iSupportRecipient';
import { ICourse} from '@cyber-range/cyber-range-api-user-client';
import { IOrganization} from '@cyber-range/cyber-range-api-organization-client';
import { useApiClientStore } from './apiClientStore';
import { ISupportMessage } from '../interfaces/iSupportMessage';
import { detect } from 'detect-browser';
import { capitalCase } from "change-case";

export const useSupportStore = defineStore('supportStore', 
{
    state: ()=>
    ({
        _browserData:undefined
    }),
    getters:
    {
        browserData:(state):{name:string, version:string, os:string, timeZone:string} =>
        {
            if(!state._browserData)
            {
                let browserInformation = detect() as {name:string, version:string, os:string};
                let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                state._browserData = {...browserInformation,timeZone} as {name:string, version:string, os:string, timeZone:string}|any;
            }
            return state._browserData as {name:string, version:string, os:string, timeZone:string}|any;
        } 
    },    
    actions:
    {
        async getSupportedCourses():Promise<ICourse[]>
        {   
            let allCourses:ICourse[] = [];    
            if(useAuthorizationStore().canListCourses())
            {
                const client = useApiClientStore().userApiClient;
                allCourses = await client.getAllCourses();
            }
            return allCourses;            
        },

        async getSupportedOrganizations():Promise<IOrganization[]>
        {
            const client = useApiClientStore().organizationApiClient;
            const organizations = await client.getAll();
            return organizations.filter(o=>useAuthorizationStore().canRequestOrganizationSupport(o.id));
        },

        async requestSupport(message:Partial<ISupportMessage>):Promise<ISupportRecipient[]>
        {
            const browser = this.browserData;
            let topicId = message.topicId as string;
            let subject = message.subject as string;

            let bodySegments = [`Browser: ${browser.name} (${browser.version})`]
            bodySegments.push(`Operating System: ${browser.os}`);
            bodySegments.push(`Time Zone: ${browser.timeZone}`);
            bodySegments.push(`Name: ${message.name}`);
            bodySegments.push(`Email: ${message.email}`);            
            bodySegments.push(`${capitalCase(message.topic as string)} Name: ${message.topicName}`);
            bodySegments.push(`Provider: ${message.provider}`);
            bodySegments.push(message.body as string);

            if(message.topic === SupportTopic.Course)
            {
                return await (useApiClientStore().userApiClient).requestCourseSupport(topicId, subject, bodySegments.join('\n'));
            }
            else
            {
                return await (useApiClientStore().organizationApiClient).requestOrganizationSupport(topicId, subject, bodySegments.join('\n'));
            }
        }
    }
});