<template>
    <cr-error-dialog v-model="dialogIsOpened" @confirm="onErrorConfirmed" data-testing="error-dialog">
        {{ errorMessage }}
    </cr-error-dialog>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useErrorStore } from '../stores/errorStore';
import { useSessionStore } from '../stores/sessionStore';

const { errorMessage, errorCode } = storeToRefs(useErrorStore());

const dialogIsOpened = ref(false);

watch(errorMessage, ()=>
{
    if(errorMessage.value)
    {
        dialogIsOpened.value = true;
    }
}, {immediate: true});

const isAuthError = computed(() =>
{
    return errorCode.value === 401 || errorCode.value === 403 || errorCode.value === 422 || (errorCode.value === 400 && errorMessage.value.includes('jwt expired'));
});

const onErrorConfirmed = ()=>
{
    if(isAuthError.value)
    {
        useErrorStore().reset();
        useSessionStore().logout();
    }
    else
    {
        useErrorStore().reset();
    }
}
</script>
