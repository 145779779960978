<template>
    <li>
        <cr-col xs12>
            <router-link :to="to" class="cr-text-decoration-none">
                <cr-chip :aria-label="ariaLabel" :text-color="textColor" :background-color="backgroundColor">
                    {{ label }}
                    <cr-icon v-if="isCurrentRoute" class="cr-ml2 cr-chip-close">bi-x-circle</cr-icon>
                </cr-chip>
            </router-link>
        </cr-col>
    </li>
</template>

<style scoped>
li
{
    list-style: none;
}
a
{
    color: var(--cr-primary-link);
}
</style>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

let props = defineProps<
{
    /**
     * The value of the filter query string.
     */
    filter: string;

    /**
     * The label.
     */
    label: string;
}>();

const { t } = useI18n();

const route = useRoute();
const filters  = computed(()=>route.query?.filter ? (Array.isArray(route.query.filter) ? route.query.filter : route.query.filter.split(',')) : []);
const isCurrentRoute = computed(()=>filters.value.find(q => q === props.filter));
const textColor = computed(()=>isCurrentRoute.value ? 'var(--cr-primary-text)' : 'var(--cr-primary)');
const backgroundColor = computed(()=>isCurrentRoute.value ? 'var(--cr-primary)' : 'var(--cr-primary-text)');
const ariaLabel = computed(()=>isCurrentRoute.value ? t('SEARCH_FILTER_REMOVE_LABEL', {filter:props.label}) : props.label);

const to = computed(()=>
{
    let newFilter = [...new Set(filters.value.filter(f => f !== props.filter).map(q => q?.toString()))];

    if(!isCurrentRoute.value)
    {
        newFilter.push(props.filter);
    }

    return {...route, query: {...route.query, filter: newFilter, token:undefined}};
})
</script>