import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import Config from '../../config';
import Route from "../../routers/route";
import { useAuthenticationStore } from '../../stores/authenticationStore';
import { useAuthorizationStore } from "../../stores/authorizationStore";
import { useSessionStore } from "../../stores/sessionStore";
import { useUserStore } from '../../stores/userStore';
import { IUser, User, UserRole } from '@cyber-range/cyber-range-api-user-client';

export default async function findHomeByClaims(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext, 
                                               authorizationStore = useAuthorizationStore(), sessionStore = useSessionStore(), authenticationStore = useAuthenticationStore(), userStore = useUserStore()): Promise<void>
{
    if (authorizationStore.canListCourses())
    {
        const user:IUser = User.fromUserResourceView(await userStore.fetchCurrentUser());
        if(user?.courses.length === 1 && user.courses[0].role === UserRole.Student && !(user.organizations.find(membership=> [UserRole.OrganizationAdmin,UserRole.OrganizationInstructor, UserRole.CourseAdmin, UserRole.SuperAdmin, UserRole.BillingAdmin].some(role=>membership.role === role))))
        {
            return next({ name: Route.MyCourse.name, params: {courseId:user.courses[0].id}})
        }
        return next(Route.MyCourses)
    }
    else if (authorizationStore.canManageOrganization())
    {
        return next(Route.Organizations)
    }
    else if (authorizationStore.canViewFiles())
    {
        const url = new URL("courseware", Config.HOMEPAGE_BASE_URL);
        url.searchParams.append("sso", await authenticationStore.getSsoToken());
        window.location.assign(url.href);
    }
    else
    {
        sessionStore.logout();
    }
}