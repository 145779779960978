export default {
    en: {
        AGREEMENTS_TITLE: "Agreements",
        AGREEMENTS_CONTINUE_DIALOG_TEXT: "In order to continue using the Cyber Range you must accept these agreements.",
        AGREEMENTS_CONTINUE_DIALOG_CONFIRM_TEXT: "Continue",
        AGREEMENTS_ERROR_MESSAGE: "Error: You have been logged out because you have not agreed to one or more of the required agreements.Please login and accept the agreements to use the Cyber Range.",
        AGREEMENTS_LOGOUT_TEXT: "Logout",
        AGREEMENTS_CHECKBOX_TEXT: "By checking this box you are agreeing to these terms and conditions.",
        AGREEMENTS_I_AGREE_TEXT: "I agree to the terms of this agreement.",
        AGREEMENTS_CURRENT_DIALOG_CANCEL_TEXT: "Decline",
        AGREEMENTS_CURRENT_DIALOG_CONFIRM_TEXT: "Accept"
    }
}