<template>
    <cr-sidebar-item tag="span" :to="Route.AccountApprovals">
        <cr-badge :number="pendingInvitations.length" icon="bi-person-check-fill" :alt="t('SIDEBAR_ACCOUNT_APPROVALS_NOTIFICATION', pendingInvitations.length)"/>
        <span class="sidebar-item-text" data-testing="sidebar-account-approvals-text">
            {{ t('SIDEBAR_ACCOUNT_APPROVALS') }}
        </span>
    </cr-sidebar-item>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import Route from '../../../../routers/route';
import { useInvitationStore } from '../../../../stores/invitationStore';


const { t } = useI18n(); 

const invitationStore = useInvitationStore()
const { pendingInvitations } = storeToRefs(invitationStore);

onMounted(async () =>
{
    await invitationStore.fetchPendingApproval();
});
</script>