<template>
    <dt :class="class">
        <cr-icon class="cr-mr3" :alt="altTitle" :title="altTitle" :data-testing="iconDataTesting" :color="iconColor">{{ icon }}</cr-icon>
    </dt>
    <dd :class="class" :data-testing="valueDataTesting">
        <span v-if="label" class='cr-font-bold cr-mr1' :data-testing="labelDataTesting">
            {{ label }}
        </span>
        <slot />
    </dd>
</template>

<style scoped>
dt
{
    font-weight: unset;
    display: unset;
    
}
dd
{
    margin-bottom: unset;
    display: unset;
}
</style>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<
{
    /**
     * The item's label.
     */
    label?:string,

    /**
     * A alternate text representative.
     * If not given, props.label value will be used.
     */
     alt?: string, 
      
    /**
     * The item's icon.
     */
    icon:string,
      
      /**
       * The color of the item's icon.
       */
    iconColor?:string,

    /**
     * data-testing for the element and its children.
     */
    dataTesting?:string,
      
    /**
     * HTML class attribute.
     * This is required because this component is a fragment.
     * It needs to be a fragment so that <dt> and <dd> are directly under <dl>.
     * If this prop is given, it will apply to <dt> and <dd>.
     */
    class?:string,
}>()

const dataTesting = props.dataTesting;
const iconDataTesting = dataTesting ? `${dataTesting}-icon` : undefined;
const labelDataTesting = dataTesting ? `${dataTesting}-label` : undefined;
const valueDataTesting = dataTesting ? `${dataTesting}-value` : undefined;

const altTitle = computed(()=>props.label ? undefined : (props.alt || props.label))
</script>