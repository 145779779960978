<template>
    <div :data-testing="dataTesting">
        <cr-select v-model="option" :items="options" :label="t('INSTRUCTOR_PICKER_LABEL')" :disabled="disabled" :data-testing="selectDataTesting"/>

        <template v-if="option === InstructorOption.ExistingInstructor">
            <cr-autocomplete v-model="existingInstructorId" :items="instructors" :label="t('INSTRUCTOR_PICKER_EXISTING')" :disabled="disabled || isFetchingInstructor" required :data-testing="existingInstructorDataTesting" />
        </template>

        <template v-if="option === InstructorOption.NewInstructor">
            <cr-text-field v-model="newInstructorName" :label="t('INSTRUCTOR_PICKER_NEW_NAME')" type="text" :disabled="disabled" required :max-length="100" :data-testing="newInstructorNameDataTesting" />
            <cr-text-field v-model="newInstructorEmail" :label="t('INSTRUCTOR_PICKER_NEW_EMAIL')" type="email" :disabled="disabled" required :max-length="100" :data-testing="newInstructorEmailDataTesting" />
        </template>
    </div>
</template>

<script lang="ts" setup>
import { IUser, UserFilter, UserRole, UserStatus } from '@cyber-range/cyber-range-api-user-client';
import { computed, ref, watch} from 'vue';
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useSessionStore } from '../../stores/sessionStore';
import { useUserStore } from '../../stores/userStore';

let props = defineProps<{
    organizationId:string|undefined,
    disabled?: boolean,
    dataTesting?: string
}>();

const emit = defineEmits<{
    (e:'update:modelValue', instructor:{id: string}|{name:string, email:string}|undefined): void,
}>();

enum InstructorOption {
    Me = 'me',
    ExistingInstructor = 'existinginstructor',
    NewInstructor = 'newinstructor'
};

const { t } = useI18n();
const apiClientStore = useApiClientStore();

const option = ref<InstructorOption>();
const instructors = ref<IUser[]>([]);
const existingInstructorId = ref<string>();
const newInstructorName = ref<string>();
const newInstructorEmail = ref<string>();
const isFetchingInstructor = ref<boolean>(false);
const dataTesting = props.dataTesting || 'instructor-picker';
const selectDataTesting = `${dataTesting}-select`;
const existingInstructorDataTesting = `${dataTesting}-existing-instructor`;
const newInstructorNameDataTesting = `${dataTesting}-new-instructor-name`;
const newInstructorEmailDataTesting = `${dataTesting}-new-instructor-email`;

const options = [
    {name: t('INSTRUCTOR_PICKER_ME'), id: InstructorOption.Me},
    {name: t('INSTRUCTOR_PICKER_EXISTING'), id: InstructorOption.ExistingInstructor},
    {name: t('INSTRUCTOR_PICKER_NEW'), id: InstructorOption.NewInstructor},
];

const disabled = computed(()=>
{
    return !props.organizationId || apiClientStore.isLoading || props.disabled;
});

watch(option, () =>
{
    if(option.value === InstructorOption.Me)
    {
        const identity = useSessionStore().identity;
        
        emit('update:modelValue', { id: identity?.id || ''});
    }
    else
    {
        // Clear the previous selection
        existingInstructorId.value = undefined;
        newInstructorName.value = undefined;
        newInstructorEmail.value = undefined;
        emit('update:modelValue', undefined);
    }
});

watch(existingInstructorId, ()=>
{
    if(existingInstructorId.value)
    {
        emit('update:modelValue', { id: existingInstructorId.value });
    }
});

const emitNewInstructorInfo = () =>
{
    if(newInstructorName.value && newInstructorEmail.value)
    {
        emit('update:modelValue', { name: newInstructorName.value, email: newInstructorEmail.value });
    }
}

watch(newInstructorName, emitNewInstructorInfo);
watch(newInstructorEmail, emitNewInstructorInfo);

const fetchInstructors = async () =>
{
    isFetchingInstructor.value = true;

    const filter = new UserFilter({organizationId: props.organizationId, roles: [UserRole.OrganizationInstructor], status: UserStatus.Ready });
    try
    {
        const users = await useUserStore().listAllUsers(filter, { background: true });
        instructors.value = users;

        // If selected existing instructor is not present in the newly selected list, then unselect
        if (existingInstructorId.value && !users.find(i => i.id === existingInstructorId.value))
        {
            existingInstructorId.value = undefined;
            emit('update:modelValue', undefined);
        }
    }
    finally
    {
        isFetchingInstructor.value = false;
    }
}

watch(()=>props.organizationId, async ()=>
{
    if(props.organizationId)
    {
        await fetchInstructors();
    }
}, {immediate: true});

</script>