<template>
    <cr-delete-confirmation-dialog id="deleteSuperAdminDialog" @confirm="onConfirmed" :disabled="isLoading" data-testing="delete-super-admin-dialog">
        <cr-html :value="t('SUPER_ADMIN_DELETE_USER_DIALOG_MESSAGE', {userName: user?.name})" />
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { IOrganizationUser, UserRole } from '@cyber-range/cyber-range-api-user-client';
import { useUserStore } from '../../../stores/userStore';
import { useEntitlementStore } from '../../../stores/entitlementStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    user?: IOrganizationUser;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    if(props.user)
    {
        const roles = props.user.roles.filter(role => role !== UserRole.SuperAdmin);

        await useUserStore().updateUserRoles(props.user.id, roles);
        await useEntitlementStore().invalidateUserClaimsCache(props.user.id);

        emit('confirm');
    }
}
</script>