<template>
    <cr-app class="cr-app">
        <router-view :key="fullPath"/>
    </cr-app>
</template>

<style>
a
{
    text-decoration: none !important;
    color: var(--cr-text);
}
a:hover
{
    color: var(--cr-text);
}
.cr-external-link
{
    color: var(--cr-primary-link);
}
.cr-checkbox-label
{
    padding-top: var(--cr-check-box-label-top-padding); /* support for adapting --bs-form-check spacing to work with console font family */
}
.cr-radio-label
{
    padding-top: var(--cr-radio-button-label-top-padding); /* support for adapting --bs-form-check spacing to work with console font family */
}
</style>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router';
const route = useRoute();
const fullPath = computed(()=> 
{
    return route.fullPath;
});
</script>

<style scoped>
.cr-app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
</style>
