import { defineStore } from 'pinia';
import { IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import { useApiClientStore } from './apiClientStore';
import { BusinessUnitFilter, IBusinessUnit, IBusinessUnitFilter } from '@cyber-range/cyber-range-api-business-unit-client';

export const useBusinessUnitStore = defineStore('businessUnitStore', 
{
    state: () => 
    ({
        businessUnitsById: <Map<string,IBusinessUnit>> new Map<string,IBusinessUnit>(),
        businessUnitsByOrganizationId: <Map<string,IBusinessUnit[]>> new Map<string,IBusinessUnit[]>()
    }),

    actions: 
    {
        async getBusinessUnit(id:string): Promise<IBusinessUnit>
        {
            let businessUnitApiClient = useApiClientStore().businessUnitApiClient;

            return businessUnitApiClient.getOne(id);
        },

        async listBusinessUnits(filter?:IBusinessUnitFilter, options?: {background?:boolean}): Promise<IApiPageResponse<IBusinessUnit>>
        {
            let businessUnitApiClient = options?.background ? useApiClientStore().backgroundBusinessUnitApiClient : useApiClientStore().businessUnitApiClient;

            return await businessUnitApiClient.get(filter);
        },

        async fetchBusinessUnits(): Promise<void>
        {
            let resultById = new Map<string, IBusinessUnit>();
            let resultByOrganizationId = new Map<string, IBusinessUnit[]>();

            let filter = new BusinessUnitFilter({limit:Number.MAX_SAFE_INTEGER});

            do
            {
                let page = await this.listBusinessUnits(filter);
                filter.token = page.nextPageToken;

                for(let businessUnit of page.items)
                {
                    //By Id
                    if(businessUnit.id)
                    {
                        resultById.set(businessUnit.id, businessUnit);
                    }

                    //By OrganizationId
                    if(!resultByOrganizationId.has(businessUnit.organizationId))
                    {
                        resultByOrganizationId.set(businessUnit.organizationId, []);
                    }
                    resultByOrganizationId.get(businessUnit.organizationId)?.push(businessUnit);
                }
            }
            while(filter.token)

            this.businessUnitsByOrganizationId = resultByOrganizationId;
            this.businessUnitsById = resultById;
        },

        async create(businessUnit: IBusinessUnit): Promise<string>
        {
            let businessUnitApiClient = useApiClientStore().businessUnitApiClient;

            const businessUnitId = await businessUnitApiClient.create(businessUnit);

            this.$reset();

            return businessUnitId;
        },

        async update(businessUnit: IBusinessUnit): Promise<void>
        {
            let businessUnitApiClient = useApiClientStore().businessUnitApiClient;

            await businessUnitApiClient.update(businessUnit);

            this.$reset();
        },

        async delete(businessUnitId: string): Promise<void>
        {
            let businessUnitApiClient = useApiClientStore().businessUnitApiClient;

            await businessUnitApiClient.delete(businessUnitId);

            this.$reset();
        }
    }
});