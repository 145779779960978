export default {
    en: {
        BUSINESS_UNITS_BUSINESS_UNIT_EDIT_BUSINESS_UNIT_BUTTON: "Edit Business Unit",
        BUSINESS_UNITS_BUSINESS_UNITS: "Business Units",
        BUSINESS_UNITS_CREATE_BUSINESS_UNIT_BUTTON: "Create a Business Unit",
        BUSINESS_UNITS_CREATE_BUSINESS_UNIT_PAGE_TITLE: "Create a Business Unit",
        BUSINESS_UNITS_EDIT_BUSINESS_UNIT_PAGE_TITLE: "Edit a Business Unit",
        BUSINESS_UNITS_NO_ITEMS: "No business units match your search criteria.",
        BUSINESS_UNIT_CATEGORY: 'Category',
        BUSINESS_UNIT_REGION: 'Region',
        BUSINESS_UNIT_CREATION: 'Creation',

        // Business Unit Picker
        BUSINESS_UNIT_PICKER_SECTION_LABEL: 'Select Business Unit',
        BUSINESS_UNIT_PICKER_SELECTING_RADIO_ITEM: 'Existing Business Units',
        BUSINESS_UNIT_PICKER_CREATING_RADIO_ITEM: 'New Business Unit',
        BUSINESS_UNIT_PICKER_SELECT_LABEL: 'Business Unit',

        // Business unit form
        BUSINESS_UNIT_FORM_INFO_SECTION: 'Business Unit Information',
        BUSINESS_UNIT_FORM_INFO_SECTION_BUSINESS_UNIT_NAME: 'Name',
        BUSINESS_UNIT_FORM_INFO_SECTION_BUSINESS_UNIT_REGION: 'Region',
        BUSINESS_UNIT_FORM_INFO_SECTION_BUSINESS_UNIT_CATEGORY: 'Category'
    }
}