import { defineStore } from 'pinia';
import jwtDecode from "jwt-decode";
import Identity from '../entities/identity';
import IIdentity from '../interfaces/iIdentity';
import { useTimerStore } from './timerStore';
import { useAuthenticationStore } from './authenticationStore';
import SessionStack from './sessions/sessionStack';
import ISession from '../interfaces/iSession';
import { useEntitlementStore } from './entitlementStore';
import { useApiClientStore } from './apiClientStore';
import { useAuthorizationStore } from './authorizationStore';
import Config from '../config';
import { useAnalyticStore } from './analyticStore';

export const useSessionStore = defineStore('sessionStore', 
{
    state: () => {
        return {
            _currentSession: undefined as ISession | undefined,
            sessionExpirationTimeInMs: undefined as number | undefined,
            identity: undefined as IIdentity | undefined,
            sessions: new SessionStack(),
            sessionIsExpiring: false
        }
    },

    getters: 
    {
        isLoggedIn(state): boolean
        {
            return !!state.sessionExpirationTimeInMs && new Date().getTime() < state.sessionExpirationTimeInMs;
        },
        
        isImpersonating(state): boolean
        {
            return !!state._currentSession?.impersonator;
        },

        session(): ISession | undefined
        {
            if(this._currentSession)
            {
                return this._currentSession;
            }

            return this._currentSession = this.sessions.peek();
        },

        token(state): string | undefined
        {
            return this.session?.token;
        },

        isSesionExpiring(): boolean
        {
            return this.sessionIsExpiring;
        }
    },

    actions:
    {
        /**
         * Start a new session with the given token.
         * @param token A token for the new session.
         * @returns true for success, false otherwise.
         */
        async use(token:string): Promise<boolean>
        {
            const jwt = jwtDecode(token) as any;

            let tokenExpirationTimeInMs = ((Number(jwt['exp']) || 0) - 30) * 1000; // 30 seconds before the actual expiration

            if(tokenExpirationTimeInMs < new Date().getTime())
            {
                this.logout();

                return false;
            }
            
            let timeInMsToShowSessionExpiringWarning = ((Number(jwt['notAfter']) || 0) - 90) * 1000; // 90 seconds before the final expiration
            
            let msToRenew = tokenExpirationTimeInMs - new Date().getTime();
            let msToWarn = timeInMsToShowSessionExpiringWarning - new Date().getTime();

            let session = { 
                            token: token,
                            returnPath: '/', 
                            userId: jwt['sub'], 
                            userName: jwt['displayName'],
                            impersonator: jwt['impersonator'],
                            impersonatorName: jwt['impersonatorName'],
                            timerIds: [useTimerStore().schedule(useAuthenticationStore().authenticate, ['vcr', token], msToRenew, false)]
                         };
                         
            session.timerIds.push(useTimerStore().schedule(()=>this.sessionIsExpiring=true, [], msToWarn, false));

            let oldSession = this.sessions.upsert(session);

            // Reset the following stores to force future API requests to use a new auth token.
            useApiClientStore().$reset();
            useSessionStore().$reset();

            if(oldSession?.timerIds)
            {
                // The session already exists.  
                // Clear the old session's timers.
                oldSession.timerIds.forEach(timerId => useTimerStore().unschedule(timerId));
            }
            else
            {
                //New session
                this._currentSession = session;
                this.sessionExpirationTimeInMs =  tokenExpirationTimeInMs
                this.identity = Identity.fromJwt(jwt);
            }

            if(session.impersonator)
            {
                useAnalyticStore().trackImpersonationBegin(session.userId);
            }

            // Change tracking's user ID
            useAnalyticStore().setUserId(session);

            await useEntitlementStore().fetchClaims();

            return true;
        },

        /**
         * Resume the session based on what in the localStorage.
         * This is to support a browser refresh.
         * @returns true for success, automaticallty logout otherwise.
         */
        async resume(): Promise<boolean>
        {
            let sessions = this.sessions.list();

            if(sessions.length === 0)
            {
                return false;
            }

            useTimerStore().unscheduleAll();
            this.sessions.clear();

            for(let session of sessions)
            {
                if(!session.token || await this.use(session.token) === false)
                {
                    return false;
                }
            }
            
            return true;
        },

        /**
         * Quit the current session and switch back to the previous session.
         */
        async quit(): Promise<boolean>
        {
            let currentSession = this.sessions.pop();

            if(currentSession?.timerIds)
            {
                currentSession.timerIds.forEach(timerId => useTimerStore().unschedule(timerId));
            }
            
            if(currentSession?.impersonator && currentSession?.userId)
            {
                useAnalyticStore().trackImpersonationEnd(currentSession?.userId);
            }

            return await this.resume();
        },
        
        /**
         * Logout from all sessions.
         */
        logout(): void
        {
            let targetLocation = useAuthorizationStore().canNavigateOutsideCourse() ? '/' : new URL('/logout', Config.LOGIN_UI_BASE_URL);

            this._currentSession = undefined;
            this.identity = undefined;
            this.sessionExpirationTimeInMs = undefined;

            this.sessions.clear();

            useTimerStore().unscheduleAll();
            
            window.location.assign(targetLocation);
        }
    }
});
