<template>
    <cr-confirmation-dialog :title="t('EXERCISE_CREDENTIALS_DIALOG_TITLE')" :cancel="false" :confirm-text="t('EXERCISE_CREDENTIALS_DIALOG_CONFIRM_TEXT')" persistent @confirm="onClose">
        <div class="credential" v-for="credential in credentials" data-testing="exercise-credential-dialog-credential">
            <h3>{{ credential.name }}</h3>
            <dl class="cr-ml3">
                <template v-if="credential.username">
                    <dt>{{ t('EXERCISE_CREDENTIALS_DIALOG_USERNAME') }}: </dt>
                    <dd>
                        <cr-selectable-text :value="credential.username" />
                        <cr-clipboard-copy :value="credential.username" :copy-label="t('EXERCISE_CREDENTIALS_DIALOG_COPY_USERNAME', { name: credential.name })" />
                    </dd>
                    <br v-if="credential.password"/>
                </template>

                <template v-if="credential.password">
                    <dt>{{ t('EXERCISE_CREDENTIALS_DIALOG_PASSWORD') }}: </dt>
                    <dd>
                        <span v-if="shownPasswords.has(credential.id)">
                            <cr-selectable-text :value="credential.password" />
                        </span>
                        <cr-button v-else text @click="shownPasswords.add(credential.id)" label="show" />

                        <cr-clipboard-copy :value="credential.password" :copy-label="t('EXERCISE_CREDENTIALS_DIALOG_COPY_PASSWORD', { name: credential.name })" />
                    </dd>
                </template>
            </dl>
        </div>
    </cr-confirmation-dialog>
</template>

<style scoped>
dt, dd {
    display: inline;
}
.credential:not(:first-of-type) {
    border-top: 1px solid black;
    padding-top: .75rem;
}
</style>

<script lang="ts" setup>
import { AccessPointFeatureName, IExercise } from '@cyber-range/cyber-range-api-exercise-client';
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
    exercise: IExercise
}>();

const credentials = computed(() => props.exercise.accessPoints.map(ap => ({
    id: ap.id,
    name: ap.name,
    username: ap.features.find(f => f.name === AccessPointFeatureName.Username)?.value,
    password: ap.features.find(f => f.name === AccessPointFeatureName.Password)?.value
})).filter(c => c.username || c.password));

const shownPasswords = reactive(new Set<string>());
const onClose = () => shownPasswords.clear();
</script>
