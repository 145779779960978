<template>
    <span :class="hintWrapperClasses" data-testing="hint-target-container">
        <slot/>
    </span>
    <span v-if="showHint" class="hint-body cr-mt2" :id="hintBodyId" data-testing="hint-dialog">
        <cr-icon medium class="shortened" data-testing="hint-icon">bi-arrow-up-short </cr-icon>
        <span v-for="message in messages" data-testing="hint-message">
            {{ message }}
        </span>
        <cr-button @click="onClicked" class="close-button cr-mb2 cr-mt1" data-testing="hint-dialog-close-button"> {{t('HINTS_CLOSE')}} </cr-button>
    </span>
</template>

<style scoped>
.glowing 
{
  animation: glow 2s 5 alternate;
}

@keyframes glow
{
  from
  {
    box-shadow: 0 0 10px var(--cr-blue-accent-1),
                0 0 20px var(--cr-blue-accent-2),
                0 0 30px var(--cr-blue-accent-3),
                0 0 40px var(--cr-blue-accent-4);
  }
  to
  {
    box-shadow: none;
  }
}

.hint-body
{
  position: absolute;
  display: grid;
  place-items: center;
  vertical-align: center;
  width: 16rem;
  background-color: #1f6ff5;
  color: var(--cr-white);
  text-align: center;
  z-index: 9999;
}
.close-button
{
    color:  var(--cr-black);
    background-color: #1f6ff5;
    border: none;
    font-weight: bold;
}
.close-button:hover
{
    outline: solid 1px var(--cr-white);
}
.shortened
{
    max-height: 2rem;
}
</style>

<script setup lang="ts">
import { ref, watch, onMounted, onBeforeUnmount, computed} from 'vue';
import { useAnnouncementStore } from '@cyber-range/cyber-range-lib-ui';
import { useI18n } from 'vue-i18n';

const props = defineProps<
{
    /**
     * display the hint if this is value is true
     * @default false
     */
    hintIf: boolean;
    /**
     * how long to wait in ms before showing the hint
     * @default 2500
     */
    delay?: number;
    /**
     * sections of text to be used in the hint dialog
     */
    messages: string[];
    /**
     * for programtic link between the hint and the target
    */
   hintBodyId:string;
}>();

const { t } = useI18n();
const announcementStore = useAnnouncementStore();

const hintTargetClass = ref<string>('');
const hintWrapperClasses = computed(() =>
{
    const classes = ["hint-wrapper"];
    if (hintTargetClass.value)
    {
        classes.push(hintTargetClass.value);
    }
    return classes;
})
const showHint = ref<boolean>(false);

const closeHint = ()=>
{
    showHint.value = false;
    hintTargetClass.value = '';
}

const onClicked = ()=>
{
   closeHint();
}

watch(() => props.hintIf, () =>
{
    if(props.hintIf === true)
    {
        setTimeout(()=>
        {
            if(props.hintIf)
            {
                showHint.value = true;
                hintTargetClass.value = 'glowing';
                announcementStore.announce(props.messages.join(' '));
            }            
        },
        props.delay ?? 2500);
    }
    else
    {
        closeHint();
    }
}, { immediate: true });

const onKeydown = (event:KeyboardEvent)=>
{
    if(event.key === 'Escape')
    {
        closeHint();
    }
}

onMounted(async () => 
{
    window.addEventListener('keydown', onKeydown);
});

onBeforeUnmount(()=>
{
    window.removeEventListener('keydown', onKeydown);
})
</script>