import { ExerciseFilter, IExercise } from "@cyber-range/cyber-range-api-exercise-client";
import { IExerciseGroup, TeamFilter } from "@cyber-range/cyber-range-api-user-client";
import { Ref, computed, ref, watch } from "vue";
import { useExerciseStore } from "../stores/exerciseStore";
import { useSessionStore } from "../stores/sessionStore";
import { useTeamStore } from "../stores/teamStore";

export function useMyExerciseGroupExercise(exerciseGroup: Ref<IExerciseGroup|undefined>)
{
    const sessionStore = useSessionStore();
    const exerciseStore = useExerciseStore();
    const teamStore = useTeamStore();

    const courseId = computed(() => exerciseGroup.value?.courseId);
    const myTeamId = ref<string>('');
    const myExercise = ref<IExercise>();
    const myExerciseInitialized = ref<boolean>(false);

    watch(myTeamId, async () =>
    {
        if (exerciseGroup.value && myTeamId.value)
        {
            const exercises = await exerciseStore.getAll(new ExerciseFilter({ courseId: courseId.value, groupId: exerciseGroup.value.id, ownerId: myTeamId.value }), { background: true });
            myExercise.value = exercises[0];
        }
        myExerciseInitialized.value = true;
    });

    watch(courseId, async () =>
    {
        if (!courseId.value)
        {
            // User won't have a teamId if exerciseGroup is unset
            myTeamId.value = '';
            // Don't change myExerciseInitialized because this runs on first load before fetching
            return;
        };

        if (exerciseGroup.value!.isCtf)
        {
            // No teams for CTF. We've determined the user does not have an exercise, so initialization complete.
            myTeamId.value = '';
            myExerciseInitialized.value = true;
            return;
        }

        const myUserId = sessionStore.session?.userId;
        if (!myUserId) return; // guard for subsequent list

        const teams = await teamStore.list(courseId.value, new TeamFilter({ userId: myUserId }));
        if (teams?.items?.[0]?.id && teams.items[0].id !== myTeamId.value)
        {
            myTeamId.value = teams.items[0].id;
        }
        else
        {
            myExerciseInitialized.value = true;
        }
    }, { immediate: true });

    return { myExercise, myExerciseInitialized };
}
