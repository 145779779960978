import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import Route from '../route';

const urlIsNotUnderCourses = new RegExp(/^(?!\/courses|\/my-courses)/);
const urlStartWithCoursesRegex = new RegExp(/^\/courses\/?/);
const urlStartWithMyCoursesRegex = new RegExp(/^\/my\-courses\/?/);

export default async function whoseCourseUrl(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void>
{
    if(to.name === Route.Courses.name || to.name === Route.MyCourses.name || urlIsNotUnderCourses.test(to.path))
    {
        return next();
    }
    
    if(urlStartWithMyCoursesRegex.test(from.path) && urlStartWithCoursesRegex.test(to.path))
    {
        let toRoute = Object.values(Route).find(route => route.name === to.name);
        
        return next({name: `${toRoute.mine.name}`, query: to.query, params: to.params, hash: to.hash});
    }
    else
    {
        return next();
    }
}