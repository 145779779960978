import { IProduct, IProductLimit } from "@cyber-range/cyber-range-api-subscription-client";
import { IFeature } from "../interfaces/iFeature";
import { FeatureCategory } from "../interfaces/featureCategory";

export class Feature implements IFeature
{
    addonId: string|undefined;
    unitPrice: number|undefined;
    name: string|undefined;
    categories: FeatureCategory[];
    limits: IProductLimit|undefined;
    
    constructor(feature: Partial<IFeature>)
    {
        this.addonId = feature.addonId;
        this.unitPrice = feature.unitPrice;
        this.name = feature.name;
        this.categories = feature.categories || this.generateCategories();
        this.limits = feature.limits;
    }

    private generateCategories()
    {
        let categories = [];

        if (this.addonId?.includes("enrollment"))
        {
            categories.push(FeatureCategory.Enrollment);
        }

        if(this.addonId?.startsWith('competition_'))
        {
            categories.push(FeatureCategory.Ctf);
            
        }

        // By addOnId
        switch(this.addonId)
        {
            case 'cloud_usage_product_id':
                categories.push(FeatureCategory.CloudUsage);
                break;

            case 'course_enrollment_product_id':
            case 'course_enrollment_federal_rate_product_id':
            case 'capped_usage_per_enrollment_cost_13_product_id':
            case 'capped_usage_per_enrollment_cost_12_product_id':
            case 'course_enrollment_pro_fixed_rate_product_id':
            case 'course_enrollment_basic_fixed_rate_product_id':
            case 'course_enrollment_non_persistent_flex_rate_product_id':
            case 'course_enrollment_teachcyber_flat_rate_plan_product_id':
            case 'course_enrollment_non_persistent_teachcyber_flat_rate_plan_product_id':
            case 'course_enrollment_basic_flat_rate_plan_product_id':
            case 'course_enrollment_basic_non_persistent_flat_rate_plan_product_id':
            case 'course_enrollment_pro_flat_rate_plan_product_id':
            
                categories.push(FeatureCategory.Enrollment);
                break;

            case 'competition_basic_product_id':
            case 'competition_premium_product_id':
            case 'feature_allow_public_registration_product_id':
            case 'feature_allow_public_registration_direct_login_product_id':
            case 'feature_allow_integrated_environments_product_id':
        
                categories.push(FeatureCategory.Ctf);
                break;

            case 'feature_snapshot_product_id':
            case 'feature_start_join_product_id':
            case 'feature_persistent_environments_product_id':
            case 'feature_non_persistent_environments_product_id':
            case 'feature_user_direct_pay':
            case 'feature_anonymous_student_and_ta_product_id':

                categories.push(FeatureCategory.ExerciseArea);
                break;

            case 'catalog_teachcyber_product_id':
            case 'catalog_global_product_id':
            case 'catalog_advanced_networking_product_id':

                categories.push(FeatureCategory.Catalog);
                break;
            
            case "feature_courseware_product_id":
        
                categories.push(FeatureCategory.Courseware);
                break;

            default:
                categories.push(FeatureCategory.Others);
                break;
        }
        
        return [...new Set(categories)];
    }

    static fromAddon(product: IProduct)
    {
        return new Feature
        (
            {
                addonId: product.id,
                unitPrice: product.unitPrice,
                name: product.name,
                limits: product.limits
            }
        )
    }
}