<template>
    <a href="#main" class="skip-to-main-link cr-p2" data-testing="page-skip-to-main">{{ t('PAGE_SKIP_TO_MAIN_CONTENT') }}</a>
    <main>
        <cr-container>
            <cr-row>
                <cr-col class="main-column cr-plr6 cr-pt4 cr-xs-p4" id="main">
                    {{ t('RELEASE_PILOT_TESTING_MESSAGE') }}
                    <cr-button text @click="onButtonClicked">{{ t('RELEASE_PILOT_TESTING_RETURN_BUTTON') }}</cr-button>
                </cr-col>
            </cr-row>
            <errors-dialog />
            <impersonation-bar/>
        </cr-container>
    </main>
</template>

<style scoped>
.skip-to-main-link
{
    position: absolute;
    height: 2rem;
    left: 50%;
    background: var(--cr-primary);
    color: var(--cr-primary-text);
    transform: translateY(-100%);
    transition: transform 0.4s;
}
.skip-to-main-link:focus
{
    transform: translateY(0%);
}
.main-column
{
    background-color: var(--cr-main-background-color);
    height: 100vh;
    width: 100vw;
}
.cr-button {
    vertical-align: unset;
}
</style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import Config from '../../config';
import ErrorsDialog from '../ErrorsDialog.vue';
import ImpersonationBar from '../impersonation/ImpersonationBar.vue';
import { useAuthenticationStore } from '../../stores/authenticationStore';

const { t } = useI18n();


async function onButtonClicked()
{
    const location = new URL(Config.PORTAL_UI_BASE_URL);
    document.cookie = `cr-new-ui=false; Domain=${location.hostname}; Path=/; Max-Age=31536000; SameSite=None; Secure;`;
    location.searchParams.append('s', await useAuthenticationStore().getSsoToken());
    window.location.assign(location.href);
}
</script>
