import { defineStore } from 'pinia';
import { useApiClientStore } from './apiClientStore';
import { AgreementFilter, AgreementStatus, AgreementType, IAgreement, IAgreementFilter } from '@cyber-range/cyber-range-api-agreement-client';

export const useAgreementStore = defineStore('agreementStore', 
{
    actions:
    {
        async fetchMyPendingAgreements(): Promise<IAgreement[]>
        {
            const agreementClient = useApiClientStore().backgroundAgreementApiClient;
        
            const pendingAgreements:IAgreement[] = [];
            const filter:IAgreementFilter = AgreementFilter.fromJson({
                status: AgreementStatus.Pending,
                type: AgreementType.ExerciseArea
            });

            do{
                let page = await agreementClient.getMyAgreements(filter);
                filter.token = page.nextPageToken;
                pendingAgreements.push(...page.items);
            }
            while(filter?.token);
            return await Promise.all(pendingAgreements.map((a:IAgreement)=> agreementClient.getOne(a.agreementId)));
        },

        async accept(agreementId:string):Promise<void>
        {
            return await (useApiClientStore().agreementApiClient).accept('',agreementId);
        }
    }
});