import { defineStore } from "pinia";
import { useApiClientStore } from "./apiClientStore";
import { ISchedule, IScheduleFilter, IScheduleHistory, IScheduleHistoryFilter } from "@cyber-range/cyber-range-api-sync-client";
import { IApiPageResponse } from "@cyber-range/cyber-range-api-client";

export const useSyncStore = defineStore('syncStore', 
{
    actions: 
    {
        async listScheduleHistories(scheduleId: string, filter?: IScheduleHistoryFilter, options?: {background?: boolean}): Promise<IApiPageResponse<IScheduleHistory>>
        {
            let syncApiClient = options?.background ? useApiClientStore().backgroundSyncApiClient : useApiClientStore().syncApiClient;

            return await syncApiClient.getScheduleHistory(scheduleId, filter);
        },

        async getOneSchedule(scheduleId: string, options?: {background?: boolean}): Promise<ISchedule>
        {
            let syncApiClient = options?.background ? useApiClientStore().backgroundSyncApiClient : useApiClientStore().syncApiClient;

            return await syncApiClient.getOne(scheduleId);
        },

        async getOneScheduleHistory(scheduleId: string, historyId: string, options?: {background?: boolean}): Promise<IScheduleHistory>
        {
            let syncApiClient = options?.background ? useApiClientStore().backgroundSyncApiClient : useApiClientStore().syncApiClient;

            return await syncApiClient.getOneScheduleHistory(scheduleId, historyId);
        },

        async runSchedule(scheduleId: string, options?: {background?: boolean}): Promise<void>
        {
            let syncApiClient = options?.background ? useApiClientStore().backgroundSyncApiClient : useApiClientStore().syncApiClient;

            return await syncApiClient.run(scheduleId);
        }
    }
});
