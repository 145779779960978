<template>
    <cr-table :items="page.items" :headers="tableHeaders" :actionable="!userIsMe" @sort="onSort" @suggested-number-of-items="onSuggestedNumberOfItems" class="cr-mb5">
        <template v-slot:action="{item}">
            <cr-table-action-item v-if="item.type === ResourceType.Course && !!item.editable" :item="item" @click="onEditCourseMembershipRoleClicked" icon="bi-pencil" data-testing="profile-edit-course-membership-role-action">
                {{ t('PROFILE_EDIT_COURSE_MEMBERSHIP_ROLE_BUTTON') }}
            </cr-table-action-item>
            <cr-table-action-item v-if="item.type === ResourceType.Course && !!item.deletable" :item="item" @click="onDeleteCourseMembershipClicked" icon="bi-trash3" data-testing="profile-delete-course-membership-action">
                {{ t('PROFILE_DELETE_COURSE_MEMBERSHIP_BUTTON') }}
            </cr-table-action-item>
            <cr-table-action-item v-if="item.type === ResourceType.Organization && !!item.editable" :item="item" @click="onEditOrganizationMembershipRolesClicked" icon="bi-pencil" data-testing="profile-edit-organization-membership-roles-action">
                {{ t('PROFILE_EDIT_ORGANIZATION_MEMBERSHIP_ROLES_BUTTON') }}
            </cr-table-action-item>
            <cr-table-action-item v-if="item.type === ResourceType.Organization && !!item.deletable" :item="item" @click="onDeleteOrganizationMembershipClicked" icon="bi-trash3" data-testing="profile-delete-organization-membership-action">
                {{ t('PROFILE_DELETE_ORGANIZATION_MEMBERSHIP_BUTTON') }}
            </cr-table-action-item>
        </template>
        <template #footer>
            <delete-course-membership-dialog v-model="showDeleteCourseMembershipDialog" :user="user" :membership="selectedMembership" @confirm="onMembershipDeleteDialogConfirmed"/>
            <edit-course-membership-role-dialog v-model="showEditCourseMembershipRoleDialog" :user="user" :membership="selectedMembership" @confirm="onMembershipUpdateDialogConfirmed"/>
            <delete-organization-membership-dialog v-model="showDeleteOrganizationMembershipDialog" :user="user" :membership="selectedMembership" @confirm="onMembershipDeleteDialogConfirmed"/>
            <edit-organization-membership-roles-dialog v-model="showEditOrganizationMembershipRolesDialog" :user="user" :membership="selectedMembership" @confirm="onMembershipUpdateDialogConfirmed"/>
        </template>
        <template #name="{value, item}">
            <router-link v-if="item.to" :to="item.to">
                {{ value }}
            </router-link>
            <template v-else>
                {{ value }}
            </template>
        </template>
        <template #roles="{item}">
            {{ [...item.roles].map(r=> toDisplayEnumName(UserRole, r)).join(", ") }}
        </template>
    </cr-table>
    <cr-pagination :previous="!!page?.prevPageToken" :first="true" :next="!!page?.nextPageToken" @first="onLoadFirstPage" @previous="onLoadPreviousPage" @next="onLoadNextPage" />
</template>

<style scoped>
:deep(.cr-table-cell a) {
    color: var(--cr-primary-link);
}
</style>

<script setup lang="ts">
import { SortOrder } from '@cyber-range/cyber-range-api-client';
import { IUser, UserRole, ResourceType } from '@cyber-range/cyber-range-api-user-client';
import { TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, onMounted, ref } from 'vue';
import { useArtificialPaging } from '../../composables/useArtificiailPaging';
import { useEnum } from '../../composables/useEnum';
import Route from '../../routers/route';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useCourseStore } from '../../stores/courseStore';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '../../stores/userStore';
import IProfileMembership from '../../interfaces/iProfileMembership';
import DeleteCourseMembershipDialog from './dialogs/DeleteCourseMembershipDialog.vue';
import EditCourseMembershipRoleDialog from './dialogs/EditCourseMembershipRoleDialog.vue';
import DeleteOrganizationMembershipDialog from './dialogs/DeleteOrganizationMembershipDialog.vue';
import EditOrganizationMembershipRolesDialog from './dialogs/EditOrganizationMembershipRolesDialog.vue';
const { t } = useI18n();

const authorizationStore = useAuthorizationStore();
const organizationStore = useOrganizationStore();
const courseStore = useCourseStore();
const { toDisplayEnumName } = useEnum()

const props = defineProps<{
    user: IUser
}>();

const emit = defineEmits<{
    (name: 'refresh'): void,
    (name: 'remove', removalData:{membershipToRemove:IProfileMembership, profileMemberships:IProfileMembership[]}): void

}>();

const tableHeaders = [
    new TableHeaderItem({ key: 'type', text: 'Type', sortable: true, sortOrder: SortOrder.Desc }),
    new TableHeaderItem({ key: 'name', text: 'Name', sortable: true }),
    new TableHeaderItem({ key: 'roles', text: 'Roles', formatter: (roles: Set<String>) => Array.from(roles.values()).sort().join(', ') }),
]

const userIsMe = computed(()=>
{
    return useUserStore().$state.currentUser?.id === props.user.id;
});

const selectedMembership = ref<IProfileMembership|undefined>();

const memberships = computed(() =>
{
    const membershipsMap = new Map<string,IProfileMembership>()
    for (const { organizationId: id, role } of props.user.organizations || [])
    {
        if (!membershipsMap.has(id))
        {
            const organization = organizationStore.organizations.find(o => o.id === id);
            membershipsMap.set(id, {
                id,
                organizationId: id,
                type: ResourceType.Organization,
                name: organization?.name || id,
                roles: new Set(),
                editable: useAuthorizationStore().canUpdateOrganizationUserRoles(id) && !props.user?.isAnonymous,
                deletable: useAuthorizationStore().canDeleteOrganizationUsers(id),
                to: organization && authorizationStore.canManageOrganization(id) ? { name: Route.Organization.name, params: { organizationId: id } } : undefined,
            });
        }
        membershipsMap.get(id)!.roles.add(role);
    }
    for (const { id, role } of props.user.courses || [])
    {
        const organizationId =  courseStore.courses.find(c => c.id === id)?.organizationId as string;
        if (!membershipsMap.has(id))
        {
            membershipsMap.set(id, {
                id,
                organizationId,
                type:  ResourceType.Course,
                name: courseStore.courses.find(c => c.id === id)?.name ?? id,
                roles: new Set(),
                editable: courseMembershipIsEditable(role as UserRole, id, organizationId),
                deletable: useAuthorizationStore().canDeleteCourseUser(id, organizationId),
                to: { name: Route.Course.name, params: { courseId: id } },
            });
        }
        membershipsMap.get(id)!.roles.add(role);
    }

    return Array.from(membershipsMap.values());
});

const courseMembershipIsEditable = (role: UserRole, courseId:string, organizationId:string) =>
{
    return useAuthorizationStore().canInvite(role, courseId, organizationId) && useAuthorizationStore().canUpdateUserCourseRole(courseId, organizationId);
}

//Edit Course Membership Role
const showEditCourseMembershipRoleDialog = ref<boolean>(false);
const onEditCourseMembershipRoleClicked = (item:IProfileMembership) =>
{
    selectedMembership.value = item;
    showEditCourseMembershipRoleDialog.value = true;
}

// Delete Course Membership
const showDeleteCourseMembershipDialog = ref<boolean>(false);
const onDeleteCourseMembershipClicked = (item:IProfileMembership) =>
{
    selectedMembership.value = item;
    showDeleteCourseMembershipDialog.value = true;
}

// Edit Organization Membership Roles
const showEditOrganizationMembershipRolesDialog = ref<boolean>(false);
const onEditOrganizationMembershipRolesClicked = (item:IProfileMembership) =>
{
    selectedMembership.value = item;
    showEditOrganizationMembershipRolesDialog.value = true;
}

// Delete Organization Membership
const showDeleteOrganizationMembershipDialog = ref<boolean>(false);
const onDeleteOrganizationMembershipClicked = (item:IProfileMembership) =>
{
    selectedMembership.value = item;
    showDeleteOrganizationMembershipDialog.value = true;
}

// Confirm
const onMembershipUpdateDialogConfirmed = () =>
{
    emit('refresh');
}

const onMembershipDeleteDialogConfirmed = () =>
{
    emit('remove', { membershipToRemove: selectedMembership.value as IProfileMembership, profileMemberships: memberships.value});
}

const {
    onLoadFirstPage,
    onLoadNextPage,
    onLoadPreviousPage,
    onSuggestedNumberOfItems,
    onSort,
    page
} = useArtificialPaging(memberships);

onMounted(async () =>
{
    await Promise.all([
        useOrganizationStore().fetchOrganizations(),
        useCourseStore().fetchAllCourses()
    ]);
})
</script>
