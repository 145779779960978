import { toRaw } from "vue";

export function useRawObject<T extends object, K extends keyof T>(obj:T, options?: {excludes?: K[], only?: K[]}): T
{
    if(obj === undefined) return obj;

    let result = {...toRaw(obj)};
    
    if(options?.only)
    {
        let tmp = <T>{};
        options.only.forEach(property => tmp[property] = result[property]);
        result = tmp;
    }

    if(options?.excludes)
    {
        options.excludes.forEach(property => delete result[property]);
    }
    
    return result;
}