import { defineStore } from 'pinia';
import { IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import { useApiClientStore } from './apiClientStore';
import { ISearchApiClient, IExerciseAreaSearchFilter, ISearchResult, ExerciseAreaSearchFilter, ItemType } from '@cyber-range/cyber-range-api-search-client';


export const useSearchStore = defineStore('searchStore', 
{
    state: () => 
    ({
       
    }),

    actions: 
    {
        async search(q:string = '', filter?:IExerciseAreaSearchFilter, options?: {background?:boolean}): Promise<IApiPageResponse<ISearchResult>>
        {
            if(!filter?.types?.length)
            {
                filter = new ExerciseAreaSearchFilter({
                    types:[ItemType.Organization,ItemType.User,ItemType.Course,ItemType.ExerciseGroup],
                    token: filter?.token
                })
            }

            const searchApiClient: ISearchApiClient = options?.background ? useApiClientStore().backgroundSearchApiClient : useApiClientStore().searchApiClient;

            return await searchApiClient.searchExerciseArea(q,filter);
        }
    }
})