<template>
    <cr-delete-confirmation-dialog v-if="!jobId" v-model="showDeleteDialog" @confirm="onDeleteConfirmed" :disabled="isLoading" persistent data-testing="bulk-delete-course-user-delete-confirmation">
        <cr-html :value="t('COURSE_USERS_BULK_DELETE_DELETE_DIALOG_MESSAGE', {itemCount: entryIds?.length || 0}, {plural: entryIds?.length || 0})" />
    </cr-delete-confirmation-dialog>
    <job-progress-dialog v-model="showJobDialog" :job-id="jobId" :title="t('COURSE_USERS_BULK_DELETE_JOB_DIALOG_TITLE')" @confirm="onConfirm" :label="t('COURSE_USERS_BULK_DELETE_ARIA_LABEL')" data-testing="bulk-delete-course-user-job-progress"/>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useUserStore } from '../../../stores/userStore';
import { storeToRefs } from 'pinia';
import { ITeam } from '@cyber-range/cyber-range-api-user-client';
import JobProgressDialog from '../../jobs/dialogs/JobProgressDialog.vue';
import { computed, ref } from 'vue';
import { IRosterEntry, RosterFilter } from '@cyber-range/cyber-range-api-roster-client';
import { useRosterStore } from '../../../stores/rosterStore';
import { useInvitationStore } from '../../../stores/invitationStore';
import { useCredentialStore } from '../../../stores/credentialStore';
import { useTeamStore } from '../../../stores/teamStore';
import { useJobStore } from '../../../stores/jobStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    entryIds?: string[];
    courseId: string;
    modelValue?: boolean;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
    (name: 'update:modelValue', value: boolean): void

}>();

const showDeleteDialog = computed(
    {
        get: () => props.modelValue,
        set: (value: boolean) => emit('update:modelValue', value)
    }
)

const showJobDialog = ref<boolean>(false)

const jobId = ref<string>("");
const teams = ref<ITeam[]>([]);

const isLastMemberOnTeam = (entry: IRosterEntry) =>
{
    const team = teams.value.find(team => team.id === entry.teamId);
    return team?.members && team?.members.length < 2;
}

const removeMemberFromTeam = (member: IRosterEntry) =>
{
    const team = teams.value.find(team => team.id === member.teamId);
    if (!team)
    {
        return;
    }

    const memberIndex = team.members.indexOf(member.id);

    if (memberIndex !== -1)
    {
        team.members.splice(memberIndex, 1);
    }
}

const deleteUser = async (userId: string) =>
{
    await useUserStore().deleteCourseUser(props.courseId, userId, {bulk: true});
}

const deleteTeam = async (teamId: string) =>
{
    await useTeamStore().delete(props.courseId, teamId, {bulk: true});
}

const deleteCredential = async (credentialId: string) =>
{
    await useCredentialStore().delete(credentialId, {bulk: true});
}

const deleteInvitation = async (invitationId: string) =>
{
    await useInvitationStore().delete(invitationId, {bulk: true});
}

const onDeleteConfirmed = async () =>
{
    teams.value = await useTeamStore().listAll(props.courseId);

    // Set up job, then set job id for the job dialog
    if (props.courseId && props.entryIds && props.entryIds?.length > 0)
    {
        const rosterEntries = (await useRosterStore().listRosterEntries(props.courseId, new RosterFilter({entryIds: props.entryIds}))).items;
        showJobDialog.value = true;
        
        for(let entry of rosterEntries)
        {
            if (entry.isUser)
            {
                if (isLastMemberOnTeam(entry))
                {
                    await deleteTeam(entry.teamId);
                }
                else
                {
                    // Multiple users on team, don't delete team yet.
                    await deleteUser(entry.id);
                    removeMemberFromTeam(entry);
                }
            }
            else if (entry.isInvitation)
            {
                if (entry.teamId !== undefined && isLastMemberOnTeam(entry))
                {
                    await deleteTeam(entry.teamId);
                }
                else
                {
                    await deleteInvitation(entry.id);
                    removeMemberFromTeam(entry);
                }
            }
            else if (entry.isCredential)
            {
                await deleteCredential(entry.id);
            }
            else if (entry.isTeam)
            {
                await deleteTeam(entry.id);
            }
        }
        jobId.value = await useJobStore().submitJob();
    }
}

const onConfirm = async () => 
{
    await useRosterStore().clearCache();
    jobId.value = "";
    emit("confirm");
}
</script>