export default {
    en: {
        SEARCH_TITLE: 'Search',
        SEARCH_TITLE_WITH_KEYWORD: 'Search Result for "{keyword}"',
        SEARCH_FILTER_SECTION_LABEL: 'Filter results',
        SEARCH_FILTER_COURSE: 'Course',
        SEARCH_FILTER_EXERCISE_ENVIRONMENT: 'Exercise Environment',
        SEARCH_FILTER_ORGANIZATION: 'Organization',
        SEARCH_FILTER_USER: 'User',
        SEARCH_FILTER_REMOVE_LABEL: 'Remove {filter}',
        SEARCH_NO_RESULTS: 'No data is available.',
        SEARCH_WITH_KEYWORD_NO_RESULTS: 'Your search -- <b>{keyword}</b> -- did not match any documents.',
        SEARCH_WITH_FILTER_NO_RESULTS: 'Your search did not match any { filter }s.',
        SEARCH_WITH_BOTH_KEYWORD_AND_FILTER_NO_RESULT: 'Your search -- <b>{keyword}</b> -- did not match any { filter }s.',
        SEARCH_NO_RESULTS_SUGGESTIONS: 'Suggestion(s):',
        SEARCH_NO_RESULTS_SUGGESTIONS_1: 'Check the spelling of your keywords.',
        SEARCH_NO_RESULTS_SUGGESTIONS_2: 'Explore different keywords.',
        SEARCH_NO_RESULTS_SUGGESTIONS_3: 'Remove any search filters.',
        SEARCH_NO_RESULTS_FILTER_NAME_DOCUMENT: 'document',
        SEARCH_NO_RESULTS_FILTER_NAME_EXERCISE_ENVIRONMENT: 'exercise environment',
        SEARCH_RESULTS_UPDATED: 'Search results updated. Now showing {itemCount} item{s}.'
    }
};