<template>
    <cr-chip :message="message" :background-color="backgroundColor" text-color="white">
        <cr-loading v-if="isPending" :message="message" color="white" />
    </cr-chip>
</template>

<script setup lang="ts">
import { ScheduleStatus } from '@cyber-range/cyber-range-api-sync-client';
import { computed } from 'vue';
import { useEnum } from '../../composables/useEnum';

const props = defineProps<{
    status: ScheduleStatus
}>()

const { toDisplayEnumName } = useEnum();

const isPending = computed(() => props.status.startsWith('pending'));

const message = computed(() => 
{
    return toDisplayEnumName(ScheduleStatus, props.status, {removePending: true});
});

const backgroundColor = computed(() => {
    switch (props.status)
    {
        case ScheduleStatus.Completed: return 'var(--cr-sync-schedule-completed-status-color)';
        case ScheduleStatus.Error: return 'var(--cr-sync-schedule-error-status-color)';
        default: return 'var(--cr-sync-schedule-pending-status-color)';
    }
});
</script>
