<template>
    <cr-confirmation-dialog v-model="isDialogShowing" :title="t('COURSE_USERS_RESET_PASSWORD_DIALOG_TITLE')" :confirm="!!password" :cancel="!password" :confirmText="t('COURSE_USERS_RESET_PASSWORD_DIALOG_CONFIRM')" @confirm="onConfirmed" :disabled="isLoading" data-testing="reset-password-dialog">
        <template #footerRight v-if="!password">
            <cr-button @click.prevent="onResetPasswordClicked" data-testing="reset-password-dialog-reset-button">{{ t('COURSE_USERS_RESET_PASSWORD_DIALOG_RESET_BUTTON') }}</cr-button>
        </template>

        <cr-row xs12>

            <cr-html v-if="!password" :value="t('COURSE_USERS_RESET_PASSWORD_TEXT',{ name: rosterEntry?.name })"/>

            <div v-else class="password-container">
                <cr-html :value="t('COURSE_USERS_RESET_PASSWORD_NEW_PASSWORD_TEXT', { name: rosterEntry?.name })"/>
                <span class="password-field cr-plr2 cr-pt1">
                    {{ password }}
                </span>
                <cr-clipboard-copy class="cr-pl1" :value="password" data-testing="invitation-code-created-dialog-clipboard-copy"/>
            </div>
        </cr-row>
    </cr-confirmation-dialog>
</template>

<style scoped>
.password-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.password-container > span {
    white-space: nowrap;
}
.password-field {
    background-color: var(--cr-grey-lighten-2);
    font-family: 'Courier New', Courier, monospace;
    border-radius: 5px;
}

</style>

<script lang="ts" setup>
import { Credential } from '@cyber-range/cyber-range-api-credential-client';
import { IRosterEntry } from '@cyber-range/cyber-range-api-roster-client';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useCredentialStore } from '../../../stores/credentialStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    modelValue: boolean
    rosterEntry?: IRosterEntry;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
    (name: 'update:modelValue', value: boolean): void
}>();

const isDialogShowing = computed({
    get() { return props.modelValue },
    set(value: boolean) {
        if (value === false)
        {
            password.value = ''
        }
        emit('update:modelValue', value);
    }
});

const password = ref('');

async function onResetPasswordClicked()
{
    if (!props.rosterEntry)
    {
        return;
    }

    const id = props.rosterEntry.isCredential ? props.rosterEntry.id : Credential.idFromDisplayName(props.rosterEntry.name);
    password.value = await useCredentialStore().resetPassword(id);
}

function onConfirmed()
{
    emit('confirm');
}
</script>
