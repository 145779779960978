import "@cyber-range/cyber-range-lib-ui/dist/style.css"

import { createApp } from 'vue'
import App from './App.vue'
import Config from './config';
import Router from './routers/router'
import { createPinia } from 'pinia';
import crUi from '@cyber-range/cyber-range-lib-ui';
import { createI18n } from 'vue-i18n'
import createMessages from "./strings/strings";
import VueMatomo from 'vue-matomo';

Config.load(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/env.json?t=${new Date().getTime()}`).then(()=>
{
    let pinia = createPinia();

    const i18n = createI18n({
        legacy: false,
        locale: 'en',
        missingWarn: false,
        warnHtmlMessage: false,
        messages: createMessages(),
        globalInjection: false
    })

    const matomo = {
        host: Config.MATOMO_BASE_URL,
        siteId: Config.MATOMO_SITE_ID,
        router: Router,
        enableLinkTracking: true,
        disableCookies: true,
        requireConsent: false,
        trackInitialView: true,
        enableHeartBeatTimer: true,
        trackSiteSearch: true,
    };
    
    createApp(App)
        .use(pinia)
        .use(Router)
        .use(i18n)
        .use(VueMatomo, matomo)
        .use(crUi, { 
            theme: { 
                font: {
                    family: 'Avenir, Helvetica, Arial, sans-serif',
                    size: '0.9rem',
                },
                colors: { 
                    text: '#666666',
                    primary: "#1867c0",
                    primaryText: "white",
                    primaryLink: "#1867c0",
                    warningText: "var(--cr-orange-darken-2)",
                    highlightBackgroundColor: 'var(--cr-yellow)',
                    sidebarItemActiveColor: "var(--cr-main-background-color)",
                    cancelBackgroundColor: "grey",
                    cancelTextColor: "white",
                    exerciseGroupStartTimeIconColor: 'var(--cr-orange-darken-3)',
                    exerciseGroupEndTimeIconColor: 'var(--cr-red)',
                    exerciseGroupActiveIconColor: 'var(--cr-green-darken-1)',
                    exerciseGroupInactiveIconColor: '',
                    exerciseStoppedStatusColor: 'var(--cr-grey-darken-1)',
                    exerciseReadyStatusColor: 'var(--cr-chip-success-background-color)',
                    exerciseErrorStatusColor: 'var(--cr-chip-error-background-color)',
                    exercisePendingStatusColor: 'var(--cr-chip-warning-background-color)',
                    syncScheduleCompletedStatusColor: 'var(--cr-chip-success-background-color)',
                    syncScheduleErrorStatusColor: 'var(--cr-chip-error-background-color)',
                    syncSchedulePendingStatusColor: 'var(--cr-chip-warning-background-color)',
                    subscriptionTileActiveColor: 'var(--cr-primary)',
                    subscriptionTileActiveTextColor: 'var(--cr-primary-text)',
                    subscriptionTileInactiveColor: '#000925',
                    subscriptionTileInactiveTextColor: 'white',
                    courseApproveBackgroundColor: 'var(--cr-green-darken-3)',
                    courseRejectBackgroundColor: 'var(--cr-red-darken-2)',
                    balanceTilePositiveColor: 'var(--cr-green-darken-3)',
                    balanceTileNegativeColor: 'var(--cr-red-darken-2)',
                    balanceTileTextColor: 'var(--cr-white)',
                    courseExpiredIconColor: 'var(--cr-red)',
                },
                cssVariables: {
                    navBarHeight: '4rem',
                    mainBackgroundColor: '#f2f1ec',
                    pageTitleFontSize: '1.2rem',
                    sectionTitleFontSize: '1rem',
                    tileTitleFontSize: '1rem',
                    tileImageBackgroundColor: '#f6f9fe',
                    tileImageHeight: '13rem',
                    rightHandsideControlWidth: '16rem',
                    dashboardGaugeValueFontSize: '1.5rem',
                    dashboardTopCoursesValueFontSize: '1.2rem',
                    sectionDividerColor: "#d3d3d3",
                    mainColumnSidePadding: "3rem",
                    mainColumnSideXsPadding: "1.5rem",
                    balanceTileValueFontSize: "2rem",
                    checkBoxLabelTopPadding: ".09rem",
                    radioButtonLabelTopPadding: ".09rem"
                }
            } 
        })
        .mount('#app')
});