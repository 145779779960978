<template>
    <template v-if="canListCourses()">
        <cr-sidebar-header tag="h2"  class="cr-mt3" data-testing="sidebar-my-courses">
            <router-link :to="Route.MyCourses" class="cr-font-bold cr-text-decoration-none">
                    {{ t('SIDEBAR_MY_COURSES') }}
            </router-link>
        </cr-sidebar-header>
        <cr-sidebar-item v-for="i in Math.min(myCourses.length, limit)" :to="{ name: Route.MyCourse.name, params: {courseId: myCourses[i-1].id}}" tag="span" ref="courseItemRefs" data-testing="sidebar-my-courses-item">
            <cr-icon class="sidebar-item-icon">bi-card-text</cr-icon>
            <span class="sidebar-item-text">
                {{ myCourses[i-1].name }}
            </span>
        </cr-sidebar-item>
        <cr-sidebar-item v-if="myCourses.length > limit" @click="onShowAllClicked" @mouseenter="onShowAllMouseEntered" @mouseleave="onShowAllMouseLeft" tag="span" class="show-all" data-testing="sidebar-my-courses-show-all">
            <span class="sidebar-item-text">
                {{ t('SIDEBAR_MY_COURSES_VIEW_MORE') }}
            </span>
        </cr-sidebar-item>
        <cr-sidebar-item v-if="canCreateCourse()" :to="Route.CreateMyCourse" data-testing="sidebar-create-course">
            <cr-icon class="sidebar-item-icon">bi-plus</cr-icon> 
            <span class="sidebar-item-text">
                {{ t('SIDEBAR_MY_COURSES_CREATE_COURSE') }}
            </span>
        </cr-sidebar-item>
    </template>
</template>

<style scoped>
.show-all:deep(button),
.show-all:deep(button:active)
{
    background: transparent;
    border: transparent;
}
</style>

<script setup lang="ts">
import { computed } from '@vue/reactivity';
import { ref, onMounted, GlobalComponents, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAuthorizationStore } from '../../../stores/authorizationStore';
import { useCourseStore } from '../../../stores/courseStore';
import { storeToRefs } from 'pinia';
import Route from '../../../routers/route';

const defaultNumberOfCoursesToShow = 3;

const { t } = useI18n();
const { canCreateCourse, canListCourses } = useAuthorizationStore();
const courseStore = useCourseStore();
let { myCourses } =  storeToRefs(courseStore)
let showAll = ref(false);
let courseItemRefs = ref<InstanceType<any>[] | null>(null);
let mouseIsHoveringOnShowAllButton = false;

let onShowAllMouseEntered = () =>
{
    mouseIsHoveringOnShowAllButton = true;
}

let onShowAllMouseLeft = () =>
{
    mouseIsHoveringOnShowAllButton = false;
}

let onShowAllClicked = async () =>
{
    showAll.value = true;

    //When a user clicks the button to view all their courses, we want to set the 
    //focus to the first item of the newly visible courses. 
    //Also, only trigger focus if the mouse is not already hovering over the show-all button, because our css hover styles are removed when .focus() is called
    if(!mouseIsHoveringOnShowAllButton)
    {
        await nextTick(); // Wait for dom to update before trying to access the newly added/updated refs
        courseItemRefs.value?.[defaultNumberOfCoursesToShow]?.$el?.getElementsByTagName('a')[0]?.focus();
    }
}

let limit = computed(()=>
{
    return showAll.value ? myCourses.value.length : defaultNumberOfCoursesToShow;
});

onMounted(async ()=>
{
    await courseStore.fetchMyCourses();
});
</script>