<template>
    <read-layout :title="t('BILLING_PAGE_TITLE')" :breadcrumbs="breadcrumbs" :ready="isReady">
        <template #top>
            <read-section :label="t('BILLING_ACTIVE_RATE_PLANS_SECTION')" class="cr-mb3" data-testing="billing-plan-section">
                <subscriptions :organization-id="organizationId" :contract-expiration="contractExpirationDate"></subscriptions>
                <cr-row class="cr-mt2">
                    <cr-col>
                        <cr-external-link :href="Config.PRICING_PAGE_URL">{{ t('BILLING_LEARN_ABOUT_FEATURES') }}</cr-external-link>
                    </cr-col>
                </cr-row>
            </read-section>
            <read-section class="cr-mt5" :label="t('BILLING_CONTRACT_EXPIRATION')" data-testing="billing-contract-expiration-section">
                <p>{{ contractExpirationMessage }}</p>
            </read-section>
            <read-section v-if="canViewBillingAdmins(organizationId)" :label="t('BILLING_INVOICE_CONTACT_SECTION')" class="cr-mt5" data-testing="invoice-contacts-section">
                <p v-if="billingAdmins.length === 0">{{ t('BILLING_INVOICE_CONTACT_NO_BILLING_ADMINS') }}</p>
                <template v-else>
                    <p class="cr-mb0">{{ t('BILLING_INVOICE_CONTACT_BILLING_ADMINS') }}</p>
                    <ul class="cr-mt2 cr-pl4">
                        <li v-for="admin of billingAdmins">{{ `${admin.name} (${admin.email})`}}</li>
                    </ul>
                </template>
            </read-section>
        </template>
        <template #controls>
            <read-control-item v-if="canViewUsages(organizationId)">
                <cr-button :to="Route.OrganizationUsages" :disabled="isLoading" outlined data-testing="billing-view-usages-button">
                    <cr-icon>bi-bar-chart-line-fill</cr-icon>
                    {{ t('BILLING_ORGANIZATION_USAGE_BUTTON') }}
                </cr-button>
            </read-control-item>
            <read-control-item v-if="canUpdateBilling(organizationId)">
                <cr-button dialog="#edit-organization-contract-dialog" :disabled="isLoading" outlined data-testing="billing-set-contract-expiration-button">
                    <cr-icon>bi-calendar2-week</cr-icon>
                    {{ t('BILLING_CONTRACT_EXPIRATION_BUTTON') }}
                </cr-button>
                <edit-organization-contract-dialog :organization="organization!" id="edit-organization-contract-dialog" @confirm="onEditOrganizationContractDialogConfirmed" data-testing="billing-edit-contract-dialog"/>
            </read-control-item>
            <read-control-item v-if="canRequestOrganizationSupport(organizationId)">
                <cr-button dialog="#changePlanDialog" :disabled="isLoading" outlined data-testing="billing-change-plan-button">
                    <cr-icon>bi-headset</cr-icon>
                    {{ t('BILLING_CONTRACT_CHANGE_PLAN_BUTTON') }}
                </cr-button>
            </read-control-item>
            <change-plan-dialog :organization="organization"/>
            <read-control-item v-if="canUpdateBilling(organizationId)">
                <cr-button :disabled="isLoading" :to="Route.Subscribe" outlined data-testing="billing-subscribe-button">
                    <cr-icon>bi-plus</cr-icon>
                    {{ t('BILLING_CONTRACT_SUBSCRIBE') }}
                </cr-button>
                <edit-organization-contract-dialog :organization="organization!" id="edit-organization-contract-dialog" @confirm="onEditOrganizationContractDialogConfirmed" data-testing="billing-edit-contract-dialog"/>
            </read-control-item>
        </template>
        <template #list>
            <balance-activity-table ref="balanceActivityTable" :organization-id="organizationId" @update="onBalanceActivityTableUpdate"/>
        </template>
        <template #listControls>
            <balance-tile :balance="postedBalance"></balance-tile>
            <read-control-item v-if="canCreateUpFrontPaymentLink(organizationId)">
                <cr-button :disabled="isLoading" outlined  @click="onUpfrontPaymentLinkButtonClicked" data-testing="billing-create-payment-link-button">
                    <cr-icon>bi-currency-dollar</cr-icon>
                    {{ t('BILLING_CREATE_PAYMENT_LINK_BUTTON') }}
                </cr-button>
                <upfront-payment-link-dialog v-model="displayUpfrontPaymentDialog" :organization-id="props.organizationId" :alternate-ids="organization?.alternateIds"></upfront-payment-link-dialog>
            </read-control-item>
            <read-control-item v-if="showMakePaymentControl">
                <cr-button :disabled="isLoading" outlined  @click="onMakePaymentLinkButtonClicked" data-testing="billing-make-payment-button">
                    <cr-icon>bi-credit-card</cr-icon>
                    {{ t('BILLING_CREATE_MAKE_PAYMENT_BUTTON') }}
                </cr-button>
                <make-payment-dialog v-model="displayMakePaymentDialog" :organization-id="props.organizationId" :alternate-ids="organization?.alternateIds" :balance="postedBalance" />
            </read-control-item>
            <read-control-item v-if="canCreateBalanceActivity(organizationId)">
                <cr-button dialog="#create-activity-dialog" :disabled="isLoading" outlined data-testing="billing-add-transaction-button">
                    <cr-icon>bi-journal-plus</cr-icon>
                    {{ t('BILLING_ADD_TRANSACTION_BUTTON') }}
                </cr-button>
                <create-activity-dialog :organization-id="organizationId" @confirm="onCreateActivityDialogConfirmed" data-testing="billing-create-activity-dialog"/>
            </read-control-item>
        </template>
    </read-layout>
</template>

<style scoped>
dt
{
    font-weight: unset;
    
}
dd
{
    margin-bottom: unset;
}
.subscription-tiles
{
    align-items: stretch;
}
</style>


<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import ReadLayout from '../layouts/ReadLayout.vue';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import Route from '../../routers/route';
import { useI18n } from 'vue-i18n';
import { IOrganization } from '@cyber-range/cyber-range-api-organization-client';
import ReadControlItem from '../layouts/sections/ReadControlItem.vue';
import ReadSection from '../layouts/sections/ReadSection.vue';
import BalanceActivityTable from './BalanceActivityTable.vue';
import { useOrganizationStore } from '../../stores/organizationStore';
import Config from '../../config';
import { useCalendar } from '../../composables/useCalendar';
import { useApiClientStore } from '../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { IOrganizationUser, OrganizationUserFilter, OrganizationUserSortBy, UserRole, UserStatus } from '@cyber-range/cyber-range-api-user-client';
import { useUserStore } from '../../stores/userStore';
import { useBalanceStore } from '../../stores/balanceStore';
import { IBalance } from '@cyber-range/cyber-range-api-balance-client';
import ChangePlanDialog from './dialogs/ChangePlanDialog.vue';
import EditOrganizationContractDialog from './dialogs/EditOrganizationContractDialog.vue';
import Subscriptions from './Subscriptions.vue';
import BalanceTile from './BalanceTile.vue';
import UpfrontPaymentLinkDialog from './dialogs/UpfrontPaymentLinkDialog.vue';
import { SortOrder } from '@cyber-range/cyber-range-api-client';
import MakePaymentDialog from './dialogs/MakePaymentDialog.vue';
import CreateActivityDialog from './dialogs/CreateActivityDialog.vue';

const props = defineProps<
{
    organizationId:string;
}>();

const { t } = useI18n();
const organization = ref<IOrganization>();
const billingAdmins = ref<IOrganizationUser[]>([]);
const balance = ref<IBalance>();
const postedBalance = computed(() => balance.value?.postedBalance || 0);
const isReady = ref(false);
const { isLoading } = storeToRefs(useApiClientStore());
const balanceActivityTable = ref();

const { getOrganization } = useOrganizationStore();
const { getOrganizationBalance } = useBalanceStore();
const { canViewUsages, canCreateBalanceActivity, canViewBillingAdmins, canCreateUpFrontPaymentLink, canUpdateBilling, canMakeOrganizationPayment, canRequestOrganizationSupport } = useAuthorizationStore();
const { toYYYYMMDD } = useCalendar()

const getBillingAdmins = async () => 
{
    if (!canViewBillingAdmins(props.organizationId))
    {
        return [];
    }
    const filter = new OrganizationUserFilter({sortBy: OrganizationUserSortBy.BillingAdmin, sortOrder:SortOrder.Asc, status: UserStatus.Ready, limit: Config.INVOICE_CONTACT_MAX_BILLING_ADMINS_DISPLAYED});
    return (await useUserStore().listOrganizationUsers(props.organizationId, filter))?.items?.filter(user=>user.roles.includes(UserRole.BillingAdmin)) || [];
}

const showMakePaymentControl = computed(() => canMakeOrganizationPayment(props.organizationId) && postedBalance.value < 0);
const displayUpfrontPaymentDialog = ref(false);
const displayMakePaymentDialog = ref(false);

const contractExpirationDate = computed(() => organization.value?.contractExpirationTimestamp ? toYYYYMMDD(new Date(organization.value.contractExpirationTimestamp)): undefined);

onMounted(async () => 
{
    [organization.value, billingAdmins.value, balance.value] = await Promise.all([getOrganization(props.organizationId), getBillingAdmins(), getOrganizationBalance(props.organizationId)]);
    await useOrganizationStore().fetchOrganizationNameAndLogo(organization.value ? [organization.value]: undefined);
    isReady.value = true;
})

const breadcrumbs = computed(()=>[
                            new BreadcrumbItem(Route.Organizations),
                            new BreadcrumbItem({...Route.Organization, text: organization.value?.name, params: {organizationId: props.organizationId}}),
                            new BreadcrumbItem({...Route.Billing, params: {organizationId: props.organizationId}})
                        ]);

const contractExpirationMessage = computed(() => 
{
    if(!organization.value) return;

    const { contractStartTimestamp, contractExpirationTimestamp } = organization.value;
    const startDate = contractStartTimestamp ? toYYYYMMDD(new Date(contractStartTimestamp)) : contractStartTimestamp;
    const expirationDate = contractExpirationTimestamp ? toYYYYMMDD(new Date(contractExpirationTimestamp)) : contractExpirationTimestamp;

    if (startDate && expirationDate)
    {
        return t('BILLING_CONTRACT_BETWEEN_MESSAGE', { startDate, expirationDate });
    }
    else if (startDate)
    {
        return t('BILLING_CONTRACT_NEVER_EXPIRES_MESSAGE', { startDate })
    }
    else if (expirationDate)
    {
        return t('BILLING_CONTRACT_EXPIRATION_MESSAGE', { expirationDate });
    }
    else
    {
        return t('BILLING_CONTRACT_EXPIRATION_NO_CONTRACT');
    }
})
const onEditOrganizationContractDialogConfirmed = async () => organization.value = await getOrganization(props.organizationId);
const onBalanceActivityTableUpdate = async () => balance.value = await getOrganizationBalance(props.organizationId);
const onUpfrontPaymentLinkButtonClicked = () => displayUpfrontPaymentDialog.value = true;
const onMakePaymentLinkButtonClicked = () => displayMakePaymentDialog.value = true;
const onCreateActivityDialogConfirmed = async () =>
{
    balanceActivityTable.value.refresh();
    balance.value = await getOrganizationBalance(props.organizationId);
}
</script>