import { computed, Ref } from "@vue/reactivity";
import { useRoute } from "vue-router";
import Route from "../routers/route";

const regex = new RegExp(/^\/my\-courses\/?/);

export function useWhoseCourse(): Ref<{mine: boolean, others: boolean, topRoute: {name:string, path:string}}>
{
    return computed(()=>{
        let mine = regex.test(useRoute()?.path)
        return {mine, others:!mine, topRoute: mine ? Route.MyCourses : Route.Courses}
    });
        
}