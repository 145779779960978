<template>
     <cr-accordion data-testing="expandable-exercise-activity-cell">
        <cr-accordion-item>
            <cr-tooltip :title="t('EXERCISE_DETAILS_TOGGLE_TOOLTIP')" data-testing="expandable-exercise-activity-cell-tooltip">
                <cr-accordion-toggle @click="onAccordionToggleClicked" data-testing="expandable-exercise-activity-accordion-toggle">
                    <div class="always-normal-font">
                        <div data-testing="expandable-exercise-activity-cell-activity">
                            {{ activity }}
                        </div>
                        <exercise-low-duration-warning :exercise="exercise"/>
                    </div>                        
                </cr-accordion-toggle>
            </cr-tooltip>                    
            <cr-accordion-content>
                <ul>
                    <li data-testing="expandable-exercise-activity-cell-course-id">
                        <b>{{ t('EXERCISE_DETAILS_COURSE_ID_LABEL') }}</b> {{ exercise.courseId }} <cr-clipboard-copy :value="exercise.courseId" data-testing="exercise-details-course-id-clipboard"/>
                    </li>
                    <li data-testing="expandable-exercise-activity-cell-team-id">
                        <b>{{ t('EXERCISE_DETAILS_TEAM_ID_LABEL') }}</b> {{ exercise.ownerId }} <cr-clipboard-copy :value="exercise.ownerId" data-testing="exercise-details-team-id-clipboard"/>
                    </li> 
                    <li data-testing="expandable-exercise-activity-cell-exercise-id">
                        <b>{{ t('EXERCISE_DETAILS_EXERCISE_ID_LABEL') }}</b> {{ exercise.id }} <cr-clipboard-copy :value="exercise.id" data-testing="exercise-details-exercise-id-clipboard"/>
                    </li>
                    <li v-if="resourceLocationId" data-testing="expandable-exercise-activity-cell-resource-location-id">
                        <b>{{ t('EXERCISE_DETAILS_RESOURCE_LOCATION_ID_LABEL') }}</b> {{ resourceLocationId }} <cr-clipboard-copy :value="resourceLocationId" data-testing="exercise-details-exercise-id-clipboard"/>
                    </li> 
                    <li v-if="account" data-testing="expandable-exercise-activity-cell-resource-location-account">
                        <b>{{ t('EXERCISE_DETAILS_RESOURCE_LOCATION_ACCOUNT_LABEL') }}</b> {{ account }} <cr-clipboard-copy :value="account" data-testing="exercise-details-exercise-id-clipboard"/>
                    </li> 
                    <template v-for="accessPoint of accessPoints">
                        <li v-if="getDisplayFeatures(accessPoint.id).length" class="cr-mt1" data-testing="expandable-exercise-activity-cell-access-point-feature-item">
                            <b> {{ accessPoint.name }} </b>: {{ getDisplayFeatures(accessPoint.id) }}
                        </li>
                    </template>                    
                </ul>
            </cr-accordion-content>
        </cr-accordion-item>
    </cr-accordion>
</template>

<style scoped>
.always-normal-font
{
    font-weight: normal;
}
:deep(.cr-accordion-toggle-button)
{
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 0;
    margin-top: 0;
}
:deep(.cr-accordion)
{
    padding: 0;
    margin: 0;
}
</style>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import ExerciseLowDurationWarning from './ExerciseLowDurationWarning.vue'
import { AccessPointFeatureName, IExercise, IResourceLocation } from "@cyber-range/cyber-range-api-exercise-client";
import { useEnum } from "../../composables/useEnum";
import { computed, ref, watch} from 'vue';
import { useExerciseStore } from "../../stores/exerciseStore";
const { t } = useI18n();

const props = defineProps<
{
    exercise: IExercise;
    activity: string;
}>();

const resourceLocation = ref<Partial<IResourceLocation>>({});
const expandedOnce = ref<boolean>(false);
    
const populateResourceLocation = async () =>
{
    const fetchedExercise = await (useExerciseStore().getOne(props.exercise.id));
    resourceLocation.value = fetchedExercise.resourceLocation;
};

const onAccordionToggleClicked = async () =>
{   
    if(!expandedOnce.value)
    {
        await populateResourceLocation();
        expandedOnce.value = true;
    }
}

watch(()=>props.exercise.status, async ()=>
{
    if(expandedOnce.value)
    {
        await populateResourceLocation();
    }
});

const account = computed(():string=>
{
    return resourceLocation.value?.account || ''; 
})

const resourceLocationId = computed(():string=>
{
    return resourceLocation.value?.id || '';
})

const accessPoints = computed(()=>
{
    return props.exercise.accessPoints || [];
})

const getDisplayFeatures = (accessPointId:string)=>
{
    const features = accessPoints.value.find(ap=>ap.id === accessPointId)?.features?.filter(f=>f.name !== AccessPointFeatureName.Username && f.name !== AccessPointFeatureName.Password) || [];

    return features.map(f=> `${useEnum().toDisplayEnumName(AccessPointFeatureName,f.name)}: ${f.value}`).join(', ');
}
</script>