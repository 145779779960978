<template>
    <cr-delete-confirmation-dialog id="deleteOrganizationUserDialog" @confirm="onConfirmed" :disabled="isLoading" data-testing="delete-organization-user-dialog">
        <cr-html :value="t('ORGANIZATION_USERS_DELETE_USER_DIALOG_MESSAGE', {userName: user?.name})" />
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import IOrganizationUserRoleView from '../../../interfaces/iOrganizationUserRoleView';
import { useOrganizationStore } from '../../../stores/organizationStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    user?: IOrganizationUserRoleView;
    organizationId?: string;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    if(props.user && props.organizationId)
    {
        await useOrganizationStore().deleteOrganizationUser(props.organizationId, props.user.id);
        emit('confirm');
    }
}
</script>