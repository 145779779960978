import { ExerciseGroup, Feature, FeatureId, FeatureStatus, IExerciseGroup } from '@cyber-range/cyber-range-api-user-client';
import { Ref, computed, ref, watch } from 'vue';
import { useExerciseGroupStore } from '../stores/exerciseGroupStore';
import { useScheduler } from './useScheduler';

export function useInterTeamConnectivityStatus(exerciseGroup: Ref<IExerciseGroup|undefined>)
{
    const featureStatus = computed(()=> exerciseGroup?.value?.features?.find(f=>f.id === FeatureId.InterTeamConnectivityControl)?.status);
    const isPending = computed(()=> featureStatus?.value === FeatureStatus.PendingDisabled || featureStatus?.value === FeatureStatus.PendingEnabled);
    const isEnabled = computed(()=> featureStatus?.value === FeatureStatus.Enabled);
    return { featureStatus, isPending, isEnabled };
}

export function useInterTeamConnectivity(courseId: Ref<string>, exerciseGroup: Ref<IExerciseGroup|undefined>, pollingDelay:number = 5000)
{
    const exerciseGroupStore = useExerciseGroupStore();

    const { featureStatus, isPending, isEnabled } = useInterTeamConnectivityStatus(exerciseGroup);
    const pollingScheduleId = ref<string>('');
    const resumePollingAfter = ref<number>(0);

    const isPolling = computed(()=>
    {
        return !!pollingScheduleId.value
    })

    const fetchExerciseGroup = async () =>
    {
        exerciseGroup.value = await exerciseGroupStore.getCourseExerciseGroup(courseId.value,exerciseGroup.value!.id);
    }

    const toggleInterTeamConnectivity = ()=>
    {
        const payload = ExerciseGroup.fromJson(exerciseGroup.value!);
        resumePollingAfter.value = pollingDelay;
        const nextStatus = (featureStatus.value === FeatureStatus.Enabled)
            ? FeatureStatus.PendingDisabled
            : FeatureStatus.PendingEnabled
        
        exerciseGroup.value!.features.find(f=>f.id === FeatureId.InterTeamConnectivityControl)!.status = nextStatus;
        exerciseGroupStore.toggleExerciseGroupInterTeamConnectivityControl(payload);
    };

    const scheduler = useScheduler();
    watch(featureStatus, async () =>
    {
        if (isPending.value && !isPolling.value)
        {
            pollingScheduleId.value = scheduler.schedule(fetchExerciseGroup, undefined,  { frequencyInMs: pollingDelay, repeat: true });
        }
        else if (!isPending.value && isPolling.value)
        {
            scheduler.unschedule(pollingScheduleId.value);
            pollingScheduleId.value = '';
        }
    }, { immediate: true });

    return { featureStatus, isPending, isEnabled, toggleInterTeamConnectivity, isPolling };
}