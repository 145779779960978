export default {
    en: {
        LIST_SECTION_NO_ITEMS: 'No items found',
        READ_LIST_SECTION_NO_ITEMS: 'No items found',
        FORM_LIST_SECTION_NO_ITEMS: 'No items found',
        DASHBOARD_SECTION_NO_ITEMS: 'No items found',
        READ_LAYOUT_READ_DETAIL_SECTION_LABEL: 'Details',
        FORM_LAYOUT_FORM_DETAIL_SECTION_LABEL: 'Details',
        FORM_LAYOUT_INSTRUCTION: '* indicates a required field',
    }
};