import { ISubscription } from "@cyber-range/cyber-range-api-subscription-client";
import { useCalendar } from "./useCalendar";

function subscriptionExpired(subscription: ISubscription)
{
    if (!subscription.endDate)
    {
        return false;
    }

    const today = useCalendar().toYYYYMMDD(new Date());
    return subscription.endDate.substring(0, 10) < today;
}

function subscriptionActive(subscription: ISubscription)
{
    if (!subscription.endDate)
    {
        return true;
    }

    const today = useCalendar().toYYYYMMDD(new Date());
    const startsOnOrBeforeToday = subscription.startDate.substring(0,10) <= today;
    const endsOnOrAfterToday = subscription.endDate.substring(0, 10) >= today;

    return startsOnOrBeforeToday && endsOnOrAfterToday;
}

let usableFunctions = 
{
    subscriptionExpired,
    subscriptionActive
}

export function useSubscriptionDates(): typeof usableFunctions
{
    return usableFunctions;
}