<template>
    <cr-chip :message="message" :background-color="backgroundColor" text-color="white">
        <cr-loading v-if="isPending" :message="message" color="white" />
    </cr-chip>
</template>

<script setup lang="ts">
import { ExerciseStatus } from '@cyber-range/cyber-range-api-exercise-client';
import { computed } from 'vue';
import { useEnum } from '../../composables/useEnum';

const props = defineProps<{
    status: ExerciseStatus
    loading?: boolean
}>()

const { toDisplayEnumName } = useEnum();

const status = computed(() => 
{
    if (props.loading)
    {
        return ExerciseStatus.PENDING
    }

    if (props.status === ExerciseStatus.STUB)
    {
        return ExerciseStatus.STOPPED
    }

    return props.status
});

const isPending = computed(() => status.value.startsWith('pending'));

const message = computed(() => 
{
    return toDisplayEnumName(ExerciseStatus, status.value).replace('Pending ', '');
});

const backgroundColor = computed(() => {
    switch (status.value)
    {
        case ExerciseStatus.STOPPED: return 'var(--cr-exercise-stopped-status-color)';
        case ExerciseStatus.READY: return 'var(--cr-exercise-ready-status-color)';
        case ExerciseStatus.ERROR: return 'var(--cr-exercise-error-status-color)';
        default: return 'var(--cr-exercise-pending-status-color)';
    }
});
</script>
