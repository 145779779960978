<template>
    <cr-table :items="page?.items" :headers="headers" :loading="isLoading" actionable class="cr-mb5" data-testing="balance-activity-table">
        <template v-slot:action="{item}">
            <template v-if="item.type === ActivityType.Payment">
                <cr-table-action-item v-if="canViewPayments(VendibleType.OrganizationBalance, organizationId)" :item="item" icon="bi-file-text" @click="onViewPaymentClicked" data-testing="balance-activity-view-payment">
                {{ t('ACTIVITY_TABLE_VIEW_PAYMENT') }}
                </cr-table-action-item>
                <cr-table-action-item v-if="canUpdateBilling(organizationId)" :item="item" @click="onApplyFinanceChargeClicked" icon="bi-clipboard-plus" data-testing="balance-activity-apply-finance-charge">
                    {{ t('ACTIVITY_TABLE_APPLY_FINANCE_CHARGE') }}
                </cr-table-action-item>
            </template>
            <template v-else-if="item.type === ActivityType.Invoice">
                <cr-table-action-item :item="item" :to="{ name: Route.Invoice.name, params: {activityId: item.id, organizationId}}" icon="bi-file-text" data-testing="balance-activity-view-invoice">
                {{ t('ACTIVITY_TABLE_VIEW_INVOICE') }}
                </cr-table-action-item>
                <cr-table-action-item v-if="canUpdateBilling(organizationId)" :item="item" @click="onEditInvoiceClicked" icon="bi-pencil" data-testing="balance-activity-edit-invoice">
                    {{ t('ACTIVITY_TABLE_EDIT_INVOICE') }}
                </cr-table-action-item>
            </template>
            <template v-else>
                <cr-table-action-item  :item="item" @click="onViewBalanceActivityClicked" icon="bi-file-text" data-testing="balance-activity-view-balance-activity">
                {{ t('ACTIVITY_TABLE_VIEW_ACTIVITY') }}
                </cr-table-action-item>
            </template>
        </template>
    </cr-table>
    <cr-pagination :previous="!!page?.prevPageToken" :first="true" :next="!!page?.nextPageToken" @first="onLoadFirstPage" @previous="onLoadPreviousPage" @next="onLoadNextPage" :loading="isLoading" data-testing="balance-activity-table-pagination"/>
    <apply-finance-charge-dialog v-model="showApplyFinanceChargeDialog" :organization-id="organizationId" :activity="selectedItem" @confirm="onApplyFinanceChargeConfirmed"/>
    <edit-invoice-dialog v-model="showEditInvoiceDialog" :invoice-id="selectedItem?.referenceId" @confirm="onEditInvoiceConfirmed"/>
    <view-payment-dialog v-model="showViewPaymentDialog" :payment-id="selectedItem?.referenceId" />
    <view-activity-dialog v-model="showActivityDialog" :activity="selectedItem"/>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import { useEnum } from '../../composables/useEnum';
import ActivityStatus from '@cyber-range/cyber-range-api-balance-client/dist/interfaces/activityStatus';
import Config from '../../config';
import { ref, toRaw } from 'vue';
import { IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import { ActivityFilter, IActivity, IActivityFilter } from '@cyber-range/cyber-range-api-balance-client';
import { ActivityType } from '@cyber-range/cyber-range-api-balance-client/dist/interfaces/activityType';
import { useQueryFilter } from '../../composables/useQueryFilter';
import { onMounted } from 'vue';
import { useBalanceStore } from '../../stores/balanceStore';
import { useCalendar } from '../../composables/useCalendar';
import { useNumberFormats } from '../../composables/useNumberFormats'
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { VendibleType } from '@cyber-range/cyber-range-api-payment-client';
import ApplyFinanceChargeDialog from './dialogs/ApplyFinanceChargeDialog.vue';
import ViewPaymentDialog from './dialogs/ViewPaymentDialog.vue';
import EditInvoiceDialog from './dialogs/EditInvoiceDialog.vue';
import Route from '../../routers/route';
import ViewActivityDialog from './dialogs/ViewActivityDialog.vue';

const props = defineProps<
{
    organizationId: string
}>();

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { toCurrency } = useNumberFormats();
const { canUpdateBilling, canViewPayments } = useAuthorizationStore();

const emit = defineEmits<{
    (name: 'update'): void,
}>();

const headers = [
    new TableHeaderItem({ text:t('ACTIVITY_TABLE_TIMESTAMP'), key: 'createdTimestamp', sortable: false, formatter: (timestamp: string) => useCalendar().toYYYYMMDD(new Date(timestamp))}),
    new TableHeaderItem({ text:t('ACTIVITY_TABLE_DESCRIPTION'), key: 'notes', sortable: false}),
    new TableHeaderItem({ text:t('ACTIVITY_TABLE_STATUS'), key: 'status', sortable: false, formatter: (status:string)=>useEnum().toDisplayEnumName(ActivityStatus, status)}),
    new TableHeaderItem({ text:t('ACTIVITY_TABLE_CHANGED'), key: 'amount', sortable: false, formatter: toCurrency, align: "right"}),
    new TableHeaderItem({ text:t('ACTIVITY_TABLE_BALANCE'), key: 'balance', sortable: false, formatter: toCurrency, align: "right"}),
];
let filter: IActivityFilter;
const page = ref<IApiPageResponse<IActivity>>();
const selectedItem = ref<IActivity>();

const showApplyFinanceChargeDialog = ref(false);
const showViewPaymentDialog = ref(false);
const showActivityDialog = ref(false);
const showEditInvoiceDialog = ref(false);


const initializeFilter = () =>
{
    filter = useQueryFilter(ActivityFilter, {limit: Config.BALANCE_ACTIVITY_TABLE_PAGE_SIZE});
}

onMounted(async () =>
{
    initializeFilter();
    [page.value] = await Promise.all([refresh()])
})

const refresh = async (data: Partial<IActivityFilter> = {}) =>
{
    page.value = await useBalanceStore().listOrganizationBalanceActivities(props.organizationId, new ActivityFilter({...toRaw(filter), ...data}));
    return page.value;
}

const reload = async (data: Partial<IActivityFilter> = {}) =>
{
    Object.assign(filter, {...data});
}

const onLoadPreviousPage = () =>
{
    reload({token: page.value?.prevPageToken || ''});
}

const onLoadNextPage = () =>
{
    reload({token: page.value?.nextPageToken || ''});
}

const onLoadFirstPage = () =>
{
    reload({token: ''});
}

const onViewPaymentClicked = (item: IActivity) =>
{
    selectedItem.value = item;
    showViewPaymentDialog.value = true;
}

const onApplyFinanceChargeClicked = (item: IActivity) =>
{
    selectedItem.value = item;
    showApplyFinanceChargeDialog.value = true;
}

const onApplyFinanceChargeConfirmed = () =>
{
    refresh();
    emit("update");
}

const onEditInvoiceClicked = (item: IActivity) =>
{
    selectedItem.value = item;
    showEditInvoiceDialog.value = true;
}

const onEditInvoiceConfirmed = (changed: boolean) =>
{
    if (changed)
    {
        refresh();
        emit("update");
    }
}

const onViewBalanceActivityClicked = (item: IActivity) =>
{
    selectedItem.value = item;
    showActivityDialog.value = true;
}

defineExpose({refresh});
</script>