<template>
    <cr-confirmation-dialog  id="renewSubscriptionDialog" :title="t('RENEW_SUBSCRIPTION_TITLE')" @confirm="onConfirmed" :disabled="isLoading" @shown="onShown" data-testing="renew-subscription-dialog">
        <p>{{ renewMessageText }}</p>
        <cr-date-picker v-model="newEndDate" :min="minEndDate" :label="t('RENEW_SUBSCRIPTION_DATE_PICKER_LABEL')"></cr-date-picker>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { ISubscription } from '@cyber-range/cyber-range-api-subscription-client';
import { useSubscriptionStore } from '../../../stores/subscriptionStore';
import { ref, watch } from 'vue';
import { computed } from 'vue';
import { useCalendar } from '../../../composables/useCalendar';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { renewSubscription } = useSubscriptionStore();
const { toYYYYMMDD } = useCalendar();

const props = defineProps<
{
    subscription?: ISubscription;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const newEndDate = ref(props.subscription?.endDate);
// Cannot set the subscription to end in the past
const minEndDate = computed(() => toYYYYMMDD(new Date()));

const renewMessageText = computed(() => props.subscription?.endDate ? t('RENEW_SUBSCRIPTION_DESCRIPTION_WITH_END_DATE', {endDate: props.subscription.endDate}) : t('RENEW_SUBSCRIPTION_DESCRIPTION_WITHOUT_END_DATE'));

const populateDate = () =>
{
    newEndDate.value = props.subscription?.endDate;
}

const onConfirmed = async ()=>
{
    const endDateChanged = newEndDate.value !== props.subscription?.endDate;
    if(props.subscription && newEndDate.value && endDateChanged)
    {
        await renewSubscription(props.subscription.id, newEndDate.value.substring(0, 10));
        emit("confirm");
    }
}

const onShown = () =>
{
    populateDate();
}
</script>