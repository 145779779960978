export default {
    en: {
        SYNC_COURSE_USERS_PAGE_TITLE: "Sync Course Users",
        SYNC_COURSE_USERS_LOAD_MORE_BUTTON: "Load More Items",
        SYNC_COURSE_USERS_SYNC_NOW_BUTTON: "Sync Now",
        SYNC_COURSE_USERS_TIMESTAMP: "Timestamp",
        SYNC_COURSE_USERS_STATUS: "Status",
        SYNC_COURSE_USERS_ADDED: "Added",
        SYNC_COURSE_USERS_UPDATED: "Updated",
        SYNC_COURSE_USERS_DELETED: "Deleted",
        SYNC_COURSE_USERS_MESSAGE: "Message",
        SYNC_COURSE_USERS_DATE_FILTER_LABEL: "Sync Date",
        SYNC_COURSE_USERS_VIEW_HISTORY_ACTION: "View",

        // Schedule History Dialog
        SCHEDULE_HISTORY_DETAILS_TITLE: "Sync Result",
        SCHEDULE_HISTORY_DETAILS_TIMESTAMP: "Timestamp:",
        SCHEDULE_HISTORY_DETAILS_DURATION: "Duration:",
        SCHEDULE_HISTORY_DETAILS_STATUS: "Status:",
        SCHEDULE_HISTORY_DETAILS_MESSAGE: "Message:",
        SCHEDULE_HISTORY_TABLE_ACTION: "Action",
        SCHEDULE_HISTORY_TABLE_USER: "User",
        SCHEDULE_HISTORY_TABLE_ADD: "Add",
        SCHEDULE_HISTORY_TABLE_UPDATE: "Update",
        SCHEDULE_HISTORY_TABLE_DELETE: "Delete",
        SCHEDULE_HISTORY_DETAILS_CONFIRM_TEXT: "Close"
    }
}