import { OrganizationUserFilter } from "@cyber-range/cyber-range-api-user-client";
import { IOrganizationUsersPageFilter } from "../interfaces/iOrganizationUsersPageFilter";

export class OrganizationUsersPageFilter extends OrganizationUserFilter implements IOrganizationUsersPageFilter
{
    organizationId?: string;

    constructor(filter: Partial<IOrganizationUsersPageFilter>)
    {
        super(filter);

        this.organizationId = filter?.organizationId;
    }
}