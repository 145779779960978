import Config from "../config";
import { BreadcrumbItem, IBreadcrumbItem } from '@cyber-range/cyber-range-lib-ui'
import { Ref, watch } from "vue";
import { useWhoseCourse } from "./useWhoseCourse";
import Route from "../routers/route";
import { useAuthorizationStore } from "../stores/authorizationStore";

export function useCourseBreadcrumbs(breadcrumbs: IBreadcrumbItem[]): BreadcrumbItem[]
{
    let whoseCourse = useWhoseCourse();

    let result: IBreadcrumbItem[] = [];

    if(whoseCourse.value.mine)
    {
        for(let item of breadcrumbs)
        {
            let toRoute = Object.values(Route).find(route => route.name === item.name);

            if(toRoute.mine)
            {
                result.push({...item, ...toRoute.mine})
            }
            else
            {
                result.push(item);
            }
        }

        result;
    }
    else
    {
        result = breadcrumbs;
    }

    if (useAuthorizationStore().canNavigateOutsideCourse() === false)
    {
        result = result.filter(item => item.name !== Route.Courses.name && item.name !== Route.MyCourses.name);
    }

    return result;
}