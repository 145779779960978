<template>
    <dl class="tile-detail-section">
        <slot />
    </dl>
</template>

<style>
.tile-detail-section {
    display: grid;
    grid-template-columns: auto 1fr;
}
</style>
