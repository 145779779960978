<template>
    <section :id="id" :class="classes" data-testing="dashboard-section">
        <component v-if="label"  :is="labelTag || 'h2'" class="dashboard-section-title cr-mb3" data-testing="dashboard-section-title">
            {{ label }}
        </component>
        <cr-row v-if="hasNoItems" class="cr-mb5 cr-ml0 dashboard-section-no-items" data-testing="dashboard-section-no-items">
            {{ noItemsMessage || t('DASHBOARD_SECTION_NO_ITEMS') }}
        </cr-row>
        <cr-row v-else class="cr-mb3 dashboard-section-content" data-testing="dashboard-section-content">
            <slot />
        </cr-row>
    </section>
</template>

<style scoped>
.dashboard-section
{
    display: grid;
}
.dashboard-section-no-items
{
    justify-self: center;
    align-self: center;
}
.dashboard-section-title
{
    font-size:  var(--cr-section-title-font-size);
}
.dashboard-section-no-items,
.dashboard-section:has(> .cr-row),
.dashboard-section > .cr-row:has(> .cr-col),
.dashboard-section > .cr-row > .cr-col
{
    padding-left: 0;
}
</style>

<script setup lang="ts">
import { VNode, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUniqueId } from '../../../composables/useUniqueId'

const { t } = useI18n();

const props = defineProps<
{
    /**
     * Element ID
     */
    id?:string,

    /**
     * Section label
     */
    label?:string,

    /**
     * HTML tag to use for the section label.
     * @default h2
     */
    labelTag?:string,

    /**
     * The message to show if there no items to display.
     */
    noItemsMessage?:string
}>()

const id = props.id || useUniqueId();

const slots = defineSlots<{
    default(): VNode[]
}>()

const hasNoItems = computed(() => 
{
    return !slots.default || (slots.default().length === 0 || slots.default().every(node => node.children?.length === 0));
});

const classes = computed(()=>
{
    let result = ['dashboard-section'];

    if(hasNoItems.value)
    {
        result.push('cr-height-100');
    }

    return result;
});

</script>