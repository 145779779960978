import { defineStore } from 'pinia';
import { useApiClientStore } from './apiClientStore';
import { IPayment, IPaymentUrl, IPrice, IVendible } from '@cyber-range/cyber-range-api-payment-client';
export const usePaymentStore = defineStore('PaymentStore', 
{
    actions: 
    {
        async getPayment(paymentId: string): Promise<IPayment>
        {
            let paymentApiClient = useApiClientStore().paymentApiClient;

            return await paymentApiClient.getOne(paymentId);
        },

        async createVendible(vendible: Partial<IVendible>): Promise<string>
        {
            let vendibleApiClient = useApiClientStore().vendibleApiClient;

            return await vendibleApiClient.create(vendible);
        },

        async createOrganizationVendible(organizationId: string, price: IPrice): Promise<IPaymentUrl>
        {
            let vendibleApiClient = useApiClientStore().vendibleApiClient;

            return await vendibleApiClient.createOrganizationVendible(organizationId, price);
        },
    }
}
);