<template>
    <table-layout :title="t('ACCOUNT_APPROVALS_PAGE_TITLE')" :breadcrumbs="breadcrumbs">
       <template #table>
            <cr-table :items="page.items" :headers="headers" actionable :loading="isLoading" @suggestedNumberOfItems="onSuggestedNumberOfItems" data-testing="account-approvals-table">
                <template v-slot:action="{item}">
                    <cr-table-action-item :item="item" @click="onApproveAccountClicked" icon="bi-check" data-testing="account-approvals-table-approve-action">
                        {{ t('ACCOUNT_APPROVALS_APPROVE') }}
                    </cr-table-action-item>
                    <cr-table-action-item :item="item" @click="onRejectAccountClicked" icon="bi-x" data-testing="account-approvals-table-reject-action">
                        {{ t('ACCOUNT_APPROVALS_REJECT') }}
                    </cr-table-action-item>
                </template>
            </cr-table>
       </template>

       <template #pagination>
           <cr-pagination :previous="!!page.prevPageToken" :first="true" :next="!!page.nextPageToken" @first="onLoadFirstPage" @previous="onLoadPreviousPage" @next="onLoadNextPage" :loading="isLoading" data-testing="account-approvals-table-pagination"/>
       </template>
   </table-layout>

    <account-approved-dialog v-model="showAccountApprovedDialog" @confirm="onAccountApprovedCompletedConfirm"/>
    <account-rejected-dialog v-model="showAccountRejectedDialog" @confirm="onAccountRejectedCompletedConfirm"/>
</template>

<script setup lang="ts">
import { UserRole } from '@cyber-range/cyber-range-api-user-client';
import { BreadcrumbItem, TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useArtificialPaging } from '../../composables/useArtificiailPaging';
import { useEnum } from '../../composables/useEnum';
import Route from '../../routers/route';
import { useInvitationStore } from '../../stores/invitationStore';
import { useOrganizationStore } from '../../stores/organizationStore';
import TableLayout from '../layouts/TableLayout.vue';
import { useCalendar } from '../../composables/useCalendar';
import { useAccountApprovals } from '../../composables/useAccountApprovals';
import AccountApprovedDialog from './dialogs/AccountApprovedDialog.vue';
import AccountRejectedDialog from './dialogs/AccountRejectedDialog.vue';

const { t } = useI18n();
const isLoading = ref(false);
const invitationStore = useInvitationStore();
const organizationStore = useOrganizationStore();
const { toDisplayEnumName } = useEnum();
const { toHumanDate } = useCalendar()

const breadcrumbs = [new BreadcrumbItem(Route.AccountApprovals)];

const {
    showAccountApprovedDialog,
    onApproveAccountClicked,
    showAccountRejectedDialog,
    onRejectAccountClicked
} = useAccountApprovals();
const onAccountApprovedCompletedConfirm = invitationStore.fetchPendingApproval;
const onAccountRejectedCompletedConfirm = invitationStore.fetchPendingApproval;

const headers = [
    new TableHeaderItem({ text: 'Name', key: 'name' }),
    new TableHeaderItem({ text: 'Email', key: 'email' }),
    new TableHeaderItem({ text: 'Organization', key: 'organizationId', formatter: (organizationId) => organizationStore.organizations.find(org => org.id === organizationId)?.name ?? organizationId }),
    new TableHeaderItem({ text: 'Business Unit', key: 'businessUnit' }),
    new TableHeaderItem({ text: 'Requested Role', key: 'roles', formatter: (roles) => toDisplayEnumName(UserRole, roles[0]) }),
    new TableHeaderItem({ text: 'Request Date', key: 'createdTimestamp', formatter: toHumanDate }),
];

const { pendingInvitations } = storeToRefs(invitationStore)
const {
    page,
    onSuggestedNumberOfItems,
    onLoadPreviousPage,
    onLoadNextPage,
    onLoadFirstPage,
} = useArtificialPaging(pendingInvitations);

const refresh = async () => 
{
    isLoading.value = true;
    try
    {
        await Promise.all([
            invitationStore.fetchPendingApproval(),
            organizationStore.fetchOrganizations(),
            organizationStore.fetchOrganizationNameAndLogo()
        ]);
    }
    finally
    {
        isLoading.value = false;
    }
}
onMounted(async ()=> await refresh());
</script>
