<template>
    <cr-row v-for="resource in resources" :data-testing="`catalog-resource-${resource.text}`">
        <cr-external-link :href="resource.url" @click.prevent="onResourceClicked(resource.url)">                    
            {{ resource.text }}
        </cr-external-link>
    </cr-row>
</template>

<style scoped>

</style>

<script setup lang="ts">

import Config from '../../config';
import { ICatalogEntry } from '@cyber-range/cyber-range-api-catalog-client';
import { computed } from 'vue';
import { useAuthenticationStore } from '../../stores/authenticationStore';
import { InstructionType } from '../../entities/instructionType';

const props = defineProps<
{
    catalogEntry: ICatalogEntry|undefined;
}>();

const resources = computed (() =>
{
    const resources:{ text:string, url:string }[] = [];

    for(let instruction of props.catalogEntry?.instructions || [])
    {
        if(instruction.value)
        {
            let baseUrl:string|undefined = undefined;
            switch(instruction.type)
            {
                case InstructionType.Courseware:
                    baseUrl = Config.COURSEWARE_UI_BASE_URL;
                    break;
                case InstructionType.Kb:
                    baseUrl = Config.KB_BASE_URL;
                    break;
            }
            resources.push({
                text: instruction.name,
                url: baseUrl ? new URL(instruction.value, baseUrl).href : instruction.value
            });
        }
    }
    return resources;
});

const onResourceClicked = async (resourceUrl:string) =>
{
    let url = new URL(resourceUrl);
    url.searchParams.set('s', await useAuthenticationStore().getSsoToken());
    window.open(url.href, "_blank");
}
</script>