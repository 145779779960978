<template>
    <cr-confirmation-dialog :title="t('PROFILE_EDIT_ORGANIZATION_MEMBERSHIP_ROLE_DIALOG_TITLE')" @confirm="onConfirmed" :disabled="isLoading" data-testing="edit-organization-membership-roles-dialog">
        <form-section :label="t('PROFILE_ORGANIZATION_MEMBERSHIP_ROLE_FORM_ROLES_PICKER_LABEL')" data-testing="edit-organization-membership-form-roles-section" class="cr-mb5">
            <role-picker v-model="formRoles" :roles="availableRoles" data-testing="edit-organization-membership-form-roles-picker"/>
        </form-section>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import FormSection from '../../layouts/sections/FormSection.vue';
import IProfileMembership from '../../../interfaces/iProfileMembership';
import { IUser, UserRole } from '@cyber-range/cyber-range-api-user-client';
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useAuthorizationStore } from '../../../stores/authorizationStore';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '../../../stores/userStore';
import RolePicker from '../../pickers/RolePicker.vue';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { canInviteOrganizationAdmin, canInviteBillingAdmin, canInviteCourseAdmin, canInviteOrganizationInstructor, canInviteCoursewareAdmin, canInviteOrganizationStudent, canInviteOrganizationTa } = useAuthorizationStore();

const formRoles = ref<UserRole[]>([]);

const props = defineProps<{
    user?: IUser,
    membership?: IProfileMembership
}>()

const emit = defineEmits<{
    (name: 'confirm'): void
    (name: 'cancel'): void
}>();

const populateRoles = ()=>
{
    let propMemberships = props.membership?.roles || new Set([]);
    formRoles.value = [...propMemberships] as UserRole[];
};

watch(()=>props.membership, populateRoles, {immediate:true});

const availableRoles = computed(()=>
{
    let results = [];

    if(canInviteOrganizationAdmin(props.membership?.organizationId as string))
    {
        results.push(UserRole.OrganizationAdmin);
    }
    if(canInviteBillingAdmin(props.membership?.organizationId as string))
    {
        results.push(UserRole.BillingAdmin);
    }
    if(canInviteCourseAdmin(props.membership?.organizationId as string))
    {
        results.push(UserRole.CourseAdmin);
    }
    if(canInviteOrganizationInstructor(props.membership?.organizationId as string))
    {
        results.push(UserRole.OrganizationInstructor);
    }
    if(canInviteCoursewareAdmin(props.membership?.organizationId as string))
    {
        results.push(UserRole.CoursewareAdmin);
    }
    if(canInviteOrganizationStudent(props.membership?.organizationId as string))
    {
        results.push(UserRole.OrganizationStudent);
    }
    if(canInviteOrganizationTa(props.membership?.organizationId as string))
    {
        results.push(UserRole.OrganizationTA);
    }
    
    return results;
});

const onConfirmed = async ()=>
{
    await useUserStore().updateOrganizationUserRoles(props.user?.id as string, props.membership?.organizationId as string, formRoles.value as UserRole[]);
    emit('confirm');
}
</script>