import { defineStore } from 'pinia';
import { useApiClientStore } from './apiClientStore';
import { IBalance, IActivityFilter, IActivity } from '@cyber-range/cyber-range-api-balance-client'
import { IApiPageResponse } from '@cyber-range/cyber-range-api-client';
export const useBalanceStore = defineStore('BalanceStore', 
{
    actions: 
    {
        async getOrganizationBalance(organizationId: string): Promise<IBalance>
        {
            let balanceApiClient = useApiClientStore().balanceApiClient;

            return await balanceApiClient.getOneOrganizationBalance(organizationId);
        },

        async getBalanceActivity(activityId: string): Promise<IActivity>
        {
            let balanceApiClient = useApiClientStore().balanceApiClient;

            return await balanceApiClient.getOneBalanceActivity(activityId);
        },

        async createOrganizationBalanceActivity(organizationId: string, activity: Partial<IActivity>): Promise<{id: string, balance: number}>
        {
            let balanceApiClient = useApiClientStore().balanceApiClient;

            return await balanceApiClient.createOrganizationBalanceActivity(organizationId, activity);
        },

        async listOrganizationBalanceActivities(organizationId: string, filter?: IActivityFilter, options?: {background?: boolean}): Promise<IApiPageResponse<IActivity>>
        {
            let balanceApiClient = options?.background ? useApiClientStore().backgroundBalanceApiClient : useApiClientStore().balanceApiClient;

            let page = await balanceApiClient.listOrganizationBalanceActivities(organizationId, filter);

            return page;
        }
    }
});