import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import Config from '../../config';
import { useAuthenticationStore } from '../../stores/authenticationStore';
import { useSessionStore } from '../../stores/sessionStore';

export default async function requireAuth(to: RouteLocationNormalized, 
                                          from: RouteLocationNormalized, 
                                          next: NavigationGuardNext, 
                                          authenticationStore = useAuthenticationStore(),
                                          sessionStore = useSessionStore()
)                                       : Promise<void>
{
    if(to.query['s'])
    {
        if(await authenticationStore.authenticate('sso', to.query['s'] as string))
        {
            delete to.query['s'];
            return next({ ...to, replace: true});
        }        
    }
    else if(to.query['t'])
    {
        if(await authenticationStore.authenticate('vcr', to.query['t'] as string))
        {
            delete to.query['t']
            return next({ ...to, replace: true });
        }        
    }
    else if(sessionStore.token && await sessionStore.resume())
    {
        return next();
    }
    
    window.location.href = new URL(`?redirect_url=${location.href}`, Config.LOGIN_UI_BASE_URL).href;
}