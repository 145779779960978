<template>
    <cr-button @click="getTokenClicked" class="classroom-button"> 
        <cr-image src="/google_classroom_logo.png" alt="" class="classroom-icon cr-pr2"/>
        {{ t('GOOGLE_CLASSROOM_BUTTON_TEXT') }} 
    </cr-button>
</template>

<style scoped>
.classroom-icon
{
    width: 2.6rem;
}
.classroom-button
{
    color: var(--cr-black);
    height: 3.2rem; 
    width: 13rem;
    background-color: var(--cr-white);
    font-family: "Roboto", sans-serif;
    border: .1rem solid var(--cr-grey);
}
</style>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useErrorStore } from '../../../stores/errorStore';
import { useI18n } from 'vue-i18n';
import Config from '../../../config';
import { classroomScopes } from '../../../composables/useGoogleClassroom';
const emit = defineEmits<{
    (name: 'signInSuccess', value:string): void
}>();

const { t } = useI18n();

const tokenClient = ref<{ requestAccessToken:()=>void }>()

const onRequestAccessTokenSuccess = (tokenResponse:{access_token:string}) =>
{
    emit('signInSuccess', tokenResponse.access_token);
}

const initilizeTokenClient = async (callback:(tokenResponse:{access_token:string})=>void)=>
{
    const googleOauth2:{ initTokenClient:(config:{ client_id:string, scope:string, prompt:string, callback:(tokenResponse:{access_token:string})=>void})=>{requestAccessToken:()=>void} } = ((window as any)['google'] as any)?.accounts?.oauth2;

    if(googleOauth2)
    {
        tokenClient.value = googleOauth2.initTokenClient({
            client_id: Config.GOOGLE_CLIENT_ID,
            scope: classroomScopes().join(' '),
            prompt:"select_account",
            callback
        })
    }
}

const getTokenClicked = async ()=>
{
    tokenClient.value?.requestAccessToken();
}

onMounted( async ()=>
{
    await initilizeTokenClient(onRequestAccessTokenSuccess);
});

</script>

