<template>
    <cr-filter icon="bi-arrow-down-up" :label="label || t('SORTING_LABEL')" :selected-values="selectedValues" :disabled="disabled || isLoading" :data-testing="dataTesting">
        <cr-autocomplete v-model="sortBy" :label="label || t('SORTING_LABEL')" :items="sortingOptions" :disabled="isLoading" :data-testing="sortByDataTesting" />
        <cr-select v-model="sortOrder" :label="t('SORTING_ORDER_LABEL')" :items="sortingOrders" :disabled="isLoading" class="cr-mt3" :data-testing="sortOrderDataTesting" />
    </cr-filter>
</template>

<style scoped>
.cr-filter:deep(.cr-dropdown-toggle),
.cr-filter:deep(.cr-dropdown-list)
{
    min-width: 12rem;
}
</style>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useApiClientStore } from '../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { SelectItem, useEnum } from '../../composables/useEnum';
import { SortOrder } from '@cyber-range/cyber-range-api-client';

const props = defineProps<
{
    /**
     * SortBy
     */
    modelValue: string | undefined;

    /**
     * SortOrder
     * @default SortOrder.Asc
     */
    modelSortOrder: string | undefined;

    /**
     * What properties can be sortable.
     */
    sortingOptions: SelectItem[]

    /**
     * Whether the filter is disabled.
     * @default false
     */
    disabled?: boolean

    /**
     * Label on the filter
     * @default t('SORTING_LABEL')
     */
    label?: string

    /**
     * data-testing
     * @default 'sorting'
     */
    dataTesting?: string
}>();

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const emit = defineEmits<{
    (name: 'update:modelValue', sortBy?: string): void
    (name: 'update:modelSortOrder', sortOrder?: string): void
}>();

function toSortOrderDisplayName(order?:string|number): string
{
    return order === SortOrder.Asc ? t('SORTING_ORDER_ASC') : t('SORTING_ORDER_DESC');
}

const sortBy = ref<string|undefined>(props.modelValue);
const sortOrder = ref<string|undefined>(props.modelSortOrder || SortOrder.Asc);
const selectedValues = ref<string[]>();
const sortingOrders = useEnum().toSelectItems(SortOrder).map(i => ({id: i.id, name: toSortOrderDisplayName(i.id)}));
const dataTesting = props.dataTesting || 'sorting';
const sortByDataTesting = `${dataTesting}-sort-by`;
const sortOrderDataTesting = `${dataTesting}-sort-order`;

function setSelectedValues()
{
    if(sortBy.value)
    {
        let sortByDisplayName = props.sortingOptions.find(i => i.id === sortBy.value)?.name;
        let sortOrderDisplayName = toSortOrderDisplayName(sortOrder.value);
        selectedValues.value = [`${sortByDisplayName}, ${sortOrderDisplayName}`] 
    }
    else
    {
        selectedValues.value = [] 
    }
}

watch(sortBy, ()=>
{
    if(!sortBy.value) { sortOrder.value = SortOrder.Asc; }
    if(sortBy.value === props.modelValue) return;
    emit('update:modelValue', sortBy.value);
    setSelectedValues();
});

watch(sortOrder, ()=>
{
    if(sortOrder.value === props.modelSortOrder) return;
    emit('update:modelSortOrder', sortOrder.value);
    setSelectedValues();
});

watch(()=>props.modelValue, ()=>
{
    sortBy.value = props.modelValue;
    setSelectedValues();
}, {immediate: true});

watch(()=>props.modelSortOrder, ()=>
{
    sortOrder.value = props.modelSortOrder;
    setSelectedValues();
}, {immediate: true});
</script>