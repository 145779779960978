<template>
    <read-layout :title="feature.name" :breadcrumbs="breadcrumbs" :ready="ready">

        <template #top>
            <price-section :feature="feature" />
            <limit-section :limits="feature.limits" />
        </template>
        
    </read-layout>
</template>

<script setup lang="ts">
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, onMounted, ref } from 'vue';
import Route from '../../routers/route';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import ReadLayout from '../layouts/ReadLayout.vue';
import { usePlanStore } from '../../stores/planStore';
import { useI18n } from 'vue-i18n';
import { useErrorStore } from '../../stores/errorStore';
import { ApiClientError } from '@cyber-range/cyber-range-api-client';
import PriceSection from './sections/priceSection.vue';
import LimitSection from './sections/limitSection.vue';
import { IFeature } from '../../interfaces/iFeature';
import { Feature } from '../../entities/Feature';
import { useRouter } from 'vue-router';

const props = defineProps<
{
    addonId:string;
}>();

const { t } = useI18n();

const ready = ref<boolean>(false)
const feature = ref<IFeature>(new Feature({}));
const router = useRouter();

const breadcrumbs = computed(()=>useCourseBreadcrumbs([
                             new BreadcrumbItem(Route.Plans),
                             new BreadcrumbItem(Route.Features),
                             new BreadcrumbItem({...Route.Feature,  text: feature.value?.name, params: {addonId: props.addonId}})
                         ]));

onMounted(async ()=>
{
    let planStore = usePlanStore();

    await planStore.fetchPlansAndFeatures();

    let selectedFeature = usePlanStore().features.get(props.addonId);
    
    if(selectedFeature)
    {
        feature.value = selectedFeature
    }
    else
    {
        useErrorStore().setError(new ApiClientError(t('FEATURE_NOT_FOUND', {addonId: props.addonId})));
        router.push(Route.Features);
    }

    ready.value = true;
})
</script>