<template>
    <cr-warning-text v-if="durationRemainingIsLow" :message="lowDurationWarningMessage" data-testing="exercise-low-duration-warning-text"/>
</template>

<script setup lang="ts">
import { IExercise } from '@cyber-range/cyber-range-api-exercise-client';
import { computed } from 'vue';
import { useCalendar } from '../../composables/useCalendar';
import { useExerciseDurationWarnings } from '../../composables/useExerciseDurationWarnings';
import { useI18n } from "vue-i18n";

const { toHumanDuration } = useCalendar();

const props = defineProps<{
    exercise: IExercise
}>();
const { t } = useI18n();

const {
    remainingExerciseDurationInMs,
    durationRemainingIsLow,
    isDepleted
} = useExerciseDurationWarnings(computed(()=>props.exercise))

const lowDurationWarningMessage = computed(() =>
{
    return isDepleted.value === true
        ? t("EXERCISE_REMAINING_DURATION_DEPLETED_MESSAGE")
        : t("EXERCISE_REMAINING_DURATION_THIS_MONTH",{ remaining: toHumanDuration(remainingExerciseDurationInMs.value) });
});
</script>
