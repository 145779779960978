<template>
    <article class="cr-mb5 search-result-item" data-testing="search-result-item">
        <h2 class="name">
            <cr-optional-container :condition="to" tag="router-link" :to="to">
                <cr-html :value="name" />
            </cr-optional-container>
        </h2>
        <p>
            <span class="type cr-mr2 cr-font-bold">{{ type }}</span> 
            <cr-html :value="description" class="search-result-item-description"/>
        </p>
    </article>
</template>

<style scoped>
.name
{
    font-size: calc(var(--cr-body-font-size) * 1.2);
}
.name a
{
    color: var(--cr-primary-link);
}
.type::after
{
    content: "|";
    margin-left: 0.5rem;
    vertical-align: text-bottom;
    color: var(--cr-caption-color);
}
.search-result-item:deep(em)
{
    font-weight: bold;
    font-style: inherit;
    background-color: var(--cr-highlight-background-color);
}
.search-result-item-description
{
    overflow-wrap: break-word;
}
</style>

<script setup lang="ts">
import { ISearchResult, ItemType } from '@cyber-range/cyber-range-api-search-client';
import { computed } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { useEnum } from '../../composables/useEnum';
import Route from '../../routers/route';

const props = defineProps<
{
    searchResult: ISearchResult & {email?:string,description?:string}
}>();

const { toDisplayEnumName } = useEnum();

const to = computed<RouteLocationRaw|undefined>(()=>
{
    switch(props.searchResult.type)
    {
        case ItemType.Course:
            return {...Route.Course, params: { courseId: props.searchResult.courseId }};
        case ItemType.Organization:
            return {...Route.Organization, params: { organizationId: props.searchResult.organizationId }};
        case ItemType.ExerciseGroup:
            return {...Route.ExerciseGroup, params: { courseId: props.searchResult.courseId, exerciseGroupId: props.searchResult.exerciseGroupId }};
        case ItemType.User:
            return {...Route.Profile, params: { userId: props.searchResult.id }};
    }
    return undefined
})

const type = computed(()=>
{
    const enumName = toDisplayEnumName(ItemType, props.searchResult.type)
    return enumName === "Exercise Group" ? "Exercise Environment" : enumName;
});

const name = computed(()=>
{
    let matchedName = (props.searchResult.matched || []).find(i => i.name.toLocaleLowerCase() === 'name');

    return matchedName ? matchedName.value : props.searchResult.name;
});

const description = computed(()=>
{
    let matchedDescription = (props.searchResult.matched || []).find(i => i.name.toLocaleLowerCase() === 'description');

    if(matchedDescription) return matchedDescription.value;
    
    let matchedEmail = (props.searchResult.matched || []).find(i => i.name.toLocaleLowerCase() === 'email');

    return matchedEmail?.value || props.searchResult.description || props.searchResult.email;
});
</script>