import { IClaim } from "@cyber-range/cyber-range-api-entitlement-client";
import * as Claims from '@cyber-range/cyber-range-shared-claims';
import { defineStore } from "pinia";
import { useApiClientStore } from "./apiClientStore";
import { ApiRequestConfig } from "@cyber-range/cyber-range-api-client";

export const useEntitlementStore = defineStore('entitlementStore', 
{
    state: () => 
    ({
        claims: <Record<string,IClaim>> {},
        myCourseIds: new Set<string>(),
        scopes: <string[]> []
    }),
    
    actions: 
    {
        hasClaim(type:string, valueOrValues:string|string[], scopeOrScopes:string|string[]): boolean
        {
            let values = Array.isArray(valueOrValues) ? [...new Set(valueOrValues)] : [valueOrValues];
            let scopes = Array.isArray(scopeOrScopes) ? [...new Set(scopeOrScopes)] : [scopeOrScopes];

            for(let value of values)
            {
                for(let scope of scopes)
                {
                    if(getClaimId(type, value, scope) in this.claims)
                    {
                        return true;
                    }
                }
            }

            return false;
        },

        getClaims(type:string, valueOrValues:string|string[]|undefined, scopeOrScopes:string|string[]): IClaim[]
        {
            let result:IClaim[] = [];
            let table:Record<string, boolean> = {};
            let values = valueOrValues === undefined ? Object.values(this.claims).map((claim:IClaim) => claim.value):
                                            (Array.isArray(valueOrValues)?
                                            [...new Set(valueOrValues)] : [valueOrValues]);
            let scopes = Array.isArray(scopeOrScopes) ? [...new Set(scopeOrScopes)] : [scopeOrScopes];

            for(let value of values)
            {
                for(let scope of scopes)
                {
                    let key = getClaimId(type, value, scope);
                    if(key in this.claims && !(key in table))
                    {
                        result.push(this.claims[key]);
                        table[key] = true;
                    }
                }
            }

            return result;
        },

        async fetchClaims(): Promise<void>
        {
            let claims:IClaim[] = await useApiClientStore().entitlementApiClient.listClaims();
            
            let result:Record<string,IClaim>  = {};
            let newCourseId = new Set<string>();
            let scopes = new Set<string>();

            for(let claim of claims)
            {
                for(let scope of claim.scopes)
                {
                    result[getClaimId(claim.type, claim.value, scope)] = claim;

                    scopes.add(scope);
                }

                if(claim.type === Claims.Type.COURSE)
                {
                    newCourseId.add(claim.value);
                }
            }

            this.claims = result;
            this.myCourseIds = newCourseId;
            this.scopes = Array.from(scopes);
        },

        async invalidateUserClaimsCache(userId: string): Promise<void>
        {
            await useApiClientStore().entitlementApiClient.invalidateUserClaimsCache(userId);
        }
    }
});

function getClaimId(type:string, value:string, scope:string)
{
    return `${scope || ''}::${type || ''}::${value || ''}`;
}
