<template>
    <search-filter-section :label="t('SEARCH_FILTER_SECTION_LABEL')">
        <search-filter-section-item v-if="canManageOrganization()" :label="t('SEARCH_FILTER_ORGANIZATION')" filter='organization' class="cr-mb3" data-testing="search-filter-organization" />
        <search-filter-section-item v-if="canListCourses()" :label="t('SEARCH_FILTER_COURSE')" filter='course' class="cr-mb3" data-testing="search-filter-course" />
        <search-filter-section-item :label="t('SEARCH_FILTER_USER')" filter='user' class="cr-mb3" data-testing="search-filter-user" />
        <search-filter-section-item v-if="canListCourses()" :label="t('SEARCH_FILTER_EXERCISE_ENVIRONMENT')" filter='exerciseenvironment' class="cr-mb3" data-testing="search-filter-exerciseenvironment" />
    </search-filter-section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import SearchFilterSection from '../layouts/sections/SearchFilterSection.vue';
import SearchFilterSectionItem from '../layouts/sections/SearchFilterSectionItem.vue';

let props = defineProps<{
    modelValue?:string
}>();

const emit = defineEmits<{
    (e:'update:modelValue', filter:string|undefined): void,
}>();

const { t } = useI18n();
const { canManageOrganization, canListCourses } = useAuthorizationStore();
</script>