<template>
    <cr-navigation-bar :label="t('HEADER_NAVIGATION_BAR_LABEL')" :border="true" :condense="true" height="var(--cr-nav-bar-height)">
        <cr-navigation-bar-title :text="t('HEADER_TITLE')" :image="Config.DEFAULT_LOGO_URL" :condense='true'  :to="Route.Home"/>
        <cr-navigation-bar-content>
            <cr-spacer />
            <template v-if="timeRemainingInSeconds === 0">
                <span class="title" data-testing="range-session-ended">{{ t('RANGE_EXERCISE_ENDED_MESSAGE') }}</span>
                <cr-spacer/>
            </template>
            <template v-else>
                <span>
                    <cr-external-link :href="environmentTimeRemainingLink" data-testing="range-time-remaining-help-link">
                        <span class="title cr-mr1">{{ t('RANGE_ENVIRONMENT_TIME_REMAINING') }}</span>
                    </cr-external-link>
                    <span class="title" data-testing="range-time-remaining">{{ timeRemainingFormatted }}</span>
                </span>

                <span v-if="showClockOutOfSyncMessage" class="cr-ml3" data-testing="range-clock-out-of-sync">
                    (
                    <cr-external-link :href="clockOutOfSync">
                        {{ t('RANGE_CLOCK_OUT_OF_SYNC_MESSAGE') }}
                    </cr-external-link>
                    )
                </span>

                <cr-spacer />

                <cr-loading v-if="isExtending" :message="t('RANGE_EXTENDING_ENVIRONMENT_TIME')" class="mr-3" data-testing="range-session-extending"/>
                <span v-else-if="extensionTimePeriod" data-testing="range-session-extended">
                    <cr-icon color="success">bi-check</cr-icon> {{ t('RANGE_EXERCISE_EXTENSION_SUCCESS_MESSAGE', { timePeriod: extensionTimePeriod }) }}
                </span>
                <cr-tooltip v-else-if="showExtendSessionButton" :title="extendButtonDisabledTooltip" data-testing="range-extend-session-button-tooltip">
                    <cr-button :disabled="!!extendButtonDisabledTooltip" text @click="onExtendSessionButtonClicked" data-testing="range-extend-session-button">
                        {{ t('RANGE_EXTEND_SESSION_BUTTON') }}
                    </cr-button>
                </cr-tooltip>

                <cr-button dialog="#terminate-session-dialog" data-testing="range-terminate-session-button">
                    <cr-icon>bi-trash</cr-icon>{{ t('RANGE_EXERCISE_EARLY_TERMINATION_BUTTON') }}
                </cr-button>
                <cr-confirmation-dialog id="terminate-session-dialog" :title="t('RANGE_EXERCISE_EARLY_TERMINATION_DIALOG_TITLE')" checkbox :confirmation-message="t('RANGE_EXERCISE_EARLY_TERMINATION_DIALOG_UNDERSTANDING_LABEL')" :confirm-text="t('RANGE_EXERCISE_EARLY_TERMINATION_DIALOG_CONFIRM')" @confirm="onTerminateConfirmed">
                    <span data-testing="range-terminate-session-dialog-body">{{ t('RANGE_EXERCISE_EARLY_TERMINATION_DIALOG_CONFIRMATION_TEXT') }}</span>
                </cr-confirmation-dialog>
            </template>
        </cr-navigation-bar-content>
    </cr-navigation-bar>
</template>

<style scoped>
.title {
    font-size: 1.25rem;
    font-weight: bold;
}
.cr-external-link {
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}
:deep(.cr-external-link-icon) {
    margin: 0 0.25rem 0 0 !important;
}
</style>

<script setup lang="ts">
import { Exercise, IExercise, SessionExpirationDetachOptions } from '@cyber-range/cyber-range-api-exercise-client';
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useCalendar } from "../../composables/useCalendar";
import Config from "../../config";
import Route from "../../routers/route";
import { useExerciseStore } from "../../stores/exerciseStore";
import { useKbStore } from "../../stores/kbStore";
import { useTimerStore } from "../../stores/timerStore";

const props = defineProps<{
    exercise: IExercise,
    showClockOutOfSyncMessage: boolean
}>();
const emit = defineEmits<{
    (name: 'session-ended'): void
    (name: 'update:exercise', exercise: IExercise): void
}>();

const { t } = useI18n();
const exerciseStore = useExerciseStore();
const timerStore = useTimerStore();
const { toHumanDuration, toHumanDurationDigital } = useCalendar();

const kbStore = useKbStore();
const environmentTimeRemainingLink = kbStore.environmentTimeRemaining()
const clockOutOfSync = kbStore.clockOutOfSync()

const timeRemainingInSeconds = ref(0);
const timeRemainingFormatted = computed(() => toHumanDurationDigital(timeRemainingInSeconds.value))

function getRemainingTimeInSeconds()
{
    const remainingTime = Math.floor((endDate.value.getTime() - new Date().getTime()) / 1000);
    return Math.max(0, remainingTime);
}

const endDate = computed(() => new Date(props.exercise.sessionExpirationTimestamp))

let timerId:string = '';

async function endSession()
{
    timerStore.unschedule(timerId);
    timeRemainingInSeconds.value = 0;

    try
    {
        await exerciseStore.detach(props.exercise.id, new SessionExpirationDetachOptions(props.exercise.sessionExpirationTimestamp));
    }
    catch (error) {}
    emit('session-ended');
}
const onTerminateConfirmed = endSession;

const extendButtonDisabledTooltip = computed(() =>
{
    if (props.exercise.remainingSessionExtensions <= 0)
    {
        return t('RANGE_EXERCISE_EXTENSION_DISABLED_NO_EXTENSIONS_REMAINING');
    }

    const remainingAccessDurationAfterExpiration = Exercise.remainingNonPersistentAccessDurationThisPeriod(props.exercise, Config.EXERCISE_MAX_NON_PERSISTENT_ACCESS_DURATION_PER_PERIOD_IN_HOURS, endDate.value.getTime())
    if (remainingAccessDurationAfterExpiration <= 0)
    {
        return t('RANGE_EXERCISE_EXTENSION_DISABLED_NO_DURATION_REMAINING');
    }

    return '';
});

onMounted(() =>
{
    timeRemainingInSeconds.value = getRemainingTimeInSeconds();
    timerId = timerStore.schedule(() =>
    {
        timeRemainingInSeconds.value = getRemainingTimeInSeconds();
        if (timeRemainingInSeconds.value <= 0)
        {
            endSession();
        }
    }, undefined, 1000, true);
});
onUnmounted(() => timerStore.unschedule(timerId));

// Session Extension
const showExtendSessionButton = computed(() => timeRemainingInSeconds.value < 600) //10 minutes
const isExtending = ref(false);
const extensionTimePeriod = ref('');
async function onExtendSessionButtonClicked()
{
    isExtending.value = true;
    try
    {
        const result = await exerciseStore.extend(props.exercise.id);
        const exercise = await exerciseStore.getOne(props.exercise.id);

        const oldTime = endDate.value.getTime();
        const newTime = new Date(result.sessionExpirationTimestamp).getTime();
        extensionTimePeriod.value = toHumanDuration(newTime - oldTime);

        emit('update:exercise', exercise);

        // Hide exercise success message after a couple of seconds
        timerStore.schedule(() => extensionTimePeriod.value = '', undefined, 5000, false);
    }
    finally
    {
        isExtending.value = false;
    }
}
</script>
