<template>
    <form-section :label="t('SUBSCRIPTION_FORM_PLAN_SECTION')">
        <cr-select v-model="model.productId" :label="t('SUBSCRIPTION_FORM_PLAN_SELECT_LABEL')" :items="availablePlans" item-value='productId' required data-testing="subscription-form-plan-select"/>
    </form-section>
    <template v-if="model.productId">
        <form-section :label="t('SUBSCRIPTION_FORM_FEATURES_SECTION')">
            <feature-picker v-model="model.addOns" :plan="selectedPlan"/>
        </form-section>
        <form-section :label="t('SUBSCRIPTION_FORM_DURATION_SECTION')">
            <cr-checkbox v-model="neverExpires" :name="t('SUBSCRIPTION_FORM_NEVER_EXPIRES')" :label="t('SUBSCRIPTION_FORM_NEVER_EXPIRES')" data-testing="subscription-form-never-expires"/>
            <cr-row>
                <cr-col xs12 sm6>
                    <cr-date-picker v-model="model.startDate" :max="model.endDate" :label="t('SUBSCRIPTION_FORM_START_DATE')" required data-testing="subscription-form-start-date"/>
                </cr-col>
                <cr-col xs12 sm6>
                    <cr-date-picker v-if="!neverExpires" v-model="model.endDate" :min="model.startDate" :label="t('SUBSCRIPTION_FORM_END_DATE')" required data-testing="subscription-form-end-date"/>
                </cr-col>
            </cr-row>
        </form-section>
    </template>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import FormSection from '../layouts/sections/FormSection.vue';
import FeaturePicker from '../pickers/FeaturePicker.vue';
import { ISubscription } from '@cyber-range/cyber-range-api-subscription-client';
import { useI18n } from 'vue-i18n';
import { usePlanStore } from '../../stores/planStore';
import { IPlan } from '../../interfaces/iPlan';

const { t } = useI18n();

const model = defineModel<ISubscription>({required: true});

const byName = (plan1: IPlan, plan2: IPlan) => (plan1.name || "").localeCompare((plan2.name || ""), undefined, { sensitivity: 'base', numeric: true });
const availablePlans = computed(() =>  usePlanStore().allPlans().sort(byName));

const selectedPlan = computed(() => availablePlans.value?.find(plan => plan.productId === model.value.productId));
const neverExpires = ref<boolean>(false);

watch(neverExpires, () => model.value.endDate = "");

watch(() => model.value.productId, () => loadFeatures());

const loadFeatures = async () => 
{
    model.value.addOns = selectedPlan.value?.features.map(f => f.addonId!) || [];
}
</script>