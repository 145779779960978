export default {
    en: {
        //Instructor_picker
        INSTRUCTOR_PICKER_LABEL: 'Instructor',
        INSTRUCTOR_PICKER_ME: 'Me',
        INSTRUCTOR_PICKER_EXISTING: 'Existing Instructor',
        INSTRUCTOR_PICKER_NEW: 'New Instructor',
        INSTRUCTOR_PICKER_NEW_NAME: 'New Instructor Name',
        INSTRUCTOR_PICKER_NEW_EMAIL: 'New Instructor Email'
    }
}