export default {
    en: {
        ORGANIZATION_FILTER_LABEL: 'Organization',
        USER_FILTER_LABEL: 'User',
        DATE_FILTER_LABEL: 'Date',
        DATE_FILTER_DATE_PICKER_LABEL: 'Date',
        DATE_FILTER_MODIFIER_LABEL: 'Range',
        DATE_FILTER_ON_OR_BEFORE: 'On or before',
        DATE_FILTER_ON_OR_AFTER: 'On or after',
        DATE_FILTER_CLEAR_BUTTON: 'Clear',
        DATE_FILTER_CONFIRM_BUTTON: 'Apply',
        BETWEEN_DATE_FILTER_DATE_PICKER_LABEL: 'Date',
        BETWEEN_DATE_FILTER_FROM: 'From',
        BETWEEN_DATE_FILTER_TO: 'To',
        BETWEEN_DATE_FILTER_CLEAR_BUTTON: 'Clear',
        BETWEEN_DATE_FILTER_CONFIRM_BUTTON: 'Apply',
        SORTING_LABEL: 'Sort by',
        SORTING_ORDER_LABEL: 'Sort Direction',
        SORTING_ORDER_ASC: 'Ascending',
        SORTING_ORDER_DESC: 'Descending',
        TEXT_FILTER_CLEAR_BUTTON: 'Clear',
        TEXT_FILTER_CONFIRM_BUTTON: 'Apply',
    }
};