<template>
    <cr-col tag='dt' xs12 sm12 md12 v-bind="lgXlLabelBreakpoints" class="key" :data-testing="labelDataTesting">
        {{ label }}
    </cr-col>
    <cr-col tag='dd' xs12 sm12 md12 v-bind="lgXlValueBreakpoints" class="value" :data-testing="valueDataTesting">
        <slot />
    </cr-col>
</template>

<style scoped>
dt
{
    font-weight: unset;
}
dd
{
    margin-bottom: unset;
}
</style>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<
{
    /**
     * The item's label.
     */
    label:string,

    /**
     * Use wider breakpoints for lg and xl
     */
     wide?:boolean,
     
    /**
     * data-testing for the element and its children.
     */
    dataTesting?:string,
}>()

const lgXlLabelBreakpoints = computed(() => props.wide 
    ? { lg6: true, xl5:true }
    : { lg4: true, xl3:true }
);
const lgXlValueBreakpoints = computed(() => props.wide 
    ? { lg6: true, xl7:true }
    : { lg8: true, xl9:true }
);
const dataTesting = props.dataTesting;
const labelDataTesting = dataTesting ? `${dataTesting}-label` : undefined;
const valueDataTesting = dataTesting ? `${dataTesting}-value` : undefined;
</script>