<template>
    <cr-progress-dialog :title="t('DOWNLOAD_CREDENTIALS_DIALOG_TITLE')" @confirm="onConfirmed" :confirm-text="t('DOWNLOAD_CREDENTIALS_CONFIRM_TEXT')" :total="total" :successes="successes" :errors="errors" persistent :progress-label="t('DOWNLOAD_CREDENTIALS_ARIA_LABEL')" data-testing="download-credentials-dialog"/>
</template>
<style scoped>
.creation-details 
{
    flex-direction: column;
    display: flex;
    align-items: center;
}
</style>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<
{
    successes: number
    total: number
    errors: number
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    emit('confirm');
}

</script>