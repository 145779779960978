import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import Route from '../route';
import { useCourseStore } from '../../stores/courseStore';

export default async function classicUiExerciseGroupCompatibility(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void>
{
    let courseId = to.params.courseId as string;

    if((await useCourseStore().fetchMyCourses()).some(c => c.id === courseId))
    {
        return next({ name: Route.MyCourseExerciseGroup.name, params: to.params})
    }
    else
    {
        return next({ name: Route.ExerciseGroup.name, params: to.params})
    }
}