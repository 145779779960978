<template>
    <cr-breadcrumb :items="breadcrumbs" class="cr-font-bold"/>
    <h1 class="page-title" data-testing="table-layout-title">
        {{ props.title }}
    </h1>
    <div v-if="ready && $slots.alert" class="table-layout-alert-slot cr-mt3" data-testing="table-layout-alert">
        <slot name="alert" />
    </div>
    <cr-container v-responsive="responsiveBreakpoints" class="table-layout-container cr-mt5 cr-pl0" data-testing="table-layout-container">
        <cr-row v-if="!ready">
            <cr-col xs12 data-testing="table-layout-loading">
                <cr-loading />
            </cr-col>
        </cr-row>
        <template v-else>
            <div class="table-layout-content-container cr-ml0 cr-pl0">
                <div v-reverse="!responsiveBreakpoints.sm" class="table-layout-sub-container">
                    <div v-if="$slots.filter" class="table-layout-filter-slot" data-testing="table-layout-filter">
                        <slot name="filter" />
                    </div>
                    <div v-if="$slots.controls" class="table-layout-controls-slot controls cr-plr0" data-testing="table-layout-controls">
                        <layout-controls>
                            <slot name="controls" />
                        </layout-controls>
                    </div>
                </div>
                <div class="cr-mb5 table-layout-content cr-plr6 cr-pt6" data-testing="table-layout-content">
                    <div v-if="$slots.top" class="cr-pb5" data-testing="table-layout-top">
                        <slot name="top" />
                    </div>
                    <div v-if="$slots.default" class="table-layout-default-slot" data-testing="table-layout-default">
                        TableLayout does not support "default" slot. Use "table" slot instead.
                    </div>
                    <div v-if="$slots.table" class="table-layout-table-slot" data-testing="table-layout-table">
                        <slot name="table" />
                    </div>
                    <div v-if="$slots.pagination" class="table-layout-pagination-slot cr-ptb4" data-testing="table-layout-pagination">
                        <slot name="pagination" />
                    </div>
                    <div v-if="$slots.bottom" data-testing="table-layout-bottom">
                        <slot  name="bottom" />
                    </div>
                </div>
            </div>
        </template>
    </cr-container>
</template>

<style scoped>
.table-layout-table-slot {
    height: 100%;
}
.table-layout-container {
    flex-grow: 1;
    container-name: table-layout;
    container-type: inline-size;
}
.table-layout-content-container {
    display: grid;
}
.table-layout-sub-container {
    display: grid;
}
.table-layout-filter-slot > :deep(section) {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
}
.table-layout-content
{
    background-color: white;
    margin-left: 0; 
    padding-left: 0;
    overflow-x: scroll;

    display: flex;
    flex-direction: column;
    height: 100%;
}
@container table-layout (min-width: 577px) {
    .table-layout-content-container {
        grid-template-columns: minmax(0, auto) minmax(auto, var(--cr-right-handside-control-width));
        min-height: 100%;
        column-gap: .75rem;
        row-gap: 1rem;
    }
    .table-layout-content-container:has(.table-layout-filter-slot) {
        grid-template-rows: auto 1fr;
    }
    .table-layout-content-container:not(:has(.table-layout-filter-slot)) {
        grid-template-rows: 1fr;
    }
    .table-layout-content-container:not(:has(ul.controls)),
    .table-layout-content-container:has(ul.controls:empty) {
        grid-template-columns: minmax(0, auto);
        column-gap: 0;
    }
    .table-layout-sub-container {
        grid-template-columns: subgrid;
        grid-template-rows: subgrid;
        grid-column: 1/-1;
        grid-row: 1/-1;
    }
    .table-layout-controls-slot {
        grid-column: -2/-1;
        grid-row: 1/-1;
    }
    .table-layout-content {
        grid-column: 1/-2;
        grid-row: -2/-1;
    }
}
@container table-layout (max-width: 576px) {
    .table-layout-content-container {
        grid-template-columns: minmax(0, 1fr);
        row-gap: .75rem;
    }
    .table-layout-sub-container {
        row-gap: .75rem;
    }
    .table-layout-filter-slot > :deep( section) {
        flex-direction: column;
    }
    .table-layout-filter-slot > :deep( section .cr-dropdown > .cr-button) {
        width: 100%;
    }
}
</style>

<script setup lang="ts">
import { IBreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed } from '@vue/reactivity';
import { usePageTitle } from '../../composables/usePageTitle';
import { reactive } from 'vue';
import LayoutControls from './LayoutControls.vue';

const props = withDefaults(defineProps<
{
    /**
     * A title for the page.
     */
    title?:string

    /**
     * Breadcrumbs for the page.
     */
    breadcrumbs:IBreadcrumbItem[]

    /**
     * Whether the layout is ready to display.
     * A loading indicator will be displayed while waiting for the layout to be ready.
     * @default true
     */
     ready?: boolean
}>(), { ready: true })

const responsiveBreakpoints = reactive({ sm: false });

usePageTitle(computed(()=>props.breadcrumbs));
</script>