<template>
    <form-section data-testing="support-form-topic-section" :label="t('SUPPORT_TOPIC_CATEGORY_SECTION_LABEL')" class="cr-mb5">
        <cr-select v-model="supportRequest.topic" :label="t('SUPPORT_CATEGORY')" :items="topics" :disabled="onlyOneTopic" required data-testing="support-form-topic-select"/>
        <cr-select v-model="supportRequest.topicId" :label="supportTopicItemLabel" :items="topicItems" :disabled="onlyOneTopic" required data-testing="support-form-topic-id-select"/>
        <cr-text-field v-if="userHasNoEmail" v-model="supportRequest.email" :label="t('SUPPORT_EMAIL_LABEL')" required :max-length="256" type="email" data-testing="support-form-manual-email-input"/>
    </form-section>
    <form-section data-testing="support-form-message-section" :label="t('SUPPORT_MESSAGE_SECTION_LABEL')" class="cr-mb5" >
        <cr-text-field v-model="supportRequest.subject" :label="t('SUPPORT_SUBJECT_LABEL')"  required :max-length="256" data-testing="support-form-subject-input"/>
        <cr-textarea v-model="supportRequest.body" :label="t('SUPPORT_MESSAGE_LABEL')"  :rows="5" :max-length="9000" required data-testing="support-form-message-body-input"/>
    </form-section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, reactive, ref, watch, onMounted } from 'vue';
import { ICourse } from '@cyber-range/cyber-range-api-user-client';
import FormSection from '../layouts/sections/FormSection.vue';
import { IOrganization } from '@cyber-range/cyber-range-api-organization-client';
import { SelectItem, useEnum } from '../../composables/useEnum';
import { ISupportMessage } from '../../interfaces/iSupportMessage';
import { SupportTopic } from '../../interfaces/supportTopic';
import { useSessionStore } from '../../stores/sessionStore';
const { t } = useI18n();

let props = defineProps<{
    courses:ICourse[];
    organizations:IOrganization[];
}>();

const emit = defineEmits<{
    (e:'update:modelValue', message: Partial<ISupportMessage>): void,
}>();

const topics = ref<SelectItem[]>([]);
const topicItems = ref<SelectItem[]>([]);
const organizationTopics = ref<SelectItem[]>([]);
const courseTopics = ref<SelectItem[]>([]);
const supportRequest = reactive<Partial<ISupportMessage>>({});
const onlyOneTopic = ref<boolean>(false);
const userHasNoEmail = ref<boolean>(false);

watch(()=>({...supportRequest}), ()=>
{
    if(supportRequest.topic === SupportTopic.Course)
    {
        topicItems.value = courseTopics.value
    }
    if(supportRequest.topic === SupportTopic.Organization)
    {
        topicItems.value = organizationTopics.value
    }
    emit('update:modelValue', supportRequest);
});

const supportTopicItemLabel = computed(()=>{
    return supportRequest.topic === SupportTopic.Organization
            ? t('SUPPORT_TOPIC_ITEM_SECTION_LABEL_ORGANIZATION')
            : supportRequest.topic === SupportTopic.Course
                ? t('SUPPORT_TOPIC_ITEM_SECTION_LABEL_COURSE')
                : t('SUPPORT_TOPIC')
});


onMounted(async ()=>
{    
    let [courseTopic, organizationTopic] = useEnum().toSelectItems(SupportTopic)
    courseTopics.value = props.courses;
    organizationTopics.value = props.organizations;

    if(props.courses.length > 0)
    {
        topics.value.push(courseTopic)
        supportRequest.topic = SupportTopic.Course
        if(props.courses.length === 1)
        {
            supportRequest.topicId = props.courses[0].id;
        }
    }
    if(props.organizations.length > 0)
    {
        topics.value.push(organizationTopic)
        if(!supportRequest.topic)
        {
            supportRequest.topic = SupportTopic.Organization
            if(props.organizations.length === 1)
            {
                supportRequest.topicId = props.organizations[0].id;
            }
        }
    }
    
    if([...props.courses,...props.organizations].length === 1)
    {
        onlyOneTopic.value = true;
    }

    const identity = (useSessionStore().identity);
    if(!identity?.email)
    {
        userHasNoEmail.value = true;
    }
    else
    {
        supportRequest.email = identity.email;
    }
    
    supportRequest.name = identity?.name;
    supportRequest.provider = identity?.provider;
})
</script>