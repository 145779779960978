import { IInvoice } from "@cyber-range/cyber-range-api-invoice-client";
import IInvoiceTableDataItem from "../../interfaces/invoice/iInvoiceTableDataItem";
import InvoiceTableData, { courseItems } from "./invoiceTableData";
import { useNumberFormats } from "../../composables/useNumberFormats";
import { TableHeaderItem } from "@cyber-range/cyber-range-lib-ui";
import  $t  from "../../strings/definitions/billingStrings"

const { toCurrency } = useNumberFormats();

interface IFlatRateInvoiceTableDataItem extends IInvoiceTableDataItem
{
    enrollments: string,
    rate: string,
}

export default class FlatRateInvoiceTableData extends InvoiceTableData
{
    headers = [
        new TableHeaderItem({text: $t.en.INVOICE_TABLE_DATA_NAME, key: 'name', sortable: false}),
        new TableHeaderItem({text: $t.en.INVOICE_TABLE_DATA_ENROLLMENTS, key: 'enrollments', align: 'right', sortable: false}),
        new TableHeaderItem({text: $t.en.INVOICE_TABLE_DATA_RATE, key: 'rate', align: 'right', sortable: false}),
        new TableHeaderItem({text: $t.en.INVOICE_TABLE_DATA_TOTAL, key: 'totalPrice', align: 'right', sortable: false}),
    ];
    declare items: IFlatRateInvoiceTableDataItem[];

    /**
     * 
     * @param invoice The Invoice to be translated to table data
     * @param balance The activity balance at the end of the transaction
     */
    constructor(invoice: IInvoice, balance: number)
    {
        super(invoice, balance);
    }

    protected processCourseItems(courseItems: courseItems): IFlatRateInvoiceTableDataItem[]
    {
        const enrollmentItems: IFlatRateInvoiceTableDataItem[] = courseItems.enrollmentItems?.map(item => ({
            name: item.name,
            rate: toCurrency(item.unitPrice),
            enrollments: item.quantity.toString(),
            totalPrice: toCurrency(item.totalPrice),
        })) || [];

        const waivedItems: IFlatRateInvoiceTableDataItem[] = courseItems.waiveItems?.map(item => ({
            name: item.name,
            enrollments: '',
            rate: '',
            totalPrice: toCurrency(item.totalPrice),
        })) || [];

        return [
            ...enrollmentItems,
            ...waivedItems
        ];
    }
}