<template>
    <form-layout :title="t('BUSINESS_UNITS_EDIT_BUSINESS_UNIT_PAGE_TITLE')" :breadcrumbs="breadcrumbs" @confirm="onConfirm" @cancel="onCancel" :ready="isReady">
        <template #form>
            <business-unit-form v-model="businessUnit" />
        </template>
    </form-layout>
</template>

<script setup lang="ts">
import BusinessUnitForm from './BusinessUnitForm.vue';
import FormLayout from '../layouts/FormLayout.vue';
import Route from '../../routers/route';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { BusinessUnit, IBusinessUnit } from '@cyber-range/cyber-range-api-business-unit-client';
import { IOrganization, Organization } from '@cyber-range/cyber-range-api-organization-client';
import { computed, onMounted, ref } from 'vue';
import { useBusinessUnitStore } from '../../stores/businessUnitStore';
import { useEntitlementStore } from '../../stores/entitlementStore';
import { useI18n } from 'vue-i18n';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useRawObject } from '../../composables/useRawObject';
import { useRouter } from 'vue-router';

const props = defineProps<
{
    organizationId:string,
    businessUnitId:string
}>();

const { t } = useI18n();
const router = useRouter();

const organization = ref<IOrganization>(new Organization());
const businessUnit = ref<IBusinessUnit>(new BusinessUnit());
const businessUnitBreadCrumbName = ref<string>();

const isReady = ref(false);

const breadcrumbs = computed(()=>[ 
                        new BreadcrumbItem(Route.Organizations), 
                        new BreadcrumbItem({...Route.Organization, text: organization.value?.name, params: {organizationId: props.organizationId}}), 
                        new BreadcrumbItem({...Route.BusinessUnits, params: {organizationId: props.organizationId}}),
                        new BreadcrumbItem({...Route.BusinessUnit, text: businessUnitBreadCrumbName?.value, params: {organizationId: props.organizationId, businessUnitId: props.businessUnitId}}),
                        new BreadcrumbItem({...Route.EditBusinessUnit, params: {organizationId: props.organizationId, businessUnitId: props.businessUnitId}})]);

const onConfirm = async () => 
{
    let payload = useRawObject(businessUnit.value, {excludes: ['createdTimestamp','organizationId','modifiedTimestamp']});
    
    await useBusinessUnitStore().update(payload);

    router.push({ name: Route.BusinessUnit.name, params: {organizationId: props.organizationId, businessUnitId: props.businessUnitId}});
}

const onCancel = () => 
{
    router.push({ name: Route.BusinessUnit.name, params: {organizationId: props.organizationId, businessUnitId: props.businessUnitId}});
}

onMounted(async ()=>
{
    try
    {
        let fetchedUnit;
        [ organization.value, fetchedUnit ] = await Promise.all([useOrganizationStore().getOrganization(props.organizationId), useBusinessUnitStore().getBusinessUnit(props.businessUnitId)]);
        businessUnit.value = fetchedUnit
        businessUnitBreadCrumbName.value = fetchedUnit?.name;        
    }
    finally
    {
        isReady.value = true;
    }
});
</script>