<template>
    <cr-confirmation-dialog :title="t('COURSE_USERS_INVITATION_CODE_CREATED_DIALOG_TITLE')" @confirm="onConfirmed" :cancel="false" :confirmText="t('COURSE_USERS_INVITATION_CODE_CREATED_CONFIRM_TEXT')" data-testing="invitation-code-created-dialog">
            <cr-row class="cr-text-center cr-justify-content-center" xs12>                
                <span v-if="isLoading" data-testing="invitation-code-creating-invitation-code-loading">
                    <cr-loading :message="t('COURSE_USERS_INVITATION_CODE_LOADING_MESSAGE')"/>
                </span>
                <span v-else class="invitation-code" data-testing="invitation-code-created-invitation-code"> 
                    {{ code }}
                    <cr-clipboard-copy :value="String(code)" data-testing="invitation-code-created-dialog-clipboard-copy"/> 
                </span> 
            </cr-row>
            <cr-row>
                <cr-html :value="t('COURSE_USERS_INVITATION_CODE_CREATED_DIALOG_BODY')" class="cr-pt4 cr-pl3 cr-pb1" data-testing="invitation-code-created-dialog-html"/>
            </cr-row>
    </cr-confirmation-dialog>
</template>

<style scoped>
.invitation-code
{
    color: var(--cr-grey-darken-1);
    font-size: calc(var(--cr-body-font-size) * 1.7 );
}
</style>

<script lang="ts" setup>
import Config from '../../../config';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    code?: string;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    emit('confirm');
}
</script>