<template>
    <cr-confirmation-dialog :title="t('COURSE_USERS_MOVE_USER_DIALOG_TITLE')" @cancel="onCanceled" @confirm="onConfirmed" :disabled="isLoading" :form-instruction="true" data-testing="move-user-dialog">
        <cr-html :value="t('COURSE_USERS_MOVE_USER_DIALOG_MESSAGE', {userName: user?.name, teamName: user?.teamName})" />
        <cr-select :items="availableTeams" v-model="newTeamId" :label="t('COURSE_USERS_MOVE_USER_DIALOG_TEAMS_DROPDOWN')" required :max-length="256" :disabled="isLoading" data-testing="move-user-dialog-new-team-select" class="cr-mt3"/>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { ITeam, Team, TeamFilter } from '@cyber-range/cyber-range-api-user-client';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useTeamStore } from '../../../stores/teamStore';
import { useRosterStore } from '../../../stores/rosterStore';
import { storeToRefs } from 'pinia';
import { IRosterEntry } from '@cyber-range/cyber-range-api-roster-client';
import { useUserStore } from '../../../stores/userStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    courseId: string;
    user?: IRosterEntry;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const newTeamId = ref<string>();
const availableTeams = ref<ITeam[]>([]);

watch(()=>props.user, async ()=> 
{
    if(props.user) 
    {   
        newTeamId.value = undefined;
        let rosterEntries = (await useTeamStore().list(props.courseId, new TeamFilter({limit:Number.MAX_SAFE_INTEGER}))).items;
        availableTeams.value = [new Team(), ...Array.from(new Set(rosterEntries.filter(entry => entry.id !== props.user?.teamId))).sort((a,b)=>a.name.localeCompare(b.name))];
    }
}, {immediate:true});

const onCanceled = () =>
{
    newTeamId.value = undefined;
}

const onConfirmed = async ()=>
{
    if(props.user && newTeamId.value)
    {
        await useUserStore().transferCourseTeam(props.courseId, props.user.id, props.user.teamId, newTeamId.value);
        useRosterStore().clearCache();
        emit('confirm');
    }
}
</script>