import { AuditEventFilter, IAuditEvent, IAuditEventFilter } from '@cyber-range/cyber-range-api-audit-client';
import { IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import { defineStore } from 'pinia';
import { useApiClientStore } from './apiClientStore';

export const useAuditStore = defineStore('auditStore', 
{
    actions:
    {
        async list(filter?: Partial<IAuditEventFilter>, options?: {background?:boolean}): Promise<IApiPageResponse<IAuditEvent>>
        {
            let auditApiClient = options?.background ? useApiClientStore().backgroundAuditApiClient : useApiClientStore().auditApiClient;

            return await auditApiClient.get(filter ? new AuditEventFilter(filter) : undefined);
        },
    }
});
