<template>
    <cr-confirmation-dialog id="editOrganizationApplicationDialog" :title="t('ORGANIZATION_APPLICATIONS_EDIT_APPLICATION_DIALOG_TITLE')" @confirm="onConfirmed" @shown="onShown" :disabled="isLoading" data-testing="edit-organization-application-dialog">
        <organization-application-form v-model="app" :key="key"/>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { IOrganizationApplication} from '@cyber-range/cyber-range-api-organization-client';
import OrganizationApplicationForm from '../OrganizationApplicationForm.vue';
import { ref, watch } from 'vue';
import { useRawObject } from '../../../composables/useRawObject';
import { useOrganizationStore } from '../../../stores/organizationStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const key = ref<number>(0);

const app = ref<IOrganizationApplication>({} as IOrganizationApplication); // OrganizationApplication is an abstract class, so can't instantiate one.

const props = defineProps<
{
    organizationId: string;
    application?: IOrganizationApplication;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const populateData = () =>
{
    if (props.application)
    {
        app.value = useRawObject(props.application);
    }
}

watch(()=>props.application, populateData, {immediate:true});

const onConfirmed = async ()=>
{
    const payload = useRawObject(app.value, {excludes: ['id', 'type', 'createdTimestamp', 'modifiedTimestamp', 'organizationId']});

    await useOrganizationStore().updateOrganizationApplication(props.organizationId, app.value.id, payload);
    emit('confirm');
}

const onShown = async ()=>
{
    key.value++;
    populateData();
};
</script>