import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import Route from '../route';
import { useCourseStore } from '../../stores/courseStore';

export default async function enterIfCourseIsNotAnonymous(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext, courseStore = useCourseStore()): Promise<void>
{
    const courseId = Array.isArray(to.params['courseId']) ? to.params['courseId'][0] : to.params['courseId'];

    const course = await courseStore.getCourse(courseId);

    if (await courseStore.courseIsAnonymous(course))
    {
        return next(Route.Home);
    }
    else
    {
        return next();
    }
}