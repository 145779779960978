<template>
    <cr-confirmation-dialog :title="t('SUPPORT_NO_TOPICS_DIALOG_TITLE')" @confirm="onConfirmed" :cancel="false" data-testing="support-no-topics-dialog">
            <p> {{  t("SUPPORT_NO_TOPICS_DIALOG_BODY") }} </p>            
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n();
const router = useRouter();

const onConfirmed = async ()=>
{
    router.back();
}
</script>