import { NavigationGuard } from 'vue-router';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import Route from '../route';


const newUIClaimCheck: NavigationGuard = (to, from, next) =>
{
    if (useAuthorizationStore().canViewNewUI())
    {
        next();
    }
    else
    {
        next(Route.PilotUnauthorized)
    }
}
export default newUIClaimCheck;
