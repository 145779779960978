<template>
    <cr-col v-if="hasCourses" xs7 class="dashboard-tile-top-courses cr-pl4" data-testing="dashboard-tile-top-courses">
        <cr-row>
            <h3 class="cr-pl0 cr-font-bold">{{ t('ORGANIZATIONS_ORGANIZATION_ENVIRONMENTS') }}</h3>
        </cr-row>
        <cr-row>    
            <ul class="cr-mb1 cr-p0">
                <li v-for="course in topCourses" class="dashboard-tile-top-courses-item cr-pb0" data-testing="dashboard-tile-top-courses-item">
                    <span class="cr-font-bold dashboard-course-environment-count-value cr-pr1 ">{{ course.statistics?.numberOfExercises }}</span>
                    {{ `in ${course.name}` }}
                </li>
            </ul>
        </cr-row>
    </cr-col>
</template>

<style scoped>
.dashboard-tile-top-courses
{
    border-left: .1rem solid var(--cr-grey);
}

h3
{
    font-size: var(--cr-card-title-font-size);
}

.dashboard-course-environment-count-value
{
    font-size: var(--cr-dashboard-top-courses-value-font-size);
}

.dashboard-tile-top-courses-link
{
    display: flex;
    justify-content: flex-end;
    color: var(--cr-primary-link)
}

.dashboard-tile-top-courses-item
{
    list-style-type: none;
}
</style>

<script setup lang="ts">
import { computed } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import { ICourse } from '@cyber-range/cyber-range-api-user-client';

const { t } = useI18n();

const props = defineProps<
{
    topCourses: ICourse[];
}>();

const hasCourses = computed(() =>
{
    return (props.topCourses || []).length > 0;
})

</script>