<template>
    <template v-if="!usersSelected">
        <form-layout v-if="importingFromCsv" :title="pageTitle" :breadcrumbs="breadcrumbs" :ready="isReady" :confirmText="t('COURSE_USERS_IMPORT_STEP_ONE_CONFIRM_TEXT')" @cancel="onImportCancelled" @confirm="onImportConfirmed" :custom-validation="validateUsersSelected" @validated="onFormValidated">
            <template #form>
                <form-section :label="t('COURSE_USERS_IMPORT_USERS_BY_CSV_LABEL')" >
                    <user-import-csv @usersImported="onUsersImported" :validate="validate" />
                </form-section>
            </template>
        </form-layout>
        <user-import-google-classroom v-if="importingFromGoogleClassroom" :course="course" @usersImported="onUsersImported" @confirm="onImportConfirmed"/>
    </template>
    <table-layout v-else :title="pageTitle" :breadcrumbs="breadcrumbs">
        <template #top>
            {{ t('COURSE_USERS_IMPORT_CSV_REVIEW_BLURB') }}
        </template>
        <template #table>
            <cr-table :items="paginator.displayItems" :headers="importUserTableHeaders" @suggestedNumberOfItems="onSuggestedNumberOfItems" :loading="sendingInvitations" data-testing="import-course-users-review-table"/>
        </template>
        <template #pagination>
            <cr-pagination :previous="paginator.showPrevious" :next="paginator.showNext" @previous="onPrevious" @next="onNext" :loading="sendingInvitations" data-testing="import-course-users-review-table-pagination"/>
        </template>
        <template #bottom>
            <div xs12 class="cr-text-right cr-mt5 cr-mb5" >
                <cr-button text class="cr-mr3" @click.prevent="onReviewCancelled" data-testing="import-course-users-review-cancel" :disabled="sendingInvitations" >
                    {{ t('COURSE_USERS_IMPORT_STEP_TWO_CANCEL_TEXT') }}
                </cr-button>
                <cr-button @click.prevent="onReviewConfirmed" :disabled="sendingInvitations" data-testing="import-course-users-review-confirm">
                    {{ t('COURSE_USERS_IMPORT_STEP_TWO_CONFIRM_TEXT') }}
                </cr-button>
            </div>
            <cr-confirmation-dialog v-model="showUsersImportedDialog" persistent :title="t('COURSE_USERS_IMPORT_USERS_IMPORTED_CONFIRMATION_DIALOG_TITLE')" :cancel="false" :confirm-text="t('COURSE_USERS_IMPORT_USERS_IMPORTED_CONFIRMATION_DIALOG_CONFIRM')" @confirm="onConfirmImportConfirmed" data-testing="import-course-users-imported-dialog">
                {{ t('COURSE_USERS_IMPORT_USERS_IMPORTED_CONFIRMATION_DIALOG_BODY', { count: paginator.allItems.length }) }}
            </cr-confirmation-dialog>
        </template>
    </table-layout>
</template>

<script setup lang="ts">
import FormLayout from '../../layouts/FormLayout.vue';
import FormSection from '../../layouts/sections/FormSection.vue';
import TableLayout from '../../layouts/TableLayout.vue';
import Route from '../../../routers/route';
import UserImportCsv from './UserImportCsv.vue'
import UserImportGoogleClassroom from './UserImportGoogleClassroom.vue'
import UserImportMethod from '../../../interfaces/userImportMethod';
import Paginator from '../../../entities/paginator';
import IPaginator from '../../../interfaces/iPaginator';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, nextTick, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useCourseStore } from '../../../stores/courseStore';
import { useCourseBreadcrumbs } from '../../../composables/useCourseBreadcrumbs';
import { useI18n } from 'vue-i18n';
import { ICourse } from '@cyber-range/cyber-range-api-user-client';
import { useRouter } from 'vue-router';
import { useInvitationStore } from '../../../stores/invitationStore';
import { Invitation } from '@cyber-range/cyber-range-api-invitation-client'

const props = defineProps<
{
    courseId:string,
    importMethod:string
}>();

const { t } = useI18n();

const { isLoading } = storeToRefs(useApiClientStore());

const isReady = ref(false);

const sendingInvitations = ref<boolean>(false);

const breadcrumbs = computed(()=>useCourseBreadcrumbs([
                          new BreadcrumbItem(Route.Courses), 
                          new BreadcrumbItem({...Route.Course,  text: course?.value?.name, params: {courseId: props.courseId}}), 
                          new BreadcrumbItem({...Route.CourseUsers, text: t('COURSE_USERS_PAGE_TITLE'), params: {courseId: props.courseId}}),
                          new BreadcrumbItem({...Route.InviteCourseUsers, text: t('COURSE_USERS_INVITE_USERS_PAGE_TITLE'), params: {courseId: props.courseId}}),
                          new BreadcrumbItem({...Route.ImportCourseUsers, text: pageTitle.value, params: {courseId: props.courseId, importMethod:props.importMethod}})]));

const router = useRouter();

const course = ref<ICourse>();

const usersSelected = ref(false);

const importUserTableHeaders = [
    {text: "Name", key:"name", sortable: false},
    {text: "Email", key:"email", sortable: false},
    {text: "Role", key:"role", sortable: false}
]

const importingFromGoogleClassroom = computed(()=>
{
    return props.importMethod === UserImportMethod.GoogleClassroom;
});

const importingFromCsv = computed(()=>
{
    return props.importMethod === UserImportMethod.Csv;
});

const pageTitle = ref<string>(t('COURSE_USERS_IMPORT_PAGE_TITLE'))

const setPageTitle = ()=>
{
    if(importingFromCsv.value === true)
    {
        if(usersSelected.value === true)
        {
            pageTitle.value = t('COURSE_USERS_IMPORT_TITLE_CSV_REVIEW');
        }
        else
        {
            pageTitle.value = t('COURSE_USERS_IMPORT_TITLE_CSV');
        }
    }
    else if(importingFromGoogleClassroom.value === true)
    {
        if(usersSelected.value === true)
        {
            pageTitle.value = t('COURSE_USERS_IMPORT_TITLE_GOOGLE_CLASSROOM_REVIEW')
        }
        else       
        {
            pageTitle.value = t('COURSE_USERS_IMPORT_TITLE_GOOGLE_CLASSROOM')
        }
    }
}

const pageSize = ref<number>(11);

const paginator = ref<IPaginator<{name:string,email:string, role:string}>>(new Paginator<{name:string,email:string, role:string}>([],pageSize.value));

const onUsersImported = async (selectedUsers:{name:string,email:string, role:string}[])=>
{
    paginator.value = new Paginator<{name:string,email:string, role:string}>(selectedUsers,pageSize.value);
}

const onSuggestedNumberOfItems = (n:number) =>
{
    pageSize.value = n;
    paginator.value.pageSize = n;
}

const onNext = ()=>
{
    paginator.value.next();
}

const onPrevious = ()=>
{
    paginator.value.previous();
}

const onImportCancelled = async ()=>
{
    router.push({ name: Route.InviteCourseUsers.name,  params: {courseId:props.courseId}})
}

const onImportConfirmed = async ()=>
{
    usersSelected.value = true;
    setPageTitle();
}

const onReviewCancelled = async ()=>
{
    usersSelected.value = false;
    validate.value = false;
    paginator.value = new Paginator<{name:string,email:string, role:string}>([],pageSize.value);
}

const showUsersImportedDialog = ref(false);
const onReviewConfirmed = async ()=>
{
    sendingInvitations.value = true;
    try
    {
        await (useInvitationStore().create(
            paginator.value.allItems.map(u=>            
                new Invitation({
                    courseId: props.courseId,
                    name: u.name,
                    email: u.email,
                    roles: [ u.role ]
                }))
        ));
        showUsersImportedDialog.value = true;
    }
    finally
    {
        sendingInvitations.value = false;
    }    
}

function onConfirmImportConfirmed()
{
    router.push({ name: Route.CourseUsers.name,  params: {courseId:props.courseId}})
}

const validate = ref<boolean>(false);

const validateUsersSelected = () =>
{
    return paginator.value.allItems.length > 0;
}

const onFormValidated = async () =>
{    
    validate.value = true;

    await nextTick();

    const errorTextElement = document.querySelectorAll(".cr-error-text")[0];

    if (errorTextElement)
    {
        (document.querySelectorAll('.cr-file-picker')[0] as any)?.focus();
    };
}

onMounted(async ()=>
{
    try
    {  
        course.value = await useCourseStore().getCourse(props.courseId);
        setPageTitle();
    }
    finally
    {
        isReady.value = true;
        sendingInvitations.value = false;
    }
});

</script>