<template>
    <cr-card :to="exerciseGroupRoute" :toDisabled="!exerciseGroupIsAccessible">
        <cr-card-image v-if="imageSrc" class="image cr-mb1 cr-p3" data-testing="exercise-group-logo" :src="imageSrc" alt="" align-right />
        <cr-card-body>
            <cr-card-title tag="h3" data-testing="exercise-group-title" class="cr-mb0">
                <span class="cr-font-bold">{{ exerciseGroup.name }}</span>
            </cr-card-title>
            <cr-card-title v-if="subtitle" tag="div" data-testing="exercise-group-subtitle">
                {{  subtitle }}
            </cr-card-title>

            <cr-card-text v-if="!exerciseGroupIsAccessible" class="cr-mt3">
                <cr-loading :message="useEnum().toDisplayEnumName(ExerciseGroupStatus, exerciseGroup.status, { removePending: true })"/>
            </cr-card-text>

            <cr-card-text v-else class="cr-mt3">
                <tile-detail-section>
                    <tile-detail-section-item icon="bi-eye" :icon-color="isActive ? 'var(--cr-exercise-group-active-icon-color)' : 'var(--cr-exercise-group-inactive-icon-color)'" data-testing="exercise-group-status" >
                        <template v-if="isActive">
                            {{ t('EXERCISE_GROUP_ENABLED') }}
                        </template>
                        <template v-else>
                            {{ t('EXERCISE_GROUP_DISABLED') }}
                        </template>
                    </tile-detail-section-item>

                    <tile-detail-section-item :label="t('EXERCISE_GROUP_START_LABEL')" icon="bi-alarm" icon-color="var(--cr-exercise-group-start-time-icon-color)" class="cr-mb1" data-testing="exercise-group-start-time">
                        {{ toHumanDate(exerciseGroup.startTime) }}
                    </tile-detail-section-item>

                    <tile-detail-section-item :label="t('EXERCISE_GROUP_END_LABEL')" icon="bi-alarm" icon-color="var(--cr-exercise-group-end-time-icon-color)" data-testing="exercise-group-end-time">
                        {{ toHumanDate(exerciseGroup.endTime) }}
                    </tile-detail-section-item>
                </tile-detail-section>
            </cr-card-text>
        </cr-card-body>
    </cr-card>
</template>

<style scoped>
.image
{
    height: 8rem;
}
.cr-card-title
{
    font-size: var(--cr-tile-title-font-size);
}
</style>

<script setup lang="ts">
import { ExerciseGroupStatus, IExerciseGroup } from '@cyber-range/cyber-range-api-user-client';
import { computed } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import { useCalendar } from '../../composables/useCalendar';
import { useEnum } from '../../composables/useEnum';
import Route from '../../routers/route';
import TileDetailSection from '../layouts/sections/TileDetailSection.vue';
import TileDetailSectionItem from '../layouts/sections/TileDetailSectionItem.vue';

const { t } = useI18n();

const { toHumanDate } = useCalendar();

const props = defineProps<
{
    courseId: string;
    exerciseGroup:IExerciseGroup
}>();

const subtitle = computed(() =>
{
    const variation = props.exerciseGroup.metadata?.variations?.join(', ');
    const release = props.exerciseGroup.metadata?.release;
    return (variation && release)
        ? `${variation} (${release})` 
        : variation || release;
});

const imageSrc = computed(() => (props.exerciseGroup.metadata?.logo));

const isActive = computed(() =>
{
    const { startTime, endTime } = props.exerciseGroup;
    let now = new Date().toISOString();
    return (!startTime || now > startTime) && (!endTime || now < endTime);
});

const exerciseGroupIsAccessible = computed(()=>
{
    return props.exerciseGroup.status === ExerciseGroupStatus.READY;
});

const exerciseGroupRoute = computed(()=>
{
    return { ...Route.ExerciseGroup, params: { courseId: props.courseId, exerciseGroupId: props.exerciseGroup.id } };
});
</script>
