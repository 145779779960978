export default {
    en: {
        SIDEBAR_LABEL: 'Sidebar',
        
        SIDEBAR_MY_COURSES: 'My Courses',
        SIDEBAR_MY_COURSES_VIEW_MORE: 'view more courses...',
        SIDEBAR_MY_COURSES_CREATE_COURSE: 'Create My Course',

        SIDEBAR_HELP: 'Help',
        SIDEBAR_HELP_KNOWLEDGE_BASE: 'Knowledge Base',
        SIDEBAR_HELP_GET_SUPPORT: 'Get Support',
        SIDEBAR_HELP_STATUS: 'System Status',

        SIDEBAR_COURSEWARE: 'Courseware',
        SIDEBAR_COURSEWARE_REPOSITORY: 'Courseware Repository',

        SIDEBAR_ADMIN: 'Admin',
        SIDEBAR_ADMIN_ORGANIZATIONS: 'Organizations',
        SIDEBAR_COURSES: 'Courses',
        SIDEBAR_COURSE_APPROVALS: 'Course Approvals',
        SIDEBAR_COURSE_APPROVALS_NOTIFICATION: 'No pending course approvals.|1 pending course approval.|{count} pending course approvals.',
        SIDEBAR_CREATE_COURSE: 'Create a Course',
        SIDEBAR_USERS: 'Users',
        SIDEBAR_SUPER_ADMINS: 'Super Admins',
        SIDEBAR_ACCOUNT_APPROVALS: 'Account Approvals',
        SIDEBAR_ACCOUNT_APPROVALS_NOTIFICATION: 'No pending account approvals.|1 pending account approval.|{count} pending account approvals.',
        
        SIDEBAR_PLANS: 'Plans',

        SIDEBAR_VIEW_LOGS: 'View Logs',
    }
};