<template>
    <cr-filter :icon="icon" :label="label" :selected-values="selectedValues" :disabled="disabled">
        <cr-autocomplete v-model="modelValue" :label="label" :items="items" :item-value="itemValue" :item-label="itemLabel" :disabled="disabled" />
    </cr-filter>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useEnum } from '../../composables/useEnum';


const props = defineProps<
{
    /**
     * Icon to display.
     */
    icon:string,

    /**
     * Label to display.
     */
    label: string,

    /**
     * Selectable items or an enum to use for the list of items.
     */
    items: Record<string,string>|any[],

    /**
     * The items' property name to be used as an item value.
     * @default id
     */
    itemValue?: string,

    /**
     * The items' property name to be used as an item label.
     * @default name
     */
    itemLabel?: string,

    /**
     * Whether the field is a disabled field.
     */
    disabled?: boolean,
}>();

const { toDisplayEnumName, toSelectItems } = useEnum()

const itemValue = computed(() => props.itemValue);

const items = computed(() => Array.isArray(props.items) ? props.items : toSelectItems(props.items));
const modelValue = defineModel<string|undefined>({ required: true });
const selectedValues = computed(() => 
{
    if (!modelValue.value)
    {
        return undefined;
    }

    let value: string;
    if (Array.isArray(props.items))
    {
        value = props.items.find(item => item[props.itemValue || 'id'] === modelValue.value)?.[props.itemLabel || 'name'] as string
    }
    else
    {
        value = toDisplayEnumName(props.items, modelValue.value);
    }
    return [value];
});
</script>
