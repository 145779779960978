<template>
    <cr-card :to="to">
        <cr-card-body class="cr-pb1">
            <cr-row class="dashboard-tile-content">
                <cr-col class="dashboard-tile-primary-metric cr-pb2" data-testing="dashboard-tile-primary-metric">
                    <cr-row>
                        <h3 class="cr-mb0 cr-font-bold" data-testing="dashboard-tile-primary-metric-title">{{metricTitle}}</h3>
                    </cr-row>
                    <cr-row class="dashboard-tile-primary-metric-content">
                        <cr-col class="dashboard-tile-primary-metric-data-container">
                            <cr-row>
                                <span class="dashboard-tile-primary-metric-data" data-testing="dashboard-tile-primary-metric-data">
                                    <span class="dashboard-tile-primary-metric-current-value cr-mr2 cr-font-bold">{{ value || 0 }}</span>
                                    <span v-if="limit">{{ `${t('ORGANIZATIONS_ORGANIZATION_DASHBOARD_OUT_OF')} ${limit}` }}</span>
                                </span>
                            </cr-row>
                        </cr-col>
                        <cr-col xs4 class="dashboard-tile-primary-metric-icon-container cr-mr3" >
                            <cr-icon rounded data-testing="dashboard-tile-primary-metric-icon">{{ icon }}</cr-icon>
                        </cr-col>
                    </cr-row>
                </cr-col>
                    <slot v-if="$slots.default"/>
            </cr-row>
        </cr-card-body>

    </cr-card>
</template>

<style scoped>
.dashboard-tile-right-column
{
    border-left: .1rem solid var(--cr-grey);
}

.dashboard-tile-primary-metric-current-value
{
    font-size: var(--cr-dashboard-gauge-value-font-size);
}

.dashboard-tile-primary-metric
{
    display: flex;
    flex-direction: column;
    height: 100%
}
.dashboard-tile-primary-metric-icon-container
{
    display: grid;
}
.dashboard-tile-primary-metric-icon-container > .cr-icon
{
    justify-self: end;
    align-self: center;
    border: 1px solid var(--cr-blue-lighten-4);
    padding: 1rem;
    background: rgba(24, 103, 192, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

.dashboard-tile-primary-metric-icon-container > .cr-icon::before
{
    color: var(--cr-primary);
}

.dashboard-tile-primary-metric-data-container
{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.dashboard-tile-primary-metric-data
{
    justify-self: center;
}

.dashboard-tile-content
{
    height: 100%;
}
.dashboard-tile-primary-metric-content
{
    flex-grow: 1;
}

h3
{
    font-size: var(--cr-card-title-font-size);
}

</style>

<script setup lang="ts">
import { computed } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import { ICourse } from '@cyber-range/cyber-range-api-user-client';
import { RouteLocationRaw } from 'vue-router';

const { t } = useI18n();

const props = defineProps<
{
    metricTitle: string;
    icon: string;
    value?: number|undefined;
    limit?: number|undefined;
    to?: RouteLocationRaw;
}>();

</script>