<template>
    <cr-breadcrumb :items="breadcrumbs" class="cr-font-bold"/>
    <h1 class="page-title" data-testing="search-layout-title">
        {{ props.title }}
    </h1>
    <cr-container ref="container" class="cr-mt5 cr-xs-p4 cr-sm-p6 search-layout-container">
        <div v-if="$slots.top" xs12 class="cr-mb5" data-testing="search-layout-top">
            <slot name="top" />
        </div>
        <cr-row class="search-layout-content-container">
            <cr-col v-if="$slots.filter" xs12 md5 lg4 xl4 xxl3 class="search-layout-filter" data-testing="search-layout-filter">
                <slot name="filter" />
            </cr-col>
            <cr-col xs12 md7 lg8 xl8 xxl9 class="search-layout-content">
                <cr-loading v-if="!ready" class="cr-p6 cr-text-center" data-testing="search-layout-loading"/>
                <template v-else>
                    <div v-if="$slots.default" class="search-layout-default-slot" data-testing="search-layout-default">
                        SearchLayout does not support "default" slot. 
                    </div>
                    <div v-if="$slots.status" class="search-layout-status-slot" data-testing="search-layout-status">
                        <slot name="status" />
                    </div>
                    <div v-if="$slots.result" class="search-layout-result-slot" data-testing="search-layout-result">
                        <slot name="result" />
                    </div>
                    <div v-if="$slots.pagination" class="search-layout-pagination-slot cr-ptb4 cr-text-center" data-testing="search-layout-pagination">
                        <slot name="pagination" />
                    </div>
                </template>
            </cr-col>
        </cr-row>
    </cr-container>
</template>

<style scoped>
.search-layout-container
{
    background-color: white;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.search-layout-content-container
{
    flex-grow: 1;
}
.search-layout-content
{
    display: flex;
    flex-direction: column;
}
.search-layout-result-slot
{
    flex-grow: 1;
}
</style>

<script setup lang="ts">
import { usePageTitle } from '../../composables/usePageTitle';
import { IBreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, onMounted, ref } from 'vue';

const props = defineProps<
{
    /**
     * A title for the page.
     */
    title?:string

    /**
     * Breadcrumbs for the page.
     */
    breadcrumbs:IBreadcrumbItem[]

    /**
     * Whether the content is ready to display.
     * A loading indicator will be displayed while if the content is not ready.
     */
    ready: boolean
}>()

usePageTitle(computed(()=>props.breadcrumbs));

const containerTopOffset = ref('0px');
const container = ref();

onMounted(async () =>
{
    const top = (container.value?.getBoundingClientRect?.()?.top || 0) + window.scrollY;
    containerTopOffset.value = `${top}px`;
});

</script>