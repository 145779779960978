<template>
    <cr-confirmation-dialog :title="t('ACCOUNT_APPROVALS_APPROVED_ACTION_TITLE')" :cancel="false" persistent :confirm-text="t('ACCOUNT_APPROVALS_DIALOG_CONFIRM_TEXT')" data-testing="account-approvals-approve-completed-dialog">
        {{ t('ACCOUNT_APPROVALS_APPROVED_ACTION_MESSAGE' ) }}
    </cr-confirmation-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n();
</script>
