<template>
    <cr-alert v-for="alert in alerts" v-bind="alert.args" class="cr-mb2">
        <span data-testing="alert-message">{{ alert.message }}</span>
    </cr-alert>
</template>

<script setup lang="ts">
import { INote, NoteType } from '@cyber-range/cyber-range-api-catalog-client';
import { computed } from 'vue';

const props = defineProps<{
    notes: INote[]
}>();

const alerts = computed(() => 
{
    return props.notes.map(note => ({
        args: {
            info: note.type === NoteType.INFO,
            warn: note.type === NoteType.WARNING,
            error: note.type === NoteType.ERROR,
        },
        message: note.message
    }));
})
</script>
