<template>
    <form-layout :title="title" :breadcrumbs="breadcrumbs" @confirm="onConfirm" @cancel="onCancel" :ready="isReady">
        <template #form>
            <course-form v-model="course" v-model:business-unit="businessUnit" />
            <form-section v-if="showInstructorPicker" :label="t('COURSE_FORM_INSTRUCTOR_SECTION')" class="cr-mt5" data-testing="course-form-instructor-section">
                <instructor-picker v-model="instructor" :organizationId="course.organizationId" data-testing="course-form-instructor-picker" class="cr-mt3"/>
                <cr-confirmation-dialog v-model="showNoInstructorsWarning" @confirm="onShowNoInstructorsWarningConfirmed" @cancel="onShowNoInstructorsWarningCancelled" data-testing="course-form-no-instructors-warning-dialog">
                    {{ t('COURSES_COURSE_CREATE_COURSE_NO_INSTRUCTOR_SET_WARNING') }}
                </cr-confirmation-dialog>
            </form-section>
            <course-payment-form-section v-if="!course.id && course.organizationId" v-model="course.isUserPaysCourse" v-model:course-cost="expectedCost" :course="course"/>
        </template>
    </form-layout>
    <cr-confirmation-dialog v-model="showCourseCostChangedDialog" @confirm="onCourseCostChangedDialogConfirmed" :title="t('COURSES_CREATE_COST_CHANGED_DIALOG_TITLE')" :cancel="false" :confirm-text="t('COURSES_CREATE_COST_CHANGED_DIALOG_CONFIRM_TEXT')" data-testing="create-course-cost-changed-dialog">
        {{ t('COURSES_CREATE_COST_CHANGED_DIALOG_MESSAGE', { expectedCost, actualCost: createCourseResponse?.price?.amount }) }}
    </cr-confirmation-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import Route from '../../routers/route';
import FormLayout from '../layouts/FormLayout.vue';
import { computed, onMounted, ref } from 'vue';
import { Course, InvitationTemplate, ICreateCourseParams, ICreateCourseResponse } from '@cyber-range/cyber-range-api-user-client';
import { useCalendar } from '../../composables/useCalendar';
import { useCourseStore } from '../../stores/courseStore';
import { useEntitlementStore } from '../../stores/entitlementStore';
import { useRouter } from 'vue-router';
import { useRawObject } from '../../composables/useRawObject';
import CourseForm from './CourseForm.vue';
import { useBusinessUnitStore } from '../../stores/businessUnitStore';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useWhoseCourse } from '../../composables/useWhoseCourse';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import FormSection from '../layouts/sections/FormSection.vue';
import InstructorPicker from '../pickers/InstructorPicker.vue';
import { UserRole } from '@cyber-range/cyber-range-api-user-client';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useSessionStore } from '../../stores/sessionStore';
import { BusinessUnit, IBusinessUnit } from '@cyber-range/cyber-range-api-business-unit-client';
import CoursePaymentFormSection from './CoursePaymentFormSection.vue';

const { t } = useI18n();
const router = useRouter();
const isReady = ref(false);
const calendar = useCalendar();
const whoseCourse  = useWhoseCourse();
const instructor = ref<{id: string}|{name:string, email:string}|undefined>();

const { canInvite } = useAuthorizationStore();
const canInviteInstructor = computed(()=>canInvite(UserRole.Instructor, 'any', course.value.organizationId));

const title = computed(()=>whoseCourse.value.mine ? t('COURSES_COURSE_CREATE_MY_COURSE_PAGE_TITLE') : t('COURSES_COURSE_CREATE_COURSE_PAGE_TITLE'));

const breadcrumbs = computed(()=> useCourseBreadcrumbs([
                                    new BreadcrumbItem(Route.Courses), 
                                    new BreadcrumbItem(Route.CreateCourse)]));

const invitationTemplate = new InvitationTemplate();
invitationTemplate.html = t('COURSE_INVITATION_BODY_HTML');
invitationTemplate.subject = t('COURSE_INVITATION_SUBJECT');
invitationTemplate.text = t('COURSE_INVITATION_BODY_TEXT');

const course = ref<ICreateCourseParams>({...new Course({invitationTemplate}), isAvailable:true, isUserPaysCourse:false, users: []});
const expectedCost = ref<number>();
const showCourseCostChangedDialog = ref(false);

const allowCourseWithoutInstructors = ref<boolean>(false);
const showNoInstructorsWarning = ref<boolean>(false);
const showInstructorPicker = computed(()=>whoseCourse.value.others && canInviteInstructor.value);

const businessUnit = ref<IBusinessUnit>(new BusinessUnit());

const onShowNoInstructorsWarningConfirmed = async () =>
{
    allowCourseWithoutInstructors.value = true;
    await onConfirm();
    showNoInstructorsWarning.value = false;
}

const onShowNoInstructorsWarningCancelled = () =>
{
    showNoInstructorsWarning.value = false;
}

const createCourseResponse = ref<ICreateCourseResponse>();
const onConfirm = async () => 
{
    let payload = useRawObject(course.value, {excludes: ['limits', 'statistics', 'isAvailable']});
    payload.startTime = calendar.toStartOfDayISOString(payload.startTime);
    payload.endTime = calendar.toEndOfDayISOString(payload.endTime);
    
    if(instructor.value)
    {
        payload.users =  'id' in instructor.value
                            ? [{id: instructor.value.id, role: UserRole.Instructor}] 
                            : [{name: instructor.value.name, email: instructor.value.email, roles: [UserRole.Instructor]}];
    }

    // For those who can invite an instructor and create a course for themselves, assign the user as the instructor.
    if(whoseCourse.value.mine && canInviteInstructor.value)
    {
        let identity = useSessionStore().identity;

        if(identity?.id)
        {
            payload.users = [{id: identity.id, role: UserRole.Instructor}];
        }
    }
    
    // Show no instructors warning if no instructors
    if(showInstructorPicker.value && payload.users.length === 0 && !allowCourseWithoutInstructors.value)
    {
        showNoInstructorsWarning.value = true;
        return;
    }

    if (!businessUnit.value.id)
    {
        payload.businessUnitId = await useBusinessUnitStore().create({ ...businessUnit.value, organizationId: course.value.organizationId });
    }
    else
    {
        payload.businessUnitId = businessUnit.value.id;
    }

    // Create a course
    createCourseResponse.value = await useCourseStore().create(payload);
    if(payload.isUserPaysCourse && expectedCost.value !== createCourseResponse.value.price.amount)
    {
        showCourseCostChangedDialog.value = true;
    }
    else
    {
        await onFinished();
    }
}
async function onFinished()
{
    //We need to fetch the new course claim.
    await useEntitlementStore().fetchClaims();
    
    //Update the sidebar
    useCourseStore().fetchMyCourses();

    router.push({ name: Route.Course.name, params: {courseId: createCourseResponse.value!.id}});
}
const onCourseCostChangedDialogConfirmed = onFinished;

const onCancel = () => 
{
    router.push(whoseCourse.value.topRoute);
}

onMounted(async ()=>
{
    await Promise.all([
        useOrganizationStore().fetchOrganizations(),
        useBusinessUnitStore().fetchBusinessUnits()
    ]);
    isReady.value = true
})
</script>