<template>
    <form-layout :title="t('ORGANIZATIONS_CREATE_ORGANIZATION_PAGE_TITLE')" :breadcrumbs="breadcrumbs" @confirm="onConfirm" @cancel="onCancel" :ready="isReady">
        <template #form>
            <organization-form v-model="organization" @update:logo-file="onFileUpdated"/>
        </template>
    </form-layout>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import Route from '../../routers/route';
import FormLayout from '../layouts/FormLayout.vue';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { Organization, OrganizationContact } from '@cyber-range/cyber-range-api-organization-client';
import { FileVisibility, File as FileApiFile, MetadataType } from '@cyber-range/cyber-range-api-file-client';
import OrganizationForm from './OrganizationForm.vue';
import { useRawObject } from '../../composables/useRawObject';
import { useEntitlementStore } from '../../stores/entitlementStore';
import { useFileStore } from '../../stores/fileStore';
import { ApiClientError } from '@cyber-range/cyber-range-api-client';
import { useErrorStore } from '../../stores/errorStore';

const { t } = useI18n();
const router = useRouter();
const isReady = ref(false);

const breadcrumbs = computed(()=> useCourseBreadcrumbs([
                                    new BreadcrumbItem(Route.Organizations), 
                                    new BreadcrumbItem(Route.CreateOrganization)]));

let organization = {...new Organization(), contact: new OrganizationContact()};
let logoFile: File|undefined;

const onFileUpdated = async (data: File|undefined) =>
{
    logoFile = data;
}

const onConfirm = async () => 
{
    let payload = useRawObject(organization, {excludes: ['parentId']});
    payload.contact.name = `${payload.contact.firstName} ${payload.contact.lastName}`;

    let organizationId = await useOrganizationStore().create(payload);

    if (logoFile)
    {
        try 
        {
            const fileApiFile = new FileApiFile({type: logoFile.type, name: logoFile.name, visibility: FileVisibility.PUBLIC, metadata: {id: organizationId, type: MetadataType.OrganizationLogo }});
            const fileId = await useFileStore().uploadFile(fileApiFile, logoFile);
            const downloadUrl = await useFileStore().getDownloadUrl(fileId);
            await useOrganizationStore().update(Organization.fromJson({id: organizationId, logo: downloadUrl}));
        } 
        catch (e)
        {
            if (e instanceof ApiClientError)
            {
                throw e;
            } 
            // Non-ApiClient errors are typically silent, set friendly error and continue to org page
            useErrorStore().setError({message: t('ORGANIZATION_FORM_LOGO_UPLOAD_FAILED_ERROR', {errorMessage: (e as Error).message}), details:'',statusCode:0});
        }
    }

    if (organization.parentId)
    {
        await useOrganizationStore().setOrganizationParentId(organizationId, organization.parentId);
    }
    
    //Fetch new organization claim
    await useEntitlementStore().fetchClaims();

    router.push({ name: Route.Organization.name, params: {organizationId}});
}

const onCancel = () => 
{
    router.push(Route.Organizations);
}

onMounted(async ()=>
{
    await useOrganizationStore().fetchParentOrganizations();
    isReady.value = true;
})
</script>