<template>
    <cr-confirmation-dialog :title="t('VIEW_ACTIVITY_TITLE')" :cancel="false" :confirm-text="t('VIEW_ACTIVITY_CONFIRM_TEXT')" data-testing="view-activity-dialog">
        <cr-container>
            <cr-row tag="dl">
                <template v-for="detail of details">
                    <cr-col tag="dt" xs12 sm12 md12 lg3 xl3>{{ detail.key }}</cr-col>
                    <cr-col tag="dd" xs12 sm12 md12 lg9 xl9>{{ detail.value }}</cr-col>
                </template>
            </cr-row>
        </cr-container>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useNumberFormats } from '../../../composables/useNumberFormats';
import { IActivity } from '@cyber-range/cyber-range-api-balance-client';
import { sentenceCase } from "change-case";

const { t } = useI18n();
const { toCurrency } = useNumberFormats();

const props = defineProps<
{
    activity?: IActivity
}>();

const details = computed(() =>
{
    return [
        {key: t('VIEW_ACTIVITY_ACTIVITY_ID', {type: sentenceCase(props.activity?.type || "")}), value: props.activity?.referenceId},
        {key: t('VIEW_ACTIVITY_CREATED_TIMESTAMP'), value: props.activity?.createdTimestamp},
        {key: t('VIEW_ACTIVITY_AMOUNT'), value: toCurrency(props.activity?.amount || 0)},
        {key: t('VIEW_ACTIVITY_NOTES'), value: props.activity?.notes}
    ]
});
</script>