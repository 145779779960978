<template>
    <cr-filter icon="bi-calendar3" :label="label || t('DATE_FILTER_DATE_PICKER_LABEL')" :selected-values="selectedValues" :disabled="disabled || isLoading" :data-testing="dataTesting">
        <cr-select v-model="modifier" :label="t('DATE_FILTER_MODIFIER_LABEL')" :items="modifierItems" :disabled="isLoading" :data-testing="selectDataTesting" />
        <cr-date-picker v-model="date" :label="label || t('DATE_FILTER_DATE_PICKER_LABEL')" :disabled="isLoading" :data-testing="dateDataTesting"/>
        <div class="date-filter-buttons">
            <cr-button :label="t('DATE_FILTER_CLEAR_BUTTON')" @click="onCleared" text :disabled="isLoading" class="cr-mr2" :data-testing="clearButtonDataTesting" />
            <cr-button :label="t('DATE_FILTER_CONFIRM_BUTTON')" @click="onConfirmed" :disabled="isLoading" :data-testing="confirmButtonDataTesting" />
        </div>
    </cr-filter>
</template>

<style scoped>
.cr-filter:deep(.cr-dropdown-toggle),
.cr-filter:deep(.cr-dropdown-list)
{
    min-width: 12rem;
}
.date-filter-buttons
{
    text-align: right;
}
</style>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useApiClientStore } from '../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { DateModifier } from '../../interfaces/DateModifier';
import { useEnum } from '../../composables/useEnum';
import { sentenceCase } from "change-case";

const props = defineProps<
{
    /**
     * The selected date
     */
    modelValue: string | undefined;

    /**
     * The selected date
     * @default DateModifier.OnOrBefore
     */
    modelModifier: DateModifier | undefined;

    /**
     * Whether the filter is disabled.
     * @default false
     */
    disabled?: boolean

    /**
     * Label on the filter
     * @default t('DATE_FILTER_LABEL')
     */
    label?: string

    /**
     * data-testing
     * @default 'to-from-date-filter'
     */
    dataTesting?: string
}>();

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const emit = defineEmits<{
    (name: 'update:modelValue', date?: string): void,
    (name: 'update:modelModifier', modifier:string): void
}>();

const modifier = ref<DateModifier>(props.modelModifier || DateModifier.OnOrBefore);
const date = ref<string|undefined>(props.modelValue);
const selectedValues = ref<string[]>();

const dataTesting = props.dataTesting || 'to-from-date-filter';
const selectDataTesting = `${dataTesting}-select`;
const dateDataTesting = `${dataTesting}-date`;
const confirmButtonDataTesting = `${dataTesting}-confirm`;
const clearButtonDataTesting = `${dataTesting}-clear`;

const { toDisplayEnumName } = useEnum();

function toDateModifierDisplayName(modifier:DateModifier)
{
    return sentenceCase(toDisplayEnumName(DateModifier, modifier));
}

const modifierItems = [ 
                        {name: toDateModifierDisplayName(DateModifier.OnOrBefore), id: DateModifier.OnOrBefore },
                        {name: toDateModifierDisplayName(DateModifier.OnOrAfter), id: DateModifier.OnOrAfter },
                      ];

function setSelectedValues()
{
    selectedValues.value =  date.value && modifier.value ? [`${toDateModifierDisplayName(modifier.value)} ${date.value}`] : [];
}

function clearSelectedValues()
{
    selectedValues.value =  [];
}

watch(date, clearSelectedValues);
watch(modifier, clearSelectedValues);

watch(()=>props.modelValue, ()=>
{
    date.value = props.modelValue;
    setSelectedValues();
}, {immediate: true});

watch(()=>props.modelModifier, ()=>
{
    modifier.value = props.modelModifier || DateModifier.OnOrBefore;
    setSelectedValues();
}, {immediate: true});

const onCleared = () =>
{
    date.value = undefined;
    modifier.value = DateModifier.OnOrBefore;
    emit('update:modelModifier', modifier.value);
    emit('update:modelValue', date.value);
    selectedValues.value = [];
};

const onConfirmed = () =>
{
    if(modifier.value !== props.modelModifier)
    {
        emit('update:modelModifier', modifier.value);
    }
    if(date.value !== props.modelValue)
    {
        emit('update:modelValue', date.value);
    }
    setSelectedValues();
}
</script>