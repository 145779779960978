import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import Route from '../route';

export default async function enterIf(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext, func:()=>Function, paramKeys: string[] = []): Promise<void>
{
    if (!func()(...paramKeys.map(key => to.params[key])))
    {
        return next(Route.Home);
    }
    else
    {
        return next();
    }
}