export default {
    en: {
        // Subscription Tile
        SUBSCRIPTION_TILE_DEFAULT_BOTTOM_MESSAGE: "Includes license and AWS Usage.",
        SUBSCRIPTION_TILE_DATE_RANGE_BOTTOM_MESSAGE: "Active from: {from} until: {until}",
        SUBSCRIPTION_TILE_DEFAULT_TO: "cancelled",
        SUBSCRIPTION_TILE_ACTION_EDIT: "Edit",
        SUBSCRIPTION_TILE_ACTION_RENEW: "Renew",
        SUBSCRIPTION_TILE_ACTION_DELETE: "Delete",
        SUBSCRIPTION_TILE_ACTIVE_ICON_ALT: "Currently Active Plan",
        SUBSCRIPTION_TILE_ACTION_BUTTON_TITLE: "Actions for {planName}",
        SUBSCRIPTION_TILE_COST_SUBTITLE: "per user/month",

        // Delete Subscription
        DELETE_SUBSCRIPTION_MESSAGE: "Are you sure you want to delete this subscription?",

        // Edit Subscription
        EDIT_SUBSCRIPTION_TITLE: "Edit Plan",
        EDIT_SUBSCRIPTION_FEATURES: "Features",

        // Renew Subscription
        RENEW_SUBSCRIPTION_TITLE: "Renew Plan",
        RENEW_SUBSCRIPTION_DATE_PICKER_LABEL: "End Date",
        RENEW_SUBSCRIPTION_DESCRIPTION_WITH_END_DATE: "This subscription is currently set to end on {endDate}. To renew this subscription, please select a new end date.",
        RENEW_SUBSCRIPTION_DESCRIPTION_WITHOUT_END_DATE: "This subscription has no end. Select a date below for the end date.",

        // Subscribe to a Plan
        SUBSCRIBE_PAGE_TITLE: "Subscribe to a Plan",
        
        // Subscription Form
        SUBSCRIPTION_FORM_PLAN_SECTION: "Select Plan",

        SUBSCRIPTION_FORM_PLAN_SELECT_LABEL: "Plan",
        SUBSCRIPTION_FORM_FEATURES_SECTION: "Features",
        SUBSCRIPTION_FORM_DURATION_SECTION: "Duration",
        SUBSCRIPTION_FORM_NEVER_EXPIRES: "Never Expires",
        SUBSCRIPTION_FORM_START_DATE: "From",
        SUBSCRIPTION_FORM_END_DATE: "To",

        // Billing Page
        BILLING_PAGE_TITLE: "Billing",
        BILLING_INVOICE_CONTACT_SECTION: "Invoice Notification Contacts",
        BILLING_INVOICE_CONTACT_NO_BILLING_ADMINS: "Invoice notifications are disabled for this organization because it does not have any billing admins.",
        BILLING_INVOICE_CONTACT_BILLING_ADMINS: "An email notification will be sent to the following users when an invoice is ready:",
        BILLING_ACTIVE_RATE_PLANS_SECTION: "Active Rate Plans",
        BILLING_ORGANIZATION_USAGE_BUTTON: "View Organization Usage",
        BILLING_CREATE_PAYMENT_LINK_BUTTON: "Create Payment Link",
        BILLING_CREATE_MAKE_PAYMENT_BUTTON: "Make a Payment",
        BILLING_CONTRACT_EXPIRATION_BUTTON: "Set Contract Expiration",
        BILLING_CONTRACT_CHANGE_PLAN_BUTTON: "Change My Plan",
        BILLING_CONTRACT_SUBSCRIBE: "Subscribe to a Plan",
        BILLING_LEARN_ABOUT_FEATURES: "Learn about Features",
        BILLING_CHANGE_PLAN_BUTTON: "Change My Plan",
        BILLING_ADD_TRANSACTION_BUTTON: "Add A Transaction",
        BILLING_CONTRACT_EXPIRATION: {
            VACR: "Virginia Cyber Range Agreement Expiration",
            USCR: "U.S. Cyber Range Agreement Expiration"
        },
        BILLING_CONTRACT_EXPIRATION_NO_CONTRACT: "This organization has no active software agreements.",
        BILLING_CONTRACT_BETWEEN_MESSAGE: "From {startDate} to {expirationDate}",
        BILLING_CONTRACT_NEVER_EXPIRES_MESSAGE: "From {startDate} until canceled",
        BILLING_CONTRACT_EXPIRATION_MESSAGE: "Software Agreement Expiration: {expirationDate}",
        BILLING_EDIT_ORGANIZATION_CONTRACT_TITLE: {
            VACR: "Virginia Cyber Range Agreement Expiration",
            USCR: "U.S. Cyber Range Agreement Expiration"
        },
        BILLING_EDIT_ORGANIZATION_CONTRACT_START_LABEL: 'From',
        BILLING_EDIT_ORGANIZATION_CONTRACT_EXPIRATION_LABEL: 'To',
        BILLING_BALANCE_ACTIVITY_SECTION: "Balance Activity",
        BILLING_PAGE_CURRENT_BALANCE: "Current Balance",

        // Balance Activity Table
        ACTIVITY_TABLE_TIMESTAMP: "Timestamp",
        ACTIVITY_TABLE_DESCRIPTION: "Description",
        ACTIVITY_TABLE_STATUS: "Status",
        ACTIVITY_TABLE_CHANGED: "Changed",
        ACTIVITY_TABLE_BALANCE: "Balance",
        ACTIVITY_TABLE_VIEW_PAYMENT: "View Payment",
        ACTIVITY_TABLE_APPLY_FINANCE_CHARGE: "Apply Finance Charge",
        ACTIVITY_TABLE_VIEW_INVOICE: "View Invoice",
        ACTIVITY_TABLE_EDIT_INVOICE: "Edit Invoice",
        ACTIVITY_TABLE_VIEW_ACTIVITY: "View Balance Activity",

        // View Payment
        VIEW_PAYMENT_TITLE: "Payment Information",
        VIEW_PAYMENT_DATE: "Date",
        VIEW_PAYMENT_PROVIDER: "Provider",
        VIEW_PAYMENT_PAYER: "Payer",
        VIEW_PAYMENT_PAYMENT_METHOD: "Payment Method",
        VIEW_PAYMENT_AMOUNT: "Amount",
        VIEW_PAYMENT_STATUS: "Status",
        VIEW_PAYMENT_MESSAGE: "Message",
        VIEW_PAYMENT_PRINT_BUTTON_TEXT: "Print",
        VIEW_PAYMENT_CANCEL_TEXT: "Close",

        // View Payment
        VIEW_ACTIVITY_TITLE: "Balance Activity",
        VIEW_ACTIVITY_ACTIVITY_ID: "{type} Id",
        VIEW_ACTIVITY_CREATED_TIMESTAMP: "Created Timestamp",
        VIEW_ACTIVITY_AMOUNT: "Amount",
        VIEW_ACTIVITY_NOTES: "Notes",
        VIEW_ACTIVITY_CONFIRM_TEXT: "Close",

        // Change My Plan
        BILLING_CHANGE_PLAN_TITLE: "Change Plan Request",
        BILLING_CHANGE_PLAN_MESSAGE: "Our Support team is ready to assist you with the plan change process. To receive an email detailing plan change options, please press the Confirm button to proceed.",
        BILLING_CHANGE_PLAN_SUPPORT_MESSAGE_SUBJECT: "Plan Change Request",
        BILLING_CHANGE_PLAN_SUPPORT_MESSAGE_BODY: "An organization would like to change their plan. Organization details:\nOrganization name: {organizationName}\nOrganization page: {organizationUrl}\nCurrent plan expiration:\n    {plans}",

        // Apply Finance Charge
        APPLY_FINANCE_CHARGE_DIALOG_TITLE: "Apply a Finance Charge",
        APPLY_FINANCE_CHARGE_AMOUNT_LABEL: "Amount",
        APPLY_FINANCE_CHARGE_POSTED_DATE_LABEL: "Posted Date",
        APPLY_FINANCE_CHARGE_DESCRIPTION_LABEL: "Description",
        APPLY_FINANCE_CHARGE_DEFAULT_DESCRIPTION: "Finance Charge",

        // Edit Invoice
        EDIT_INVOICE_DIALOG_TITLE: "Edit Invoice",
        EDIT_INVOICE_DIALOG_DISPLAY_ID: "Invoice ID",
        EDIT_INVOICE_DIALOG_INVOICE_STATUS: "Invoice Status",

        // View Invoice
        INVOICE_TABLE_DATA_NAME: "Name",
        INVOICE_TABLE_DATA_TOTAL: "Total",
        INVOICE_TABLE_DATA_CLOUD_USAGE: "Cloud Usage",
        INVOICE_TABLE_DATA_ENROLLMENTS: "Enrollments",
        INVOICE_TABLE_DATA_RATE: "Rate",
        VIEW_INVOICE_DIALOG_TITLE: "Invoice Details",
        VIEW_INVOICE_INVOICE_ID: "Invoice ID",
        VIEW_INVOICE_INVOICE_PERIOD: "Period",
        VIEW_INVOICE_STATUS: "Status",
        VIEW_INVOICE_FROM_TO: "{from} to {to}",
        VIEW_INVOICE_PRINT_BUTTON_TEXT: "Print Invoice",
        VIEW_INVOICE_CANCEL_TEXT: "Close",
        VIEW_INVOICE_TOTAL_NAME: "Total",
        VIEW_INVOICE_EXERCISE_USAGE_TOOLTIP: "Storage and compute costs for virtual machines provisioned for students, teaching assistants, and instructors.",
        VIEW_INVOICE_OVERHEAD_TOOLTIP: "A portion of the total cloud infrastructure support cost based on organizational use.",

        // Create Upfront Payment Link
        UPFRONT_PAYMENT_LINK_DIALOG_TITLE: "Create a Payment Link",
        UPFRONT_PAYMENT_LINK_NO_ALT_ID: "This account has not been configured to make a payment online.  Please add an alternate ID and retry.",
        UPFRONT_PAYMENT_LINK_AMOUNT_LABEL: "Amount",
        UPFRONT_PAYMENT_LINK_VIEW_LINK_TEXT: "Below is the payment url. Please copy the link and send to the customer. The link will not be accessible once this dialog is closed.",

        // Make a Payment Dialog
        MAKE_PAYMENT_DIALOG_TITLE: "Make a Payment",
        MAKE_PAYMENT_DIALOG_AMOUNT_DUE: "Amount due: {amount}",
        MAKE_PAYMENT_DIALOG_CUSTOM_AMOUNT: "Custom amount",
        MAKE_PAYMENT_DIALOG_NO_ALT_ID_TEXT: "Your account has not been configured to make a payment online.  Please contact our customer support to enable this feature.",
        MAKE_PAYMENT_AMOUNT_LABEL: "Amount",

        // Create Activity Dialog
        CREATE_ACTIVITY_DIALOG_TITLE: "Add a Transaction",
        CREATE_ACTIVITY_DIALOG_AMOUNT: "Amount",
        CREATE_ACTIVITY_DIALOG_POSTED_DATE: "Posted Date",
        CREATE_ACTIVITY_DIALOG_DESCRIPTION: "Description",
    }
}