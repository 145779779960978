<template>
    <cr-confirmation-dialog @confirm="onConfirmed" @cancel="onCancelled" data-testing="delete-invitation-code-dialog" confirmTextColor="var(--cr-confirmation-dialog-with-checkbox-confirm-text-color)" confirmBackgroundColor="var(--cr-confirmation-dialog-with-checkbox-confirm-background-color)">
        <cr-col class="cr-pb3">
            {{ t('COURSE_USERS_DELETE_INVITATION_CODE_DIALOG_MESSAGE') }}
        </cr-col>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useCourseStore } from '../../../stores/courseStore';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    courseId?: string;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void,
    (name: 'cancel'): void
}>();

const onConfirmed = async ()=>
{
    await useCourseStore().deleteCourseCode(String(props.courseId));
    emit('confirm');
}

const onCancelled = async ()=>
{
    emit('cancel');
}

</script>