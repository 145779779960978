<template>
    <form-section v-if="!application.type">
        <cr-select v-model="application.type" :label="t('ORGANIZATION_APPLICATIONS_FORM_TYPE')" :disabled="isLoading" :items="types" required id="organization-application-type-select" data-testing="organization-application-type-select" class="cr-mt3"/>
    </form-section>
    <component v-else :is="form" v-model="application"/>
</template>

<script setup lang="ts">
import { IOrganizationApplication, Lti13OrganizationApplication, OrganizationApplicationType } from '@cyber-range/cyber-range-api-organization-client';
import FormSection from '../layouts/sections/FormSection.vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../stores/apiClientStore';
import { computed } from 'vue';
import { useEnum } from '../../composables/useEnum';
import Lti13OrganizationApplicationFormSection from './Lti13OrganizationApplicationFormSection.vue';

const props = defineProps<{
    modelValue: IOrganizationApplication,
}>();
const emit = defineEmits<{
    (e:'update:modelValue', application:IOrganizationApplication): void,
}>();

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const application = computed(
    {
        get: () => props.modelValue.type === OrganizationApplicationType.Lti13 ? props.modelValue as Lti13OrganizationApplication : props.modelValue as never,
        set: (value: IOrganizationApplication) => emit('update:modelValue', value)
    });


const form = computed(() => 
    {
        switch (application.value.type) {
            case OrganizationApplicationType.Lti13:
                return Lti13OrganizationApplicationFormSection;
            default:
                return undefined;
        }
    });

const toApplicationTypeDisplayName = (key?: string|number, fallbackName?: string) =>
{
    return key === OrganizationApplicationType.Lti13 ? t('ORGANIZATION_APPLICATIONS_TYPE_LTI') : fallbackName;
}
const types = useEnum().toSelectItems(OrganizationApplicationType).map(i => ({id: i.id, name: toApplicationTypeDisplayName(i.id, i.name)}));


</script>