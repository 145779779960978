<template>
    <form-section v-if="allowOrgSelection && !hasOneOrganization" :label="t('INVITE_ORGANIZATION_USER_FORM_ORGANIZATION_SECTION')" data-testing="invite-organization-user-organization-section" class="cr-mb5">
        <cr-select v-model="invitation.organizationId" :label="t('INVITE_ORGANIZATION_USER_FORM_ORGANIZATION_SECTION_ORGANIZATION')" :disabled="isLoading" :items="allowedOrganizations" required class="cr-mt3"/>
    </form-section>
    <form-section class="invite-organization-user-form" :label="t('INVITE_ORGANIZATION_USER_FORM_INFO_SECTION')">
        <cr-text-field v-model="invitation.name" required :label="t('INVITE_ORGANIZATION_USER_FORM_NAME')" :max-length="100" data-testing="invite-organization-user-invitation-name" class="cr-mb3 cr-mt3"/>
        <cr-text-field v-model="invitation.email" required :label="t('INVITE_ORGANIZATION_USER_FORM_EMAIL')" type="email" :max-length="256" data-testing="invite-organization-user-invitation-email" class="cr-mb3 cr-mt3"/>
        <cr-text-field v-model="invitation.title" required :label="t('INVITE_ORGANIZATION_USER_FORM_TITLE')" :max-length="100" data-testing="invite-organization-user-invitation-title" class="cr-mt3"/>
    </form-section>
    <business-unit-picker v-if="invitation.organizationId" v-model="businessUnit" :organization-id="invitation.organizationId" class="cr-mt5"/>
    <form-section :label="t('INVITE_ORGANIZATION_USER_FORM_BUSINESS_ROLES')" class="cr-mt5">
        <role-picker v-model="selectedRoles" :roles="availableRoles" data-testing="invite-organization-user-role-picker"/>
        <cr-error-text v-if="validate && !validRoleSelection" :message="t('INVITE_ORGANIZATION_USER_FORM_ROLE_ERROR')" class="role-error-text" data-testing="invite-organization-user-role-error-text"/>
    </form-section>
</template>
<style>
.role-error-text
{
    font-size: 0.8rem;
}
</style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import FormSection from '../layouts/sections/FormSection.vue';
import RolePicker from '../pickers/RolePicker.vue';
import { computed, reactive, ref, watch } from 'vue';
import { IBusinessUnit } from '@cyber-range/cyber-range-api-business-unit-client/dist/interfaces/iBusinessUnit';
import { BusinessUnit } from '@cyber-range/cyber-range-api-business-unit-client';
import { UserRole } from '@cyber-range/cyber-range-api-organization-client';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { IInvitation } from '@cyber-range/cyber-range-api-invitation-client';
import BusinessUnitPicker from '../pickers/BusinessUnitPicker.vue';
import { storeToRefs } from 'pinia';
import { useOrganizationStore } from '../../stores/organizationStore';
import { onMounted } from 'vue';

const { t } = useI18n();
const { canInviteOrganizationAdmin, canInviteBillingAdmin, canInviteCourseAdmin, canInviteOrganizationInstructor, canInviteCoursewareAdmin, canInviteOrganizationStudent, canInviteOrganizationTa } = useAuthorizationStore();

const selectedRoles = ref<UserRole[]>([]);

const props = defineProps<
{
    allowOrgSelection?: boolean
    modelValue: IInvitation;
    businessUnit: IBusinessUnit;

    /**
     * Whether or not to check for valid roles and display the validation message.
     * Only show validation message when true.
     * @default false
     */
    validate?: boolean;
}>();

const authorizationStore = useAuthorizationStore();

const { organizations } = storeToRefs(useOrganizationStore());
const allowedOrganizations = computed(() => organizations.value.filter(org => authorizationStore.canInviteOrganizationUsers(org.id)));
const hasOneOrganization = computed(() => allowedOrganizations.value.length === 1);
const isLoading = ref(false);
watch(hasOneOrganization, ()=>
{
    if (hasOneOrganization.value)
    {
        invitation.organizationId = allowedOrganizations.value[0].id;
    }
}, { immediate: true });
onMounted(async () => 
{
    if (props.allowOrgSelection)
    {
        isLoading.value = true;
        await useOrganizationStore().fetchOrganizations()
        isLoading.value = false
    }
});


const emit = defineEmits(['update:modelValue', 'update:businessUnit']);

let invitation = reactive(props.modelValue);

watch(()=>({...invitation}), ()=>
{
    emit('update:modelValue', invitation);
});

const businessUnit = computed(
    {
        get: () => props.businessUnit,
        set: (unit: IBusinessUnit) => emit('update:businessUnit', new BusinessUnit(unit)) // strip display prop with constructor
    }
);

watch(() => selectedRoles.value, (roles) =>
{
    invitation.roles = roles;
})

const availableRoles = computed(()=>
{
    let results: UserRole[] = [];

    if (!invitation.organizationId)
    {
        return results;
    }

    if(canInviteOrganizationAdmin(invitation.organizationId))
    {
        results.push(UserRole.ORGANIZATION_ADMIN);
    }
    if(canInviteBillingAdmin(invitation.organizationId))
    {
        results.push(UserRole.BILLING_ADMIN);
    }
    if(canInviteCourseAdmin(invitation.organizationId))
    {
        results.push(UserRole.COURSE_ADMIN);
    }
    if(canInviteOrganizationInstructor(invitation.organizationId))
    {
        results.push(UserRole.ORGANIZATION_INSTRUCTOR);
    }
    if(canInviteCoursewareAdmin(invitation.organizationId))
    {
        results.push(UserRole.COURSEWAREADMIN);
    }
    return results;
});

const validRoleSelection = computed(() =>
{
    return selectedRoles.value.length > 0;
})
</script>