export default {
    en: {
        HEADER_TITLE: {
            VACR: 'Virginia Cyber Range',
            USCR: 'U.S. Cyber Range'
        },
        HEADER_NAVIGATION_BAR_LABEL: 'Header Navigation',
        HEADER_SEARCH_LABEL: 'Search',
        HEADER_ACCOUNT_DROPDOWN: 'Account Menu',
        HEADER_ACCOUNT_DROPDOWN_PROFILE: 'Profile',
        HEADER_ACCOUNT_DROPDOWN_CUSTOMER_SUPPORT: 'Customer Support',
        HEADER_ACCOUNT_DROPDOWN_KNOWLEDGE_BASE: 'Knowledge Base',
        HEADER_ACCOUNT_DROPDOWN_LOGOUT: 'Logout',
    }
};