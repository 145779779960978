<template>
    <list-layout :ready="isReady" :title="t('ORGANIZATIONS_ORGANIZATIONS')" :breadcrumbs="breadcrumbs">
        <template #filter>
            <list-filter-section>
                <list-filter-section-item>
                    <organization-filter v-model="selectedParentOrganizationId" :organizations="parentOrganizations" :label="t('ORGANIZATION_USERS_PARENT_FILTER_LABEL')" data-testing="organizations-organization-filter"/>
                </list-filter-section-item>
            </list-filter-section>
        </template>
        <template #list>
            <list-section :no-items-message="t('ORGANIZATIONS_ORGANIZATIONS_NO_ITEMS')">
                <list-section-item v-for="organization in page.items">
                    <organization-tile :organization="organization" />
                </list-section-item>
            </list-section> 
        </template>
        
        <template #pagination>
            <cr-pagination :previous="!!page.prevPageToken"  :first="true" :next="!!page.nextPageToken" @previous="onPreviousPageClicked" @first="onFirstPageClicked" @next="onNextPageClicked" data-testing="organizations-pagination"/>
        </template>

        <template #controls>
            <list-control-item v-if="canCreateOrganization()">
                <cr-button :to="Route.CreateOrganization" outlined data-testing="create-organization-button">
                    <cr-icon>bi-plus</cr-icon>
                    {{ t('ORGANIZATIONS_ORGANIZATION_CREATE_ORGANIZATION_BUTTON') }}
                </cr-button>
            </list-control-item>
        </template>
        
    </list-layout>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import ListLayout from '../layouts/ListLayout.vue';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import Route from '../../routers/route';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { onMounted, ref, computed, watch, toRaw, onBeforeUnmount } from 'vue';
import { ApiPageResponse, IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import Config from '../../config';
import { useQueryFilter } from '../../composables/useQueryFilter';
import ListSectionItem from '../layouts/sections/ListSectionItem.vue';
import ListSection from '../layouts/sections/ListSection.vue';
import { useOrganizationStore } from '../../stores/organizationStore';
import { IOrganization, OrganizationFilter as ApiOrganizationFilter, IOrganizationFilter, OrganizationSortBy, OrganizationStatus } from '@cyber-range/cyber-range-api-organization-client';
import OrganizationTile from './OrganizationTile.vue';
import ListFilterSection from '../layouts/sections/ListFilterSection.vue';
import ListFilterSectionItem from '../layouts/sections/ListFilterSectionItem.vue';
import OrganizationFilter from '../filters/OrganizationFilter.vue'
import { useTimerStore } from '../../stores/timerStore';
import ListControlItem from '../layouts/sections/ListControlItem.vue';

const { t } = useI18n();
let { canCreateOrganization } = useAuthorizationStore();
let { fetchParentOrganizations, fetchOrganizationNameAndLogo } = useOrganizationStore();
const timerStore = useTimerStore();


const isReady = ref(false);

let page = ref<IApiPageResponse<IOrganization>>(new ApiPageResponse<IOrganization>({items:[]}));
let filter = useQueryFilter(ApiOrganizationFilter, {limit:Config.DEFAULT_TILE_LISTING_PAGE_SIZE,sortBy:OrganizationSortBy.Name,manageable:true});

let parentOrganizations = ref<IOrganization[]>([]);
const selectedParentOrganizationId = ref<string>(filter.parentId || '');

watch(selectedParentOrganizationId, async () =>
{
    await refresh({token: ''});
});

const breadcrumbs = computed(()=>[ new BreadcrumbItem(Route.Organizations) ]);

const reload = async (data: Partial<IOrganizationFilter>) =>
{
    Object.assign(filter, {parentId: selectedParentOrganizationId.value, ...data});
}

const continuePolling = computed(()=>
{
    const statusesToPoll = [OrganizationStatus.DELETING];
    return page.value?.items.some(organization => statusesToPoll.includes(organization.status));
})

let timerId: string;
const refresh = async (data: Partial<IOrganizationFilter> = {}, background: boolean = false) =>
{
    if (timerId) {
        timerStore.unschedule(timerId);
    }
    
    const listOrganizationFilter = {...toRaw(filter), ...{parentId: selectedParentOrganizationId.value}, ...data};
    page.value = await useOrganizationStore().listOrganizations(new ApiOrganizationFilter(listOrganizationFilter), {background});
    if (continuePolling.value) {
        timerId = timerStore.schedule(refresh, [data, true], 5000, false);
    }
}

const onPreviousPageClicked = async () =>
{
    await reload({token: page.value?.prevPageToken || ''});
}

const onFirstPageClicked = async () =>
{
    await reload({token: ''});
}

const onNextPageClicked = async () =>
{
    await reload({token: page.value?.nextPageToken || ''});
}

onMounted(async ()=>
{
    try
    {
        await Promise.all([fetchParentOrganizations(), refresh(), fetchOrganizationNameAndLogo() ]);
        parentOrganizations.value = useOrganizationStore().parentOrganizations;
    }
    finally
    {
        isReady.value = true;
    }
});

onBeforeUnmount(() =>
{
    timerStore.unschedule(timerId);
});
</script>