import { defineStore } from 'pinia';
import { IApiClientError } from '@cyber-range/cyber-range-api-client';
import { useAnalyticStore } from './analyticStore';

export const useErrorStore = defineStore('errorStore', 
{
    state: () => {
        return {
            errorCode: 0,
            errorMessage: '',
            errorDetails: ''
        }
    },

    actions: 
    {
        setError(e?:IApiClientError)
        {
            if(e)
            {
                let errorMessage = e.message;

                // Suppress Bad Gateway error until Sysops fix the root cause.
                if(e.statusCode === 502) return;

                if(e.statusCode === 404 && errorMessage === 'Invalid Organization Code.'){
                    return;
                }

                if(e.statusCode === 403 || e.statusCode === 422 || (e.statusCode === 400 && e.message === 'jwt expired'))
                {
                    if(e.message === 'Forbidden' || e.message === 'jwt expired') errorMessage = 'Your session has expired.'
                }

                //When a user does not have an account, multiple errors will be generated.
                //Ignore all errors if the current error indicates the user does not have an account.
                if(errorMessage && errorMessage.indexOf('Your account could not be found') === 0)
                {
                    return;
                }

                this.errorCode = e.statusCode;
                this.errorMessage = errorMessage;
                this.errorDetails = e.details;

                useAnalyticStore().trackError(this.errorMessage, this.errorCode);
            }
            else
            {
                this.reset();
            }
        },

        reset()
        {
            this.errorCode = 0;
            this.errorMessage = '';
            this.errorDetails = '';
        }
    }
})