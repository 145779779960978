<template>
    <cr-card :to="businessUnitRoute" data-testing="business-unit-tile">
        <cr-card-body>
            <cr-card-title tag="h2" data-testing="business-unit-name" class="cr-mb3 cr-font-bold">{{ businessUnit.name }}</cr-card-title>
            <cr-card-text >
                <tile-detail-section>
                    <tile-detail-section-item icon="bi-geo-alt" :label="t('BUSINESS_UNIT_REGION')" data-testing="business-unit-region">
                        {{ businessUnit.region }}
                    </tile-detail-section-item>
                    <tile-detail-section-item icon="bi-stack" :label="t('BUSINESS_UNIT_CATEGORY')" data-testing="business-unit-category">
                        {{ businessUnit.category }}
                    </tile-detail-section-item>
                </tile-detail-section>
            </cr-card-text>
        </cr-card-body>
    </cr-card>
</template>

<style scoped>
.image
{
    background-color: var(--cr-tile-image-background-color);
    height: var(--cr-tile-image-height);
}
.cr-card-title
{
    font-size: var(--cr-tile-title-font-size);
}
</style>

<script setup lang="ts">
import Route from '../../routers/route';
import { IBusinessUnit } from '@cyber-range/cyber-range-api-business-unit-client';
import { computed } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import TileDetailSection from '../layouts/sections/TileDetailSection.vue';
import TileDetailSectionItem from '../layouts/sections/TileDetailSectionItem.vue';

const { t } = useI18n();

const props = defineProps<
{
    businessUnit:IBusinessUnit

}>();

const businessUnitRoute = computed(()=>
{
    return {...Route.BusinessUnit, params: {organizationId:props.businessUnit.organizationId, businessUnitId: props.businessUnit.id}}
});


</script>