import { defineStore } from 'pinia';
import { IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import { useApiClientStore } from './apiClientStore';
import { IRosterFilter } from '@cyber-range/cyber-range-api-roster-client/dist/interfaces/iRosterFilter';
import { IRosterEntry } from '@cyber-range/cyber-range-api-roster-client/dist/interfaces/iRosterEntry';

export const useRosterStore = defineStore('rosterStore', 
{
    state: () => 
    ({
        
    }),

    actions: 
    {
        async listRosterEntries(courseId:string, filter?:IRosterFilter, options?: {background?:boolean}): Promise<IApiPageResponse<IRosterEntry>>
        {
            let rosterApiClient = options?.background ? useApiClientStore().backgroundRosterApiClient : useApiClientStore().rosterApiClient;

            let page = await rosterApiClient.get(courseId, filter);

            return page;
        },

        async clearCache(): Promise<void>
        {
            let rosterApiClients = [useApiClientStore().backgroundRosterApiClient, useApiClientStore().rosterApiClient];

            rosterApiClients.forEach(rosterApiClient => rosterApiClient.clearCache());
        },
    }
})