<template>
    <div class="guac-container">
        <div v-if="disabled" class="overlay" data-testing="guacamole-disabled-overlay"/>
        <iframe v-if="canConnect" :src="iframeSource.href" tabindex="1" @mouseenter="onMouseEnterGuacIframe" @mouseleave="onMouseLeaveGuacIframe" ref="guacContainer" class="guac-client" :class="{ disabled }"/>
    </div>
</template>

<style scoped>
.guac-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
}

.guac-client {
    width: 100%;
    height: 100%;
    border: none;
}

.guac-client.disabled {
    pointer-events: none;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 70%;
}
</style>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import Config from '../../config';

const props = defineProps<{
    guacamoleAccessPointId: string,
    disabled?: boolean,
}>();

const guacContainer = ref<HTMLIFrameElement>();
const onMouseEnterGuacIframe = () => guacContainer.value?.focus();
const onMouseLeaveGuacIframe = () => guacContainer.value?.blur();

const iframeSource = computed(() => new URL('/range', window.location.origin));
const canConnect = ref(false);
watch(() => props.guacamoleAccessPointId, () => 
{
    canConnect.value = false;

    localStorage.removeItem('GUAC_AUTH');
    localStorage.removeItem('GUAC_HISTORY');
    localStorage.removeItem('GUAC_PREFERENCES');

    document.cookie = `apid=${props.guacamoleAccessPointId}; Max-Age=30; Domain=${iframeSource.value.host}; SameSite=Strict; Secure; Path=${iframeSource.value.pathname}`;

    canConnect.value = true;
},
{ immediate: true, flush: 'post'});
</script>
