<template>
    <ul class="controls">
        <slot/>
    </ul>
</template>

<style scoped>
.controls:deep(li),
.controls:deep(li > .cr-tooltip),
.controls:deep(li > .cr-tooltip .cr-button),
.controls:deep(li > .cr-tooltip > a > .cr-button),
.controls:deep(li > .hint-wrapper > a > .cr-button),
.controls:deep(li > .hint-wrapper > .cr-button),
.controls:deep(li .cr-dropdown > .cr-button),
.controls:deep(li >.cr-button),
.controls:deep(li > a > .cr-button),
.controls:deep(.cr-card)
{
    width: 100%;
}
ul.controls {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
</style>