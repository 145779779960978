<template>
        <table-layout :title="t('FEATURES_PAGE_TITLE')" :breadcrumbs="breadcrumbs">

            <template #table> 
                <cr-table :items="features" :headers="headers" actionable class="cr-pb6" :loading="isLoading">
                    
                    <template v-slot:action="{item}">
                        <cr-table-action-item v-if="canViewProduct()" :item="item" :to="{name: Route.Feature.name, params: { addonId: item.addonId }}" icon="bi-file-earmark" data-testing="features-view-feature-action-item">
                            {{ t('FEATURE_TABLE_VIEW_PLAN_ACTION_ITEM') }}
                        </cr-table-action-item>
                    </template>

                    <template v-slot:unitPrice="{item}">
                        <template v-if="item.unitPrice">
                            {{ useNumberFormats().toCurrency(item.unitPrice) }}
                        </template>
                    </template>

                    <template v-slot:notes="{item}">
                        {{ getNotes(item) }}
                    </template>
                </cr-table>
            </template>

        </table-layout>
</template>


<script setup lang="ts">
import { BreadcrumbItem, TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import { useApiClientStore } from '../../stores/apiClientStore';
import TableLayout from '../layouts/TableLayout.vue';
import { computed, onMounted, ref } from 'vue';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import { useI18n } from 'vue-i18n';
import Route from '../../routers/route';
import { usePlanStore } from '../../stores/planStore';
import { storeToRefs } from 'pinia';
import { useNumberFormats } from '../../composables/useNumberFormats';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { IFeature } from '../../interfaces/iFeature';
import { IProductLimit, ProductLimit } from '@cyber-range/cyber-range-api-subscription-client';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { canViewProduct } = useAuthorizationStore();

const breadcrumbs = computed(()=>useCourseBreadcrumbs([
                            new BreadcrumbItem(Route.Plans),
                            new BreadcrumbItem(Route.Features),
                         ]));
                         
const headers = [
    new TableHeaderItem({ text: t('FEATURES_TABLE_NAME'), key: 'name'}),
    new TableHeaderItem({ text: t('FEATURES_TABLE_UNIT_PRICE'), key: 'unitPrice', align: 'center' }),
    new TableHeaderItem({ text: t('FEATURES_TABLE_NOTES'), key: 'notes', align: 'center' }),
];

const ready = ref<boolean>(false);

const features = ref<IFeature[]>([]);

const getNotes = (feature: IFeature) =>
{
    if(feature.limits)
    {
        let nonZeroLimits = Object.keys(new ProductLimit(feature.limits))
                                  .map(key => key as keyof IProductLimit)
                                  .filter(limit => feature.limits?.[limit] !== 0);

        if(nonZeroLimits.length === 0)
        {
            return '';
        }
        else if(nonZeroLimits.length === 1)
        {
            return `${nonZeroLimits[0]}: ${feature.limits[nonZeroLimits[0]]}`
        }
        else
        {
            return t('FEATURE_NOTES', {number: nonZeroLimits.length});
        }
    }

    return '';
}

onMounted(async ()=>
{
    let planStore = usePlanStore();

    await planStore.fetchPlansAndFeatures();
    features.value = planStore.allFeatures().sort((a,b)=>(a.name || '') < (b.name || '') ? -1 : 1);
    
    ready.value = true;  
})
</script>