export default {
    en: {
        AUDIT_LOGS_PAGE_TITLE: 'Logs',
        AUDIT_LOGS_STATUS_OK: 'OK',
        AUDIT_LOGS_STATUS_FAILED: 'Failed',
        AUDIT_LOGS_PAGINATION_PREVIOUS: 'Newer',
        AUDIT_LOGS_PAGINATION_NEXT: 'Older',
        AUDIT_PAGE_ON_BEHALF_OF_USER: 'obo ',
        AUDIT_LOGS_TIMESTAMP: 'Timestamp',
        AUDIT_LOGS_ORGANIZATION: 'Organization',
        AUDIT_LOGS_USER: 'User',
        AUDIT_LOGS_ACTION: 'Action',
        AUDIT_LOGS_RESULT: 'Result',
        AUDIT_LOGS_EVENT_ID: 'Event Id',
        AUDIT_LOGS_DETAILS: 'Details',
        AUDIT_LOGS_FILTER_DATE_LABEL: 'Date',
        AUDIT_LOGS_FILTER_STATUS_LABEL: 'Status',
        AUDIT_LOGS_FILTER_USER_LABEL: 'User ID',
        AUDIT_LOGS_FILTER_OBJECT_ID_LABEL: 'Object ID',
    }
};