<template>
    <cr-confirmation-dialog id="create-activity-dialog" :title="t('CREATE_ACTIVITY_DIALOG_TITLE')" @shown="onShown" @confirm="onConfirmed" :form-instruction="true" data-testing="create-activity-dialog">
        <form-section>
            <cr-text-field v-model="amount" :label="t('CREATE_ACTIVITY_DIALOG_AMOUNT')" :pattern="patterns.anyNonZeroUpToTwoDecimals" prefix="$" required data-testing="create-activity-amount"/>
            <cr-date-picker v-model="postedDate" :label="t('CREATE_ACTIVITY_DIALOG_POSTED_DATE')" required data-testing="create-activity-posted-date"/>
            <cr-textarea v-model="notes" :label="t('CREATE_ACTIVITY_DIALOG_DESCRIPTION')" required :max-length="400" data-testing="create-activity-description"/>
        </form-section>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import FormSection from '../../layouts/sections/FormSection.vue';
import { useI18n } from 'vue-i18n';
import { useBalanceStore } from '../../../stores/balanceStore';
import { useCalendar } from '../../../composables/useCalendar';
import { ActivityType } from '@cyber-range/cyber-range-api-balance-client/dist/interfaces/activityType';
import { useNumberFormats } from '../../../composables/useNumberFormats';

const props = defineProps<{
    organizationId: string
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();


const { t } = useI18n();
const {patterns} = useNumberFormats();

const amount = ref<number>();
const postedDate = ref<string>();
const notes = ref<string>();

const setValues = () =>
{
    amount.value = undefined;
    postedDate.value = "";
    notes.value = "";
}

const onShown = () =>
{
    setValues();
}

const onConfirmed = async () =>
{
    if (!amount.value || !postedDate.value || !notes.value)
    {
        return;
    }

    await useBalanceStore().createOrganizationBalanceActivity(props.organizationId, {
        amount: Number(amount.value),
        postedDate: useCalendar().toYYYYMMDD(new Date(postedDate.value)),
        notes: notes.value,
        type: ActivityType.User
    });

    emit('confirm');
}
</script>