import { defineStore } from 'pinia';
import { useApiClientStore } from './apiClientStore';
import { ICredential, ICredentialApiClient } from '@cyber-range/cyber-range-api-credential-client';

export const useCredentialStore = defineStore('credentialStore', 
{
    state: () => 
    ({
       
    }),

    actions: 
    {
        async resetPassword(credentialId:string, options?: {background?:boolean}): Promise<string>
        {
            const credentialApiClient = options?.background ? useApiClientStore().backgroundCredentialApiClient : useApiClientStore().credentialApiClient;

            return await credentialApiClient.resetPassword(credentialId);
        },

        async delete(credentialId:string, options?: {background?:boolean, bulk?:boolean}): Promise<void>
        {
            let credentialApiClient: ICredentialApiClient = options?.background ? useApiClientStore().backgroundCredentialApiClient : useApiClientStore().credentialApiClient;

            credentialApiClient = options?.bulk ? useApiClientStore().bulkCredentialApiClient : credentialApiClient;

            await credentialApiClient.delete(credentialId);
        },

        async create(payload: Pick<ICredential, 'role' | 'courseId'>, options?: {background?:boolean}): Promise<ICredential>
        {
            const credentialApiClient = options?.background ? useApiClientStore().backgroundCredentialApiClient : useApiClientStore().credentialApiClient;

            return await credentialApiClient.create(payload);
        },
    }
})