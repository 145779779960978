<template>
    <cr-confirmation-dialog :title="t('EXERCISE_GROUP_COPYING_TITLE')" :cancel="false" data-testing="environment-copying-dialog" >
        <p> {{ t('EXERCISE_GROUP_COPYING_MESSAGE') }} </p>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

</script>
