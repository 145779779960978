<template>
    <cr-col xs12 sm12 md12 lg12 xl6 xxl4 class="read-list-section-item" data-testing="read-list-section-item">
        <slot />
    </cr-col>
</template>

<style scoped>
.cr-col:deep(> .cr-card-link),
.cr-col:deep(> .cr-card-link > .cr-card),
.cr-col:deep(> .cr-card )
{
    /* Any card in side this component should has height set to 100% so all tiles have the same height. */
    height: 100% !important;
}
</style>