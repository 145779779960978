<template>
    <cr-delete-confirmation-dialog @confirm="onConfirmed" @cancel="onCanceled" :disabled="isLoading" data-testing="delete-course-membership-dialog">
        <cr-html :value="t('PROFILE_DELETE_COURSE_MEMBERSHIP_DIALOG_MESSAGE', {userName:user?.name, courseName: membership?.name })" />
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import IProfileMembership from '../../../interfaces/iProfileMembership';
import { IUser } from '@cyber-range/cyber-range-api-user-client';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '../../../stores/userStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<{
    user?: IUser,
    membership?: IProfileMembership
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
    (name: 'cancel'): void
}>();

const onConfirmed = async ()=>
{
    await useUserStore().deleteCourseUser(props.membership?.id as string, props.user?.id as string);
    emit('confirm');
}

function onCanceled()
{
    emit('cancel');
}
</script>