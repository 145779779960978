<template>
    <component :is="tag || 'div'" v-for="role in roles" v-bind="props.childProps" :data-testing="`${dataTesting}-${role}`">
        <cr-checkbox v-model="modelValues[role]" :name="role" :label="toDisplayEnumName(UserRole, role)" class="cr-role-picker-item" :data-testing="`role-picker-${role}`"/>
    </component>
</template>

<style scoped>
.cr-role-picker-item:not(:last-child)
{
    margin-bottom: 0.5rem;
}
</style>

<script setup lang="ts">
import { UserRole } from '@cyber-range/cyber-range-api-user-client';
import { useEnum } from '../../composables/useEnum';
import { watch, ref } from 'vue';

const props = defineProps<
{
    roles: UserRole|string[];
    modelValue?: UserRole|string[];
    dataTesting?: string;

    /**
     * HTML tag to use.
     * @default div
     */
    tag?: string;

    /**
     * Props to pass to the child component.
     */
     childProps?: object;
}>();

const emit = defineEmits(['update:modelValue']);

const { toDisplayEnumName } = useEnum();

const dataTesting = props.dataTesting || 'role-picker';

// create modelValues for the checkboxes
const toModelValues = (values: UserRole|string[]|undefined) =>
{
    const result: Record<string,boolean> = {};

    if (values === undefined)
    {
        return result;
    }

    for(let role of props.roles)
    {
        result[role] = values.includes(role) || false;
    }

    return result;
}

const modelValues = ref(toModelValues(props.modelValue));


watch(modelValues.value, (values)=>
{
    const selectedValues = Object.keys(values).filter(key => values[key]);
    emit('update:modelValue', selectedValues);
})

watch(() => props.modelValue, (value) =>
{    
    const newModelValues = toModelValues(value);
    Object.keys(newModelValues).forEach(key => modelValues.value[key] = newModelValues[key]);
})

</script>

