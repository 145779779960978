<template>
    <read-section :label="t('PLAN_FEATURES')" data-testing="features-section">
        <cr-row tag="dl">
            <cr-col tag='dt' xs12 sm12 md12  lg6 xl6 xxl4 label="" data-testing="features-section-rate-key">
                {{ t('PLAN_FEATURES_RATE') }}
            </cr-col>
            <cr-col tag='dd' xs12 sm12 md12 lg6 xl6 xxl8 class="cr-pb4" data-testing="features-section-rate-value">
                <template v-if="plan.isFlexRate">
                    {{ t('PLAN_FEATURES_FLEX_RATE') }}
                    <br/>
                </template>
                <template v-else>
                    {{ t('PLAN_FEATURES_FLAT_RATE') }}
                    <br/>
                </template>
                <template v-if="plan.isFederalRate">
                    {{ t('PLAN_FEATURES_FEDERAL_RATE') }}
                    <br/>
                </template>
            </cr-col>
            <template v-for="[category, features] of featuresByCategory.entries()">
                <cr-col tag='dt' xs12 sm12 md12  lg6 xl6 xxl4 :data-testing="`features-section-${category}-key`">
                    {{ toDisplayEnumName(FeatureCategory, category) }}
                </cr-col>
                <cr-col tag='dd' xs12 sm12 md12 lg6 xl6 xxl8 class="cr-pb4" :data-testing="`features-section-${category}-value`">
                    <template v-for="feature of features">
                        {{ feature.name }}
                        <br/>
                    </template>
                </cr-col>
            </template>
            <template v-if="plan.optionalFeatures?.length > 0">
                <cr-col tag='dt' xs12 sm12 md12  lg6 xl6 xxl4 label="" data-testing="features-section-optional-key">
                    {{ t('PLAN_FEATURES_OPTIONAL') }}
                </cr-col>
                <cr-col tag='dd' xs12 sm12 md12 lg6 xl6 xxl8 class="cr-pb4" data-testing="features-section-optional-value">
                    <template v-for="feature of plan.optionalFeatures">
                        {{ feature.name }}
                        <br/>
                    </template>
                </cr-col>
            </template>
        </cr-row>
    </read-section>
</template>

<style scoped>
dt
{
    font-weight: unset;
    
}
dd
{
    margin-bottom: unset;
}
</style>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useEnum } from '../../../composables/useEnum';
import { Plan } from '../../../entities/Plan';
import { FeatureCategory } from '../../../interfaces/featureCategory';
import { IPlan } from '../../../interfaces/iPlan';
import { IFeature } from '../../../interfaces/iFeature';
import ReadSection from '../../layouts/sections/ReadSection.vue';

const props = defineProps<
{
    plan:IPlan;
}>();

const { t } = useI18n();
const { toDisplayEnumName } = useEnum();

const plan = ref<IPlan>(new Plan());
const featuresByCategory = ref(new Map<FeatureCategory, IFeature[]>());
    

watch(props.plan, ()=>
{    
    if(props.plan)
    {
        // Populate featuresByCategory map
        let table = new Map<FeatureCategory, IFeature[]>();

        for(let feature of props.plan.features)
        {
            for(let category of feature.categories || [])
            {
                if(!table.has(category))
                {
                    table.set(category, [])
                }
                table.get(category)?.push(feature);
            }
        }

        // Select only certain categories in certain order.
        for(let category of [FeatureCategory.Catalog, FeatureCategory.Courseware, FeatureCategory.ExerciseArea, FeatureCategory.Ctf, FeatureCategory.Others])
        {
            if(table.has(category))
            {
                featuresByCategory.value.set(category, table.get(category) || []);
            }
        }

        plan.value = props.plan;
    }
}, 
{immediate: true})
</script>
