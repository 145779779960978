<template>
    <cr-confirmation-dialog :title="t('PROFILE_EDIT_COURSE_MEMBERSHIP_ROLE_DIALOG_TITLE')" @confirm="onConfirmed" :disabled="isLoading" form-instruction data-testing="edit-course-membership-role-dialog">
        <form-section :label="t('PROFILE_COURSE_MEMBERSHIP_ROLE_FORM_ROLE_SECTION')" data-testing="course-membership-role-form-role-section" class="cr-mb5">
            <cr-select v-model="formRole" :items="availableRoles" :label="t('PROFILE_COURSE_MEMBERSHIP_ROLE_FORM_ROLE_SELECT_LABEL')"  :disabled="isLoading" required id="course-membership-role-form-role" data-testing="course-membership-role-form-role-select" />
        </form-section>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import FormSection from '../../layouts/sections/FormSection.vue';
import IProfileMembership from '../../../interfaces/iProfileMembership';
import { IUser, UserRole } from '@cyber-range/cyber-range-api-user-client';
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useAuthorizationStore } from '../../../stores/authorizationStore';
import { useEnum } from '../../../composables/useEnum';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '../../../stores/userStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { canInvite } = useAuthorizationStore();

const formRole = ref<UserRole|undefined>()

const props = defineProps<{
    user?: IUser,
    membership?: IProfileMembership
}>()

const emit = defineEmits<{
    (name: 'confirm'): void
    (name: 'cancel'): void
}>();

const populateRole = ()=>
{
    let propMemberships = props.membership?.roles || new Set();
    formRole.value = [...propMemberships][0] as UserRole;
};

watch(()=>props.membership, populateRole, {immediate:true});

const availableRoles = computed(()=>
{
    let results = [];
    
    if(canInvite(UserRole.Student, props.membership?.id as string, props.membership?.organizationId ))
    {
        results.push(UserRole.Student);
    }
    if(canInvite(UserRole.TA, props.membership?.id as string, props.membership?.organizationId ))
    {
        results.push(UserRole.TA);
    } 
    if(canInvite(UserRole.Instructor, props.membership?.id as string, props.membership?.organizationId ) && !props.user?.isAnonymous)
    {
        results.push(UserRole.Instructor);
    }    
    return useEnum().toSelectItems(UserRole, results);
});

const onConfirmed = async ()=>
{
    let propRole =  [...(props.membership?.roles || new Set())][0];
    if(formRole.value !== propRole)
    {
        await useUserStore().updateCourseUserRole(props.membership?.id as string, props.user?.id as string, formRole.value as UserRole);
    }
    emit('confirm');
}
</script>