<template>
    <cr-confirmation-dialog v-model="showDialog" :title="t('VIEW_PAYMENT_TITLE')" :confirm="false" :cancel-text="t('VIEW_PAYMENT_CANCEL_TEXT')" data-testing="view-payment-dialog">
        <div ref="paymentDetails">
            <cr-container>
                <cr-row v-for="detail of details" tag="dl" data-testing="view-payment-dialog-detail-row">
                    <cr-col tag="dt" xs12 sm12 md12 lg3 xl3>{{ detail.key }}</cr-col>
                    <cr-col tag="dd" xs12 sm12 md12 lg9 xl9 class="cr-mb0">{{ detail.value }}</cr-col>
                </cr-row>
            </cr-container>
        </div>
        <template #footerRight>
            <cr-button @click.prevent="onPrintButtonClicked" data-testing="view-payment-dialog-print-button">
                {{ t('VIEW_PAYMENT_PRINT_BUTTON_TEXT') }}
            </cr-button>
        </template>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { computed, ref, watch } from 'vue';
import { IPayment } from '@cyber-range/cyber-range-api-payment-client';
import { useNumberFormats } from '../../../composables/useNumberFormats';
import { usePrinter } from '../../../composables/usePrinter';
import { usePaymentStore } from '../../../stores/paymentStore';
import { useErrorStore } from '../../../stores/errorStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const { toCurrency } = useNumberFormats();
const { printElement } = usePrinter();

const props = defineProps<
{
    modelValue: boolean
    paymentId?: string
}>();

const emit = defineEmits<{
    (name: 'update:modelValue', value: boolean): void
}>();

const showDialog = computed({
    get: () => props.modelValue,
    set:(value: boolean) => emit('update:modelValue', value)
})

const payment = ref<IPayment>();

const details = computed(() =>
{
    return [
        {key: t('VIEW_PAYMENT_DATE'), value: payment.value?.transaction.date},
        {key: t('VIEW_PAYMENT_PROVIDER'), value: payment.value?.provider},
        {key: t('VIEW_PAYMENT_PAYER'), value: payment.value?.transaction.payerName},
        {key: t('VIEW_PAYMENT_PAYMENT_METHOD'), value: payment.value?.transaction.accountType},
        {key: t('VIEW_PAYMENT_AMOUNT'), value: toCurrency(payment.value?.transaction.amount || 0)},
        {key: t('VIEW_PAYMENT_STATUS'), value: payment.value?.transaction.status},
        {key: t('VIEW_PAYMENT_MESSAGE'), value: payment.value?.transaction.message},
    ]
});

watch(() => showDialog.value, async () =>
{
    if (showDialog.value && props.paymentId)
    {
        payment.value = await usePaymentStore().getPayment(props.paymentId); 
    }
});

const paymentDetails = ref<HTMLElement>();
const onPrintButtonClicked = () =>
{
    if (paymentDetails.value)
    {
        printElement(paymentDetails.value)
    }
}
</script>