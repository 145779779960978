import Config from "../config";
import { IBreadcrumbItem } from '@cyber-range/cyber-range-lib-ui'
import { Ref, watch } from "vue";

export function usePageTitle(breadcrumbs: Ref<IBreadcrumbItem[]>)
{
    watch(breadcrumbs, (newValue) =>
    {
        if(newValue && newValue.length >  0)
        {
            let title = [Config.APP_TITLE, ... newValue.map(b => b.text ? b.text : b.name)].reverse().join(' - ');

            document.title = title;
        }
        else
        {
            document.title = Config.APP_TITLE;
        }
    }, {immediate:true});
}