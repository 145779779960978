import { reactive, toRaw, UnwrapNestedRefs, watch } from "vue";
import { useRouter } from "vue-router";

type Properties<T> = {[K in keyof T]-?: T[K] extends (...args: any[]) => any ? never : K}[keyof T];

export function useQueryFilter<T extends Record<string,any>, M extends Properties<T> >(type:{new(...args: any[]):T}, query?:Record<string, any>, options?:{arrayProperties:M[]}) : UnwrapNestedRefs<T>
{
    const router = useRouter();
    
    let data = new type(query ? {...query, ...router.currentRoute.value.query} : router.currentRoute.value.query);

    if(options?.arrayProperties)
    {
        for(let arrayProperty of options?.arrayProperties)
        {
            if(!Array.isArray(data[arrayProperty]))
            {
                if(data[arrayProperty])
                {
                    (<any>data[arrayProperty]) = [data[arrayProperty]];
                }
                else
                {
                    (<any>data[arrayProperty]) = []
                }
            }
        }
    }

    const filter = reactive(data)

    watch(filter, ()=>
    {
        router.push({query: toRaw(filter)});
    });

    return filter;
}