<template>
    <cr-delete-confirmation-dialog id="deleteOrganizationInvitationDialog" @confirm="onConfirmed" :disabled="isLoading" data-testing="delete-organization-invitation-dialog">
        <cr-html :value="t('ORGANIZATION_USERS_DELETE_INVITATION_DIALOG_MESSAGE', {invitationName: invitation?.name})" />
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import IOrganizationUserRoleView from '../../../interfaces/iOrganizationUserRoleView';
import { useInvitationStore } from '../../../stores/invitationStore';
import { IOrganizationUser } from '@cyber-range/cyber-range-api-user-client';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    invitation?: IOrganizationUserRoleView|IOrganizationUser;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    if(props.invitation)
    {
        await useInvitationStore().delete(props.invitation.id);
        emit('confirm');
    }
}
</script>