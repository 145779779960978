export default {
    en: {
        //Account Approvals Page
        ACCOUNT_APPROVALS_PAGE_TITLE: "Account Approvals",
        ACCOUNT_APPROVALS_APPROVE: "Approve",
        ACCOUNT_APPROVALS_REJECT: "Reject",
        ACCOUNT_APPROVALS_DIALOG_CONFIRM_TEXT: 'OK',
        ACCOUNT_APPROVALS_APPROVED_ACTION_TITLE: "Approved",
        ACCOUNT_APPROVALS_APPROVED_ACTION_MESSAGE: "The user has been approved, and an invitation has been sent.",
        ACCOUNT_APPROVALS_REJECTED_ACTION_TITLE: "Rejected",
        ACCOUNT_APPROVALS_REJECTED_ACTION_MESSAGE: "The user has been rejected, and an email has been sent to inform them."
    }
};
