<template>
    <cr-confirmation-dialog v-model="show" :cancel="false" :title="t('PAGE_EXPIRING_SESSION_WARNING_DIALOG_TITLE')"  :confirm-text="t('PAGE_EXPIRING_SESSION_WARNING_DIALOG_CONFIRM')">
        {{ t('PAGE_EXPIRING_SESSION_WARNING_DIALOG_MESSAGE') }}
    </cr-confirmation-dialog>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSessionStore } from '../stores/sessionStore';

const { t } = useI18n();
const { isSesionExpiring } = storeToRefs(useSessionStore());
const hasNotBeenOpenedBefore = ref(true);
const show = ref(false);

watch(isSesionExpiring, () =>
{
    if(isSesionExpiring.value && hasNotBeenOpenedBefore.value)
    {
        show.value = true;
        hasNotBeenOpenedBefore.value = false;
    }
}, { immediate: true });
</script>
