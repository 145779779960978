<template>
    <cr-filter icon="bi-calendar3" :label="label || t('BETWEEN_DATE_FILTER_DATE_PICKER_LABEL')" :selected-values="selectedValues" :disabled="disabled || isLoading" :data-testing="dataTesting">
        <cr-date-picker v-model="fromDate" :max="fromMax" :label="t('BETWEEN_DATE_FILTER_FROM')" :disabled="isLoading" :data-testing="`${dateDataTesting}-from`"/>
        <cr-date-picker v-model="toDate" :min="toMin" :label="t('BETWEEN_DATE_FILTER_TO')" :disabled="isLoading" :data-testing="`${dateDataTesting}-to`"/>
        <div class="date-filter-buttons">
            <cr-button :label="t('BETWEEN_DATE_FILTER_CLEAR_BUTTON')" @click="onCleared" text :disabled="isLoading" class="cr-mr2" :data-testing="clearButtonDataTesting"/>
            <cr-button :label="t('BETWEEN_DATE_FILTER_CONFIRM_BUTTON')" @click="onConfirmed" :disabled="isLoading" :data-testing="confirmButtonDataTesting" />
        </div>
    </cr-filter>
</template>

<style scoped>
.cr-filter:deep(.cr-dropdown-toggle),
.cr-filter:deep(.cr-dropdown-list)
{
    min-width: 12rem;
}
.date-filter-buttons
{
    text-align: right;
}
</style>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useApiClientStore } from '../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

const props = defineProps<
{
    /**
     * The selected from date
     */
    fromDate: string | undefined;

    /**
     * The selected to date
     */
    toDate: string | undefined;

    /**
     * Label on the filter
     * @default t('DATE_FILTER_LABEL')
     */
    label?: string

    /**
     * Whether the filter is disabled.
     * @default false
     */
    disabled?: boolean

    /**
     * data-testing
     * @default 'between-date-filter'
     */
    dataTesting?: string
}>();

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const emit = defineEmits<{
    (name: 'update:fromDate', date?: string): void,
    (name: 'update:toDate', date?:string): void
}>();

const fromDate = ref<string|undefined>(props.fromDate);
const toDate = ref<string|undefined>(props.toDate);

const dataTesting = props.dataTesting || 'between-date-filter';
const dateDataTesting = `${dataTesting}-date`;
const confirmButtonDataTesting = `${dataTesting}-confirm`;
const clearButtonDataTesting = `${dataTesting}-clear`;

const fromMax = computed(() => toDate.value || undefined);
const toMin = computed(() => fromDate.value || undefined);

const selectedValues = computed(() =>
{
    if (props.fromDate !== fromDate.value || props.toDate !== toDate.value)
    {
        return []
    }
    if (fromDate.value && toDate.value)
    {
        return [`${t('BETWEEN_DATE_FILTER_FROM')} ${fromDate.value} ${t('BETWEEN_DATE_FILTER_TO').toLocaleLowerCase()} ${toDate.value}`];
    }
    else if (fromDate.value)
    {
        return [`${t('BETWEEN_DATE_FILTER_FROM')} ${fromDate.value}`];
    } 
    else if (toDate.value)
    {
        return [`${t('BETWEEN_DATE_FILTER_TO')} ${toDate.value}`];
    }
    else
    {
        return [];
    }
});

watch(()=>props.fromDate, ()=>
{
    fromDate.value = props.fromDate;
}, {immediate: true});

watch(()=>props.toDate, ()=>
{
    toDate.value = props.toDate;
}, {immediate: true});


const onCleared = () =>
{
    fromDate.value = props.fromDate;
    toDate.value = props.toDate;
    emit('update:fromDate', undefined);
    emit('update:toDate', undefined);
};

const onConfirmed = () =>
{
    if(fromDate.value !== props.fromDate)
    {
        emit('update:fromDate', fromDate.value);
    }
    if(toDate.value !== props.toDate)
    {
        emit('update:toDate', toDate.value);
    }
}
</script>