<template>
    <cr-confirmation-dialog :title="t('COURSE_USERS_CREATE_TEAM_DIALOG_TITLE')" @confirm="onConfirmed" @cancel="onCancel" :disabled="isLoading" :form-instruction="true" data-testing="create-team-dialog">
        <cr-text-field v-model="team.name" :label="t('COURSE_USERS_CREATE_TEAM_DIALOG_LABEL')" required :max-length="256" :disabled="isLoading" data-testing="create-team-dialog-team-name" />
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { ITeam, Team } from '@cyber-range/cyber-range-api-user-client';
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useTeamStore } from '../../../stores/teamStore';
import { useRawObject } from '../../../composables/useRawObject';
import { useRosterStore } from '../../../stores/rosterStore';
import { storeToRefs } from 'pinia';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    courseId: string;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
    (name: 'cancel'): void
}>();

const team = reactive<ITeam>(new Team());

const onConfirmed = async ()=>
{
    await useTeamStore().create(props.courseId, useRawObject(team));
    useRosterStore().clearCache();
    team.name = "";
    emit('confirm');
}
async function onCancel()
{
    team.name = "";
    emit('cancel');
}
</script>