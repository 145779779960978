<template>
    <read-layout :title="t('COURSE_USERS_IMPORT_TITLE_GOOGLE_CLASSROOM')" :ready="isReady" :breadcrumbs="breadcrumbs">
        <template #top>
            <read-section :label="t('COURSE_USERS_IMPORT_SIGNIN_GOOGLE_CLASSROOM_LABEL')">
                <p class="cr-ptb5"> {{ t('COURSE_USERS_IMPORT_SIGNIN_GOOGLE_CLASSROOM_BLURB') }}</p>
                <google-classroom-button @signInSuccess="onSigninSuccess"/>
                <select-google-classroom-dialog v-model="signedIn" :classrooms="googleClassroomClasses" @confirm="onSelectGoogleClassroomConfirmed" @cancel="onSelectGoogleClassroomCancelled"/>
            </read-section>
        </template>
    </read-layout>
</template>

<script setup lang="ts">
import SelectGoogleClassroomDialog from './dialogs/SelectGoogleClassroomDialog.vue';
import ReadLayout from '../../layouts/ReadLayout.vue';
import ReadSection from '../../layouts/sections/ReadSection.vue';
import GoogleClassroomButton from './GoogleClassroomButton.vue';
import UserImportMethod from '../../../interfaces/userImportMethod';
import Route from '../../../routers/route';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useCourseBreadcrumbs  } from '../../../composables/useCourseBreadcrumbs';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { useErrorStore } from '../../../stores/errorStore';
import { useI18n } from 'vue-i18n';
import { ICourse } from '@cyber-range/cyber-range-api-user-client';
import { IGoogleClassroomClass, listUserGoogleClassrooms, listGoogleClassroomStudents, classroomScopes } from '../../../composables/useGoogleClassroom';

const emit = defineEmits<{
    (name: 'usersImported', value:{ name:string, email:string, role:string }[]): void,
    (name: 'confirm'):void
}>();

const props = defineProps<
{
    course:ICourse|undefined
}>();

const signedIn = ref(false);

const isReady = ref(false);

const breadcrumbs = computed(()=>useCourseBreadcrumbs([
                        new BreadcrumbItem(Route.Courses), 
                        new BreadcrumbItem({...Route.Course,  text: props.course?.name, params: {courseId: props.course?.id}}), 
                        new BreadcrumbItem({...Route.CourseUsers, text: t('COURSE_USERS_PAGE_TITLE'), params: {courseId: props.course?.id}}),
                        new BreadcrumbItem({...Route.InviteCourseUsers, text: t('COURSE_USERS_INVITE_USERS_PAGE_TITLE'), params: {courseId: props.course?.id}}),
                        new BreadcrumbItem({...Route.ImportCourseUsers, text: t('COURSE_USERS_IMPORT_TITLE_GOOGLE_CLASSROOM'), params: {courseId: props.course?.id, importMethod:UserImportMethod.GoogleClassroom }})]));

const { t } = useI18n();

const accessToken = ref<string>();

const googleClassroomClasses = ref<IGoogleClassroomClass[]>([]);

const fetchGoogleClassroomStudents = async (classroomid:string) =>
{
        let users:{ name:string, email:string, role:string }[] = await listGoogleClassroomStudents(classroomid, accessToken.value as string);
        emit('usersImported', users);
}
        
const onSelectGoogleClassroomConfirmed = async (selectedClassId:string)=>
{
    try
    {
        await fetchGoogleClassroomStudents(selectedClassId);
        emit('confirm');
    }
    catch(e)
    {
        signedIn.value = false;
        accessToken.value = undefined;
        googleClassroomClasses.value = [];
        useErrorStore().setError({message: t('COURSE_USERS_IMPORT_GOOGLE_CLASSROOM_ERROR_ON_FETCH_COURSE_STUDENTS'), details:'',statusCode:0});
    }
}

const onSelectGoogleClassroomCancelled= async ()=>
{
    signedIn.value = false;
    googleClassroomClasses.value = [];
}

const onSigninSuccess = async (token:string)=>
{
    accessToken.value = token;
    try
    {
        googleClassroomClasses.value = await listUserGoogleClassrooms(accessToken.value);
        signedIn.value = true;
    }
    catch(e)
    {
        signedIn.value = false;
        googleClassroomClasses.value = [];
        useErrorStore().setError({message: t('COURSE_USERS_IMPORT_GOOGLE_CLASSROOM_ERROR_ON_FETCH_COURSES'), details:'',statusCode:0})
    }
}

onMounted( async ()=>
{
    isReady.value = true;
    await nextTick();// wait for google sign in button container 
});
</script>