<template>
    <cr-alert v-for="alert in alerts" v-bind="alert.args" class="cr-mb2" @close="onClose(alert.id)">
        <span data-testing="exercise-group-alert-message" class="exercise-group-alert-text">{{ alert.message }}</span>
    </cr-alert>
</template>

<style scoped>
.exercise-group-alert-text {
    white-space: pre-line;
}
</style>

<script setup lang="ts">
import { INote, NoteType } from '@cyber-range/cyber-range-api-user-client'
import { computed } from 'vue';

const props = defineProps<{
    notes: INote[]
}>();

const emit = defineEmits<{
    (name: 'noteRemoved', noteId: string): void
}>();


const alerts = computed(() => 
{
    return props.notes.map(note => ({
        args: {
            info: note.type === NoteType.Info,
            warn: note.type === NoteType.Warning,
            error: note.type === NoteType.Error,
            closeable: note.dismissible
        },
        message: note.message,
        id: note.id
    }));
})

const onClose = async (noteId: string) =>
{
    emit('noteRemoved', noteId);
}
</script>
