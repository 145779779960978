import { onUnmounted } from "vue";
import { useTimerStore } from "../stores/timerStore";

export function useScheduler()
{
    const scheduleIds = new Set<string>();

    const timerStore = useTimerStore();

    function schedule(task: Function, args?: any[], options?: { frequencyInMs?:number, repeat?:boolean, unscheduleOnUnmount?:boolean })
    {
        const id = timerStore.schedule(task, args, options?.frequencyInMs, options?.repeat)
        if (options?.unscheduleOnUnmount || options?.unscheduleOnUnmount === undefined)
        {
            scheduleIds.add(id);
        }
        return id;
    }

    function unschedule(id: string)
    {
        timerStore.unschedule(id);
        scheduleIds.delete(id);
    }

    onUnmounted(() =>
    {
        for (const id of scheduleIds)
        {
            timerStore.unschedule(id);
        }
    });

    return { schedule, unschedule };
}
