import Config from "../config";

export default function createMessages()
{
    let mode:string  = Config.IS_VACR ? 'VACR' : 'USCR';

    let messages:{[key:string]: {[stringKey:string]: string}} = {};
    
    // Dynamically search for strings files
    let stringFiles = import.meta.glob<any>("./definitions/*.ts", { eager: true });
    
    for (const filename of Object.keys(stringFiles)) 
    {
        let string = stringFiles[filename].default;

        for (const key of Object.keys(string)) 
        {
            if (!messages[key]) 
            {
                messages[key] = {};
            }
            
            for (const stringKey of Object.keys(string[key]))
            {
                messages[key][stringKey] = string[key][stringKey][mode] ?? string[key][stringKey];
            }
        }
    }
    
    return messages;
}