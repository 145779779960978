<template>
    <read-control-item>
        <cr-button outlined :disabled="buttonDisabled" @click="onStartAllClicked">
            <cr-icon>bi-power</cr-icon>
            {{ t('EXERCISE_GROUP_START_ALL_BUTTON') }}
        </cr-button>
    </read-control-item>
    <cr-confirmation-dialog v-model="showConfirmationDialog" @confirm="onConfirm">
        {{ t('EXERCISE_GROUP_START_ALL_CONFIRM_MESSAGE') }}
    </cr-confirmation-dialog>
</template>

<script setup lang="ts">
import { IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import { ExerciseStatus, IExercise } from '@cyber-range/cyber-range-api-exercise-client';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useExerciseStore } from '../../stores/exerciseStore';
import { useJobStore } from '../../stores/jobStore';
import ReadControlItem from '../layouts/sections/ReadControlItem.vue';

const props = defineProps<{
    exerciseGroupId: string
}>()

const emit = defineEmits<{
    (name: 'confirm', exerciseIds: string[]): void,
}>();

const { t } = useI18n();
const exerciseStore = useExerciseStore();
const jobStore = useJobStore();

const buttonDisabled = ref(false);
const showConfirmationDialog = ref(false);
const onStartAllClicked = () => showConfirmationDialog.value = true;

async function onConfirm()
{
    buttonDisabled.value = true;

    try 
    {
        let token: string|undefined = undefined;
        let exerciseIds: string[] = []
        do
        {
            const response: IApiPageResponse<IExercise> = await exerciseStore.getExerciseGroupExercises(props.exerciseGroupId, { token }, { background: true })
            token = response.nextPageToken
            for (const exercise of response.items)
            {
                if (exercise.status === ExerciseStatus.STUB || exercise.status === ExerciseStatus.STOPPED)
                {
                    exerciseIds.push(exercise.id);
                }
            }
        }
        while (token);

        await jobStore.startExercises(exerciseIds);

        emit('confirm', exerciseIds);
    }
    finally
    {
        buttonDisabled.value = false;
    }    
}
</script>
