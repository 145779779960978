export default {
    en: {
        // Plans
        PLANS_PAGE_TITLE: 'Plans',
        PLANS_MANAGE_FEATURES_BUTTON: 'Manage Features',
        
        // Plans table
        PLANS_TABLE_NAME: 'Name',
        PLANS_TABLE_TYPE: 'Type',
        PLANS_TABLE_ENROLLMENT_FEE: 'Price per Enrollment',
        PLANS_TABLE_COURSEWARE: 'Courseware',
        PLANS_TABLE_EXERCISE_AREA: 'Exercise Area',
        PLANS_TABLE_PERSISRENCE: 'Persistentce',
        PLANS_TABLE_CTF: 'CTF',
        PLANS_TABLE_FLEX_RATE: 'Flex',
        PLANS_TABLE_FLAT_RATE: 'Flat',
        PLANS_TABLE_PROVIDE_COURSEWARE: 'Courseware',
        PLANS_TABLE_PROVIDE_EXERCISE_AREA: 'Exercise Area',
        PLANS_TABLE_PROVIDE_PERSISTENT_ENVIRONMENT: 'Persistent Only',
        PLANS_TABLE_PROVIDE_NON_PERSISTENT_ENVIRONMENT: 'Non-persistent Only',
        PLANS_TABLE_PROVIDE_BOTH_PERSISTENT_AND_NON_PERSISTENT_ENVIRONMENT: 'Both',
        PLANS_TABLE_PROVIDE_CTF: 'CTF',
        PLANS_TABLE_VIEW_PLAN_ACTION_ITEM: 'View Plan',

        // Plan
        PLAN_PRICING: 'Pricing',
        PLAN_PRICING_BASE_PRICE: 'Base Price',
        PLAN_FEATURES: 'Features',
        PLAN_FEATURES_RATE: 'Rate',
        PLAN_FEATURES_FLEX_RATE: 'Flex Rate',
        PLAN_FEATURES_FLAT_RATE: 'Flat Rate',
        PLAN_FEATURES_FEDERAL_RATE: 'Federal Rate',
        PLAN_FEATURES_OPTIONAL: 'Optional',
        PLAN_NOT_FOUND: 'The plan "{productId}" could not be found.',
        PLAN_LIMITS: 'Limits',
    }
}