export default {
    en: {
        SUPER_ADMIN_PAGE_TITLE: "Super Admins",
        SUPER_ADMIN_HEADER_NAME: "Name",
        SUPER_ADMIN_HEADER_EMAIL: "Email",
        SUPER_ADMIN_HEADER_IDENTITY_PROVIDER: "Identity Provider",
        SUPER_ADMIN_HEADER_LAST_LOGIN: "Last Login",
        SUPER_ADMIN_HEADER_PENDING_SINCE: "Pending Since",
        SUPER_ADMIN_VIEW_PROFILE_BUTTON: "View Profile",
        SUPER_ADMIN_RESEND_INVITATION_BUTTON: "Resend",
        SUPER_ADMIN_DELETE_INVITATION_BUTTON: "Delete",
        SUPER_ADMIN_DELETE_USER_BUTTON: "Delete",
        SUPER_ADMIN_DELETE_USER_DIALOG_MESSAGE: 'Are you sure you want to delete <b>{userName}</b>?',
        SUPER_ADMIN_INVITE_SUPER_ADMIN_BUTTON: 'Invite a Super Admin',

        // Invite Super Admin
        INVITE_SUPER_ADMIN_PAGE_TITLE: 'Invite a Super Admin',
        SUPER_ADMIN_INFO_SECTION: 'Super Admin Information',
        SUPER_ADMIN_INFO_SECTION_NAME: 'Name',
        SUPER_ADMIN_INFO_SECTION_EMAIL: 'Email',
    }
};