import { ICatalogEntry, ICatalogFamily, ICatalogFamilySearchFilter } from '@cyber-range/cyber-range-api-catalog-client';
import { defineStore } from 'pinia';
import { useApiClientStore } from './apiClientStore';

export const useCatalogStore = defineStore('catalogStore', 
{
    state: () => ({
        catalogsById: new Map<string,ICatalogEntry>()
    }),

    actions: 
    {
        async fetch(catalogId: string, options?: {force?: boolean, background?:boolean}): Promise<ICatalogEntry|undefined>
        {
            if (!this.catalogsById.has(catalogId) || options?.force)
            {
                let catalogApiClient = options?.background ? useApiClientStore().backgroundCatalogApiClient : useApiClientStore().catalogApiClient;
                const catalogEntry = await catalogApiClient.getOne(catalogId);
                this.catalogsById.set(catalogId, catalogEntry);
            }
            return this.catalogsById.get(catalogId);
        },

        async listCatalogFamilies(searchFilter?: Partial<ICatalogFamilySearchFilter>): Promise<ICatalogFamily[]>
        {
            const apiClientStore = useApiClientStore();
            return (searchFilter)
                ? await apiClientStore.catalogFamilySearchApiClient.searchAll(searchFilter)
                : await apiClientStore.catalogFamilyApiClient.getAll();
        },

        async getCatalogFamily(catalogFamilyId: string): Promise<ICatalogFamily|undefined>
        {
            const apiClientStore = useApiClientStore();
            return apiClientStore.catalogFamilyApiClient.getOne(catalogFamilyId);
        },
    }
});
