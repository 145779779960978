<template>
    <cr-container :class="classes">
        <cr-row>
            <cr-col v-if="showCheck" class="icon-section cr-p0 cr-pl1 center-align">
                <cr-icon :alt="t('SUBSCRIPTION_TILE_ACTIVE_ICON_ALT')" class="cr-ml2" data-testing="subscription-tile-header-icon">
                    bi-check-circle
                </cr-icon>
            </cr-col>
            <cr-col class="center-align">
                <h3 class="cr-mb0 cr-text-center" data-testing="subscription-tile-header-title">
                    {{title}}
                </h3>
            </cr-col>
        </cr-row>
    </cr-container>
</template>

<style scoped>
.cr-row
{
    min-height: 4rem;
    vertical-align: center;
}
.subscription-active
{
    background-color: var(--cr-subscription-tile-active-color);
    color: var(--cr-subscription-tile-active-text-color);
}
.subscription-inactive
{
    background-color: var(--cr-subscription-tile-inactive-color);
    color: var(--cr-subscription-tile-inactive-text-color);
}
.icon-section
{
    flex-grow: 0;
    font-size: 1.25rem;
}
.center-align
{
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>
<script setup lang="ts">
import { ISubscription } from '@cyber-range/cyber-range-api-subscription-client';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSubscriptionDates } from '../../composables/useSubscriptionDates';

const { t } = useI18n();
const { subscriptionActive } = useSubscriptionDates();
const props = defineProps<
{
    title:string
    subscription?: ISubscription
}>();

const classes = computed(() => 
{
    const classes = ["subscription-tile-header-wrapper"];
    const active = !!props.subscription && subscriptionActive(props.subscription);
    classes.push( active ? "subscription-active" : "subscription-inactive");
    return classes;
})

const showCheck = computed(() => !!props.subscription && subscriptionActive(props.subscription));
</script>