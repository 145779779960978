import { ApiPageResponse, Filter, IApiPageResponse, IFilter } from "@cyber-range/cyber-range-api-client";
import { Ref, computed, reactive } from "vue";
import Config from "../config";
import { ITableHeaderItem, SortOrder } from "@cyber-range/cyber-range-lib-ui";

interface ISortableFilter<T> extends IFilter
{
    sortOrder?: SortOrder,
    sortBy?: keyof T
}

export function useArtificialPaging<T>(items: Ref<T[]>)
{
    const filter: ISortableFilter<T> = reactive(new Filter({
        limit: Config.DEFAULT_TILE_LISTING_PAGE_SIZE
    }));

    const page = computed<IApiPageResponse<T>>(() =>
    {
        const sortedItems = [...items.value]
        if (filter.sortBy)
        {
            sortedItems.sort((a,b) => 
            {
                const aValue = a[filter.sortBy as keyof typeof a]
                if (typeof aValue === 'string')
                {
                    return aValue.localeCompare(b[filter.sortBy as keyof typeof b] as string);
                }
                else if (typeof aValue === 'number')
                {
                    return aValue - (b[filter.sortBy as keyof typeof b] as number);
                }
                return 0;
            })
        }
        if (filter.sortOrder === SortOrder.Desc)
        {
            sortedItems.reverse();
        }
        const pageIndex = Number(filter.token || 0);
        const start = pageIndex * filter.limit;
        const end = start + filter.limit;
        const total = sortedItems.length;
        return new ApiPageResponse({
            items: sortedItems.slice(start, end),
            prevPageToken: start > 0 ? `${pageIndex - 1}` : undefined,
            nextPageToken: end < total ? `${pageIndex + 1}` : undefined,
        });
    });

    const onSuggestedNumberOfItems = (n:number) => filter.limit = n
    const onLoadPreviousPage = () => filter.token = page.value.prevPageToken || ''
    const onLoadNextPage = () => filter.token = page.value.nextPageToken;
    const onLoadFirstPage = () => filter.token = '';
    const onSort = (header:ITableHeaderItem) => Object.assign(filter, { sortOrder: header.sortOrder as SortOrder, sortBy: header.key?.toLowerCase(), token: "" });

    return {
        filter,
        page,
        onSuggestedNumberOfItems,
        onLoadPreviousPage,
        onLoadNextPage,
        onLoadFirstPage,
        onSort,
    };
}
