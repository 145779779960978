<template>
    <form-section v-if="isVisible" collapsible :label="t('COURSE_FORM_PAYMENT_SECTION')" data-testing="course-form-payment-section" class="cr-mb3">
        <p>{{ t('COURSE_FORM_PAYMENT_SECTION_TEXT', { organizationName }) }}</p>
        <cr-external-link :href="directPayHelpLink">
            {{ t('COURSE_FORM_DIRECT_PAY_HELP_LINK_TEXT') }}
        </cr-external-link>
        <cr-checkbox :name="t('COURSE_FORM_DIRECT_PAY_ENABLED_LABEL')" :label="t('COURSE_FORM_DIRECT_PAY_ENABLED_LABEL')" v-model="isUserPaysCourse" class="cr-mt3" data-testing="course-form-direct-pay-enabled-checkbox"/>
        <template v-if="isUserPaysCourse">
            <cr-html :value="t('COURSE_FORM_DIRECT_PAY_COURSE_COST_MESSAGE', { cost: formattedCourseCost })" data-testing="course-form-direct-pay-cost"/>
            <cr-checkbox :label="t('COURSE_FORM_DIRECT_PAY_ACKNOWLEDGEMENT')" name="thisField" required class="cr-mt3" data-testing="course-form-direct-pay-acknowledged-checkbox"/>
        </template>
    </form-section>
</template>

<script setup lang="ts">
import { ICreateCourseParams } from '@cyber-range/cyber-range-api-user-client';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useNumberFormats } from '../../composables/useNumberFormats';
import { useInvoiceStore } from '../../stores/invoiceStore';
import { useKbStore } from '../../stores/kbStore';
import FormSection from '../layouts/sections/FormSection.vue';
import { useSubscriptionStore } from '../../stores/subscriptionStore';
import { Products } from '@cyber-range/cyber-range-api-subscription-client';
import { useOrganizationStore } from '../../stores/organizationStore';

const { t } = useI18n();
const { toCurrency } = useNumberFormats()
const subscriptionStore = useSubscriptionStore();

const props = defineProps<{
    course: ICreateCourseParams
}>();
const isUserPaysCourse = defineModel<boolean>();
const isVisible = computed(() => subscriptionStore.isSubscribedTo(props.course.organizationId, Products.UserDirectPayFeature));
const courseCost = defineModel<number>('courseCost');
const formattedCourseCost = computed(() => toCurrency(courseCost.value || 0));
const directPayHelpLink = useKbStore().directPayHelp();
const organizationName = computed(() => useOrganizationStore().organizations.find(({ id }) => id === props.course.organizationId)?.name);

watch(() => props.course.organizationId, () =>
{
    if (props.course.organizationId)
    {
        subscriptionStore.fetchOrganizationSubscribedProducts(props.course.organizationId);
    }
}, { immediate: true });

watch(isVisible, () => 
{
    if (!isVisible.value)
    {
        props.course.isUserPaysCourse = false;
        courseCost.value &&= undefined;
    }
});

watch(() => [
    props.course.isUserPaysCourse,
    props.course.organizationId,
    props.course.startTime,
    props.course.endTime
], async () =>
{
    courseCost.value = props.course.isUserPaysCourse && props.course.organizationId && props.course.startTime && props.course.endTime
        ? await useInvoiceStore().estimateUserDirectPayCourse(props.course.organizationId, props.course.startTime, props.course.endTime)
        : undefined;
})
</script>