<template>
    <form-layout :title="t('CREATE_ORGANIZATION_APPLICATION_PAGE_TITLE')" :breadcrumbs="breadcrumbs" :ready="isReady" @confirm="onConfirmCreateOrganizationApplication" @cancel="onCancelCreateOrganizationApplication">
        <template #form>
            <organization-application-form v-model="application"/>
        </template>
    </form-layout>
</template>

<script lang="ts" setup>
import FormLayout from '../layouts/FormLayout.vue';
import { useI18n } from 'vue-i18n';
import OrganizationApplicationForm from './OrganizationApplicationForm.vue';
import { useRouter } from 'vue-router';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import Route from '../../routers/route';
import { computed, onMounted, ref } from 'vue';
import { IOrganization, IOrganizationApplication } from '@cyber-range/cyber-range-api-organization-client';
import { useOrganizationStore } from '../../stores/organizationStore';

const router = useRouter();
const { t } = useI18n();
const isReady = ref(false);

const props = defineProps<
{
   organizationId:string
}>();

const application = ref<IOrganizationApplication>({} as IOrganizationApplication);
let organization = ref<IOrganization>();

const applicationsRoute = {...Route.OrganizationApplications,text: t('ORGANIZATION_APPLICATIONS_PAGE_TITLE'), params: {organizationId: props.organizationId}};
const breadcrumbs = computed(()=>[
                            new BreadcrumbItem(Route.Organizations),
                            new BreadcrumbItem({...Route.Organization, text: organization.value?.name, params: {organizationId: props.organizationId}}),
                            new BreadcrumbItem(applicationsRoute),
                            new BreadcrumbItem({...Route.CreateOrganizationApplication,text: t('CREATE_ORGANIZATION_APPLICATION_PAGE_TITLE'), params: {organizationId: props.organizationId}})]);

const onConfirmCreateOrganizationApplication = async () => 
{
    application.value.organizationId = props.organizationId;
    await useOrganizationStore().createOrganizationApplication(props.organizationId, application.value);
    router.push(applicationsRoute);
}

const onCancelCreateOrganizationApplication = async () => 
{
    router.push(applicationsRoute);
}

onMounted(async () => 
{
    organization.value = await useOrganizationStore().getOrganization(props.organizationId);
    isReady.value = true;
});
</script>
