import { defineStore } from 'pinia';
import { nanoid } from 'nanoid';

export const useTimerStore = defineStore('timerStore', 
{
    state: () =>
    ({
        timers: {} as {[id:string]:number}
    }),

    actions: 
    {
        isScheduled(id:string): boolean
        {
            return this.timers[id] !== undefined;
        },
        schedule(task: Function, args: any[] = [], frequencyInMs:number = 60000, repeat:boolean = true): string
        {
            let func = repeat ? setInterval : setTimeout;

            let id = nanoid();

            this.timers[id] = func(task, frequencyInMs, ...args);

            return id;
        },
        unschedule(id:string): void
        {
            let schedule = this.timers[id];

            if(schedule)
            {
                clearInterval(schedule);
                delete this.timers[id];
            }
        },
        unscheduleAll(): void
        {
            for(let id of Object.keys(this.timers))
            {
                this.unschedule(id);
            }
        }
    }
});