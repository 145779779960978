<template>
    <p>{{ persistenceText }}</p>
    <cr-external-link :href="useKbStore().exercisePersistence()">
        {{  t('EXERCISE_ENVIRONMENT_PERSISTENCE_HELP_TEXT') }}
    </cr-external-link>
    <cr-select class="cr-mt3" label="Persistence" v-model="selectedPersistenceValue" :items="persistenceOptions" item-value="value" :disabled="disabled" :required="showPersistence"/>
</template>

<script setup lang="ts">
import { IRecipeParameter } from '@cyber-range/cyber-range-api-catalog-client';
import { computed, ref, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSubscriptionStore } from '../../stores/subscriptionStore';
import { useKbStore } from '../../stores/kbStore';

const props = defineProps<{
    organizationId: string,
    recipeParameters: IRecipeParameter[],
    modelValue: string,
    showPersistence?: boolean,
    disabled?: boolean,
}>();
const emit = defineEmits<{
    (e:'update:modelValue', selectedPersistenceValue:string): void,
    (e:'update:showPersistence', showPersistence:boolean): void,
}>();

const selectedPersistenceValue = computed({
    get(): string
    {
        return props.modelValue;
    },
    set(newValue: string)
    {
        emit('update:modelValue', newValue);
    }
})

const { t } = useI18n();

const persistenceOptions = ref<{ name: string, value: string, disabled: boolean }[]>([]);
watch(() => props.recipeParameters, async () => 
{
    const persistenceParameter = props.recipeParameters.find(({ id }) => id === 'persistent');
    if (!persistenceParameter)
    {
        persistenceOptions.value = [];
        selectedPersistenceValue.value = '';
        return;
    }

    const options: { name: string, value: string, disabled: boolean }[] = [];

    const subscribedProductIds = props.organizationId ? await useSubscriptionStore().fetchOrganizationSubscribedProducts(props.organizationId) : [];
    for (const option of persistenceParameter!.options)
    {
        let disabled:boolean = false;
        let name:string = option.name;
        if((option.value === 'true' && !subscribedProductIds.includes('feature_persistent_environments_product_id'))
            || (option.value === 'false' && !subscribedProductIds.includes('feature_non_persistent_environments_product_id')))
            {
                disabled = true;
                name = `${option.name.replace(/\(([^)]+)\)/g,"")} ${t('EXERCISE_ENVIRONMENT_PERSISTENCE_REQUIRES_SUBSCRIPTION').toString()}`;
            }

        options.push({ ...option, disabled, name });
    }
    persistenceOptions.value = options;
    selectedPersistenceValue.value = (options.find(o=> o.value === persistenceParameter!.default)?.disabled === true
        ? options.find(o=> o.disabled === false)?.value
        : options.find(o=> o.value === persistenceParameter!.default)?.value) ?? '';
}, { deep: true, immediate: true });

const persistenceText = computed(() =>
{
    let hasPersistentOption = false;
    let hasNonPersistentOption = false;
    for (const option of persistenceOptions.value)
    {
        if (option.value === 'true')
        {
            hasPersistentOption = true;
        }
        else if (option.value === 'false')
        {
            hasNonPersistentOption = true;
        }
    }

    if (hasPersistentOption && hasNonPersistentOption)
    {
        return t('EXERCISE_ENVIRONMENT_PERSISTENCE_DESCRIPTION_BOTH_OPTIONS').toString();
    }
    else if (hasPersistentOption)
    {
        return t('EXERCISE_ENVIRONMENT_PERSISTENCE_DESCRIPTION_PERSISTENT_ONLY_OPTIONS').toString();
    }
    else if (hasNonPersistentOption)
    {
        return t('EXERCISE_ENVIRONMENT_PERSISTENCE_DESCRIPTION_NON_PERSISTENT_ONLY_OPTIONS').toString();
    }
    else
    {
        return '';
    }
});

watchEffect(() =>
{
    emit('update:showPersistence', !!persistenceText.value);
});
</script>
