<template>
    <form-layout :title="t('ORGANIZATIONS_EDIT_ORGANIZATION_PAGE_TITLE')" :breadcrumbs="breadcrumbs" @confirm="onConfirm" @cancel="onCancel" :ready="isReady">
        <template #form>
            <organization-form v-model="organization" v-model:limits="limits" @update:logo-file="onFileUpdated"/>
        </template>
    </form-layout>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import Route from '../../routers/route';
import FormLayout from '../layouts/FormLayout.vue';
import { computed, onMounted, ref, shallowRef } from 'vue';
import { useRouter } from 'vue-router';
import { useOrganizationStore } from '../../stores/organizationStore';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { ICustomOrganizationLimits, IOrganization, Organization } from '@cyber-range/cyber-range-api-organization-client';
import { FileVisibility, File as FileApiFile, MetadataType } from '@cyber-range/cyber-range-api-file-client';
import OrganizationForm from './OrganizationForm.vue';
import { useRawObject } from '../../composables/useRawObject';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useFileStore } from '../../stores/fileStore';
import { ApiClientError } from '@cyber-range/cyber-range-api-client';
import { useErrorStore } from '../../stores/errorStore';

let props = defineProps<{
    organizationId:string
}>();

const { t } = useI18n();
const router = useRouter();
const isReady = ref(false);
const { canEditOrganizationCode, canEditOrganizationAlternateId, canEditOrganizationName, canManageOrganizationCustomAttributes } = useAuthorizationStore();

const breadcrumbs = computed(()=>[
                            new BreadcrumbItem(Route.Organizations),
                            new BreadcrumbItem({...Route.Organization, text: organizationBreadcrumbName.value, params: {organizationId: props.organizationId}}),
                            new BreadcrumbItem({...Route.EditOrganization, params: {organizationId: props.organizationId}})]);


let organization = ref<IOrganization>(new Organization());
const limits = shallowRef<ICustomOrganizationLimits>();
let organizationBreadcrumbName = ref<string>();
let existingParentId = ref<string|undefined>();
let existingCode = ref("");
let existingAlternateIds = ref<string[]>([]);
let logoFile: File|undefined;
let logoChanged: boolean = false;

const alternateIdsChanged = computed(()=>
{
    return existingAlternateIds.value.sort().join(',') !== (organization.value.alternateIds || []).sort().join(',');
})

const onFileUpdated = async (data: File|undefined) =>
{
    logoChanged = true;
    logoFile = data;
}

const onConfirm = async () => 
{
    const organizationId = organization.value.id;

    let payload = useRawObject(organization.value, {only: ['id', 'description', 'contact']});
    if (canEditOrganizationName(organizationId))
    {
        payload.name = organization.value.name;
    }
    if (organization.value.code && canEditOrganizationCode(organizationId))
    {
        payload.code = organization.value.code;
    }
    if(canManageOrganizationCustomAttributes(organizationId))
    {
        payload.customAttributes = organization.value.customAttributes;
        payload.internalCustomAttributes = organization.value.internalCustomAttributes;
    }
    if (logoChanged)
    {
        if (logoFile)
        {
            try 
            {
                const fileApiFile = new FileApiFile({type: logoFile.type, name: logoFile.name, visibility: FileVisibility.PUBLIC, metadata: {id: organizationId, type: MetadataType.OrganizationLogo }});
                let fileId: string;
                fileId = await useFileStore().uploadFile(fileApiFile, logoFile);
                payload.logo = await useFileStore().getDownloadUrl(fileId);
            } 
            catch (e)
            {
                if (e instanceof ApiClientError)
                {
                    throw e; 
                }

                // Non-ApiClient errors are typically silent, set friendly error and continue to org page after patching other fields
                useErrorStore().setError({message: t('ORGANIZATION_FORM_LOGO_UPLOAD_FAILED_ERROR', {errorMessage: (e as Error).message}), details:'',statusCode:0});
            }
        }
        else if(organization.value.logo)
        {
            payload.logo = "";
        }
    }
    payload.contact.name = `${payload.contact.firstName} ${payload.contact.lastName}`;
    await useOrganizationStore().update(payload);

    const newParentId = organization.value.parentId;
    if (newParentId !== existingParentId.value)
    {
        if (newParentId)
        {
            await useOrganizationStore().setOrganizationParentId(organizationId, newParentId);
        }
        else
        {
            await useOrganizationStore().removeOrganizationParentId(organizationId);
        }
    }

    if (existingCode.value && !organization.value.code && canEditOrganizationCode(organizationId))
    {
        await useOrganizationStore().deleteOrganizationCode(organizationId);
    }

    if (alternateIdsChanged.value && canEditOrganizationAlternateId(organizationId))
    {
        await useOrganizationStore().removeOrganizationAlternateIds(organizationId);
        if (organization.value.alternateIds?.length > 0)
        {
            for (const alternateId of organization.value.alternateIds)
            {
                await useOrganizationStore().setOrganizationAlternateId(organizationId, alternateId);
            }
        }
    }

    if (limits.value)
    {
        await useOrganizationStore().updateOrganizationLimits(organizationId, limits.value);
    }

    router.push({ name: Route.Organization.name, params: {organizationId:props.organizationId}})
}

const onCancel = () => 
{
    router.push({ name: Route.Organization.name, params: {organizationId:props.organizationId}});
}

onMounted(async ()=>
{
    try
    {
        [organization.value] = await Promise.all([
            useOrganizationStore().getOrganization(props.organizationId),
            useOrganizationStore().fetchParentOrganizations()
        ]);
        organizationBreadcrumbName.value = organization.value.name;
        existingParentId.value = organization.value.parentId;
        existingCode.value = organization.value.code || "";
        existingAlternateIds.value = organization.value.alternateIds || [];
    }
    finally
    {
        isReady.value = true
    }
})
</script>