import { IInvoice, IItem, ItemType } from "@cyber-range/cyber-range-api-invoice-client";
import IInvoiceTableData from "../../interfaces/invoice/iInvoiceTableData";
import IInvoiceTableDataItem from "../../interfaces/invoice/iInvoiceTableDataItem";
import { TableHeaderItem } from "@cyber-range/cyber-range-lib-ui";
import { useNumberFormats } from "../../composables/useNumberFormats";
import  $t  from "../../strings/definitions/billingStrings"

const { toCurrency } = useNumberFormats();

export type courseItems =  { cloudUsageItems?: IItem[], enrollmentItems?: IItem[], waiveItems?: IItem[] };

export default abstract class InvoiceTableData implements IInvoiceTableData
{
    headers = [
        new TableHeaderItem({text: $t.en.INVOICE_TABLE_DATA_NAME, key: 'name', sortable: false}),
        new TableHeaderItem({text: $t.en.INVOICE_TABLE_DATA_TOTAL, key: 'totalPrice', sortable: false}),
    ];

    items: { name: string; totalPrice: string; }[];
    totalPrice: number

    /**
     * 
     * @param invoice The Invoice to be translated to table data
     * @param balance The activity balance at the end of the transaction
     */
    constructor(invoice: IInvoice, balance: number)
    {
        const courseItemsMap = new Map<string, courseItems>()
        const organizationItems: IItem[] = [];

        for (const item of invoice.items || [])
        {
            if (this.isCourseItem(item))
            {
                const courseItem = courseItemsMap.get(item.referenceId) || {};

                switch (item.type) {
                    case ItemType.CloudUsage:
                        if (!courseItem.cloudUsageItems) { courseItem.cloudUsageItems = [] };
                        courseItem.cloudUsageItems.push(item);
                        break;
                    case ItemType.CourseEnrollment:
                        if (!courseItem.enrollmentItems) { courseItem.enrollmentItems = [] };
                        courseItem.enrollmentItems.push(item);
                        break;
                    case ItemType.Waived:
                        if (!courseItem.waiveItems) { courseItem.waiveItems = [] };
                        courseItem.waiveItems.push(item);
                        break
                }

                courseItemsMap.set(item.referenceId, courseItem);
            }
            else
            {
                organizationItems.push(item);
            }
        }

        const courseItems = Array.from(courseItemsMap.values())

        this.items = [
            ...courseItems.flatMap(this.processCourseItems),
            ...organizationItems.map(this.processOrganizationItem)
        ];

        if (balance > -invoice.totalPrice) {
            const credit = (balance < 0) ? ((-invoice.totalPrice) - balance) : (-invoice.totalPrice);
            this.items.push({
                name: 'Credit',
                totalPrice: toCurrency(credit)
            })
            this.totalPrice = invoice.totalPrice + credit
        }
        else
        {
            this.totalPrice = invoice.totalPrice;
        }
    }

    protected isCourseItem(item: IItem): boolean
    {
        return !!item.referenceId && [ItemType.CloudUsage, ItemType.CourseEnrollment, ItemType.Waived].includes(item.type);
    }

    protected abstract processCourseItems(courseItems: courseItems): IInvoiceTableData['items'];
    protected processOrganizationItem(item: IItem): IInvoiceTableDataItem
    {
        return {
            name: item.name,
            totalPrice: toCurrency(item.totalPrice)
        }
    }
}
