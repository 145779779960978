import { OrganizationUser, OrganizationUserTargetType, UserRole, UserStatus } from "@cyber-range/cyber-range-api-user-client";
import IOrganizationUserRoleView from "../interfaces/iOrganizationUserRoleView";

export default class OrganizationUserRoleView extends OrganizationUser implements IOrganizationUserRoleView 
{
    [UserRole.BillingAdmin]: boolean = false;
    [UserRole.CourseAdmin]: boolean = false;
    [UserRole.OrganizationAdmin]: boolean = false;
    [UserRole.OrganizationInstructor]: boolean = false;
    [UserRole.OrganizationStudent]: boolean = false;
    [UserRole.OrganizationTA]: boolean = false;

    constructor(user: Partial<IOrganizationUserRoleView>)
    {
        super();
        if (user.roles)
        {
            type OrgUserRoles = (keyof IOrganizationUserRoleView)[] & UserRole[]
            const organizationRoles: OrgUserRoles  = [UserRole.BillingAdmin, UserRole.CourseAdmin, UserRole.OrganizationAdmin, UserRole.OrganizationInstructor, UserRole.OrganizationStudent, UserRole.OrganizationTA];
    
            const filteredRoles = user?.roles?.filter(role => organizationRoles.includes(role));
            for (const role of filteredRoles as OrgUserRoles)
            {
                this[role] = true;
            }
        }

        Object.assign(this, OrganizationUser.fromJson(user));
    }

    get isUser(): boolean
    {
        return this.targetType === OrganizationUserTargetType.User;
    }

    get isInvitation(): boolean
    {
        return this.targetType === OrganizationUserTargetType.Invitation;
    }

    get isPendingApproval()
    {
        return this.isInvitation && this.status === UserStatus.PendingApproval;
    }

    get displayedBusinessUnit(): string
    {
        return this.businessUnits?.[0];
    }
}