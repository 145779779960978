import { defineStore } from 'pinia';
import { ApiRequestConfig } from '@cyber-range/cyber-range-api-client';
import { AuthenticationApiClient, IAuthenticationApiClient } from '@cyber-range/cyber-range-api-authentication-client';
import Config from '../config';
import { useSessionStore } from './sessionStore';
import { useApiClientStore } from './apiClientStore';

//To avoid circular referencing, this store has to use its own AuthApiClient here.
function createUserAuthenticationApiClient(): IAuthenticationApiClient
{
    let config = new ApiRequestConfig({ 'User-Agent': undefined });
    return new AuthenticationApiClient(Config.AUTHENTICATION_API_BASE_URL, config);
}

export const useAuthenticationStore = defineStore('authenticationStore', 
{
    state: () => {
        return {
            userAuthenticationApiClient: createUserAuthenticationApiClient()
        }
    },

    actions:
    {
        async authenticate(provider:string, token:string): Promise<boolean>
        {
            try
            {
                let authenticatedToken = await this.userAuthenticationApiClient.authenticate(provider, token);
                
                let sessionStore = useSessionStore();
                sessionStore.use(authenticatedToken.value);
            }
            catch(e)
            {
                return false;
            }

            return true;
        },
        async impersonate(userId:string): Promise<boolean>
        {
            try
            {
                let sessionStore = useSessionStore();

                if(!sessionStore.token)
                {
                    return false;
                }
                
                let authenticatedToken = await this.userAuthenticationApiClient.authenticate('vcr', sessionStore.token, userId);
                
                await sessionStore.use(authenticatedToken.value);
            }
            catch
            {
                return false;
            }

            return true;
        },
        async exitImpersonation(): Promise<boolean>
        {
            return await useSessionStore().quit();
        },
        async getSsoToken(): Promise<string>
        {
            return await useApiClientStore().authenticationApiClient.sso();
        }
    }
});