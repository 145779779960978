<template>
    <cr-sidebar-header tag="h2" class="cr-font-bold cr-sidebar-item cr-mt3" data-testing="sidebar-help">
        {{ t('SIDEBAR_HELP') }}
    </cr-sidebar-header>
    <cr-sidebar-item tag="span" :href="Config.KB_BASE_URL" target="_blank" data-testing="sidebar-help-knowledge-base">
        <cr-icon class="sidebar-item-icon">bi-info-circle</cr-icon> 
        <span class="sidebar-item-text">
            {{ t('SIDEBAR_HELP_KNOWLEDGE_BASE') }}
        </span>
    </cr-sidebar-item>
    <cr-sidebar-item :to="Route.Support" data-testing="sidebar-help-get-support">
        <cr-icon class="sidebar-item-icon">bi-chat-right-quote</cr-icon> 
        <span class="sidebar-item-text">
            {{ t('SIDEBAR_HELP_GET_SUPPORT') }}
        </span>
    </cr-sidebar-item>
    <cr-sidebar-item tag="span" :href="Config.STATUS_BASE_URL" target="_blank" data-testing="sidebar-help-status">
        <cr-icon class="sidebar-item-icon">bi-heart-pulse</cr-icon> 
        <span class="sidebar-item-text">
            {{ t('SIDEBAR_HELP_STATUS') }}
        </span>
    </cr-sidebar-item>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import Config from './../../../config';
import Route from '../../../routers/route';

const { t } = useI18n();
</script>