<template>
    <form-layout :title="t('INVITE_ORGANIZATION_USER_PAGE_TITLE')" :breadcrumbs="breadcrumbs" :ready="isReady" :custom-validation="validateRoles" @confirm="onConfirmCreateOrganizationInvitation" @cancel="onCancelCreateOrganizationInvitation" @validated="onFormValidated" >
        <template #form>
            <invite-organization-user-form v-model="invitation" v-model:business-unit="businessUnit" :validate="validate" :allow-org-selection="onInviteUserPage"/>
        </template>
    </form-layout>
</template>

<script setup lang="ts">
import FormLayout from '../layouts/FormLayout.vue';
import { useI18n } from 'vue-i18n';
import InviteOrganizationUserForm from './InviteOrganizationUserForm.vue';
import { RouteLocationRaw, useRouter } from 'vue-router';
import { computed, onMounted, ref, watch } from 'vue';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import Route from '../../routers/route';
import { useOrganizationStore } from '../../stores/organizationStore';
import { BusinessUnit, IBusinessUnit } from '@cyber-range/cyber-range-api-business-unit-client';
import { BusinessCategory, IInvitation, Invitation } from '@cyber-range/cyber-range-api-invitation-client';
import { useRawObject } from '../../composables/useRawObject';
import { useBusinessUnitStore } from '../../stores/businessUnitStore';
import { useInvitationStore } from '../../stores/invitationStore';

const router = useRouter();
const { t } = useI18n();

const props = defineProps<
{
    organizationId?:string,
}>();

const businessUnit = ref<IBusinessUnit>(new BusinessUnit());
const invitation = ref<IInvitation>(new Invitation());

const validate = ref<boolean>(false);
const validateRoles = () => 
{
    return invitation.value?.roles?.length > 0;
}
const onFormValidated = (result: boolean) =>
{
    validate.value = true;
    
    if (!result)
    {
        /**
         * This is a workaround for handling focus on a form with both custom (roles) and native html form validation.
         * This form has the roles at the bottom so if there are any other elements that are invalid, we want default browser focus behavior.
         * When the roles is the only invalid field, place focus on the first checkbox.
         */
        const otherValidatedFields = document.querySelectorAll(".cr-validated");

        let invalidElementsExist = false;
        otherValidatedFields.forEach(field => 
        {
            if (field.attributes.getNamedItem('aria-invalid'))
            {
                invalidElementsExist = true;
            }
        })

        if (!invalidElementsExist)
        {
            document.querySelector('.cr-role-picker-item')?.getElementsByTagName('label')[0]?.focus();
        }
    }
}

const organizationName = ref<string>("");
const onInviteUserPage = router.currentRoute.value.name === Route.InviteUser.name;
let previousRoute = computed<RouteLocationRaw>(() =>
{
    return onInviteUserPage
        ? { name: Route.Users.name, query: { organizationId: invitation.value.organizationId }}
        : { name: Route.OrganizationUsers.name, params: {organizationId: invitation.value.organizationId} };
})
const breadcrumbs = computed(()=> {
    return onInviteUserPage
        ? [new BreadcrumbItem(Route.Users), new BreadcrumbItem(Route.InviteUser)]
        : [
            new BreadcrumbItem(Route.Organizations),
            new BreadcrumbItem({...Route.Organization, text: organizationName.value, params: {organizationId: invitation.value.organizationId}}),
            new BreadcrumbItem({...Route.OrganizationUsers, params: {organizationId: invitation.value.organizationId}}),
            new BreadcrumbItem({...Route.InviteOrganizationUser, params: {organizationId: invitation.value.organizationId}})
        ];
});

const isReady = ref(false);

const isValidCreatableBusinessUnit = computed(() =>
{
    return !businessUnit.value.id && !!businessUnit.value.name && !!businessUnit.value.region && !!businessUnit.value.category;
})

const deduplicateBusinessUnit = () =>
{
    const existingUnits = useBusinessUnitStore().businessUnitsByOrganizationId.get(invitation.value.organizationId!);
    const duplicateUnit =  existingUnits?.find(bu => bu.name.trim() === businessUnit.value.name.trim() && bu.region.trim() === businessUnit.value.region.trim());
    if (duplicateUnit)
    {
        businessUnit.value = duplicateUnit; // includes id to avoid re-creating
    }
}

const onConfirmCreateOrganizationInvitation = async ()=>
{
    deduplicateBusinessUnit();
    if (isValidCreatableBusinessUnit.value)
    {
        let unitPayload = useRawObject(businessUnit.value, {excludes: ['createdTimestamp']});
        unitPayload.organizationId = invitation.value.organizationId!;

        businessUnit.value.id = await useBusinessUnitStore().create(unitPayload);
    }

    invitation.value.businessUnit = businessUnit.value?.name;
    invitation.value.businessRegion = businessUnit.value?.region;
    // Invitation API and Business unit API have the same enum values, but different keys. 
    invitation.value.businessCategory = businessUnit.value?.category as unknown as BusinessCategory;
    invitation.value.businessUnitId = businessUnit.value?.id
    
    let invitationPayload = useRawObject(invitation.value);
    await useInvitationStore().create([invitationPayload]);

    router.push(previousRoute.value);
}

const onCancelCreateOrganizationInvitation = async ()=>
{
    router.push(previousRoute.value);
}

onMounted(async () => 
{
    const organizationId = props.organizationId || router.options.history.state.organizationId as string;
    invitation.value.organizationId = organizationId;
    isReady.value = true;
    if (!onInviteUserPage)
    {
        const organization = await useOrganizationStore().getOrganization(organizationId)
        organizationName.value = organization.name;
        await useOrganizationStore().fetchOrganizationNameAndLogo([organization]);
    }
});
</script>