import { defineStore } from 'pinia';
import { IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import { useApiClientStore } from './apiClientStore';
import { ITeam, ITeamFilter, IUserApiClient } from '@cyber-range/cyber-range-api-user-client';


export const useTeamStore = defineStore('teamStore', 
{
    state: () => 
    ({
       
    }),

    actions: 
    {
        async get(courseId:string, teamId:string): Promise<ITeam>
        {
            let userApiClient = useApiClientStore().userApiClient;

            return await userApiClient.getCourseTeam(courseId, teamId);
        },

        async list(courseId:string, filter?:ITeamFilter, options?: {background?:boolean}): Promise<IApiPageResponse<ITeam>>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            let page = await userApiClient.getCourseTeams(courseId, filter);

            return page;
        },

        async listAll(courseId:string, filter?:ITeamFilter, options?: {background?:boolean}): Promise<ITeam[]>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            let teams = await userApiClient.getAllCourseTeams(courseId, filter);

            return teams;
        },

        async create(courseId:string, team:ITeam, options?: {background?:boolean}): Promise<void>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            await userApiClient.createCourseTeam(courseId, team);
        },

        async update(courseId:string, teamId:string, team:ITeam, options?: {background?:boolean}): Promise<void>
        {
            let userApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            await userApiClient.updateCourseTeam(courseId, teamId, team);
        },

        async delete(courseId:string, teamId:string, options?: {background?:boolean, bulk?: boolean}): Promise<void>
        {
            let userApiClient: IUserApiClient = options?.background ? useApiClientStore().backgroundUserApiClient : useApiClientStore().userApiClient;

            userApiClient = options?.bulk ? useApiClientStore().bulkUserApiClient : userApiClient;

            await userApiClient.deleteCourseTeam(courseId, teamId);
        },

    }
})