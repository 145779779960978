import { IInvoice } from "@cyber-range/cyber-range-api-invoice-client";
import IInvoiceTableDataItem from "../../interfaces/invoice/iInvoiceTableDataItem";
import InvoiceTableData, { courseItems } from "./invoiceTableData";
import { TableHeaderItem } from "@cyber-range/cyber-range-lib-ui";
import { useNumberFormats } from "../../composables/useNumberFormats";
import  $t  from "../../strings/definitions/billingStrings"

const { toCurrency } = useNumberFormats();

interface IStandardInvoiceTableDataItem extends IInvoiceTableDataItem
{
    cloudUsage: string,
    enrollments: string
}

export default class StandardInvoiceTableData extends InvoiceTableData
{
    headers = [
        new TableHeaderItem({text: $t.en.INVOICE_TABLE_DATA_NAME, key: 'name', sortable: false}),
        new TableHeaderItem({text: $t.en.INVOICE_TABLE_DATA_CLOUD_USAGE, key: 'cloudUsage', align: 'right', sortable: false}),
        new TableHeaderItem({text: $t.en.INVOICE_TABLE_DATA_ENROLLMENTS, key: 'enrollments', align: 'right', sortable: false}),
        new TableHeaderItem({text: $t.en.INVOICE_TABLE_DATA_TOTAL, key: 'totalPrice', align: 'right', sortable: false}),
    ];

    declare items: IStandardInvoiceTableDataItem[];

    /**
     * 
     * @param invoice The Invoice to be translated to table data
     * @param balance The activity balance at the end of the transaction
     */
    constructor(invoice: IInvoice, balance: number)
    {
        super(invoice, balance);
    }

    protected processCourseItems(courseItems: courseItems): IStandardInvoiceTableDataItem[]
    {
        const cloudUsageCost = courseItems.cloudUsageItems?.reduce((sum, item) => sum + item.totalPrice, 0) || 0;
        const enrollmentCost = courseItems.enrollmentItems?.reduce((sum, item) => sum + item.totalPrice, 0) || 0;
        const totalCost = cloudUsageCost + enrollmentCost;

        const courseItemName = courseItems.enrollmentItems?.find(item => !!item.name)?.name || courseItems.cloudUsageItems?.find(item => !!item.name)?.name || "";

        const courseItem: IStandardInvoiceTableDataItem = {
            name: courseItemName,
            cloudUsage: toCurrency(cloudUsageCost),
            enrollments: toCurrency(enrollmentCost),
            totalPrice: toCurrency(totalCost),
        }

        if (courseItems.waiveItems && courseItems.waiveItems.length > 0)
        {
            const waivedTotal = courseItems.waiveItems.reduce((sum, item) => sum + item.totalPrice, 0) || 0;
            const waivedItem: IStandardInvoiceTableDataItem = {
                name: `${courseItemName} - Waived`,
                cloudUsage: '',
                enrollments: '',
                totalPrice: toCurrency(waivedTotal),
            };

            return [courseItem, waivedItem];
        }

        return [courseItem];
    }
}
