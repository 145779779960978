<template>
    <form-section :label="t('SUPER_ADMIN_INFO_SECTION')" data-testing="super-admin-form-info-section" class="cr-mb3">
        <cr-text-field v-model="invitation.name" :label="t('SUPER_ADMIN_INFO_SECTION_NAME')" :disabled="isLoading" required :min-length="1" :max-length="100" data-testing="super-admin-form-name-input" class="cr-mb3 cr-mt3" />
        <cr-text-field v-model="invitation.email" :label="t('SUPER_ADMIN_INFO_SECTION_EMAIL')" type="email" :disabled="isLoading" required :min-length="1" :max-length="256" data-testing="super-admin-form-email-input" class="cr-mt3" />
    </form-section>
</template>

<script setup lang="ts">
import FormSection from '../layouts/sections/FormSection.vue';
import { reactive, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useI18n } from 'vue-i18n';
import { IInvitation } from '@cyber-range/cyber-range-api-invitation-client';

const props = defineProps<{
    modelValue:IInvitation,
}>();
const emit = defineEmits<{
    (e:'update:modelValue', invitation:IInvitation): void,
}>();

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

let invitation = reactive(props.modelValue);

watch(()=>({...invitation}), ()=>
{
    emit('update:modelValue', invitation);
});
</script>