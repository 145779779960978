<template>
    <cr-confirmation-dialog :title="t('BILLING_EDIT_ORGANIZATION_CONTRACT_TITLE')" @confirm="onDialogConfirmed" @cancel="onDialogCanceled">
        <cr-row>
            <cr-col xs12 sm6>
                <cr-date-picker v-model="contractStartDate" :max="contractExpirationDate" :label="t('BILLING_EDIT_ORGANIZATION_CONTRACT_START_LABEL')" data-testing="edit-organization-contract-start-date"/>
            </cr-col>
            <cr-col xs12 sm6>
                <cr-date-picker v-model="contractExpirationDate" :min="contractStartDate" :label="t('BILLING_EDIT_ORGANIZATION_CONTRACT_EXPIRATION_LABEL')" data-testing="edit-organization-contract-expiration-date"/>
            </cr-col>
        </cr-row>
    </cr-confirmation-dialog>
</template>

<script setup lang="ts">
import { IOrganization, Organization } from '@cyber-range/cyber-range-api-organization-client';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCalendar } from '../../../composables/useCalendar';
import { useOrganizationStore } from '../../../stores/organizationStore';

const { t } = useI18n();
const { toStartOfDayISOString, toEndOfDayISOString, toYYYYMMDD } = useCalendar()

const props = defineProps<
{
    organization: IOrganization;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void,
    (name: 'cancel'): void,
}>()

const contractStartDate = ref('')
const contractExpirationDate = ref('')

function syncContractStartDateWithProps()
{
    contractStartDate.value = props.organization.contractStartTimestamp ? toYYYYMMDD(new Date(props.organization.contractStartTimestamp)) : ''
}
watch(() => props.organization.contractStartTimestamp, () => syncContractStartDateWithProps(), { immediate: true });

function syncContractExpirationDateWithProps()
{
    contractExpirationDate.value = props.organization.contractExpirationTimestamp ? toYYYYMMDD(new Date(props.organization.contractExpirationTimestamp)) : ''
}
watch(() => props.organization.contractExpirationTimestamp, () => syncContractExpirationDateWithProps(), { immediate: true });

async function onDialogConfirmed()
{
    const contractStartTimestamp = contractStartDate.value ? toStartOfDayISOString(contractStartDate.value) : '';
    const contractExpirationTimestamp = contractExpirationDate.value ? toEndOfDayISOString(contractExpirationDate.value) : '';

    await useOrganizationStore().update(Organization.fromJson({
        id: props.organization.id,
        contractStartTimestamp,
        contractExpirationTimestamp,
    }));

    emit('confirm');
}

async function onDialogCanceled()
{
    syncContractStartDateWithProps();
    syncContractExpirationDateWithProps();
    emit('cancel');
}
</script>
