<template>
    <table-layout :title="t('VIEW_INVOICE_DIALOG_TITLE')" :breadcrumbs="breadcrumbs" :ready="ready">
        <template #top>
            <div ref="invoiceDetails">
                <cr-container>
                    <cr-row tag="dl" data-testing="invoice-detail-row">
                        <template v-for="detail of details">
                            <cr-col tag="dt" xs12 sm12 md12 lg3 xl2>{{ detail.key }}</cr-col>
                            <cr-col tag="dd" xs12 sm12 md12 lg9 xl10 class="cr-mb0">{{ detail.value }}</cr-col>
                        </template>
                    </cr-row>
                </cr-container>
            </div>
        </template>
        <template #table>
            <div ref="invoiceTable">
                <cr-table :headers="tableData?.headers" :items="tableData?.items">
                    <template v-slot:name="{value}">
                        {{ value }}
                        <cr-tooltip v-if="tooltips[value]" :title="tooltips[value]">
                            <cr-icon>bi-info-circle</cr-icon>
                        </cr-tooltip>
                    </template>
                    <template #footer>
                        <tr>
                            <td colspan="3" class="cr-font-bold">{{ t('VIEW_INVOICE_TOTAL_NAME') }}</td>
                            <td class="cr-text-right cr-font-bold">{{ toCurrency(tableData?.totalPrice || 0) }}</td>
                        </tr>
                    </template>
                </cr-table>
            </div>
        </template>
        <template #controls>
            <table-control-item>
                <cr-button @click="onPrintButtonClicked" outlined data-testing="invoice-print-button">
                    <cr-icon>bi-printer</cr-icon>
                    {{ t('VIEW_INVOICE_PRINT_BUTTON_TEXT') }}
                </cr-button>
            </table-control-item>
        </template>
    </table-layout>
</template>


<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../stores/apiClientStore';
import { computed, onMounted, ref, watch } from 'vue';
import { useNumberFormats } from '../../composables/useNumberFormats';
import { usePrinter } from '../../composables/usePrinter';
import { IInvoice } from '@cyber-range/cyber-range-api-invoice-client';
import { useInvoiceStore } from '../../stores/invoiceStore';
import FlatRateInvoiceTableData from '../../entities/invoice/flatRateInvoiceTableData';
import StandardInvoiceTableData from '../../entities/invoice/standardInvoiceTableData';
import TableLayout from '../layouts/TableLayout.vue';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import Route from '../../routers/route';
import { IOrganization } from '@cyber-range/cyber-range-api-organization-client';
import {  IActivity } from '@cyber-range/cyber-range-api-balance-client';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useBalanceStore } from '../../stores/balanceStore';
import TableControlItem from '../layouts/sections/TableControlItem.vue';


const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const { toCurrency } = useNumberFormats();
const { printElements } = usePrinter();
const { getOrganization } = useOrganizationStore();
const { getBalanceActivity } = useBalanceStore();
const { getInvoice } = useInvoiceStore();

const props = defineProps<
{
    activityId: string
    organizationId: string
}>();

const breadcrumbs = computed(()=>[
                            new BreadcrumbItem(Route.Organizations),
                            new BreadcrumbItem({...Route.Organization, text: organization.value?.name, params: {organizationId: props.organizationId}}),
                            new BreadcrumbItem({...Route.Billing, params: {organizationId: props.organizationId}}),
                            new BreadcrumbItem({...Route.Invoice, params: {organization: props.organizationId, activityId: props.activityId}})
                        ]);

const invoice = ref<IInvoice>();
const activity = ref<IActivity>();
const organization = ref<IOrganization>();
const balance = ref<number>();
const ready = ref(false);

onMounted(async () => 
{
    [organization.value, activity.value] = await Promise.all([getOrganization(props.organizationId), await getBalanceActivity(props.activityId)]);
    invoice.value = await getInvoice(activity.value!.referenceId);
    balance.value = activity.value?.balance;
    ready.value = true;
})

const EXERCISE_USAGE ='Exercise Environment Usage';
const OVERHEAD = 'Infrastructure Overhead';

const tooltips: Record<string, string> = {
    [EXERCISE_USAGE]: t('VIEW_INVOICE_EXERCISE_USAGE_TOOLTIP'),
    [OVERHEAD]: t('VIEW_INVOICE_OVERHEAD_TOOLTIP')
}
const tableData = computed(() =>
{
    if (!invoice.value || balance.value === undefined) return;

    const isFlatRateInvoice = !!invoice.value?.subscriptions?.length && 
            invoice.value?.subscriptions?.every(subscription => subscription.flatRate);
    
    if (isFlatRateInvoice)
    {
        return new FlatRateInvoiceTableData(invoice.value, balance.value);
    }
    else
    {
        return new StandardInvoiceTableData(invoice.value, balance.value);
    }
})

const details = computed(() =>
{
    return [
        {key: t('VIEW_INVOICE_INVOICE_ID'), value: invoice.value?.displayId},
        {key: t('VIEW_INVOICE_INVOICE_PERIOD'), value: t('VIEW_INVOICE_FROM_TO', {from: invoice.value?.period?.startDate, to: invoice.value?.period?.endDate})},
        {key: t('VIEW_INVOICE_STATUS'), value: invoice.value?.status},
    ]
});

const invoiceDetails = ref<HTMLElement>();
const invoiceTable = ref<HTMLElement>();
const onPrintButtonClicked = () =>
{
    if (invoiceDetails.value && invoiceTable.value)
    {
        printElements([invoiceDetails.value, invoiceTable.value]);
    }
}
</script>