<template>
    <table-layout :title="t('ORGANIZATION_APPLICATIONS_PAGE_TITLE')" :breadcrumbs="breadcrumbs">
        <template #table>
            <cr-table :items="items" :headers="headers" actionable :loading="isLoading">
                <template v-slot:action="{item}">
                    <cr-table-action-item v-if="canUpdateOrganizationApplication(organizationId)" :item="item" @click="onEditApplicationClicked" icon="bi-pencil" data-testing="organization-applications-table-edit-application-action">
                        {{ t('ORGANIZATION_APPLICATIONS_EDIT_APPLICATION_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="canDeleteOrganizationApplication(organizationId)" :item="item" @click="onDeleteApplicationClicked" icon="bi-trash" data-testing="organization-applications-table-delete-application-action">
                        {{ t('ORGANIZATION_APPLICATIONS_DELETE_APPLICATION_BUTTON') }}
                    </cr-table-action-item>
                </template>
            </cr-table>
        </template>
        <template #controls>
            <table-control-item v-if="canCreateOrganizationApplication(organizationId)">
                <cr-button :disabled="isLoading" :to="Route.CreateOrganizationApplication" outlined data-testing="create-organization-application-button">
                    <cr-icon>bi-plus</cr-icon>
                    {{ t('ORGANIZATION_APPLICATIONS_CREATE_APPLICATION_BUTTON') }}
                </cr-button>
            </table-control-item>
            <delete-organization-application-dialog v-model="showDeleteApplicationDialog" :application="selectedItem" :organization-id="organizationId" @confirm="onDeleteDialogConfirmed"/>
            <edit-organization-application-dialog v-model="showEditApplicationDialog" :application="selectedItem" :organization-id="organizationId" @confirm="onEditDialogConfirmed"/>
       </template>
    </table-layout>
</template>

<script setup lang="ts">
import { IOrganization, IOrganizationApplication, OrganizationApplicationFilter, OrganizationApplicationType } from '@cyber-range/cyber-range-api-organization-client';
import { BreadcrumbItem, TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useOrganizationStore } from '../../stores/organizationStore';
import TableLayout from '../layouts/TableLayout.vue';
import Route from '../../routers/route';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import DeleteOrganizationApplicationDialog from './dialogs/DeleteOrganizationApplicationDialog.vue';
import EditOrganizationApplicationDialog from './dialogs/EditOrganizationApplicationDialog.vue';
import { useCalendar } from '../../composables/useCalendar';
import TableControlItem from '../layouts/sections/TableControlItem.vue';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { canCreateOrganizationApplication, canDeleteOrganizationApplication, canUpdateOrganizationApplication} = useAuthorizationStore();
const { toYYYYMMDD } = useCalendar();

const props = defineProps<
{
   organizationId:string
}>();

const breadcrumbs = computed(()=>[
                            new BreadcrumbItem(Route.Organizations),
                            new BreadcrumbItem({...Route.Organization, text: organization.value?.name, params: {organizationId: props.organizationId}}),
                            new BreadcrumbItem({...Route.OrganizationApplications,text: t('ORGANIZATION_APPLICATIONS_PAGE_TITLE'), params: {organizationId: props.organizationId}})]);

const formatApplicationType = (value: OrganizationApplicationType) =>
{
    switch (value) {
        case OrganizationApplicationType.Lti13:
            return t('ORGANIZATION_APPLICATIONS_TYPE_LTI');
        default:
            return value;
    }
}
                            
const headers = [
    new TableHeaderItem({ text: t('ORGANIZATION_APPLICATIONS_APPLICATION_ID'), key: 'id', sortable: false}),
    new TableHeaderItem({ text: t('ORGANIZATION_APPLICATIONS_NAME'), key: 'name', sortable: false}),
    new TableHeaderItem({ text: t('ORGANIZATION_APPLICATIONS_TYPE'), key: 'type', sortable: false, formatter: formatApplicationType}),
    new TableHeaderItem({ text: t('ORGANIZATION_APPLICATIONS_CLIENT_ID'), key: 'clientId', sortable: false}),
    new TableHeaderItem({text: t('ORGANIZATION_APPLICATIONS_CREATED'), key: 'createdTimestamp', formatter: (isoString) => toYYYYMMDD(new Date(isoString))})
];

let selectedItem = ref<IOrganizationApplication>();
let items = ref<IOrganizationApplication[]>();
let organization = ref<IOrganization>();

const refresh = async () =>
{
    [items.value, organization.value] = await Promise.all([useOrganizationStore().listAllOrganizationApplications(new OrganizationApplicationFilter({organizationId: props.organizationId})), useOrganizationStore().getOrganization(props.organizationId)]);
}

onMounted(async () => 
{
    await refresh();
    if (organization.value)
    {
        await useOrganizationStore().fetchOrganizationNameAndLogo([organization.value]);
    }
});

const showEditApplicationDialog = ref<boolean>(false);
const showDeleteApplicationDialog = ref<boolean>(false);

const onDeleteDialogConfirmed = async () =>
{
    await refresh();
}

const onEditDialogConfirmed = async () =>
{
    await refresh();
}

const onEditApplicationClicked = (application: IOrganizationApplication) =>
{
    selectedItem.value = application;
    showEditApplicationDialog.value = true;
}

const onDeleteApplicationClicked = (application: IOrganizationApplication) =>
{
    selectedItem.value = application;
    showDeleteApplicationDialog.value = true;
}
</script>