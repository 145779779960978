import { Organization, IOrganization, IOrganizationCustomAttribute, InternalCustomAttributeName, InternalCustomAttributeValue, OrganizationCustomAttribute, OrganizationCustomAttributeName } from "@cyber-range/cyber-range-api-organization-client";
import { computed, reactive } from "vue";
import { useEnum } from "./useEnum";

export function useOrganizationCustomAttributes()
{
    const { toDisplayEnumName,toSelectItems } = useEnum();
    let unmappedCustomAttributes:IOrganizationCustomAttribute[] = []; // to support ignoring attributes that are not included in the enum;
    let unmappedInternalCustomAttributes:IOrganizationCustomAttribute[] = []; // to support ignoring internal attributes that not included in the enum;

    // load custom attributes into reactive that is programmatically referenced by the form text fields for custom attributes
    const customAttributeEnumValues = Object.values(OrganizationCustomAttributeName);
    const customAttributes = reactive<Record<OrganizationCustomAttributeName, {label:string, value:string} >>({} as any);
    const loadCustomAttributes = (organization:IOrganization) =>
    {
        for(let value of customAttributeEnumValues)
        {
            customAttributes[value] = { label: toDisplayEnumName(OrganizationCustomAttributeName, value), value: Organization.getOrganizationCustomAttributeValue(organization,value) }
        }
    };
    // load internal custom attributes into reactive that is programmatically referenced by the form cr-selects for internal custom attributes
    const internalAttributeEnumValues = Object.values(InternalCustomAttributeName);
    const internalCustomAttributes = reactive<Record<InternalCustomAttributeName, {label:string, value:string} >>({} as any);
    const internalAttributeSelectItems = toSelectItems(InternalCustomAttributeValue); // select items

    const loadInternalCustomAttributes = (organization:IOrganization) =>
    {
        for(let value of internalAttributeEnumValues)
        {
            internalCustomAttributes[value] = { label: toDisplayEnumName(InternalCustomAttributeName, value), value: Organization.getInternalCustomAttributeValue(organization,value) }
        }
    }; 
    const loadAttributes = (organization:IOrganization) => // to simplify load in component
    {
        unmappedCustomAttributes = [...(organization.customAttributes || []).filter(ca => !(customAttributeEnumValues).includes(ca.name as OrganizationCustomAttributeName))];
        unmappedInternalCustomAttributes = [...(organization.internalCustomAttributes || []).filter(ica => !(internalAttributeEnumValues).includes(ica.name as InternalCustomAttributeName))];
        loadCustomAttributes(organization);
        loadInternalCustomAttributes(organization);
    }

    //reactivity
    const attributeValues = computed(()=> // to support watching changes to any field in the form
    {
        return JSON.stringify({...customAttributes,...internalCustomAttributes})
    })
    
    const setOrganizationAttributes = (organization:IOrganization)=> // maps form field values to modelValue
    {
        let customAttributesPayload:IOrganizationCustomAttribute[] = [];
        for(let name of customAttributeEnumValues)
        {
            const value = customAttributes[name]?.value
            if(!!value)
            {
                customAttributesPayload.push(new OrganizationCustomAttribute(name,value))
            }
        }
        organization.customAttributes = [...customAttributesPayload, ...unmappedCustomAttributes];

        let internalCustomAttributesPayload:IOrganizationCustomAttribute[] = [];
        for(let name of internalAttributeEnumValues)
        {
            const value = internalCustomAttributes[name]?.value
            if(!!value)
            {
                internalCustomAttributesPayload.push(new OrganizationCustomAttribute(name,value))
            }
        }
        organization.internalCustomAttributes = [...internalCustomAttributesPayload,...unmappedInternalCustomAttributes];
    }
    return {
        customAttributeEnumValues, // supports v-for to make fields for custom attributes 
        loadAttributes, // sets initial values, loads reactive objects 
        customAttributes, // reactive mapping for form fields
        internalAttributeEnumValues, // supports v-for to make selects for internal custom attributes 
        internalCustomAttributes, // reactive mapping for form selects
        internalAttributeSelectItems, // populates drop downs for internal attributes
        attributeValues, // support a watcher for changes in anything
        setOrganizationAttributes // map fields back to model value
    }
}