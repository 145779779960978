<template>
    <form-layout :title="t('SUPPORT_TITLE')" :breadcrumbs="breadcrumbs" :ready="isReady" @confirm="onSupportFormConfirmed" @cancel="onCancel">
        <template #form>
            <support-form :courses="supportedCourses" :organizations="supportedOrganizations" @update:model-value="onSupportFormUpdated"/>
        </template>        
    </form-layout> 
    <support-recipients-dialog v-model="showRecipientsDialog" :recipients="recipients" @confirm="onSupportRecipientsConfirmed"/>
    <no-support-topics-dialog v-model="showNoSupportedTopicsDialog"/>
</template>

<script setup lang="ts">
import FormLayout from '../layouts/FormLayout.vue';
import NoSupportTopicsDialog from './dialogs/NoSupportTopicsDialog.vue';
import SupportRecipientsDialog from './dialogs/SupportRecipientsDialog.vue';
import Route from '../../routers/route';
import SupportForm from './SupportForm.vue';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { IOrganization } from '@cyber-range/cyber-range-api-organization-client';
import { ICourse } from '@cyber-range/cyber-range-api-user-client';
import { ISupportRecipient } from '../../interfaces/iSupportRecipient'
import { useSupportStore } from '../../stores/supportStore';
import { ISupportMessage } from '../../interfaces/iSupportMessage';
import { SupportTopic } from '../../interfaces/supportTopic';
import { SelectItem } from '../../composables/useEnum';
import { useRouter } from 'vue-router';

const router = useRouter();

const { t } = useI18n();

const isReady = ref(false);

const breadcrumbs = computed(()=> [new BreadcrumbItem(Route.Support)]);

const supportedCourses = ref<ICourse[]>([]);

const supportedOrganizations = ref<IOrganization[]>([]);

const supportMessage = ref<Partial<ISupportMessage>>({});

const recipients = ref<ISupportRecipient[]>([]);

const showRecipientsDialog = ref<boolean>(false);

const showNoSupportedTopicsDialog = ref<boolean>(false);

const findTopicName = ():string =>
{
    const items:SelectItem[] = supportMessage.value.topic === SupportTopic.Course
        ? supportedCourses.value
        : supportedOrganizations.value;

    return items.find((item:SelectItem)=> item.id === supportMessage.value.topicId)?.name as string;
}

const onSupportFormConfirmed = async ()=>
{
    supportMessage.value.topicName = findTopicName();
    recipients.value = await (useSupportStore().requestSupport(supportMessage.value));
    showRecipientsDialog.value = true;
}

const onSupportFormUpdated = (eventUpdatedMessage:Partial<ISupportMessage>)=>
{
    supportMessage.value = eventUpdatedMessage;
}

const onCancel = ()=>
{
    router.back();
}

const onSupportRecipientsConfirmed = () =>
{
    router.back();
}

onMounted(async ()=>
{   
    [supportedOrganizations.value, supportedCourses.value] = await Promise.all(
        [
            (useSupportStore().getSupportedOrganizations()),
            (useSupportStore().getSupportedCourses())
        ]);    

    isReady.value = true;
    if(supportedOrganizations.value.length === 0 && supportedCourses.value.length === 0)
    {
        showNoSupportedTopicsDialog.value = true;
    }
});
</script>