<template>
    <cr-snackbar v-if="isImpersonating" class="impersonation-snackbar" :message="t('IMPERSONATION_BAR_MESSAGE')" location="bottom" data-testing="impersonation-bar">
        <cr-button @click.prevent="onExitClicked" background-color="cr-white" text-color="cr-black" small text data-testing="impersonation-bar-exit-button">
            {{t('IMPERSONATION_BAR_BUTTON_TEXT')}}
        </cr-button>
    </cr-snackbar>
</template>

<style>
.cr-snackbar-body {
    padding-top: .1rem; /* Reduce default padding to avoid overlap with pagination */
    padding-bottom: .1rem;
}
</style>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useSessionStore } from '../../stores/sessionStore';
import { useAuthenticationStore } from '../../stores/authenticationStore';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n();
const { isImpersonating } = storeToRefs(useSessionStore());
const router = useRouter();

const onExitClicked = async () => 
{
    await useAuthenticationStore().exitImpersonation();

    router.go(0);
}
</script>