import Config from '../config';
import axios from "axios";
import t from '../strings/definitions/googleClassroomStrings';

export function classroomScopes():string[]
{
    return [
        'https://www.googleapis.com/auth/classroom.profile.emails',
        'https://www.googleapis.com/auth/classroom.rosters.readonly',
        'https://www.googleapis.com/auth/classroom.courses.readonly'
    ];
}

export interface IHttpClient
{
    get(url:string):Promise<any>;
}

class AxiosHttpClient implements IHttpClient
{
    constructor(){};

    get(url:string):Promise<any>
    {
        return axios.get(url)
    }
}

export interface IGoogleClassroomClass
{
    id:string;
    name:string;
    description:string;
}

export function listUserGoogleClassrooms(accessToken:string, client = new AxiosHttpClient()):Promise<IGoogleClassroomClass[]>
{   
    // GET https://classroom.googleapis.com/v1/courses
    // API reference: https://developers.google.com/classroom/reference/rest/v1/courses/list

    return new Promise((resolve, reject) => 
    {
        let courseMap = new Map<string,IGoogleClassroomClass>();
        client
            .get(`${Config.GOOGLE_CLASSROOM_URL}/v1/courses?access_token=${accessToken}`)
            .then(response => {
                if(!response.data.courses || response.data.courses.length === 0)
                {
                    return reject(new Error(t.en.GOOGLE_CLASSROOM_LIST_CLASSROOMS_ERROR_NO_COURSES));
                }
                else
                {
                    for (const course of response.data.courses)
                    {
                        courseMap.set(course.id,{
                            name: course.name,
                            description: course.descriptionHeading,
                            id: course.id
                        });
                    }
                }
                return resolve([...courseMap.values()]);
            })
            .catch((e)=>
            {
                return reject(new Error(t.en.GOOGLE_CLASSROOM_LIST_CLASSROOMS_ERROR_FAILED));
            });
    });
}

function getClassroomStudentsPage(classroomId:string, accessToken:String, pageToken?:string, client = new AxiosHttpClient()):Promise<IListClassroomStudentsPage>
{
    return new Promise((resolve, reject) => {
        client
            .get(`${Config.GOOGLE_CLASSROOM_URL}/v1/courses/${classroomId}/students?access_token=${accessToken}&pageSize=${Config.GOOGLE_CLASSROOM_DEFAULT_PAGE_SIZE}&pageToken=${(pageToken || "")}`)
            .then(response=>{
                if(response.data.error)
                {
                    return reject(new Error(t.en.GOOGLE_CLASSROOM_LIST_STUDENTS_ERROR_FAILED));
                }
                return resolve(response as IListClassroomStudentsPage);
            })
            .catch(()=>{
                return reject(new Error(t.en.GOOGLE_CLASSROOM_LIST_STUDENTS_ERROR_FAILED));
            })
    });
}

interface IListClassroomStudentsPage
{
    data:{
        nextPageToken:string | undefined;
        students:{
            profile:{
                name:{
                    fullName:string;
                },
                emailAddress:string;
            }
        }[]
    }
}


export async function listGoogleClassroomStudents(classroomId:string, accessToken:string, client = new AxiosHttpClient()):Promise<{name:string,email:string,role:string}[]> {
    // GET https://classroom.googleapis.com/v1/courses/{courseId}/students
    // API Reference: https://developers.google.com/classroom/reference/rest/v1/courses.students/list
    
    let studentsMap = new Map<string,{name:string,email:string,role:string}>();
    let nextPageToken:string|undefined;
    do
    {
        let listStudentsResponse:IListClassroomStudentsPage = await getClassroomStudentsPage(classroomId,accessToken,nextPageToken,client);
        nextPageToken = listStudentsResponse?.data?.nextPageToken;
        if(listStudentsResponse.data.students.length === 0)
        {
            throw new Error(t.en.GOOGLE_CLASSROOM_LIST_STUDENTS_ERROR_NO_STUDENTS)
        }
        for (const student of listStudentsResponse.data.students)
        {
            const user = {
                name: student.profile.name.fullName,
                email: student.profile.emailAddress,
                role: "student"
            };
            studentsMap.set(user.email,user);
        }
    }while(nextPageToken)

    return [...studentsMap.values()];
}