import { defineStore } from "pinia";
import { useApiClientStore } from "./apiClientStore";
import { IJob } from "@cyber-range/cyber-range-api-job-client";
import { IRosterEntry } from "@cyber-range/cyber-range-api-roster-client";

export const useJobStore = defineStore('jobStore', 
{
    actions: 
    {
        async startExercises(exerciseId: string[]): Promise<string>
        {
            const { jobApiClient, bulkExerciseApiClient: bulkExerciseClient } = useApiClientStore()

            for (const id of exerciseId)
            {
                await bulkExerciseClient.start(id);
            }
            return await jobApiClient.submit();
        },

        async getJob(jobId: string, options?: {background?:boolean}): Promise<IJob>
        {
            let jobApiClient = options?.background ? useApiClientStore().backgroundJobApiClient : useApiClientStore().jobApiClient;
            return await jobApiClient.getOne(jobId);
        },

        async submitJob(): Promise<string>
        {
            let jobApiClient = useApiClientStore().jobApiClient;

            return await jobApiClient.submit();
        }
    }
});
