import { defineStore } from 'pinia';
import { useApiClientStore } from './apiClientStore';
import { IInvoice, InvoiceStatus } from '@cyber-range/cyber-range-api-invoice-client'
export const useInvoiceStore = defineStore('InvoiceStore', 
{
    actions: 
    {
        async getInvoice(invoiceId: string): Promise<IInvoice>
        {
            let invoiceApiClient = useApiClientStore().invoiceApiClient;

            return await invoiceApiClient.getOne(invoiceId);
        },

        async updateDisplayId(invoiceId: string, displayId: string): Promise<void>
        {
            let invoiceApiClient = useApiClientStore().invoiceApiClient;

            await invoiceApiClient.updateDisplayId(invoiceId, displayId);
        },

        async updateStatus(invoiceId: string, status: InvoiceStatus, notify?: boolean): Promise<void>
        {
            let invoiceApiClient = useApiClientStore().invoiceApiClient;

            await invoiceApiClient.updateStatus(invoiceId, status, notify);
        },

        async estimateUserDirectPayCourse(organizationId: string, courseStart: string, courseEnd: string): Promise<number>
        {
            let invoiceApiClient = useApiClientStore().invoiceApiClient;

            return await invoiceApiClient.estimateUserDirectPayCourse(organizationId, courseStart, courseEnd);
        }
    }
});