import { IApiPageResponse, IFilter, SortOrder } from "@cyber-range/cyber-range-api-client";
import { ITableHeaderItem } from "@cyber-range/cyber-range-lib-ui";
import { reactive, shallowRef, toRaw, watch } from "vue";
import Config from "../config";

interface ISortableFilter extends IFilter
{
    sortOrder?: SortOrder,
    sortBy?: string
}

export function useTableLayoutPagination<T, F extends ISortableFilter>(queryFilter: Partial<F>, refreshFn: (filter: Partial<F>) => Promise<IApiPageResponse<T>>, options?: { clearTokenKeys?: Array<keyof F> })
{
    const filter = reactive<Partial<F>>({
        limit: Config.DEFAULT_TILE_LISTING_PAGE_SIZE,
        ...queryFilter
    }) as Partial<F>;

    const page = shallowRef<IApiPageResponse<T>>();

    const reload = (data: Partial<IFilter>) => Object.assign(queryFilter, {...filter, ...data});

    const refresh = async () => page.value = await refreshFn(toRaw(filter))
    watch(() => options?.clearTokenKeys?.map(key => filter[key]), () => filter.token &&= '');
    watch(()=>filter, () => refresh(), { deep: true });

    const onSuggestedNumberOfItems = (n: number) => filter.limit = n;
    const onLoadPreviousPage = () => reload({ token: page.value?.prevPageToken || '' });
    const onLoadNextPage = () => reload({ token: page.value?.nextPageToken || '' });
    const onLoadFirstPage = () => reload({ token: filter.token === '' ? undefined : '' });
    const onSort = (header:ITableHeaderItem) => Object.assign(filter, { sortOrder: header.sortOrder as SortOrder, sortBy: header.key?.toLowerCase(), token: "" });

    return {
        page,
        filter,
        onSuggestedNumberOfItems,
        onLoadPreviousPage,
        onLoadNextPage,
        onLoadFirstPage,
        onSort,
    };
}
