<template>
    <cr-filter icon="bi-diagram-3" :label="props.label || t('ORGANIZATION_FILTER_LABEL')" :selected-values="selectedOrganizationName" :disabled="isLoading" class="organization-filter" data-testing="organization-filter">
        <cr-autocomplete v-model='selectedOrganizationId' :label="props.label || t('ORGANIZATION_FILTER_LABEL')" :items="organizations" :disabled="isLoading" :required="required"/>
    </cr-filter>
</template>

<style scoped>
.cr-filter:deep(.cr-dropdown-toggle),
.cr-filter:deep(.cr-dropdown-list)
{
    min-width: 12rem;
}
</style>

<script setup lang="ts">
import { IOrganization } from '@cyber-range/cyber-range-api-organization-client';
import { computed, ref, watch } from 'vue';
import { useApiClientStore } from '../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

const props = defineProps<
{
    /**
     * Filterable organizations
     */
    organizations: IOrganization[];

    /**
     * Selected organization's ID
     */
    modelValue: string | undefined;

    /**
     * Optional custom label
     */
    label?: string;

    /**
     * Whether the autocomplete in this filter is required
     */
    required?: boolean;
}>();

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());


const selectedOrganizationId = computed(
{
    get: () => props.modelValue,
    set: (value: string|undefined) =>  emit('update:modelValue', value)
});

const selectedOrganizationName = computed(() =>
{
    return props.organizations?.filter(o => o.id === selectedOrganizationId.value).map(o => o.name);
})

const emit = defineEmits(['update:modelValue']);

</script>