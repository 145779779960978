<template>
    <cr-checkbox v-for="addonId of featureAddonIds" v-model="modelValues[addonId]" :name="featureName(addonId)" :label="featureName(addonId)" :disabled="isRequired(addonId)" :data-testing="`feature-picker-${addonId}`"/>
</template>

<script setup lang="ts">
import {  computed, ref, watch } from 'vue';
import { IPlan } from '../../interfaces/iPlan';
import { IFeature } from '../../interfaces/iFeature';

const props = defineProps<
{
    plan?: IPlan,
}>();

const model = defineModel<string[]>();

const allFeaturesSorted = computed(() => 
{
    const features = [...(props.plan?.features || []), ...(props.plan?.optionalFeatures || [])];

    const byName = (feature1: IFeature, feature2: IFeature) => (feature1.name || "").localeCompare((feature2.name || ""), undefined, { sensitivity: 'base', numeric: true });
    return features.sort(byName);
});

const featureAddonIds = computed(() => allFeaturesSorted.value.map(feature => feature.addonId!).filter(id => !!id));

const isRequired = (addonId: string) => !!props.plan?.features.find(f => f.addonId === addonId);
const featureName = (addonId: string) => allFeaturesSorted.value.find(feature => feature.addonId === addonId)?.name || "";

const toModelValues = (values: string[]|undefined) =>
{
    const result: Record<string,boolean> = {};

    if (values === undefined)
    {
        return result;
    }

    for(let feature of allFeaturesSorted.value)
    {
        if (feature.addonId)
        {
            result[feature.addonId] = values.includes(feature.addonId) || false;
        }
    }

    return result;
}

const modelValues = ref(toModelValues(model.value));

watch(modelValues.value, (values)=>
{
    // Also emits via defineModel macro
    model.value = Object.keys(values).filter(key => values[key]);
})

watch(() => model.value, (value) =>
{
    const newModelValues = toModelValues(value);
    Object.keys(newModelValues).forEach(key => modelValues.value[key] = newModelValues[key]);
})

watch(() => props.plan, () =>
{
    // Cleanup any modelValues from a previous plan
    Object.keys(modelValues.value).forEach(key =>
    {
        if (!featureAddonIds.value.includes(key))
        {
            delete modelValues.value[key];
        }
    });
})

</script>