<template>
    <form-section v-if="showSection" :label="t('BUSINESS_UNIT_PICKER_SECTION_LABEL')">
        <cr-radio v-if="showBusinessUnitToggle" v-model="selectedBusinessUnitOption" name="opt" class="cr-mt1 cr-mb2" data-testing="business-unit-radio">
            <cr-radio-item :value="BusinessUnitOptions.SELECTING">{{ t('BUSINESS_UNIT_PICKER_SELECTING_RADIO_ITEM') }}</cr-radio-item>
            <cr-radio-item :value="BusinessUnitOptions.CREATING">{{ t('BUSINESS_UNIT_PICKER_CREATING_RADIO_ITEM') }}</cr-radio-item>
        </cr-radio>

        <cr-select v-if="showBusinessUnitDropdown" v-model="selectedBusinessUnitId" :label="t('BUSINESS_UNIT_PICKER_SELECT_LABEL')" :items="selectableBusinessUnits" item-label="display" required data-testing="business-unit-dropdown"/>
        <business-unit-form v-else v-model="businessUnit"/>
    </form-section>
</template>

<script lang="ts" setup>
import { BusinessUnit } from '@cyber-range/cyber-range-api-business-unit-client';
import { IBusinessUnit } from '@cyber-range/cyber-range-api-business-unit-client/dist/interfaces/iBusinessUnit';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useBusinessUnitStore } from '../../stores/businessUnitStore';
import BusinessUnitForm from '../businessUnits/BusinessUnitForm.vue';
import FormSection from '../layouts/sections/FormSection.vue';

const { t } = useI18n();
const authorizationStore = useAuthorizationStore();
const businessUnitStore = useBusinessUnitStore();

const props = defineProps<
{
    modelValue: IBusinessUnit;
    organizationId: string;
}>();

const emit = defineEmits<{
    (name: 'update:modelValue', value: IBusinessUnit): void
}>();


const businessUnit = computed(
    {
        get: () => props.modelValue,
        set: (unit: IBusinessUnit) => emit('update:modelValue', new BusinessUnit(unit)) // strip display prop with constructor
    }
);

const selectedBusinessUnitId = ref<string>();
const selectableBusinessUnits = computed(() =>
{
    const result = businessUnitStore.businessUnitsByOrganizationId.get(props.organizationId) || [];
    return result.map(bu => ({ ...bu, display: `${bu.name} : ${bu.region}` }))
                 .sort((a, b) => a.display.localeCompare(b.display, undefined, { sensitivity: 'base' }));
});

const enum BusinessUnitOptions {
    SELECTING = 'selecting',
    CREATING = 'creating',
}
const selectedBusinessUnitOption = ref(BusinessUnitOptions.SELECTING);

const canCreateBusinessUnit = computed (() => authorizationStore.canCreateBusinessUnit(props.organizationId));
const hasSelectableBusinessUnits = computed<boolean>(() => selectableBusinessUnits.value.length > 0)
const showSection = computed<boolean>(() => canCreateBusinessUnit.value || hasSelectableBusinessUnits.value);
const showBusinessUnitToggle = computed<boolean>(() => canCreateBusinessUnit.value && hasSelectableBusinessUnits.value);
const showBusinessUnitDropdown = computed<boolean>(() => hasSelectableBusinessUnits.value && selectedBusinessUnitOption.value === BusinessUnitOptions.SELECTING);


watch(() => selectedBusinessUnitId.value, () => setSelectedBusinessUnit());
function setSelectedBusinessUnit()
{
    const selectedBusinessUnit = selectableBusinessUnits.value.find(bu => bu.id === selectedBusinessUnitId.value);
    if (selectedBusinessUnit !== undefined)
    {   
        businessUnit.value = selectedBusinessUnit;
    }
}

watch(selectableBusinessUnits, () => 
{
    if (selectableBusinessUnits.value.length === 1)
    {
        selectedBusinessUnitId.value = selectableBusinessUnits.value[0].id;
    }
}, { immediate: true });

watch(selectedBusinessUnitOption, () =>
{
    if (selectedBusinessUnitOption.value === BusinessUnitOptions.SELECTING)
    {
        setSelectedBusinessUnit()
    }
    else
    {
        businessUnit.value = new BusinessUnit();
    }
})

onMounted(async () =>
{
    await useBusinessUnitStore().fetchBusinessUnits();
});
</script>
