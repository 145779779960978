<template>
    <cr-sidebar :label="t('SIDEBAR_LABEL')" class="sidebar">
        <Admin />
        <MyCourses v-if="canListCourses()"/>
        <Courseware />
        <Help />
    </cr-sidebar>
</template>

<style scoped>
.sidebar :deep(a)
{
    white-space: normal !important;
    display: flex;
    gap: 1rem;
}
</style>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import MyCourses from './items/MyCourses.vue';
import Help from './items/Help.vue';
import Courseware from './items/Courseware.vue';
import Admin from './items/Admin.vue';
import { useAuthorizationStore } from '../../stores/authorizationStore';

const { t } = useI18n();

const { canListCourses } = useAuthorizationStore();

</script>