<template>
    <cr-confirmation-dialog :title="t('SCHEDULE_HISTORY_DETAILS_TITLE')" :confirm-text="t('SCHEDULE_HISTORY_DETAILS_CONFIRM_TEXT')" :cancel="false" data-testing="schedule-history-details-dialog">
        <cr-row v-for="detail of details" tag="dl" class="history-detail-row cr-mb1">
            <cr-col tag="dt"  xs12 sm12 md12 lg3 xl3 class="history-detail-title">{{ detail.key }}</cr-col>
            <cr-col tag="dd" xs12 sm12 md12 lg9 xl9 class="history-detail-description cr-mb0">{{ detail.value }}</cr-col>
        </cr-row>
        <cr-table :items="paginator.displayItems" :headers="headers"/>
        <cr-pagination v-if="paginator.hasMultiplePages" :previous="paginator.showPrevious" :next="paginator.showNext" @previous="onPrevious" @next="onNext" :loading="isLoading" data-testing="schedule-history-details-pagination"/>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { computed, ref, watch } from 'vue';
import { IScheduleHistory } from '@cyber-range/cyber-range-api-sync-client';
import { TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import IPaginator from '../../../interfaces/iPaginator';
import Paginator from '../../../entities/paginator';
import Config from '../../../config';
import { useCalendar } from '../../../composables/useCalendar';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    history?: IScheduleHistory
}>();

const details = computed(() =>
{
    return [
        {key: t('SCHEDULE_HISTORY_DETAILS_TIMESTAMP'), value: useCalendar().toHuman(props.history?.createdTimestamp)},
        {key: t('SCHEDULE_HISTORY_DETAILS_DURATION'), value: useCalendar().toHumanDuration(props.history?.durationInMs)},
        {key: t('SCHEDULE_HISTORY_DETAILS_STATUS'), value: props.history?.status},
        {key: t('SCHEDULE_HISTORY_DETAILS_MESSAGE'), value: props.history?.message},
    ]
})

const headers = [
    new TableHeaderItem({ text:t('SCHEDULE_HISTORY_TABLE_ACTION'), key: 'action', sortable: false}),
    new TableHeaderItem({ text:t('SCHEDULE_HISTORY_TABLE_USER'), key: 'user', sortable: false}),
]

const items = computed(() => 
{
    return [
        ...(props.history?.changes.added.map(user => ({ action: t('SCHEDULE_HISTORY_TABLE_ADD'), user})) || []),
        ...(props.history?.changes.updated.map(user => ({ action: t('SCHEDULE_HISTORY_TABLE_UPDATE'), user})) || []),
        ...(props.history?.changes.deleted.map(user => ({ action: t('SCHEDULE_HISTORY_TABLE_DELETE'), user})) || [])
    ];
})

const pageSize = ref<number>(Config.DEFAULT_DIALOG_TABLE_PAGE_SIZE);

const paginator = ref<IPaginator<{action: string, user: string}>>(new Paginator<{action: string, user: string}>(items.value,pageSize.value));

watch(() => items.value, () =>
{
    paginator.value = new Paginator<{action: string, user: string}>(items.value,pageSize.value);
})

const onNext = ()=>
{
    paginator.value.next();
}

const onPrevious = ()=>
{
    paginator.value.previous();
}
</script>