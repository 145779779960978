<template>
    <cr-confirmation-dialog id="editOrganizationInvitationDialog" :title="t('ORGANIZATION_USERS_EDIT_INVITATION_DIALOG_TITLE')" @confirm="onConfirmed" @shown="onShown" :disabled="isLoading" data-testing="edit-organization-invitation-dialog">
        <organization-user-form v-model="entry" :organization-id="organizationId" :key="key"/>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import IOrganizationUserRoleView from '../../../interfaces/iOrganizationUserRoleView';
import { useInvitationStore } from '../../../stores/invitationStore';
import OrganizationUserForm from './OrganizationUserForm.vue';
import OrganizationUserRoleView from '../../../entities/organizationUserRoleView';
import { ref, watch } from 'vue';
import { Invitation } from '@cyber-range/cyber-range-api-invitation-client';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const entry = ref<IOrganizationUserRoleView>(new OrganizationUserRoleView({}));
const key = ref<number>(0);


const props = defineProps<
{
    organizationId: string;
    invitation?: IOrganizationUserRoleView;
}>();


const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const populateData = ()=>
{
    if(props.invitation)
    {
        entry.value.id = props.invitation.id;
        entry.value.name = props.invitation.name;
        entry.value.email = props.invitation.email;
        entry.value.roles = props.invitation.roles;
        entry.value.targetType = props.invitation.targetType;
    }
};

watch(()=>props.invitation, populateData, {immediate:true});

const onConfirmed = async ()=>
{
    if (!props.invitation) return;

    const payload = Invitation.fromJson({name: entry.value.name, email: entry.value.email, roles: entry.value.roles});

    await useInvitationStore().update(entry.value.id, payload);

    emit('confirm');
}

const onShown = async ()=>
{
    key.value++;
    populateData();
};
</script>