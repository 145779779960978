<template>
    <form-section v-if="canUpdateUser(courseId, organizationId) && !entry.isAnonymous" :label="t('COURSE_USER_FORM_INFO_SECTION')" data-testing="course-user-form-info-section" class="cr-mb5">
        <cr-text-field v-model="modelValue.name" :label="t('COURSE_USER_FORM_NAME')" :disabled="isLoading" required :max-length="100" id="course-user-form-name" data-testing="course-user-form-name" class="cr-mt3"/>
        <cr-text-field v-model="modelValue.email" :label="t('COURSE_USER_FORM_EMAIL')" type="email" :disabled="isLoading" required :max-length="256" id="course-user-form-email" data-testing="course-user-form-email" class="cr-mt3"/>
    </form-section>
    <form-section v-if="canUpdateUserCourseRole(courseId, organizationId)" :label="t('COURSE_USER_FORM_ROLE_SECTION')" data-testing="course-user-form-role-section" class="cr-mb5">
        <cr-select v-model="modelValue.role" :items="availableRoles" :label="t('COURSE_USER_FORM_ROLE')"  :disabled="isLoading" required id="course-user-form-role" data-testing="course-user-form-role" />
    </form-section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import FormSection from '../../layouts/sections/FormSection.vue';
import { IRosterEntry } from '@cyber-range/cyber-range-api-roster-client';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useAuthorizationStore } from '../../../stores/authorizationStore';
import { UserRole } from '@cyber-range/cyber-range-api-user-client';
import { useEnum } from '../../../composables/useEnum';

const props = defineProps<{
    modelValue:IRosterEntry,
    courseId:string,
    organizationId?:string
}>();
const emit = defineEmits<{
    (e:'update:modelValue', entry:IRosterEntry): void,
}>();

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { canInvite, canUpdateUserCourseRole, canUpdateUser } = useAuthorizationStore();

let entry = ref<IRosterEntry>(props.modelValue);

watch(()=>({...entry.value}), ()=>
{
    emit('update:modelValue', entry.value);
});

const availableRoles = computed(()=>
{
    let results = [];
    
    if(canInvite(UserRole.Student, props.courseId, props.organizationId))
    {
        results.push(UserRole.Student);
    }
    if(canInvite(UserRole.TA, props.courseId, props.organizationId))
    {
        results.push(UserRole.TA);
    } 
    if(canInvite(UserRole.Instructor, props.courseId, props.organizationId) && !entry.value.isAnonymous)
    {
        results.push(UserRole.Instructor);
    }
    
    return useEnum().toSelectItems(UserRole, results);
});
</script>