<template>
    <form-section v-if="canUpdateLimits" collapsible :label="t('ORGANIZATION_FORM_LIMITS_SECTION_LABEL')">
        <cr-slider :label="t('ORGANIZATION_DIALOG_MAX_EXERCISE_GROUPS_PER_COURSE')" v-model="limitInputs.maxExerciseGroupsPerCourse" :min="0" :max="customOrganizationLimits.maxExerciseGroupsPerCourse?.max ?? 0" />
        <cr-slider :label="t('ORGANIZATION_DIALOG_MAX_INSTRUCTORS_PER_COURSE')" v-model="limitInputs.maxInstructorsPerCourse" :min="0" :max="customOrganizationLimits.maxInstructorsPerCourse?.max ?? 0" />
        <cr-slider :label="t('ORGANIZATION_DIALOG_MAX_TAS_PER_COURSE')" v-model="limitInputs.maxTasPerCourse" :min="0" :max="customOrganizationLimits.maxTasPerCourse?.max ?? 0" :aria-label="t('ORGANIZATION_DIALOG_MAX_TAS_PER_COURSE_ARIA')" />
        <cr-slider :label="t('ORGANIZATION_DIALOG_MAX_STUDENTS_PER_COURSE')" v-model="limitInputs.maxStudentsPerCourse" :min="0" :max="customOrganizationLimits.maxStudentsPerCourse?.max ?? 0" />
        <cr-slider v-if="canUpdateAllLimits" :label="t('ORGANIZATION_DIALOG_MAX_ENROLLMENTS_PER_ORGANIZATION')" v-model="limitInputs.maxEnrollmentsPerOrganization" :min="0" :max="customOrganizationLimits.maxEnrollmentsPerOrganization?.max ?? 0" />
        <cr-external-link v-if="helpLink" :href="helpLink" data-testing="help-link">{{ t('ORGANIZATION_FORM_LIMITS_HELP_TEXT') }}</cr-external-link>
    </form-section>
</template>

<script setup lang="ts">
import { CustomOrganizationLimits, ICustomOrganizationLimits } from '@cyber-range/cyber-range-api-organization-client';
import { computed, reactive, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useOrganizationStore } from '../../stores/organizationStore';
import FormSection from '../layouts/sections/FormSection.vue';
import { useKbStore } from '../../stores/kbStore';

const { t } = useI18n();
const authorizationStore = useAuthorizationStore();
const helpLink = useKbStore().organizationLimits();

const props = defineProps<{
    organizationId: string;
}>()
const emit = defineEmits<{
    (name: 'update:modelValue', limits?: Partial<ICustomOrganizationLimits>): void
}>();

const canUpdateLimits = computed(() => authorizationStore.canUpdateOrganizationLimits(props.organizationId));
const canUpdateAllLimits = computed(() => authorizationStore.canUpdateAllOrganizationLimits(props.organizationId));

const customOrganizationLimits = shallowRef<ICustomOrganizationLimits>(new CustomOrganizationLimits({
    maxExerciseGroupsPerCourse: { max: Number.MAX_SAFE_INTEGER },
    maxInstructorsPerCourse: { max: Number.MAX_SAFE_INTEGER },
    maxTasPerCourse: { max: Number.MAX_SAFE_INTEGER },
    maxStudentsPerCourse: { max: Number.MAX_SAFE_INTEGER },
    maxEnrollmentsPerOrganization: { max: Number.MAX_SAFE_INTEGER },
}));
const limitInputs = reactive({
    maxExerciseGroupsPerCourse: 0,
    maxInstructorsPerCourse: 0,
    maxTasPerCourse: 0,
    maxStudentsPerCourse: 0,
    maxEnrollmentsPerOrganization: 0,
} satisfies Partial<Record<keyof ICustomOrganizationLimits, number>>);

watch(() => props.organizationId, async () =>
{
    if (!canUpdateLimits.value)
    {
        return;
    }

    customOrganizationLimits.value = await useOrganizationStore().getOrganizationLimits(props.organizationId);
}, { immediate: true });

watch(customOrganizationLimits, () =>
{
    const limitFields = Object.keys(limitInputs) as Array<keyof typeof limitInputs>;
    for (const key of limitFields)
    {
        const { current, max } = customOrganizationLimits.value[key] ?? {};
        limitInputs[key] = current ?? max ?? 0;
    }
});

watch(limitInputs, () =>
{
    let limits: Partial<ICustomOrganizationLimits>|undefined = undefined;
    const limitFields = Object.keys(limitInputs) as Array<keyof typeof limitInputs>;
    for (const key of limitFields)
    {
        const { current, max } = customOrganizationLimits.value[key] ?? {};
        if (limitInputs[key] !== (current ?? max))
        {
            limits ??= {}
            limits[key] = { current: limitInputs[key] };
        }
    }
    emit('update:modelValue', limits);
});
</script>
