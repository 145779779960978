<template>
    <cr-container>
        <cr-row>
            <cr-col v-if="showActions" class="subscription-body-action-section cr-p0">
                <cr-dropdown :title="t('SUBSCRIPTION_TILE_ACTION_BUTTON_TITLE', {planName})" class="subscription-action-dropdown" data-testing="subscription-tile-body-action-menu">
                    <cr-dropdown-toggle :aria-label="t('SUBSCRIPTION_TILE_ACTION_BUTTON_TITLE', {planName})" text text-color="var(--cr-table-action-text)" background-color="var(--cr-table-action-background)">
                        <cr-icon rounded>bi-gear-fill</cr-icon>
                    </cr-dropdown-toggle>
                    <cr-dropdown-list>
                        <cr-dropdown-list-item icon="bi-pencil" @click="onEditClicked" data-testing="subscription-tile-body-action-menu-edit">
                            {{ t('SUBSCRIPTION_TILE_ACTION_EDIT')}}
                        </cr-dropdown-list-item>
                        <cr-dropdown-list-item v-if="canRenewSubscription(props.organizationId)" icon="bi-calendar-plus" @click="onRenewClicked" data-testing="subscription-tile-body-action-menu-renew">
                            {{ t('SUBSCRIPTION_TILE_ACTION_RENEW') }}
                        </cr-dropdown-list-item>
                        <cr-dropdown-list-item icon="bi-trash3" @click="onDeleteClicked" data-testing="subscription-tile-body-action-menu-delete">
                            {{ t('SUBSCRIPTION_TILE_ACTION_DELETE') }}
                        </cr-dropdown-list-item>
                    </cr-dropdown-list>
                </cr-dropdown>
            </cr-col>
            <cr-col class="subscription-body-main-section cr-plr0 cr-ptb2">
                <cr-row>
                    <span class="subscription-cost" data-testing="subscription-tile-body-cost">
                        {{ cost }}
                    </span>
                </cr-row>
                <cr-row>
                    <span data-testing="subscription-tile-body-cost-subtitle">
                        {{ t('SUBSCRIPTION_TILE_COST_SUBTITLE') }}
                    </span>
                </cr-row>
            </cr-col>
        </cr-row>
        <hr class="cr-mt2 cr-mb2"/>
        <cr-row class="cr-mb2">
            <span class="cr-text-center" data-testing="subscription-tile-body-bottom-message">
                {{bottomMessage}}
            </span>
        </cr-row>
    </cr-container>
</template>

<style scoped>
.subscription-body-wrapper
{
    position: relative;
}
.subscription-body-action-section
{
    flex-grow: 0;
}

.subscription-body-main-section span
{
    text-align: center;
}
.subscription-cost
{
    font-size: 2rem;
}

</style>
<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ISubscription } from '@cyber-range/cyber-range-api-subscription-client';
import { useSubscriptionDates } from '../../composables/useSubscriptionDates';
import { useAuthorizationStore } from '../../stores/authorizationStore';

const props = defineProps<
{
    cost:string
    planName: string
    organizationId: string
    subscription?: ISubscription
}>();

const emit = defineEmits<{
    (name: 'delete', subscription: ISubscription): void
    (name: 'edit', subscription: ISubscription): void
    (name: 'renew', subscription: ISubscription): void
}>();

const { t } = useI18n();

const { subscriptionExpired } = useSubscriptionDates();
const { canUpdateBilling, canRenewSubscription } = useAuthorizationStore();

const bottomMessage = computed(() => 
{
    if (!props.subscription || subscriptionExpired(props.subscription))
    {
        return t('SUBSCRIPTION_TILE_DEFAULT_BOTTOM_MESSAGE');
    }

    const until = props.subscription.endDate ? props.subscription.endDate : t('SUBSCRIPTION_TILE_DEFAULT_TO');

    return t('SUBSCRIPTION_TILE_DATE_RANGE_BOTTOM_MESSAGE', {from: props.subscription.startDate, until: until});
})

const showActions = computed(() => canUpdateBilling(props.organizationId) && !!props.subscription && !subscriptionExpired(props.subscription));

const onEditClicked = () => 
{
    if(props.subscription)
    {
        emit('edit', props.subscription);
    }
};
const onRenewClicked = () =>
{
    if(props.subscription)
    {
        emit('renew', props.subscription);
    }
};
const onDeleteClicked = () => 
{
    if (props.subscription)
    {
        emit('delete', props.subscription);
    }
};

</script>