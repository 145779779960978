import { CompetitionFilter, ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { defineStore } from 'pinia';
import { useApiClientStore } from './apiClientStore';

export const useCtfStore = defineStore('CtfStore', 
{
    actions: 
    {
        async getCompetitionBySubdomain(subdomain: string): Promise<ICompetition>
        {
            const competitionClient = useApiClientStore().competitionApiClient;
            const response = await competitionClient.get(new CompetitionFilter({ subdomain, limit: 1 }));
            return response.items?.[0];
        },

        async snapshot(competitionId: string, name: string): Promise<string>
        {
            const competitionClient = useApiClientStore().competitionApiClient;
            return await competitionClient.snapshot(competitionId, name);
        }
    }
});
