<template>
    <cr-row class="subscription-tiles">
        <cr-col v-for="{plan, subscription} of planSubscriptionRecords" xs12 sm12 md12 lg6 xl4 class="cr-mt2">
            <subscription-tile :organizationId="organizationId" :displayedCost="planDisplayCost(plan)" :plan="plan" :subscription="subscription" @delete="onDelete" @edit="onEdit" @renew="onRenew"/>
        </cr-col>
    </cr-row>
    <delete-subscription-dialog v-model="showDeleteSubscriptionDialog" :subscription="selectedSubscription" @confirm="onSubscriptionChange"/>
    <edit-subscription-dialog v-model="showEditSubscriptionDialog" :subscription="selectedSubscription" :plan="selectedPlan" @confirm="onSubscriptionChange"/>
    <renew-subscription-dialog v-model="showRenewSubscriptionDialog" :subscription="selectedSubscription" @confirm="onSubscriptionChange"/>
</template>

<script setup lang="ts">
import { ISubscription, SubscriptionFilter, SubscriptionStatus } from '@cyber-range/cyber-range-api-subscription-client';
import { ref } from 'vue';
import { IPlan } from '../../interfaces/iPlan';
import { useCalendar } from '../../composables/useCalendar';
import { usePlanStore } from '../../stores/planStore';
import { useSubscriptionStore } from '../../stores/subscriptionStore';
import SubscriptionTile from './SubscriptionTile.vue';
import DeleteSubscriptionDialog from './dialogs/DeleteSubscriptionDialog.vue';
import { onMounted } from 'vue';
import { watchEffect } from 'vue';
import EditSubscriptionDialog from './dialogs/EditSubscriptionDialog.vue';
import RenewSubscriptionDialog from './dialogs/RenewSubscriptionDialog.vue';
import { Plan } from '../../entities/Plan';

const { getFeaturedPlans, getReferencePlans, getPlanByProductId, fetchPlansAndFeatures } = usePlanStore();
const { fetchOrganizationSubscriptions } = useSubscriptionStore();
const { toYYYYMMDD } = useCalendar()

const props = defineProps<
{
    organizationId:string;
    contractExpiration?: string;
}>();

const subscriptions = ref<ISubscription[]>();

const selectedSubscription = ref<ISubscription>();
const selectedPlan = ref<IPlan>();

const showDeleteSubscriptionDialog = ref(false);
const showEditSubscriptionDialog = ref(false);
const showRenewSubscriptionDialog = ref(false);

const planSubscriptionRecords = ref<{plan: IPlan, subscription?: ISubscription}[]>([]);

onMounted(async () => 
{
    await fetchPlansAndFeatures();
    await refreshSubscriptions();
});

const refreshSubscriptions = async () =>
{
    const subscriptionFilter = new SubscriptionFilter({status: SubscriptionStatus.Ready});
    await fetchOrganizationSubscriptions(props.organizationId, subscriptionFilter);
}

const generateProductSubscriptionRecords = async () =>
{
    if (!subscriptions.value) return;

    const referencePlans = getReferencePlans();
    const featuredPlans = getFeaturedPlans();

    const records: {plan: IPlan, subscription?: ISubscription}[] = [];

    // Subscriptions are default sorted by created timestamp which can lead to order changing when subscribing/unsubscribing addons.
    subscriptions.value.sort((sub1, sub2) => new Date(sub1.startDate).getTime() - new Date(sub2.startDate).getTime());

    for (const subscription of subscriptions.value)
    {
        const matchingPlan = referencePlans.find(plan => plan.productId === subscription.productId);

        const planFromProduct = await getPlanByProductId(subscription.productId);

        if (matchingPlan)
        {
            const mergedPlan = new Plan({...matchingPlan, features: planFromProduct.features, optionalFeatures: planFromProduct.optionalFeatures})
            records.push({plan: mergedPlan, subscription: subscription});
        }
        else
        {
            records.push({plan: planFromProduct, subscription: subscription});
        }
    }

    // Add back in any plans that aren't active and should be advertised
    const remainingPlans = featuredPlans.filter(plan => !records.some(r => r.plan.productId === plan.productId));
    remainingPlans.forEach(plan => records.push({plan}))

    planSubscriptionRecords.value = records;
}

watchEffect(() =>
{
    subscriptions.value = useSubscriptionStore().organizationSubscriptions(props.organizationId);
    generateProductSubscriptionRecords();
})

const planDisplayCost = (plan: IPlan) => plan.displayedPrice || `$${plan.pricePerEnrollment}`;

const onDelete = (subscription: ISubscription) =>
{
    selectedSubscription.value = subscription;
    showDeleteSubscriptionDialog.value = true;
}

const onEdit = (subscription: ISubscription, plan: IPlan) =>
{
    selectedSubscription.value = subscription;
    selectedPlan.value = plan;
    showEditSubscriptionDialog.value = true;
}

const onRenew = (subscription: ISubscription) =>
{
    selectedSubscription.value = subscription;
    showRenewSubscriptionDialog.value = true;
}

const onSubscriptionChange = () => refreshSubscriptions();
</script>