<template>
    <cr-filter :icon="icon" :label="label" :selected-values="selectedValues" :disabled="disabled">
        <cr-text-field v-model="modelValue" :label="label" :disabled="disabled"/>
        <div class="input-buttons">
            <cr-button :label="t('TEXT_FILTER_CLEAR_BUTTON')" @click="onCleared" text class="cr-mr2" :disabled="disabled" data-testing="text-filter-clear"/>
            <cr-button :label="t('TEXT_FILTER_CONFIRM_BUTTON')" @click="onConfirmed" :disabled="disabled" data-testing="text-filter-confirm"/>
        </div>
    </cr-filter>
</template>

<style scoped>
.cr-filter:deep(.cr-dropdown-toggle),
.cr-filter:deep(.cr-dropdown-list)
{
    min-width: 12rem;
}
.input-buttons {
    text-align: right;
}
</style>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
    /**
     * Icon to use for the filter
     */
    icon: string;

    /**
     * Label to use for the filter
     */
    label: string;

    /**
     * The User Id
     */
    modelValue?: string

    /**
     * Disable the filter
     */
    disabled?: boolean;
}>();

const emits = defineEmits<{
    (name: 'update:modelValue', value?: string): void
}>()

const modelValue = ref('');
watch(() => props.modelValue, () => modelValue.value = props.modelValue || '', { immediate: true });
const onCleared = () => emits('update:modelValue', undefined);
const onConfirmed = () => emits('update:modelValue', modelValue.value);

const selectedValues = computed(() => modelValue.value ? [modelValue.value] : undefined);
</script>
