<template>
    <form-layout :title="t('COURSES_COURSE_CREATE_COURSE_PAGE_TITLE')" :breadcrumbs="breadcrumbs" @confirm="onConfirm" @cancel="onCancel" :ready="isReady">
        <template #form>
            <course-form v-model="course" />
        </template>
    </form-layout>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import Route from '../../routers/route';
import FormLayout from '../layouts/FormLayout.vue';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, onMounted, ref } from 'vue';
import { Course, ICourse, InvitationTemplate } from '@cyber-range/cyber-range-api-user-client';
import { useCalendar } from '../../composables/useCalendar';
import { useCourseStore } from '../../stores/courseStore';
import { useRouter } from 'vue-router';
import { useRawObject } from '../../composables/useRawObject';
import CourseForm from './CourseForm.vue';
import { useBusinessUnitStore } from '../../stores/businessUnitStore';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';

let props = defineProps<{
    courseId:string
}>();

const { t } = useI18n();
const router = useRouter();
const isReady = ref(false);

const breadcrumbs = computed(()=>useCourseBreadcrumbs([ 
                                   new BreadcrumbItem(Route.Courses), 
                                   new BreadcrumbItem({...Route.Course, text: course.value?.name, params: {courseId: props.courseId}}),
                                   new BreadcrumbItem({...Route.EditCourse, params: {courseId: props.courseId}}) ]));

const invitationTemplate = new InvitationTemplate();
invitationTemplate.html = t('COURSE_INVITATION_BODY_HTML');
invitationTemplate.subject = t('COURSE_INVITATION_SUBJECT');
invitationTemplate.text = t('COURSE_INVITATION_BODY_TEXT');

let course = ref<ICourse>(new Course());

const onConfirm = async () => 
{
    let payload = useRawObject(course.value, {only: ['id', 'name', 'description', 'startTime', 'endTime', 'justification']});

    delete (<any>payload)['image'];
    
    payload.startTime = useCalendar().toStartOfDayISOString(payload.startTime);
    payload.endTime = useCalendar().toEndOfDayISOString(payload.endTime);

    let courseId = await useCourseStore().update(payload);
    
    //Update the sidebar
    useCourseStore().fetchMyCourses();

    router.push({ name: Route.Course.name,  params: {courseId:props.courseId}});
}

const onCancel = () => 
{
    router.go(-1);
}

onMounted(async ()=>
{
    [course.value] = await Promise.all([
        useCourseStore().getCourse(props.courseId),
        useOrganizationStore().fetchOrganizations(),
        useBusinessUnitStore().fetchBusinessUnits()
    ]);

    await useOrganizationStore().fetchOrganizationNameAndLogo(course.value.organizationId);

    isReady.value = true
})
</script>