<template>
    <cr-delete-confirmation-dialog :title="t('COURSE_USERS_DELETE_CREDENTIAL_DIALOG_TITLE')" @confirm="onConfirmed" :disabled="isLoading" data-testing="delete-credential-dialog">
        <cr-html :value="t('COURSE_USERS_DELETE_CREDENTIAL_DIALOG_MESSAGE', {name:credential?.name})" />
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useCredentialStore } from '../../../stores/credentialStore';
import { useRosterStore } from '../../../stores/rosterStore';
import { storeToRefs } from 'pinia';
import { IRosterEntry } from '@cyber-range/cyber-range-api-roster-client';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    credential?: IRosterEntry;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    if(props.credential)
    {
        await useCredentialStore().delete(props.credential.id);
        useRosterStore().clearCache();
        emit('confirm');
    }
}
</script>