import {Printd} from 'printd';

function printElement(ele: HTMLElement, printer = new Printd()) 
{
    printer.print(ele);
}

function printElements(elements: HTMLElement[], printer = new Printd())
{
    const clones = elements.map(ele => ele.cloneNode(true));
    
    const divToPrint = document.createElement('div');
    clones.forEach(clone => divToPrint.appendChild(clone));

    printElement(divToPrint, printer);

    divToPrint.remove();
}
let usableFunctions = 
{
    printElement,
    printElements
}

export function usePrinter(): typeof usableFunctions
{
    return usableFunctions;
}