import IIdentity from "../interfaces/iIdentity";

export default class Identity implements IIdentity
{
    id: string | undefined;
    name: string | undefined;
    email: string | undefined;
    avatar: string | undefined;
    provider: string | undefined;

    constructor(params: Partial<IIdentity>)
    {
        this.id = params.id;
        this.name = params.name;
        this.email = params.email;
        this.avatar = params.avatar;
        this.provider = params.provider;
    }

    static fromJwt(jwt:any): IIdentity
    {
        return new Identity({
            id: jwt['sub'],
            name: jwt['displayName'],
            email: jwt['email'],
            avatar: jwt['picture'],
            provider: jwt['provider']
        })
    }
}