<template>
    <list-layout :ready="isReady" :title="t('BUSINESS_UNITS_BUSINESS_UNITS')" :breadcrumbs="breadcrumbs">
        
        <template #list>
            <list-section :no-items-message="t('BUSINESS_UNITS_NO_ITEMS')">
                <list-section-item v-for="unit in page.items">
                    <business-unit-tile :businessUnit="unit" />
                </list-section-item>
            </list-section> 
        </template>
        
        <template #pagination>
            <cr-pagination :previous="!!page.prevPageToken"  :first="true" :next="!!page.nextPageToken" @previous="onPreviousPageClicked" @first="onFirstPageClicked" @next="onNextPageClicked" data-testing="business-units-pagination"/>
        </template>

        <template #controls>
            <list-control-item v-if="canCreateBusinessUnit(organizationId)">
                <cr-button :to="Route.CreateBusinessUnit" outlined data-testing="create-business-unit-button">
                    <cr-icon>bi-plus</cr-icon>
                    {{ t('BUSINESS_UNITS_CREATE_BUSINESS_UNIT_BUTTON') }}
                </cr-button>
            </list-control-item>
        </template>
        
    </list-layout>
</template>

<script setup lang="ts">

import Config from '../../config';
import BusinessUnitTile from './BusinessUnitTile.vue';
import ListLayout from '../layouts/ListLayout.vue';
import ListSection from '../layouts/sections/ListSection.vue';
import ListSectionItem from '../layouts/sections/ListSectionItem.vue';
import Route from '../../routers/route';
import { ApiPageResponse, IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { BusinessUnitFilter, IBusinessUnit } from '@cyber-range/cyber-range-api-business-unit-client';
import { IOrganization } from '@cyber-range/cyber-range-api-organization-client';
import { onMounted, ref, computed } from 'vue';
import { useBusinessUnitStore } from '../../stores/businessUnitStore';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useI18n } from 'vue-i18n';
import { useQueryFilter } from '../../composables/useQueryFilter';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import ListControlItem from '../layouts/sections/ListControlItem.vue';

const { t } = useI18n();
const { canCreateBusinessUnit } = useAuthorizationStore();

const isReady = ref(false);

const props = defineProps<
{
    organizationId:string
}>();

const organization = ref<IOrganization>();

let page = ref<IApiPageResponse<IBusinessUnit>>(new ApiPageResponse<IBusinessUnit>({items:[]}));
let filter = useQueryFilter(BusinessUnitFilter, {limit:Config.DEFAULT_TILE_LISTING_PAGE_SIZE, organizationId: props.organizationId});

const breadcrumbs = computed(()=>[ 
                        new BreadcrumbItem(Route.Organizations), 
                        new BreadcrumbItem({...Route.Organization, text: organization.value?.name, params: {organizationId: props.organizationId}}), 
                        new BreadcrumbItem({...Route.BusinessUnits, params: {organizationId: props.organizationId}})])

const onPreviousPageClicked =async  () =>
{
    filter.token = page.value.prevPageToken;
}

const onFirstPageClicked = async () =>
{
    filter.token = '';
}

const onNextPageClicked = async () =>
{
    filter.token = page.value.nextPageToken;
}

onMounted(async ()=>
{
    try
    {
        [page.value, organization.value] = await Promise.all([useBusinessUnitStore().listBusinessUnits(filter), useOrganizationStore().getOrganization(props.organizationId)])
        await useOrganizationStore().fetchOrganizationNameAndLogo([organization.value]);
    }
    finally
    {
        isReady.value = true;
    }
});
</script>