<template>
    <cr-confirmation-dialog  id="editSubscriptionDialog" :title="t('EDIT_SUBSCRIPTION_TITLE')" @confirm="onConfirmed" @cancel="onCanceled" :disabled="isLoading" data-testing="edit-subscription-dialog">
        <form-section :label="t('EDIT_SUBSCRIPTION_FEATURES')" data-testing="edit-subscription-features-section">
            <feature-picker v-model="selectedAddonIds" :plan="plan"/>
        </form-section>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { ISubscription } from '@cyber-range/cyber-range-api-subscription-client';
import { useSubscriptionStore } from '../../../stores/subscriptionStore';
import { IPlan } from '../../../interfaces/iPlan';
import FeaturePicker from '../../pickers/FeaturePicker.vue';
import { ref, watch } from 'vue';
import FormSection from '../../layouts/sections/FormSection.vue';


const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { unsubscribeAddon, subscribeAddon } = useSubscriptionStore();

const props = defineProps<
{
    plan?: IPlan;
    subscription?: ISubscription;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
    (name: 'cancel'): void
}>();

const selectedAddonIds = ref(props.subscription?.addOns)

function populateData()
{
    selectedAddonIds.value = props.subscription?.addOns ? [...props.subscription.addOns] : [];
}
watch(() => props.subscription, populateData);

const onConfirmed = async ()=>
{
    if(props.subscription && selectedAddonIds.value)
    {
        // Unsubscribe addons that have been unselected
        for(let addonId of props.subscription.addOns.filter(addonId => !selectedAddonIds.value?.includes(addonId)))
        {
            await unsubscribeAddon(props.subscription.organizationId, props.subscription.id, addonId);
        }

        // Subscribe new addons that have been selected
        for(let addonId of selectedAddonIds.value.filter(addonId => !props.subscription!.addOns.includes(addonId)))
        {
            await subscribeAddon(props.subscription.organizationId, props.subscription.id, addonId);
        }

        emit("confirm");
    }
}
function onCanceled()
{
    populateData();
    emit('cancel');
}
</script>