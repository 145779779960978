<template>
    <form-layout :title="t('COURSE_USERS_INVITE_USERS_PAGE_TITLE')" :breadcrumbs="breadcrumbs" :ready="isReady" @confirm="onCreateOneInvitationConfirmed" @cancel="onCreateOneInvitationCancelled" >
        <template #form>
            <form-section :label="t('COURSE_USERS_INVITE_ONE_USER_SECTION_LABEL')" :disabled="isLoading">
                <cr-text-field v-model="invitationName" :label="t('COURSE_USERS_INVITE_USERS_NAME_LABEL')" :disabled="isLoading" required :max-length="100" data-testing="invitation-form-name-input" class="cr-mb3 cr-mt3" />
                <cr-text-field v-model="invitationEmail" :label="t('COURSE_USERS_INVITE_USERS_EMAIL_LABEL')" :disabled="isLoading" required :max-length="256" type="email" data-testing="invitation-form-email-input" class="cr-mb3 cr-mt3" />
                <cr-select v-model="selectedRole" :label="t('COURSE_USERS_INVITE_USERS_ROLE_LABEL')" :disabled="isLoading" :items="selectableRoles" required data-testing="invitation-form-role-input" class="cr-mt3" />
            </form-section>
        </template>
        <template #bottom v-if="!isAnonymous">
            <form-section divider-top :label="t('COURSE_USERS_INVITE_USERS_IMPORT_SECTION_LABEL')">
                <form-list-section class="cr-mt5 cr-text-center">
                    <form-list-section-item>
                        <cr-card :to="importUsersByCsv" data-testing="import-from-csv-card">
                            <cr-card-body>
                                <cr-icon class="giant-csv-icon cr-pt2"> bi-filetype-csv </cr-icon>
                                <cr-card-title class="cr-pt0" data-testing="import-from-csv-card-title"> {{t('COURSE_USERS_IMPORT_FROM_CSV_CARD_TITLE')}} </cr-card-title>                                
                                <cr-card-text class="cr-pb3" data-testing="import-from-csv-card-text"> {{t('COURSE_USERS_IMPORT_FROM_CSV_CARD_TEXT')}} </cr-card-text>
                            </cr-card-body>
                        </cr-card>
                    </form-list-section-item>
                    <form-list-section-item>
                        <cr-card :to="importUsersByGoogleClassroom" data-testing="import-from-google-classroom-card">
                            <cr-card-body>
                                <cr-image src="/google_classroom_logo.png" alt="" class="cr-pt2 classroom-logo"/>
                                <cr-card-title class="cr-pt4" data-testing="import-from-google-classroom-card-title"> {{t('COURSE_USERS_IMPORT_FROM_GOOGLE_CLASSTOOM_CARD_TITLE')}} </cr-card-title>                                
                                <cr-card-text class="cr-pb3" data-testing="import-from-google-classroom-card-text">  {{t('COURSE_USERS_IMPORT_FROM_GOOGLE_CLASSTOOM_CARD_TEXT')}} </cr-card-text>
                            </cr-card-body>
                        </cr-card>
                    </form-list-section-item>
                    <form-list-section-item>
                        <cr-card :to="Route.ImportCourseUsersCanvas" data-testing="link-with-canvas-card">
                            <cr-card-body>
                                <cr-image src="/canvas_logo.jpg" alt="" class="cr-pt2 canvas-logo"/>
                                <cr-card-title class="cr-pt4" data-testing="link-with-canvas-card-title"> {{t('COURSE_USERS_IMPORT_FROM_CANVAS_CARD_TITLE')}} </cr-card-title>                                
                                <cr-card-text class="cr-pb3" data-testing="link-with-canvas-card-text">  {{t('COURSE_USERS_IMPORT_FROM_CANVAS_CARD_TEXT')}} </cr-card-text>
                            </cr-card-body>
                        </cr-card>
                    </form-list-section-item>
                </form-list-section>
            </form-section>
        </template>
    </form-layout>
</template>

<style scoped>
.classroom-logo
{
    width: 5rem;
}
.canvas-logo
{
    width: 5rem;
}
.giant-csv-icon
{
    font-size: 4.72rem;
    color: var(--cr-confirmation-dialog-confirm-background-color);
}
</style>

<script setup lang="ts">
import FormLayout from '../layouts/FormLayout.vue';
import FormSection from '../layouts/sections/FormSection.vue';
import FormListSection from '../layouts/sections/FormListSection.vue';
import FormListSectionItem from '../layouts/sections/FormListSectionItem.vue';
import Route from '../../routers/route';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useCourseStore } from '../../stores/courseStore';
import { useInvitationStore } from '../../stores/invitationStore';
import { Invitation } from '@cyber-range/cyber-range-api-invitation-client'
import { useI18n } from 'vue-i18n';
import { ICourse, UserRole } from '@cyber-range/cyber-range-api-user-client';
import { useEnum, SelectItem } from '../../composables/useEnum';
import { useRouter } from 'vue-router';
import UserImportMethod from '../../interfaces/userImportMethod';
import { useSubscriptionStore } from '../../stores/subscriptionStore';
import { Products } from '@cyber-range/cyber-range-api-subscription-client';
import { useOrganizationStore } from '../../stores/organizationStore';

const props = defineProps<
{
    courseId:string;
}>();

const { t } = useI18n();

const { isLoading } = storeToRefs(useApiClientStore());

const isReady = ref(false);

const breadcrumbs = computed(()=>useCourseBreadcrumbs([
                          new BreadcrumbItem(Route.Courses), 
                          new BreadcrumbItem({...Route.Course,  text: course?.value?.name, params: {courseId: props.courseId}}), 
                          new BreadcrumbItem({...Route.CourseUsers, text: t('COURSE_USERS_PAGE_TITLE'), params: {courseId: props.courseId}}),
                          new BreadcrumbItem({...Route.InviteCourseUsers, text: t('COURSE_USERS_INVITE_USERS_PAGE_TITLE'), params: {courseId: props.courseId}})]));

const router = useRouter();

const course = ref<ICourse>();
const invitationName = ref<string>();
const invitationEmail = ref<string>();
const selectedRole = ref<UserRole>();
const selectableRoles = ref<SelectItem[]>([]);

const importUsersByCsv = {...Route.ImportCourseUsers, params:{ courseId:props.courseId, importMethod:UserImportMethod.Csv}};

const importUsersByGoogleClassroom = {...Route.ImportCourseUsers, params:{ courseId:props.courseId, importMethod:UserImportMethod.GoogleClassroom}};

const onCreateOneInvitationConfirmed = async ()=>
{
    let role:string = selectedRole.value as string;
    await (useInvitationStore().create([
        new Invitation({
            courseId: props.courseId,
            name: invitationName.value,
            email: invitationEmail.value,
            roles: [ role ]
        })
    ]));
    router.push({ name: Route.CourseUsers.name,  params: {courseId:props.courseId}})
}

const onCreateOneInvitationCancelled = async ()=>
{
    router.push({ name: Route.CourseUsers.name,  params: {courseId:props.courseId}})
}

const isAnonymous = ref<boolean>(false);

onMounted(async ()=>
{
    try
    {  
        let results = [];
        course.value = await useCourseStore().getCourse(props.courseId);

        await Promise.all([useSubscriptionStore().fetchOrganizationSubscribedProducts(course.value?.organizationId), useOrganizationStore().fetchOrganizationNameAndLogo(course.value?.organizationId)]);
        isAnonymous.value = course.value?.organizationId ? useSubscriptionStore().isSubscribedTo(course.value?.organizationId, Products.AnonymousStudentsAndTasFeatures) : false;
        const roles = isAnonymous.value ? [UserRole.Instructor] : [UserRole.Student, UserRole.TA, UserRole.Instructor];

        for(let role of roles)
        {
            if(useAuthorizationStore().canInvite(role, props.courseId, course.value.organizationId))
            {
                results.push(role);
            }
        }    
        selectableRoles.value = useEnum().toSelectItems(UserRole, results);
    }
    finally
    {
        isReady.value = true;
    }
});

</script>