<template>
    <cr-sidebar-header tag="h2" class="cr-font-bold cr-sidebar-item cr-mt3" data-testing="sidebar-courseware">
        {{ t('SIDEBAR_COURSEWARE') }}
    </cr-sidebar-header>
    <cr-sidebar-item tag="span" :to="{path: Route.Courseware.path}" data-testing="sidebar-courseware-repository">
        <cr-icon class="sidebar-item-icon">bi-book</cr-icon> 
        <span class="sidebar-item-text">
            {{ t('SIDEBAR_COURSEWARE_REPOSITORY') }}
        </span>
    </cr-sidebar-item>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import Route from '../../../routers/route';

const { t } = useI18n();
</script>