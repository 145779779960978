<template>
    <cr-filter icon="bi-people" :label="label || t('USER_FILTER_LABEL')" :selected-values="selectedUserName" :disabled="disabled || isLoading || !users?.length" data-testing="user-filter">
        <cr-autocomplete v-model='selectedUserId' :label="label || t('USER_FILTER_LABEL')" :items="users || []" :disabled="disabled || isLoading" />
    </cr-filter>
</template>

<style scoped>
.cr-filter:deep(.cr-dropdown-toggle),
.cr-filter:deep(.cr-dropdown-list)
{
    min-width: 12rem;
}
</style>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useApiClientStore } from '../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { IUser } from '@cyber-range/cyber-range-api-user-client';

const props = defineProps<
{
    /**
     * Filterable users
     */
    users?: IUser[];

    /**
     * Selected user's ID
     */
    modelValue: string | undefined;

    /**
     * Whether the filter is disabled.
     * @default false
     */
    disabled?: boolean

    /**
     * Label on the filter
     * @default t('USER_FILTER_LABEL')
     */
    label?: string
}>();

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const selectedUserId = ref(props.modelValue);
const selectedUserName = ref<string[]>([]);
const emit = defineEmits(['update:modelValue']);

const setSelectedUserNameByUserId = (userId:string|undefined) =>
{
    selectedUserName.value = (props.users || []).filter(o => o.id === userId).map(o => o.name);
}

watch(selectedUserId, (value)=>
{
    setSelectedUserNameByUserId(value);
    emit('update:modelValue', value);
}, 
{ immediate: true })

watch(()=>props.modelValue, (value)=>
{
    if(props.modelValue)
    {
        setSelectedUserNameByUserId(value);
        selectedUserId.value = value;
    }
    else
    {
        selectedUserName.value = [];
        selectedUserId.value = undefined;
    }
}, 
{ immediate: true })

watch(()=>props.users, ()=>
{
    if(props.modelValue)
    {
        setSelectedUserNameByUserId(props.modelValue);
    }
}, 
{ immediate: true })
</script>