<template>
    <cr-confirmation-dialog class="cr-pb0 cr-mt0" :title="t('COURSE_USERS_IMPORT_SELECT_A_GOOGLE_CLASSROOM_DIALOG_TITLE')" @confirm="onConfirmed" @cancel="onCanceled" data-testing="select-google-classroom-dialog">
        <cr-row xs12>
            <span class="cr-pt2 cr-pb6 cr-pl3" data-testing="select-google-classroom-dialog-body-message" > 
                {{t('COURSE_USERS_IMPORT_SELECT_A_GOOGLE_CLASSROOM_DIALOG_BODY')}} 
            </span>
        </cr-row>
        <cr-row class="cr-pb4 cr-pt0 cr-mt0">
            <cr-select v-model="selectedClassroomId" :label="t('COURSE_USERS_IMPORT_SELECT_A_GOOGLE_CLASSROOM_SELECT_LABEL')" required :items="classrooms" data-testing="select-google-classroom-dialog-classroom-select" class="cr-mb2 cr-pt2"/>
        </cr-row>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { IGoogleClassroomClass } from '../../../../composables/useGoogleClassroom';

const { t } = useI18n();

const props = defineProps<
{
    classrooms:IGoogleClassroomClass[];
}>();

const emit = defineEmits<{
    (name: 'confirm', value:string): void,
    (name: 'cancel'): void
}>();

const selectedClassroomId = ref<string>();

const onConfirmed = async ()=>
{
    emit('confirm', selectedClassroomId.value as string);
}

const onCanceled = async ()=>
{
    selectedClassroomId.value = undefined;
    emit('cancel');
}
</script>