<template>
    <cr-confirmation-dialog class="cr-pb0 cr-mt0" :title="t('COURSE_USERS_INVITATION_CODE_RESERVED_ENROLLMENTS_TITLE')" @confirm="onConfirmed" @cancel="onCanceled" :disabled="isLoading" :form-instruction="true" data-testing="edit-reserved-enrollments-dialog">
        <cr-row xs12>
            <span data-testing="edit-reserved-enrollments-dialog-body-message" class="cr-pt2 cr-pb6 cr-pl3"> 
                {{t('COURSE_USERS_INVITATION_CODE_RESERVED_ENROLLMENTS_DIALOG_BODY')}} 
            </span>
        </cr-row>
        <cr-row class="invitation-code cr-pb4 cr-pt0 cr-mt0">
            <cr-text-field type="number" v-model="reservedEnrollments" :label="t('COURSE_USERS_INVITATION_CODE_RESERVED_ENROLLMENTS_FIELD_LABEL')" :min="0" :max="maxUsersPerCourse" required :disabled="isLoading" data-testing="edit-reserved-enrollments-dialog-expected-number-of-users-field" />
        </cr-row>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { ICourse } from '@cyber-range/cyber-range-api-user-client'; 
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useCourseStore } from '../../../stores/courseStore';
import { useI18n } from 'vue-i18n';
import { nextTick } from 'vue';


const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    courseId?: string;
    estimatedNumberOfUsers?: number;
    maxUsersPerCourse?: number;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void,
    (name: 'cancel'): void
}>();

const reservedEnrollments = ref<number>(0);

const onConfirmed = async ()=>
{
    await useCourseStore().update({ id: props.courseId, statistics: { estimatedNumberOfUsers: Number(reservedEnrollments.value) }} as ICourse);
    emit('confirm');
}

const onCanceled = async ()=>
{
    await nextTick();
    reservedEnrollments.value = props.estimatedNumberOfUsers || 0;
    emit('cancel');
}

watch(() => props.estimatedNumberOfUsers, () =>{
    reservedEnrollments.value = props.estimatedNumberOfUsers || 0;
}, { immediate: true });
</script>