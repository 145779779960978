<template>
    <read-layout :title="plan.name" :breadcrumbs="breadcrumbs" :ready="ready">

        <template #top>
            <price-section :plan="plan" />
            <feature-section :plan="plan" />
            <limit-section :limits="plan.effectiveLimits" />
        </template>
        
    </read-layout>
</template>

<script setup lang="ts">
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, onMounted, ref } from 'vue';
import Route from '../../routers/route';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import ReadLayout from '../layouts/ReadLayout.vue';
import { IPlan } from '../../interfaces/iPlan';
import { usePlanStore } from '../../stores/planStore';
import { Plan } from '../../entities/Plan';
import { useI18n } from 'vue-i18n';
import { useErrorStore } from '../../stores/errorStore';
import { ApiClientError } from '@cyber-range/cyber-range-api-client';
import FeatureSection from './sections/featureSection.vue';
import PriceSection from './sections/priceSection.vue';
import LimitSection from './sections/limitSection.vue';
import { useRouter } from 'vue-router';

const props = defineProps<
{
    productId:string;
}>();

const { t } = useI18n();

const ready = ref<boolean>(false)
const plan = ref<IPlan>(new Plan());
const router = useRouter();

const breadcrumbs = computed(()=>useCourseBreadcrumbs([
                             new BreadcrumbItem(Route.Plans),
                             new BreadcrumbItem({...Route.Plan,  text: plan.value?.name, params: {productId: props.productId}})
                         ]));

onMounted(async ()=>
{
    let planStore = usePlanStore();

    await planStore.fetchPlansAndFeatures();

    let selectedPlan = usePlanStore().plans.get(props.productId);
    
    if(selectedPlan)
    {
        plan.value = selectedPlan
    }
    else
    {
        useErrorStore().setError(new ApiClientError(t('PLAN_NOT_FOUND', {productId: props.productId})));
        router.push(Route.Plans);
    }

    ready.value = true;
})
</script>