<template>
    <cr-confirmation-dialog :title="t('EXERCISE_RESET_EXERCISE_CONFIRM_DIALOG_TITLE')">
        <p>{{ t('EXERCISE_RESET_EXERCISE_CONFIRM_DIALOG_TEXT') }}</p>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
