import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import Config from '../../config';
import { useAuthenticationStore } from '../../stores/authenticationStore';

export default async function ssoToCourseware(to: RouteLocationNormalized, 
                                          from: RouteLocationNormalized, 
                                          next: NavigationGuardNext, 
                                          authenticationStore = useAuthenticationStore()
)                                       : Promise<void>
{
    let ssoToken = await authenticationStore.getSsoToken();

    window.location.href = new URL(`?s=${ssoToken}`, Config.COURSEWARE_UI_BASE_URL).href;
}