<template>
    <cr-confirmation-dialog :title="t('APPLY_FINANCE_CHARGE_DIALOG_TITLE')" @confirm="onConfirmed" @shown="onShown" :disabled="isLoading" :form-instruction="true" data-testing="apply-finance-charge-dialog">
        <form-section>
            <cr-text-field v-model="amount" :label="t('APPLY_FINANCE_CHARGE_AMOUNT_LABEL')" type="text" required :pattern="patterns.positiveNumberUpToTwoDecimals" data-testing="apply-finance-charge-amount"/>
            <cr-text-field v-model="postedDate" :label="t('APPLY_FINANCE_CHARGE_POSTED_DATE_LABEL')" type="text" :disabled="true" data-testing="apply-finance-charge-posted-date"/>
            <cr-textarea v-model="notes" :label="t('APPLY_FINANCE_CHARGE_DESCRIPTION_LABEL')" required :max-length="400" data-testing="apply-finance-charge-description"/>
        </form-section>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import FormSection from '../../layouts/sections/FormSection.vue';
import { IActivity } from '@cyber-range/cyber-range-api-balance-client';
import { useBalanceStore } from '../../../stores/balanceStore';
import { useCalendar } from '../../../composables/useCalendar';
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { useNumberFormats } from '../../../composables/useNumberFormats';
import { ActivityType } from '@cyber-range/cyber-range-api-balance-client/dist/interfaces/activityType';

const props = defineProps<{
    organizationId: string,
    activity?: IActivity
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { patterns } = useNumberFormats();
const amount = ref<string>("");
const notes = ref<string>("");
const postedDate = ref<string>("");

const populateData = () =>
{
    // These 2 values will be set by the user, so reset to default
    amount.value = "";
    notes.value = t('APPLY_FINANCE_CHARGE_DEFAULT_DESCRIPTION');

    if (props.activity)
    {
        postedDate.value = props.activity.postedDate;
    }
}

const onConfirmed = async () =>
{
    await useBalanceStore().createOrganizationBalanceActivity(props.organizationId, {
        amount: Number(amount.value) * -1,
        postedDate: useCalendar().toYYYYMMDD(new Date(postedDate.value)),
        notes: notes.value,
        type: ActivityType.User
    });

    emit('confirm');
}

const onShown = () =>
{
    populateData();
}

</script>