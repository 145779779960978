<template>
    <read-control-item>
        <cr-button outlined :disabled="!competition || isLoading || isUnavailable" @click="onJoinClicked" data-testing="exercise-group-ctf-control-join">
            <cr-icon>bi-play</cr-icon>
            {{ t('EXERCISE_GROUP_JOIN_MY_EXERCISE_BUTTON') }}
        </cr-button>
    </read-control-item>
    <read-control-item v-if="canSnapshotCompetition">
        <cr-tooltip :title="toolTipText" data-testing="copy-competition-environment-button-tooltip">
            <cr-button outlined :disabled="copyEnvironmentDisabled" @click="onCopyExerciseClicked" data-testing="exercise-group-ctf-control-copy" class="cr-mb2">
                <cr-icon>bi-files</cr-icon>
                {{ t('EXERCISE_GROUP_COPY_MY_EXERCISE_BUTTON') }}
            </cr-button>
        </cr-tooltip>
        <copy-exercise-dialog v-model="showCopyExerciseDialog" @confirm="onCopyExerciseConfirmed"/>
        <environment-copying-dialog v-model="showCopyingDialog"/>
    </read-control-item>
</template>

<script setup lang="ts">
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { IExerciseGroup } from '@cyber-range/cyber-range-api-user-client';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAuthenticationStore } from '../../stores/authenticationStore';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useCtfStore } from '../../stores/ctfStore';
import { useErrorStore } from '../../stores/errorStore';
import { useUserStore } from '../../stores/userStore';
import ReadControlItem from '../layouts/sections/ReadControlItem.vue';
import CopyExerciseDialog from './dialogs/CopyExerciseDialog.vue';
import EnvironmentCopyingDialog from './dialogs/EnvironmentCopyingDialog.vue';

const props = defineProps<
{
    exerciseGroup: IExerciseGroup;
}>();

const { t } = useI18n();
const authorizationStore = useAuthorizationStore();
const authenticationStore = useAuthenticationStore();
const ctfStore = useCtfStore()
const userStore = useUserStore();

const isLoading = ref(false);
const competition = ref<ICompetition>();
const link = computed(() => props.exerciseGroup.accessPoints?.[0].resource);
const canSnapshotCompetition = computed(() => competition.value ? useAuthorizationStore().canSnapshotCompetition(competition.value.courseId, competition.value.organizationId) : false)

const subdomain = computed(() => new URL(link.value)?.hostname.split('.', 1)[0]);

const isUnavailable = computed(():boolean=>
{
    const now = new Date();
    const outsideAvailability = (!!props.exerciseGroup.startTime && now < new Date(props.exerciseGroup.startTime)) || (!!props.exerciseGroup.endTime && now > new Date(props.exerciseGroup.endTime));    
    return outsideAvailability && (!authorizationStore.canViewAllTeamsInCourse(props.exerciseGroup.courseId,props.exerciseGroup.organizationId))
});

watch(subdomain, async () =>
{
    if (!subdomain) return;

    competition.value = await ctfStore.getCompetitionBySubdomain(subdomain.value);
}, { immediate: true });

async function onJoinClicked()
{
    isLoading.value = true;

    try
    {
        if(!authorizationStore.canAccessCompetition(competition.value!.id, competition.value!.organizationId))
        {
            await userStore.createUserByCtfCompetitionSubdomain(subdomain.value)
        }

        const ssoToken = await authenticationStore.getSsoToken();
        const href = `${link.value}?s=${ssoToken}`

        let result = window.open(href, "_blank");

        if (result == null || typeof result == "undefined")
        {
            useErrorStore().setError({
                message: t("EXERCISE_POPUP_BLOCKER_MESSAGE"),
                statusCode: 0,
                details: '',
            });
        }
    }
    finally
    {
        isLoading.value = false;
    }
}


const hasEnvironmentsAndCannotCreateEnvironmentArtifacts = computed(()=>
{
    return !!((competition.value?.statistics?.numberOfEnvironments || 0) > 0) && !useAuthorizationStore().canCreateEnvironmentArtifact(competition.value?.id, competition?.value?.organizationId);
}); 

const copyEnvironmentDisabled = computed(()=>
{
    return isLoading.value || !competition.value || isUnavailable.value || hasEnvironmentsAndCannotCreateEnvironmentArtifacts.value;
});

const toolTipText = computed(()=>
{    
    if(isUnavailable.value)
    {
        return t('EXERCISE_GROUP_CTF_COPY_ENVIRONMENT_UNAVAILABLE_TOOLTIP');
    }
    if(hasEnvironmentsAndCannotCreateEnvironmentArtifacts.value)
    {
        return t('EXERCISE_GROUP_CTF_COPY_ENVIRONMENT_DISABLED_DUE_TO_ENVIRONMENT_ARTIFACT_PERMISSION_TOOLTIP');
    }    
    return undefined;
});

const showCopyExerciseDialog = ref(false);

const onCopyExerciseClicked = () => showCopyExerciseDialog.value = true;

const showCopyingDialog = ref(false);

const onCopyExerciseConfirmed = async (name: string) => 
{
    isLoading.value = true;
    try
    {
        showCopyingDialog.value = true;
        await ctfStore.snapshot(competition.value!.id, name);
    }
    finally
    {
        isLoading.value = false;
    }
}
</script>
