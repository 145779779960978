<template>
    <form-section :label="t('BUSINESS_UNIT_FORM_INFO_SECTION')" data-testing="business-unit-form-business-unit-info-section" class="cr-mb3">
        <cr-text-field v-model="businessUnit.name" :label="t('BUSINESS_UNIT_FORM_INFO_SECTION_BUSINESS_UNIT_NAME')" :disabled="isLoading" required :max-length="256" data-testing="business-unit-form-business-unit-name-input" class="cr-mb3 cr-mt3" />
        <cr-text-field v-model="businessUnit.region" :label="t('BUSINESS_UNIT_FORM_INFO_SECTION_BUSINESS_UNIT_REGION')" :disabled="isLoading" required :max-length="256" data-testing="business-unit-form-business-unit-region-input" class="cr-mb3 cr-mt3" />
        <cr-select v-model="businessUnit.category" :label="t('BUSINESS_UNIT_FORM_INFO_SECTION_BUSINESS_UNIT_CATEGORY')" :disabled="isLoading" :items="categories" required id="business-unit-category-select" data-testing="business-unit-form-category-input" class="cr-mt3"/>
    </form-section>
</template>

<script setup lang="ts">
import Config from '../../config';
import FormSection from '../layouts/sections/FormSection.vue';
import { IBusinessUnit, BusinessUnitCategory } from '@cyber-range/cyber-range-api-business-unit-client';
import { reactive, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useEnum } from '../../composables/useEnum';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
    modelValue:IBusinessUnit,
}>();
const emit = defineEmits<{
    (e:'update:modelValue', businessUnit:IBusinessUnit): void,
}>();

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

let businessUnit = reactive(props.modelValue);
const categories = useEnum().toSelectItems(BusinessUnitCategory);

watch(()=>({...businessUnit}), ()=>
{
    emit('update:modelValue', businessUnit);
});
</script>