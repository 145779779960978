<template>
    <cr-delete-confirmation-dialog id="deleteCourseDialog" @confirm="onConfirmed" :disabled="isLoading" data-testing="delete-course-dialog">
        <cr-html :value="t('COURSES_COURSE_DELETE_COURSE_DIALOG_MESSAGE', {courseName: course?.name})" />
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useCourseStore } from '../../../stores/courseStore';
import { storeToRefs } from 'pinia';
import { ICourse } from '@cyber-range/cyber-range-api-user-client';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    course?: ICourse;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    if(props.course)
    {
        await useCourseStore().delete(props.course.id);
        emit('confirm');
    }
}
</script>