<template>
    <cr-confirmation-dialog v-model="showDialog" :title="t('EXERCISE_COPY_EXERCISE_DIALOG_TITLE')" @confirm="onConfirmed" @cancel="onCanceled">
        <p v-for="text in bodyTexts">{{ text }}</p>
        <cr-text-field class="cr-mt3" v-model="name" :label="t('EXERCISE_COPY_EXERCISE_DIALOG_NAME_LABEL')" required :max-length="264" />
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
    modelValue: boolean
}>();
const emit = defineEmits<{
    (name: 'update:modelValue', value: boolean): void
    (name: 'confirm', snapshotName: string): void
    (name: 'cancel'): void
}>()
const showDialog = computed({
    get: () => props.modelValue,
    set:(value: boolean) => emit('update:modelValue', value)
})
const name = ref('')

const bodyTexts = t('EXERCISE_COPY_EXERCISE_DIALOG_BODY').split('\n')

const onConfirmed = () => emit('confirm', name.value);
function onCanceled()
{
    name.value = '';
    emit('cancel');
}
</script>
