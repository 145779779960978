<template>
    <search-layout :title="title" :ready="ready" :breadcrumbs="breadcrumbs">
        <template #top>
            <cr-search-bar v-model="keyword" />
        </template>
        <template #filter>
            <search-filter v-model="filter" />
        </template>
        <template #status v-if="searchResults?.length === 0">
            <span data-testing="search-page-no-results">
                <cr-html :value="noResultsText"/>
            </span>
            <span data-testing="search-page-no-results-suggestions">
                <h2 class="cr-mt5">{{ t('SEARCH_NO_RESULTS_SUGGESTIONS') }}</h2>
                <ul>
                    <li v-if="keyword">{{  t('SEARCH_NO_RESULTS_SUGGESTIONS_1') }}</li>
                    <li v-if="keyword">{{  t('SEARCH_NO_RESULTS_SUGGESTIONS_2') }}</li>
                    <li v-if="filter">{{  t('SEARCH_NO_RESULTS_SUGGESTIONS_3') }}</li>
                </ul>
            </span>
        </template>
        <template #result v-else>
            <search-result-item v-for="searchResult in searchResults" :search-result="searchResult"/>
        </template>
        <template #pagination>
            <cr-pagination :previous="!!page.prevPageToken"  :first="true" :next="!!page.nextPageToken" @previous="onPreviousPageClicked" @first="onFirstPageClicked" @next="onNextPageClicked" data-testing="search-pagination"/>
        </template>
    </search-layout>
</template>

<style scoped>
.cr-searchbar
{
    max-width: 15rem;
}
h2
{
    font-size: var(--cr-body-font-size);
    font-weight: bold;
}
</style>

<script setup lang="ts">
import { ApiPageResponse, IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import { ISearchResult, ItemType, ExerciseAreaSearchFilter} from '@cyber-range/cyber-range-api-search-client';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import Route from '../../routers/route';
import SearchLayout from '../layouts/SearchLayout.vue';
import SearchFilter from './SearchFilter.vue';
import { useEnum } from '../../composables/useEnum';
import { useAnnouncementStore } from '@cyber-range/cyber-range-lib-ui';
import { useSearchStore } from '../../stores/searchStore';
import SearchResultItem from './SearchResultItem.vue';

const { t } = useI18n();
const { toDisplayEnumName } = useEnum();
const ready = ref<boolean>(false);
const query = computed(()=>useRoute().query);
const breadcrumbs = [ Route.Search ];
const keyword = ref<string|undefined>(query.value?.q?.toString());
const filter = ref<string|undefined>(query.value?.filter?.toString());
const token = ref<string|undefined>(query.value?.token?.toString());
const searchResults = ref<ISearchResult[]>();
const title = computed(()=>t(keyword.value ? 'SEARCH_TITLE_WITH_KEYWORD' : 'SEARCH_TITLE', {keyword: keyword.value}));
const router = useRouter();

let page = ref<IApiPageResponse<ISearchResult>>(new ApiPageResponse<ISearchResult>({items:[]}));

const search = ()=>
{
    router.push({query: {q: keyword.value, filter: filter.value, token:undefined}});
}

const onFirstPageClicked = () => { router.push({query: {q: keyword.value, filter: filter.value, token:token.value ? undefined : "0"}}); };
const onNextPageClicked = () => { router.push({ query: { q: keyword.value, filter: filter.value, token: page.value.nextPageToken } }) };
const onPreviousPageClicked = () => { router.push({ query: { q: keyword.value, filter: filter.value, token: page.value.prevPageToken } }) };

const toNoResultsFilterValue = ():string =>
{
    const itemTypes = filter.value?.split(',') || [];
    let result = toDisplayEnumName(ItemType, filter.value).toLocaleLowerCase();
    if(!result)
    {
        if(itemTypes.length > 1)
        {
            result = t('SEARCH_NO_RESULTS_FILTER_NAME_DOCUMENT').toString();
        }
        else if(itemTypes.length === 1 && itemTypes[0] === 'exerciseenvironment')
        {
            result = t('SEARCH_NO_RESULTS_FILTER_NAME_EXERCISE_ENVIRONMENT').toString();
        }
    }
    return result;
}

const noResultsText = computed(():string =>
{
    if(!!filter.value && !!keyword.value)
    {
        return t('SEARCH_WITH_BOTH_KEYWORD_AND_FILTER_NO_RESULT', {keyword:keyword.value, filter: toNoResultsFilterValue()}).toString();
    }
    else if(!!filter.value)
    {
        return t('SEARCH_WITH_FILTER_NO_RESULTS', {filter: toNoResultsFilterValue()}).toString();
    }
    else if(!!keyword.value)
    {
        return t('SEARCH_WITH_KEYWORD_NO_RESULTS', {keyword:keyword.value});
    }
    else
    {
        return t('SEARCH_NO_RESULTS');
    }                
});

watch(searchResults,()=>
{
    let itemCount = searchResults.value?.length;
    if(itemCount)
    {
        useAnnouncementStore().announce(t('SEARCH_RESULTS_UPDATED',{itemCount,s:itemCount===1?'':'s'}))
    }
    else
    {
        useAnnouncementStore().announce(noResultsText.value.replace(/(<([^>]+)>)/ig, ''))
    }
})

watch([keyword, filter],  search,);

onMounted(async () =>
{
    if (token.value === "0")
    {
        search();
    }
    else
    {
        ready.value = false;

        let exerciseAreaSearchFilter = new ExerciseAreaSearchFilter({ types: [], token: token.value });
        const displayItemTypeExerciseEnvironment = 'exerciseenvironment';
        [ItemType.Organization, ItemType.Course, ItemType.User].forEach(ItemType =>
        {
            if (filter.value?.includes(ItemType))
            {
                exerciseAreaSearchFilter.types.push(ItemType);
            }
        });

        if (filter.value?.includes(displayItemTypeExerciseEnvironment))
        {
            exerciseAreaSearchFilter.types.push(ItemType.ExerciseGroup);
        }
        page.value = await useSearchStore().search(keyword.value, exerciseAreaSearchFilter)
        searchResults.value = page.value.items;

        ready.value = true;
    }
});
</script>