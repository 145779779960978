<template>
    <read-section :label="t('PLAN_PRICING')" data-testing="pricing-section">
        <cr-row tag="dl">
            <template v-if="feature || basePrice">
                <cr-col tag='dt' xs12 sm12 md12  lg6 xl6 xxl4 label="">
                    {{ t('PLAN_PRICING_BASE_PRICE') }}
                </cr-col>
                <cr-col tag='dd' xs12 sm12 md12 lg6 xl6 xxl8>
                    {{ toCurrency(basePrice) }}
                </cr-col>
            </template>
            <template v-for="feature of features">
                <template v-if="feature.unitPrice">
                    <cr-col tag='dt' xs12 sm12 md12 lg6 xl6 xxl4>
                        {{ feature.name }}
                    </cr-col>
                    <cr-col tag='dd' xs12 sm12 md12 lg6 xl6 xxl8>
                        {{ toCurrency(feature.unitPrice) }}
                    </cr-col>
                </template>
            </template>
        </cr-row>
    </read-section>
</template>

<style scoped>
dt
{
    font-weight: unset;
    
}
dd
{
    margin-bottom: unset;
}
</style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { IPlan } from '../../../interfaces/iPlan';
import ReadSection from '../../layouts/sections/ReadSection.vue';
import { useNumberFormats } from '../../../composables/useNumberFormats';
import { IFeature } from '../../../interfaces/iFeature';
import { computed } from 'vue';

const props = defineProps<
{
    plan?:IPlan;
    feature?:IFeature;
}>();

const { t } = useI18n();
const { toCurrency } = useNumberFormats();

const basePrice = computed(()=> props.plan?.basePrice || props.feature?.unitPrice || 0);
const features = computed(()=> props.plan?.features || []);
</script>
