<template>
    <form-info-section :label="t('FORM_LAYOUT_FORM_DETAIL_SECTION_LABEL')">
        <cr-row tag="dl">
            <slot />
        </cr-row>
    </form-info-section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import FormInfoSection from './FormInfoSection.vue';

const { t } = useI18n()
</script>