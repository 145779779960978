<template>
    <form-layout :title="t('INVITE_SUPER_ADMIN_PAGE_TITLE')" :breadcrumbs="breadcrumbs" :ready="isReady" @confirm="onConfirm" @cancel="onCancel">
        <template #form>
            <super-admin-form v-model="invitation"/>
        </template>
    </form-layout>
</template>

<script setup lang="ts">
import FormLayout from '../layouts/FormLayout.vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { computed, onMounted, ref } from 'vue';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import Route from '../../routers/route';
import { useOrganizationStore } from '../../stores/organizationStore';
import { IInvitation, Invitation } from '@cyber-range/cyber-range-api-invitation-client';
import { useRawObject } from '../../composables/useRawObject';
import { useInvitationStore } from '../../stores/invitationStore';
import { UserRole } from '@cyber-range/cyber-range-api-user-client';
import SuperAdminForm from './SuperAdminForm.vue';

const router = useRouter();
const { t } = useI18n();

const invitation = ref<IInvitation>(new Invitation({}));

const breadcrumbs = computed(()=> {
    return [
        new BreadcrumbItem(Route.SuperAdmins),
        new BreadcrumbItem(Route.InviteSuperAdmins)
    ]
});

const isReady = ref(false);

const onConfirm = async ()=>
{

    let invitationPayload = useRawObject(invitation.value);

    await useInvitationStore().createWithRole(invitationPayload, UserRole.SuperAdmin);

    router.push({ name: Route.SuperAdmins.name });
}

const onCancel = async ()=>
{
    router.push({ name: Route.SuperAdmins.name });
}

onMounted(async () => 
{
    await useOrganizationStore().fetchOrganizationNameAndLogo();
    isReady.value = true;
});

</script>