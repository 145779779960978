<template>
    <cr-card :to="organizationRoute" :toDisabled="!organizationIsAccessible" data-testing="organization-tile">
        <cr-card-image class="image cr-mb1 cr-p3" contain :src="organizationLogo" alt="" data-testing="organization-logo"/>
        <cr-card-body>
            <cr-card-title tag="h2" data-testing="organization-name" class="cr-mb3 cr-font-bold">{{ organization.name }}</cr-card-title>
            <cr-card-text v-if="organization.status === OrganizationStatus.READY">
                <tile-detail-section>
                    <tile-detail-section-item icon="bi-journals" :alt="t('ORGANIZATIONS_ORGANIZATION_NUMBER_OF_COURSES')" data-testing="organization-courses">
                        {{ organization.statistics?.numberOfCourses }} {{ t('ORGANIZATIONS_ORGANIZATION_NUMBER_OF_COURSES') }}
                    </tile-detail-section-item>
                    <tile-detail-section-item icon="bi-people-fill" :alt="t('ORGANIZATIONS_ORGANIZATION_NUMBER_OF_USERS')" data-testing="organization-users">
                        {{ organization.statistics?.numberOfUsers }} {{ t('ORGANIZATIONS_ORGANIZATION_NUMBER_OF_USERS') }}
                    </tile-detail-section-item>
                    <tile-detail-section-item icon="bi-display" :alt="t('ORGANIZATIONS_ORGANIZATION_NUMBER_OF_EXERCISES')" data-testing="organization-exercises">
                        {{ organization.statistics?.numberOfExercises }} {{ t('ORGANIZATIONS_ORGANIZATION_NUMBER_OF_EXERCISES') }}
                    </tile-detail-section-item>
                </tile-detail-section>
            </cr-card-text>
            <cr-card-text v-else class="cr-text-center cr-ptb4">
                <cr-error-text v-if="organization.status === OrganizationStatus.ERROR"  :message="toDisplayEnumName(OrganizationStatus, organization.status)"/>
                <cr-loading v-else-if="organization.status === OrganizationStatus.DELETING" :message="toDisplayEnumName(OrganizationStatus, organization.status)" />
                <cr-warning-text v-else :message="useEnum().toDisplayEnumName(OrganizationStatus, organization.status)" />
            </cr-card-text>
        </cr-card-body>
    </cr-card>
</template>

<style scoped>
.image
{
    background-color: var(--cr-tile-image-background-color);
    height: var(--cr-tile-image-height);
}
.cr-card-title
{
    font-size: var(--cr-tile-title-font-size);
}
</style>

<script setup lang="ts">
import { computed } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import { useEnum } from '../../composables/useEnum';
import Route from '../../routers/route';
import { IOrganization, OrganizationStatus } from '@cyber-range/cyber-range-api-organization-client';
import TileDetailSection from '../layouts/sections/TileDetailSection.vue';
import TileDetailSectionItem from '../layouts/sections/TileDetailSectionItem.vue';
import { useOrganizationStore } from '../../stores/organizationStore';

const { t } = useI18n();
const { toDisplayEnumName } = useEnum();

const props = defineProps<
{
    organization:IOrganization
}>();

const organizationLogo = computed(() => props.organization.logo || useOrganizationStore().defaultLogo);

const organizationIsAccessible = computed(()=>
{
    return ![OrganizationStatus.DELETING, OrganizationStatus.ERROR, OrganizationStatus.UPDATING].includes(props.organization.status);
});

const organizationRoute = computed(()=>
{
    return organizationIsAccessible ? {...Route.Organization, params:{organizationId: props.organization.id}} : undefined;
});
</script>