export default {
    en: {
        // Features
        FEATURES_PAGE_TITLE: 'Features',

        // Features table
        FEATURES_TABLE_NAME: 'Name',
        FEATURES_TABLE_UNIT_PRICE: 'Unit Price',
        FEATURES_TABLE_NOTES: 'Notes',
        FEATURE_NOTES: '{number} additional limits',
        FEATURE_TABLE_VIEW_PLAN_ACTION_ITEM: 'View Plan',

        // Feature
        FEATURE_NOT_FOUND: 'The feature "{addonId}" could not be found.',
    }
}