<template>
    <form-section>
        <cr-text-field v-model="application.name" :label="t('ORGANIZATION_APPLICATIONS_LTI_FORM_NAME')" :disabled="isLoading" required :max-length="100" data-testing="lti-13-organization-application-form-name-input" class="cr-mb3" />
        <cr-text-field v-model="application.issuer" :label="t('ORGANIZATION_APPLICATIONS_LTI_FORM_ISSUER')" :disabled="isLoading" required :max-length="100" data-testing="lti-13-organization-application-form-issuer-input" class="cr-mb3 cr-mt3" />
        <cr-text-field v-model="application.clientId" :label="t('ORGANIZATION_APPLICATIONS_LTI_FORM_CLIENT_ID')" :disabled="isLoading" required :max-length="100" data-testing="lti-13-organization-application-form-client-id-input" class="cr-mb3 cr-mt3" />
        <cr-text-field v-model="application.keySetUrl" :label="t('ORGANIZATION_APPLICATIONS_LTI_FORM_KEY_SET_URL')" :disabled="isLoading" required :max-length="1024" type="url" data-testing="lti-13-organization-application-form-key-set-input" class="cr-mb3 cr-mt3" />
        <cr-text-field v-model="application.accessTokenUrl" :label="t('ORGANIZATION_APPLICATIONS_LTI_FORM_ACCESS_TOKEN_URL')" :disabled="isLoading" required :max-length="1024" type="url" data-testing="lti-13-organization-application-form-access-token-url-input" class="cr-mb3 cr-mt3" />
        <cr-text-field v-model="application.authenticationUrl" :label="t('ORGANIZATION_APPLICATIONS_LTI_FORM_AUTHENTICATION_URL')" :disabled="isLoading" required :max-length="1024" type="url" data-testing="lti-13-organization-application-form-authentication-url-input" class="cr-mb3 cr-mt3" />
        <cr-text-field v-model="deploymentIds" id="lti13-deployment-id-text-field" :label="t('ORGANIZATION_APPLICATIONS_LTI_FORM_DEPLOYMENT_IDS')" :disabled="isLoading" required data-testing="lti-13-organization-application-form-deployment-ids-input" class="cr-mt3" />
        <label for="lti13-deployment-id-text-field" class="cr-caption" data-testing="lti13-deployment-id-hint">{{ t('ORGANIZATION_APPLICATIONS_LTI_FORM_DEPLOYMENT_IDS_HINT') }}</label>
    </form-section>
</template>

<script setup lang="ts">
import { Lti13OrganizationApplication } from '@cyber-range/cyber-range-api-organization-client';
import FormSection from '../layouts/sections/FormSection.vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../stores/apiClientStore';
import { computed, reactive, watch } from 'vue';


const props = defineProps<{
    modelValue: Lti13OrganizationApplication,
}>();
const emit = defineEmits<{
    (e:'update:modelValue', application:Lti13OrganizationApplication): void,
}>();

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

let application = reactive(props.modelValue);

const deploymentIds = computed(
    {
        get: () => application.deploymentIds?.join(', ') || '',
        set: (value: string) => application.deploymentIds = value.split(',').map(id => id.trim()).filter(id => !!id)
    })

watch(()=>({...application}), ()=>
{
    emit('update:modelValue', application);
});

</script>