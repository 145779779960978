<template>
    <cr-confirmation-dialog :title="t('SUPPORT_RECIPIENTS_DIALOG_TITLE')" @confirm="onConfirmed" :cancel="false" data-testing="support-recipients-dialog">
            <p> {{ t('SUPPORT_RECIPIENT_DIALOG_TEXT',{recipientsText}) }} </p>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { ISupportRecipient } from '../../../interfaces/iSupportRecipient';
import { computed } from 'vue';

const { t } = useI18n();

const props = defineProps<
{
    recipients: ISupportRecipient[]
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>()

const recipientsText = computed(():string=>
{
    return props.recipients.map(r=> `${r.name} (${r.role})`).join(t('SUPPORT_RECIPIENT_SEPARATOR')) + (props.recipients.length > 1 ? t('SUPPORT_PERFECT_PRESENT_PLURAL') : t('SUPPORT_PERFECT_PRESENT_SINGULAR'))  
})

const onConfirmed = ()=> emit('confirm');
</script>