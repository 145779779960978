function toYYYYMMDD(date:Date): string
{
    return date.toISOString().substring(0,10);
}

function toHuman(timestamp?:string): string
{
    if(!timestamp) return '';

    return new Date(timestamp).toLocaleString('en-US', { timeZone: 'UTC' }) + ' UTC';
}

function toHumanDate(timestamp?:string): string
{
    if(!timestamp) return '';

    return new Date(timestamp).toLocaleDateString('en-US', { timeZone: 'UTC' }) + ' UTC';
}

function toHumanDuration(duration?: number, options?: { minimumResolution: 'week'|'day'|'hour'|'minute'|'second'}): string
{
    if (!duration) return '';

    const durationStrings: string[] = [];
    const minimumResolution = options?.minimumResolution || 'second';

    const values = [
        { key: 'week', divisor: 7 * 24 * 60 * 60 * 1000 },
        { key: 'day', divisor: 24 * 60 * 60 * 1000 },
        { key: 'hour', divisor: 60 * 60 * 1000 },
        { key: 'minute', divisor: 60 * 1000 },
        { key: 'second', divisor: 1000 }
    ];
    for (const {key, divisor} of values)
    {
        if (divisor > duration && key !== minimumResolution) continue;

        const value = Math.floor(duration / divisor);

        if (value === 1)
        {
            durationStrings.push(`${value} ${key}`);
        }
        else if (value > 1)
        {
            durationStrings.push(`${value} ${key}s`);
        }
        else if (durationStrings.length === 0 && key === minimumResolution)
        {
            durationStrings.push(`${value} ${key}s`);
        }
        duration %= divisor;

        if (duration === 0 || key === minimumResolution) break;
    }
    return durationStrings.join(', ');
}

function toHumanDurationDigital(duration: number): string
{
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.round(duration % 60);
    return [
        hours,
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0'),
    ].join(':')
}

function getEndOfAcademicYear(date:Date): Date
{
    return date.getUTCMonth() <= 2 // March, geMonth() returns 0 for January
        ? new Date(Date.UTC(date.getUTCFullYear(), 5, 15))
        : new Date(Date.UTC(date.getUTCFullYear() + 1, 5, 15))
}

function getEndOfSemester(date:Date): Date
{
    let month = date.getUTCMonth(); //geMonth() returns 0 for January
    let year = date.getUTCFullYear();

    //Spring, <= April
    if(month <= 3) return new Date(Date.UTC(year, 4, 15));
    //Summer, <= July
    if(month <= 6) return new Date(Date.UTC(year, 7, 15));
    //Falls, >= August
    return new Date(Date.UTC(year, 11, 15)); 
}

function toEndOfDayISOString(dateOrString:string|Date): string
{
    let date = typeof dateOrString === 'string' ? new Date(Date.parse(dateOrString)) : dateOrString;
    return new Date(date.setUTCHours(23,59,59,999)).toISOString();
}

function toStartOfDayISOString(dateOrString:string|Date): string
{
    let date = typeof dateOrString === 'string' ? new Date(Date.parse(dateOrString)) : dateOrString;
    return new Date(date.setUTCHours(0,0,0,0)).toISOString();
}

function getStartOfMonth(date: Date = new Date()): Date
{
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1))
}

function getEndOfMonth(date: Date = new Date()): Date
{
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0))
}

let usableFunctions = 
{
    toYYYYMMDD,
    toHuman,
    toHumanDate,
    toHumanDuration,
    toHumanDurationDigital,
    getEndOfAcademicYear,
    getEndOfSemester,
    toEndOfDayISOString,
    toStartOfDayISOString,
    getStartOfMonth,
    getEndOfMonth,
}

export function useCalendar(): typeof usableFunctions
{
    return usableFunctions;
}