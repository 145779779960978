<template>
    <table-layout :title="t('SUPER_ADMIN_PAGE_TITLE')" :breadcrumbs="breadcrumbs">
        <template #table>
            <cr-table :items="page?.items" :headers="headers" @sort="onSort" :loading="isLoading" actionable @suggested-number-of-items="onSuggestedNumberOfItems" data-testing="super-admin-table">
                <template v-slot:identityProvider="{value}">
                    <cr-identity-provider-icon v-if="value" :provider="value"/>
                </template>
                <template v-slot:action="{item}">
                    <cr-table-action-item v-if="isUser(item)" :item="item" :to="{ name: Route.Profile.name, params: { userId: item.id }}" icon="bi-person-vcard" data-testing="super-admin-table-view-profile-action">
                        {{ t('SUPER_ADMIN_VIEW_PROFILE_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="isUser(item)" :item="item" @click="onDeleteUserActionItemClicked" icon="bi-trash" data-testing="super-admin-table-delete-user-action">
                        {{ t('SUPER_ADMIN_DELETE_USER_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="isInvitation(item)" :item="item" @click="onReInviteActionItemClicked" icon="bi-envelope" data-testing="super-admin-table-resend-invitation-action">
                        {{ t('SUPER_ADMIN_RESEND_INVITATION_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="isInvitation(item)" :item="item" @click="onDeleteInvitationActionItemClicked" icon="bi-trash" data-testing="super-admin-table-delete-invitation-action">
                        {{ t('SUPER_ADMIN_DELETE_INVITATION_BUTTON') }}
                    </cr-table-action-item>
                </template>
            </cr-table>
            <resend-organization-invitation-dialog v-model="showReinviteDialog" :invitation="selectedItem"/>
            <delete-organization-invitation-dialog v-model="showDeleteInvitationDialog" :invitation="selectedItem" @confirm="onTableChangeConfirmed"/>
            <delete-super-admin-dialog v-model="showDeleteUserDialog" :user="selectedItem" @confirm="onTableChangeConfirmed"/>
        </template>
        <template #pagination>
            <cr-pagination :previous="!!page?.prevPageToken" :first="true" :next="!!page?.nextPageToken" @first="onLoadFirstPage" @previous="onLoadPreviousPage" @next="onLoadNextPage" :loading="isLoading" data-testing="super-admin-table-pagination"/>
        </template>
        <template #controls>
            <table-control-item v-if="canInviteSuperAdmin()">
                <cr-button :to="Route.InviteSuperAdmins" outlined data-testing="superadmins-invite-superadmin-button">
                        <cr-icon>bi-plus</cr-icon>
                        {{ t('SUPER_ADMIN_INVITE_SUPER_ADMIN_BUTTON') }}
                </cr-button>
            </table-control-item>
       </template>
    </table-layout>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import TableLayout from '../layouts/TableLayout.vue';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../stores/apiClientStore';
import { BreadcrumbItem, TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import Route from '../../routers/route';
import { useTableLayoutPagination } from '../../composables/useTableLayoutPagination';
import { useQueryFilter } from '../../composables/useQueryFilter';
import { IOrganizationUser, IOrganizationUserFilter, OrganizationUserFilter, OrganizationUserTargetType, UserRole } from '@cyber-range/cyber-range-api-user-client';
import { useUserStore } from '../../stores/userStore';
import { useCalendar } from '../../composables/useCalendar';
import { ref } from 'vue';
import ResendOrganizationInvitationDialog from '../organizations/dialogs/ResendOrganizationInvitationDialog.vue';
import DeleteOrganizationInvitationDialog from '../organizations/dialogs/DeleteOrganizationInvitationDialog.vue';
import DeleteSuperAdminDialog from './dialogs/DeleteSuperAdminDialog.vue';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import TableControlItem from '../layouts/sections/TableControlItem.vue';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { canInviteSuperAdmin } = useAuthorizationStore();

const breadcrumbs = [new BreadcrumbItem(Route.SuperAdmins)];

const defaultFilter: Partial<OrganizationUserFilter> = 
{
    roles: [UserRole.SuperAdmin]
};

const refresh = (filter?: Partial<IOrganizationUserFilter>) =>
{
    return useUserStore().listOrganizationUsers('any', new OrganizationUserFilter(filter));
}

const {
    filter,
    onLoadFirstPage,
    onLoadNextPage,
    onLoadPreviousPage,
    onSort,
    onSuggestedNumberOfItems,
    page
} = useTableLayoutPagination(useQueryFilter(OrganizationUserFilter, defaultFilter, {arrayProperties: ['roles']}), refresh, { clearTokenKeys: []})

const headers = [
    new TableHeaderItem({text: t('SUPER_ADMIN_HEADER_NAME'), key: 'name', sortable: true}),
    new TableHeaderItem({text: t('SUPER_ADMIN_HEADER_EMAIL'), key: 'email', sortable: true}),
    new TableHeaderItem({text: t('SUPER_ADMIN_HEADER_IDENTITY_PROVIDER'), key: 'identityProvider', sortable: true, align: 'center'}),
    new TableHeaderItem({text: t('SUPER_ADMIN_HEADER_LAST_LOGIN'), key: 'lastLogin', sortable: true, formatter: useCalendar().toHumanDate}),
    new TableHeaderItem({text: t('SUPER_ADMIN_HEADER_PENDING_SINCE'), key: 'pendingSince', sortable: true, formatter: useCalendar().toHumanDate}),
]

const isUser = (orgUser: IOrganizationUser) => orgUser.targetType === OrganizationUserTargetType.User;
const isInvitation = (orgUser: IOrganizationUser) => orgUser.targetType === OrganizationUserTargetType.Invitation;

const selectedItem = ref<IOrganizationUser>();
const showReinviteDialog = ref<boolean>(false);
const showDeleteInvitationDialog = ref<boolean>(false);
const showDeleteUserDialog = ref<boolean>(false);

const onTableChangeConfirmed = async () =>
{
    page.value = await refresh(filter);
}

const onReInviteActionItemClicked = (item:IOrganizationUser) =>
{
    selectedItem.value = item;
    showReinviteDialog.value = true;
}

const onDeleteInvitationActionItemClicked = (item:IOrganizationUser) =>
{
    selectedItem.value = item;
    showDeleteInvitationDialog.value = true;
}

const onDeleteUserActionItemClicked = (item:IOrganizationUser) =>
{
    selectedItem.value = item;
    showDeleteUserDialog.value = true;
}
</script>