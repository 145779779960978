export default {
    en: {
        SUPPORT_TITLE: "Support",
        SUPPORT_TOPIC_CATEGORY_SECTION_LABEL:"Topic",
        SUPPORT_CATEGORY:"This issue is related to my",
        SUPPORT_TOPIC:"Topic",
        SUPPORT_TOPIC_ITEM_SECTION_LABEL_COURSE:"Course",
        SUPPORT_TOPIC_ITEM_SECTION_LABEL_ORGANIZATION:"Organization",
        SUPPORT_MESSAGE_SECTION_LABEL:"How can we help you?",
        SUPPORT_SUBJECT_LABEL:"Subject",
        SUPPORT_MESSAGE_LABEL:"Message",
        SUPPORT_NO_TOPICS_DIALOG_TITLE:"No Active Courses",
        SUPPORT_NO_TOPICS_DIALOG_BODY:"We cannot route your support request because you are not currently enrolled in any courses. Please contact your instructor for more information.",
        SUPPORT_NO_TOPICS_DIALOG_INVITATION_KB_TEXT:"Learn about accepting an invitation",
        SUPPORT_NO_TOPICS_DIALOG_COURSE_CODE_KB_TEXT:"Learn about course codes.",
        SUPPORT_RECIPIENTS_DIALOG_TITLE:"Help is on the way!",
        SUPPORT_RECIPIENT_DIALOG_TEXT:"{recipientsText} been notified to assist you.",
        SUPPORT_RECIPIENT_SEPARATOR: " and ",
        SUPPORT_PERFECT_PRESENT_PLURAL:" have",
        SUPPORT_PERFECT_PRESENT_SINGULAR:" has",
        SUPPORT_EMAIL_LABEL: "email" 
    }
}