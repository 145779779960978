import { CourseStatus } from "@cyber-range/cyber-range-api-user-client";
import { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import Config from "../../config";
import { useAuthenticationStore } from "../../stores/authenticationStore";
import { useAuthorizationStore } from "../../stores/authorizationStore";
import { useCourseStore } from "../../stores/courseStore";
import { useEntitlementStore } from "../../stores/entitlementStore";

const checkCourseAccess: NavigationGuard = async (to, from, next) =>
{
    const courseId = to.params.courseId as string;

    const { isCourseRequestorOnly } = useAuthorizationStore();

    if(!isCourseRequestorOnly(courseId))
    {
        return next();
    }

    const course = await useCourseStore().getCourse(courseId);
    if (course.status !== CourseStatus.Ready)
    {
        return next();
    }

    // Retry claim check after refreshing claims for users that were logged in before course was approved
    await useEntitlementStore().fetchClaims();
    if (!isCourseRequestorOnly(courseId))
    {
        return next();
    }

    // If they are still a course requester only, direct them to login to join the course
    const ssoToken = await useAuthenticationStore().getSsoToken()
    window.location.assign(new URL(`/join-course/${courseId}/role/instructor?s=${ssoToken}`, Config.LOGIN_UI_BASE_URL).href);
}
export default checkCourseAccess;
