<template>
    <cr-delete-confirmation-dialog id="deleteOrganizationDialog" @confirm="onConfirmed" :disabled="isLoading" data-testing="delete-organization-dialog">
        <cr-html :value="t('ORGANIZATIONS_ORGANIZATION_DELETE_ORGANIZATION_DIALOG_MESSAGE', {organizationName: organization?.name})" />
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useOrganizationStore } from '../../../stores/organizationStore';
import { storeToRefs } from 'pinia';
import { IOrganization } from '@cyber-range/cyber-range-api-organization-client';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    organization?: IOrganization;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    if(props.organization)
    {
        await useOrganizationStore().deleteOrganization(props.organization.id);
        emit('confirm');
    }
}
</script>