import ISession from "../interfaces/iSession";

export default class Session implements ISession
{
    userId: string | undefined;
    userName: string | undefined;
    impersonator: string | undefined;
    impersonatorName: string | undefined;
    token: string | undefined;
    returnPath: string | undefined;
    timerIds: string[] | undefined;

    constructor(params: Partial<ISession>)
    {
        this.userId = params.userId;
        this.userName = params.userName;
        this.impersonator = params.impersonator;
        this.impersonatorName = params.impersonatorName;
        this.token = params.token;
        this.returnPath = params.returnPath;
        this.timerIds = params.timerIds;
    }
}