<template>
    <read-layout :title="businessUnit?.name" :breadcrumbs="breadcrumbs" :ready="isReady">
        <template #top>
            <cr-row>
                <cr-col xs12 sm12 md12 lg12 xl8>
                    <read-detail-section data-testing="business-unit-details-label">
                        <read-detail-section-item :label="t('BUSINESS_UNIT_REGION')" data-testing="business-unit-region">
                            {{ businessUnit?.region }}
                        </read-detail-section-item>
                        <read-detail-section-item :label="t('BUSINESS_UNIT_CATEGORY')" data-testing="business-unit-category">
                            {{ useEnum().toDisplayEnumName(BusinessUnitCategory, businessUnit?.category) }}
                        </read-detail-section-item>
                        <read-detail-section-item :label="t('BUSINESS_UNIT_CREATION')" data-testing="business-unit-creation">
                            {{ toHumanDate(businessUnit?.createdTimestamp) }}
                        </read-detail-section-item>
                    </read-detail-section>
                </cr-col>
            </cr-row>
        </template>
        <template #controls v-if="canEditBusinessUnit()">
            <read-control-item>
                <cr-button :diabled="isLoading" outlined :to="{ name: Route.EditBusinessUnit.name, params:{organizationId:props.organizationId, businessUnitId: props.businessUnitId}}" data-testing="edit-business-unit-button">
                    <cr-icon>bi-pencil</cr-icon>
                    {{ t('BUSINESS_UNITS_BUSINESS_UNIT_EDIT_BUSINESS_UNIT_BUTTON') }}
                </cr-button>
            </read-control-item>
        </template>
    </read-layout>
</template>

<script setup lang="ts">
import ReadLayout from '../layouts/ReadLayout.vue';
import ReadDetailSection from '../layouts/sections/ReadDetailSection.vue';
import ReadDetailSectionItem from '../layouts/sections/ReadDetailSectionItem.vue';
import Route from '../../routers/route';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { IBusinessUnit, BusinessUnitCategory } from '@cyber-range/cyber-range-api-business-unit-client';
import { IOrganization } from '@cyber-range/cyber-range-api-organization-client';
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useBusinessUnitStore } from '../../stores/businessUnitStore';
import { useCalendar } from '../../composables/useCalendar';
import { useEnum } from '../../composables/useEnum';
import { useI18n } from 'vue-i18n';
import { useOrganizationStore } from '../../stores/organizationStore';
import ReadControlItem from '../layouts/sections/ReadControlItem.vue';

const props = defineProps<
{
    organizationId:string;
    businessUnitId:string;
}>();

const { t } = useI18n();
const { canEditBusinessUnit } = useAuthorizationStore();
const { isLoading } = storeToRefs(useApiClientStore());
const { toHumanDate } = useCalendar();
const { toDisplayEnumName } = useEnum();

const businessUnit = ref<IBusinessUnit>();
const organization = ref<IOrganization>();
const isReady = ref(false);

const breadcrumbs = computed(()=>[ 
                        new BreadcrumbItem(Route.Organizations), 
                        new BreadcrumbItem({...Route.Organization, text: organization.value?.name, params: {organizationId: props.organizationId}}), 
                        new BreadcrumbItem({...Route.BusinessUnits, params: {organizationId: props.organizationId}}),
                        new BreadcrumbItem({...Route.BusinessUnit, text: businessUnit.value?.name, params: {businessUnitId: props.businessUnitId, organizationId: props.organizationId}})])

onMounted( async () => 
{
    businessUnit.value = await useBusinessUnitStore().getBusinessUnit(props.businessUnitId);
    organization.value = await useOrganizationStore().getOrganization(props.organizationId);
    isReady.value = true;
});
</script>