<template>
    <read-control-item>
        <cr-button :disabled="isExportProcessing" outlined @click="onExportDnsClicked">
            <cr-icon>bi-download</cr-icon>
            {{ t('EXERCISE_GROUP_EXPORT_DNS_BUTTON') }}
        </cr-button>
    </read-control-item>
</template>

<script setup lang="ts">
import { ref, toRaw } from 'vue';
import { useI18n } from 'vue-i18n';
import { useExerciseStore } from '../../stores/exerciseStore';
import ReadControlItem from '../layouts/sections/ReadControlItem.vue';
import {stringify} from 'csv-stringify/sync'
import { useDownload } from '../../composables/useDownload';
import { ExerciseGroupExercisesFilter, ExerciseGroupExercisesSortBy } from '@cyber-range/cyber-range-api-exercise-client';

const props = defineProps<{
    exerciseGroupId: string
}>()

const { t } = useI18n();
const exerciseStore = useExerciseStore();

const isExportProcessing = ref(false);
async function onExportDnsClicked()
{
    isExportProcessing.value = true
    try
    {
        const exercises = await exerciseStore.getAllExerciseGroupExercises(props.exerciseGroupId, new ExerciseGroupExercisesFilter({sortBy: ExerciseGroupExercisesSortBy.OwnerName}));
        
        const exportRecords = exercises.map(exercise =>
        {
            const record: Record<string, string> = {Team: exercise.ownerName};

            exercise.dnsRecords.forEach(r => record[r.name] = r.value)
            return record;
        });

        const data = stringify(exportRecords, {
            header: true,
        });
        useDownload().downloadFromCsv(data, 'exported_dns_records');
    }
    finally
    {
        isExportProcessing.value = false;
    }
}
</script>
