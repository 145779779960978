<template>
    <cr-card data-testing="subscription-tile">
        <cr-card-body class="cr-p0">
            <cr-card-title tag="div">
                <subscription-tile-header class="cr-pt2" :title="plan.name!" :subscription="subscription"/>
            </cr-card-title>
            <subscription-tile-body :cost="displayedCost" :plan-name="plan.name!" :subscription="subscription" :organization-id="organizationId" @delete="onDelete" @edit="onEdit" @renew="onRenew"/>
        </cr-card-body>
    </cr-card>
</template>

<style scoped>
.cr-card
{
    height: 100%;
}
</style>

<script setup lang="ts">
import { ISubscription } from "@cyber-range/cyber-range-api-subscription-client";
import SubscriptionTileBody from "./SubscriptionTileBody.vue";
import SubscriptionTileHeader from "./SubscriptionTileHeader.vue";
import { IPlan } from "../../interfaces/iPlan";

const props = defineProps<
{
    organizationId: string
    plan: IPlan
    subscription?: ISubscription
    displayedCost: string
}>();

const emit = defineEmits<{
    (name: 'delete', subscription: ISubscription): void
    (name: 'edit', subscription: ISubscription, plan: IPlan): void
    (name: 'renew', subscription: ISubscription): void
}>();

const onDelete = (subscription: ISubscription) => emit('delete', subscription);
const onEdit = (subscription: ISubscription) => emit('edit', subscription, props.plan);
const onRenew = (subscription: ISubscription) => emit('renew', subscription);
</script>