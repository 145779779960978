<template>
    <list-layout :ready="isReady" :title="whoseCourse.mine ? t('COURSES_MY_COURSES') : t('COURSES_COURSES')" :breadcrumbs="breadcrumbs">
        
        <template #filter v-if="showFilters">
            <list-filter-section>
                <list-filter-section-item>
                    <organization-filter v-model="organizationId" :organizations="organizations" data-testing="courses-organization-filter"/>
                </list-filter-section-item>
                <list-filter-section-item>
                    <user-filter v-model="userId" :users="users" :disabled="!organizationId" :label="t('COURSES_INSTRUCTOR_FILTER')" data-testing="courses-user-filter"/>
                </list-filter-section-item>
                <list-filter-section-item>
                    <to-from-date-filter v-model:model-value="date" v-model:model-modifier="modifier" :disabled="isLoading" :label="t('COURSES_DATE_FILTER')" data-testing="courses-expiration-filter"/>
                </list-filter-section-item>
                <list-filter-section-item>
                    <sorting v-model:model-value="sortBy" v-model:model-sort-order="sortOrder" :sortingOptions="sortingOptions" :disabled="isLoading" :label="t('COURSES_SORTING')" data-testing="courses-sorting"/>
                </list-filter-section-item>
            </list-filter-section>
        </template>

        <template #list>
            <list-section :no-items-message="whoseCourse.mine ? t('COURSES_COURSES_NO_ITEMS_MINE') : t('COURSES_COURSES_NO_ITEMS')">
                <list-section-item v-for="course in page.items">
                    <course-tile :course="course" />
                </list-section-item>
            </list-section> 
        </template>
        
        <template #pagination>
            <cr-pagination :previous="!!page.prevPageToken"  :first="true" :next="!!page.nextPageToken" @previous="onPreviousPageClicked" @first="onFirstPageClicked" @next="onNextPageClicked" data-testing="courses-pagination"/>
        </template>

        <template #controls>
            <list-control-item v-if="canCreateCourse()">
                <hint :hint-if="showCreateCourseHint" :messages="createCourseHintMessages" :hint-body-id="createCourseHintId">
                    <cr-button :to="Route.CreateCourse" outlined :aria-describedby="createCourseHintId" data-testing="create-course-button">
                        <cr-icon>bi-plus</cr-icon>
                        {{ t('COURSES_COURSE_CREATE_COURSE_BUTTON') }}
                    </cr-button>
                </hint>
            </list-control-item>
        </template>
        
    </list-layout>
</template>

<script setup lang="ts">
import CourseTile from './CourseTile.vue';
import { useCourseStore } from '../../stores/courseStore';
import { useI18n } from 'vue-i18n';
import ListLayout from '../layouts/ListLayout.vue';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import Route from '../../routers/route';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { onMounted, ref, computed, watch, toRaw, onBeforeUnmount } from 'vue';
import { CourseFilter, ICourse, IUser, UserRole, UserFilter as ApiUserFilter, CourseSortBy, CourseStatus } from '@cyber-range/cyber-range-api-user-client';
import { ApiPageResponse, IApiPageResponse, SortOrder } from '@cyber-range/cyber-range-api-client';
import Config from '../../config';
import { useWhoseCourse } from '../../composables/useWhoseCourse';
import { useQueryFilter } from '../../composables/useQueryFilter';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import ListSectionItem from '../layouts/sections/ListSectionItem.vue';
import ListSection from '../layouts/sections/ListSection.vue';
import { useOrganizationStore } from '../../stores/organizationStore';
import OrganizationFilter from '../filters/OrganizationFilter.vue';
import UserFilter from '../filters/UserFilter.vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '../../stores/userStore';
import { useApiClientStore } from '../../stores/apiClientStore';
import ToFromDateFilter from '../filters/ToFromDateFilter.vue';
import { DateModifier } from '../../interfaces/DateModifier';
import Sorting from '../filters/Sorting.vue';
import { useEnum } from '../../composables/useEnum';
import ListFilterSection from '../layouts/sections/ListFilterSection.vue';
import ListFilterSectionItem from '../layouts/sections/ListFilterSectionItem.vue';
import { useTimerStore } from '../../stores/timerStore';
import ListControlItem from '../layouts/sections/ListControlItem.vue';
import Hint from '../hints/Hint.vue'; 
import { useUniqueId } from '../../composables/useUniqueId';

const { t } = useI18n();
const whoseCourse = useWhoseCourse();
let { canCreateCourse } = useAuthorizationStore();
const { fetchOrganizationNameAndLogo, fetchOrganizations } = useOrganizationStore();
const { isLoading } = useApiClientStore();
const timerStore = useTimerStore();

const { organizations } = storeToRefs(useOrganizationStore())
const isReady = ref(false);

let page = ref<IApiPageResponse<ICourse>>(new ApiPageResponse<ICourse>({items:[]}));
let filter = useQueryFilter(CourseFilter, {limit:Config.DEFAULT_TILE_LISTING_PAGE_SIZE}, {arrayProperties: ['organizationIds']});

const organizationId = ref<string>(filter.organizationIds?.at(0) || '');
const organizationIds = computed(()=>organizationId.value ? [organizationId.value] : []);

const userId = ref<string|undefined>(filter.userId);
const users = ref<IUser[]>([]);

const date = ref<string>(filter.endsOnAfter || filter.endsOnBefore);
const modifier = ref<DateModifier|undefined>(filter.endsOnAfter ? DateModifier.OnOrAfter : DateModifier.OnOrBefore);
const endsOnAfter = computed(()=>date.value && modifier.value === DateModifier.OnOrAfter ? date.value : '');
const endsOnBefore = computed(()=>date.value && modifier.value === DateModifier.OnOrBefore ? date.value : '');
const endsOn = computed(()=>({ endsOnAfter: endsOnAfter.value, endsOnBefore: endsOnBefore.value }));

const sortBy = ref<CourseSortBy>(filter.sortBy);
const sortOrder = ref<SortOrder>(filter.sortOrder || SortOrder.Asc);
const sortingOptions = useEnum().toSelectItems(CourseSortBy);
const sorting = computed(()=>({sortBy: sortBy.value, sortOrder: sortBy.value ? sortOrder.value : SortOrder.Asc}));

const breadcrumbs = computed(()=>useCourseBreadcrumbs([ new BreadcrumbItem(Route.Courses) ]));
const showFilters = computed(()=>whoseCourse.value.others);

const showCreateCourseHint = computed(()=>
{
    const noCoursesShown = page.value?.items.length === 0;
    return !!(noCoursesShown && !!whoseCourse.value.mine);
});
const createCourseHintId = useUniqueId();
const createCourseHintMessages = [t('COURSES_HINTS_NO_COURSES'),t('COURSES_HINTS_REQUEST_COURSE'),t('COURSES_HINTS_USE_CREATE_COURSE_BUTTON')];

const reload = async (data:Partial<CourseFilter> = {}) =>
{
    Object.assign(filter, endsOn.value, sorting.value, {organizationIds: organizationIds.value, userId: userId.value}, data);
}
const continuePolling = computed(()=>
{
    const statusesToPoll = [CourseStatus.Pending_Deleting];
    return page.value?.items.some(course => statusesToPoll.includes(course.status));
})

let timerId: string;
const refresh = async (data:Partial<CourseFilter> = {}, background:boolean = false) =>
{
    if (timerId) 
    {
        timerStore.unschedule(timerId);
    }
    let listCoursesFilter = {...toRaw(filter), ...toRaw(endsOn.value), ...sorting.value, ...{organizationIds: organizationIds.value, userId: userId.value}, ...data}
    page.value = await useCourseStore().listCourses(new CourseFilter(listCoursesFilter), {background});

    if (continuePolling.value)
    {
        timerId = timerStore.schedule(refresh, [data, true], 5000, false);
    }
}

watch(modifier, async ()=>await refresh({ token: '' }));
watch(date,  async ()=>await refresh({ token: '' }));
watch(sortBy,  async ()=>await refresh({ token: '' }));
watch(sortOrder,  async ()=>{ if(sortBy.value) await refresh({ token: '' })});

let firstLoad = true;

watch(organizationId, async ()=>
{
    // On the initial page load, there is no need to refresh the courses, 
    // as they will be fetched in the onMounted function.
    if(!firstLoad)
    {
        await refresh({ organizationIds: organizationIds.value, token: '' });
    }
    firstLoad = false;

    if(showFilters.value)
    {
        // Fetch users for the user-filter or reset it everytime there is a change to org-filter's organizationId
        if(organizationId.value)
        {
            let listUserFilter = new ApiUserFilter({organizationId: organizationId.value, roles: [UserRole.OrganizationInstructor]});
            users.value = await useUserStore().listAllUsers(listUserFilter);
        }
        else
        {
            userId.value = undefined;
            users.value = [];
        }
    }
},{ immediate: true });

watch(userId, async ()=>
{
    // When the user-filter value changes, re-fetch courses with the new userId.
    await refresh({ userId: userId.value, organizationIds: organizationIds.value, token: '' });
});

const onPreviousPageClicked = async  () =>
{
    await reload({token: page.value.prevPageToken});
}

const onFirstPageClicked = async () =>
{
    await reload({token: ''});
}

const onNextPageClicked = async () =>
{
    await reload({token: page.value.nextPageToken});
}

onMounted(async ()=>
{
    if(whoseCourse.value.mine && filter.userId !== 'me')
    {
        filter.userId = 'me';
    }

    try
    {
        const [orgs] = await Promise.all([fetchOrganizations(), refresh(filter)]);
        await fetchOrganizationNameAndLogo(orgs);
    }
    finally
    {
        isReady.value = true;
    }
});

onBeforeUnmount(() =>
{
    timerStore.unschedule(timerId);
});
</script>