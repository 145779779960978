<template>
    <cr-delete-confirmation-dialog id="deleteExerciseGroupDialog" @confirm="onConfirmed" :disabled="isLoading" data-testing="delete-exercise-group-dialog">
        <cr-html :value="t('EXERCISE_GROUP_DELETE_DIALOG_MESSAGE', {exerciseGroupName: exerciseGroup.name})" />
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import { IExerciseGroup } from '@cyber-range/cyber-range-api-user-client';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useExerciseGroupStore } from '../../../stores/exerciseGroupStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    exerciseGroup: IExerciseGroup;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    await useExerciseGroupStore().deleteCourseExerciseGroup(props.exerciseGroup.courseId, props.exerciseGroup.id);
    emit('confirm');
}
</script>
