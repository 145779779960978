import { IProduct, ISubscription, Product, ProductFilter, ProductStatus, ProductType } from '@cyber-range/cyber-range-api-subscription-client';
import { defineStore } from 'pinia';
import { IPlan } from '../interfaces/iPlan';
import { useApiClientStore } from './apiClientStore';
import { IFeature } from '../interfaces/iFeature';
import { Feature } from '../entities/Feature';
import { Plan } from '../entities/Plan';
import featuredPlans from "./data/featuredPlans.json";
import referencePlans from "./data/referencePlans.json";

export const usePlanStore = defineStore('planStore', 
{
    state: () => 
    ({
        plans: new Map<string, IPlan>(),
        features: new Map<string, IFeature>()
    }),

    getters:
    {
        allPlans: (state) => () =>
        {
            return [...state.plans.values()];
        },
        allFeatures: (state) => () =>
        {
            return [...state.features.values()];
        }
    },

    actions: 
    {
        async fetchPlansAndFeatures(options?: {background: boolean})
        {
            let productApiClient = options?.background ? useApiClientStore().backgroundProductApiClient : useApiClientStore().productApiClient;

            let products = await productApiClient.get(new ProductFilter(ProductStatus.Ready));

            const features = products.filter(product => product.type === ProductType.Addon).map(addon => Feature.fromAddon(addon));
            features.forEach(f => this.$state.features.set(f.addonId!, f));

            const plans = await Promise.all(products.filter(product => product.type === ProductType.Product).map(product => this.toPlan(product)))
            plans.forEach(p => this.$state.plans.set(p.productId!, p));
        },

        async getFeatureByAddonId(addonId: string): Promise<IFeature>
        {
            let feature = this.$state.features.get(addonId);
            if (!feature)
            {
                const addon = await useApiClientStore().productApiClient.getOne(addonId);
                feature = Feature.fromAddon(addon);
                this.$state.features.set(addonId, feature);
            }
            return feature;
        },

        async getPlanByProductId(productId: string): Promise<IPlan>
        {
            let plan = this.$state.plans.get(productId);
            if (!plan)
            {
                const product = await useApiClientStore().productApiClient.getOne(productId);
                plan = await this.toPlan(product);
                this.$state.plans.set(productId, plan);
            }
            return plan;
        },

        async toPlan(product: IProduct): Promise<IPlan>
        {
            let features = (await Promise.all(product.requiredAddons.map(addonId => this.getFeatureByAddonId(addonId)))).filter(f => f !== undefined) as IFeature[];
            let optionalFeatures = (await Promise.all(product.allowedAddons.filter(addonId => !product.requiredAddons.includes(addonId)).map(addonId => this.getFeatureByAddonId(addonId)))).filter(f => f !== undefined) as IFeature[];

            return new Plan
            (
                {
                    productId: product.id,
                    name: product.name,
                    basePrice: product.unitPrice,
                    features: features,
                    optionalFeatures: optionalFeatures.sort((a:IFeature,b:IFeature)=> { return (a.name || "") > (b.name || "")? 1: -1}),
                    baseLimits: product.limits,
                }
            )
        },

        /**
         * Featured plans are those that are always displayed on the billing page
         */
        getFeaturedPlans(plans = featuredPlans)
        {
            return plans.map(plan => new Plan(plan));
        },

        /**
         * Includes featured plans + any plans that are not actively advertised, but we want to provide custom properties for.
         */
        getReferencePlans(plans = referencePlans)
        {
            return [...this.getFeaturedPlans(), ...plans.map(plan => new Plan(plan))];
        }
    }
})