import IPaginator from "../interfaces/iPaginator";

export default class Paginator<T> implements IPaginator<T>
{
    constructor(public allItems:T[], public pageSize:number, private index:number = 0){}

    public get displayItems():T[]
    {
        return this.allItems.slice(this.index,(this.index + this.pageSize));
    }

    private get size():number
    {
        return this.allItems.length;
    }

    public get showPrevious():boolean
    {
        return this.index > 0;
    }

    public get showNext():boolean
    {
        return this.index + this.pageSize < this.size;
    }

    public get hasMultiplePages():boolean
    {
        return this.size > this.pageSize;
    }

    public first():void
    {
        this.index = 0;
    }

    public next():void
    {
       if(this.index + this.pageSize < this.size)
       {
            this.index += this.pageSize;
       }
    }

    public previous():void
    {
        this.index - this.pageSize < 0
            ? this.index = 0
            : this.index -= this.pageSize;
    }
}