<template>
    <cr-delete-confirmation-dialog @confirm="onConfirmed" :disabled="isLoading" data-testing="delete-team-dialog">
        <cr-html :value="t('COURSE_USERS_DELETE_TEAM_DIALOG_MESSAGE', {teamName: team?.name})" />
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useTeamStore } from '../../../stores/teamStore';
import { useRosterStore } from '../../../stores/rosterStore';
import { IRosterEntry } from '@cyber-range/cyber-range-api-roster-client';
import { storeToRefs } from 'pinia';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    courseId: string;
    team?: IRosterEntry;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    if(props.team?.teamId)
    {
        await useTeamStore().delete(props.courseId, props.team.teamId);
        useRosterStore().clearCache();
        emit('confirm');
    }
}
</script>