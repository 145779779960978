import Config from '../config'
import { IExercise, Exercise } from '@cyber-range/cyber-range-api-exercise-client';
import { computed, ComputedRef } from 'vue';

export function useExerciseDurationWarnings(exercise:ComputedRef<IExercise|undefined>)
{
    
    const remainingExerciseDurationInMs = computed(() =>
    {
        return Exercise.remainingNonPersistentAccessDurationThisPeriod(Exercise.fromJson(exercise.value || {}),Config.EXERCISE_MAX_NON_PERSISTENT_ACCESS_DURATION_PER_PERIOD_IN_HOURS, new Date().getTime())
    });
    
    const durationRemainingIsLow = computed(() =>
    {
        return remainingExerciseDurationInMs.value <= (Config.EXERCISE_MAX_NON_PERSISTENT_ACCESS_DURATION_WARNING_THRESHOLD_IN_HOURS * 60 * 60 * 1000);
    });

    const isDepleted = computed(()=>
    {
        return remainingExerciseDurationInMs.value <= 0
    });
    
    return {
        remainingExerciseDurationInMs, // support message variables
        durationRemainingIsLow, //support display logic in various places
        isDepleted, // support various control button 'disabled' prop
    }
}