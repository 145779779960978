<template>
    <cr-confirmation-dialog @confirm="onConfirmed" @cancel="onCanceled" :disabled="isLoading" data-testing="delete-user-dialog" :confirm-text-color="confirmTextColor" :confirm-background-color="confirmBackgroundColor" :cancel-text-color="cancelTextColor" :cancel-background-color="cancelBackgroundColor">
        <cr-html :value="t('COURSE_USERS_DELETE_USER_DIALOG_MESSAGE', {name: user?.name})" />
        <cr-checkbox v-model="alsoRemoveTeam" name="thisField" :label="t('COURSE_USERS_DELETE_USER_DIALOG_DELETE_TEAM_MESSAGE', {name: user?.teamName})" class="cr-mt3" data-testing="delete-user-dialog-also-delete-team-checkbox" />
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useTeamStore } from '../../../stores/teamStore';
import { useRosterStore } from '../../../stores/rosterStore';
import { IRosterEntry } from '@cyber-range/cyber-range-api-roster-client';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useUserStore } from '../../../stores/userStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const alsoRemoveTeam = ref<boolean>(true);

const props = defineProps<
{
    courseId: string;
    user?: IRosterEntry;
}>();

watch(()=>props.user, ()=>alsoRemoveTeam.value=true);

const cancelTextColor = 'var(--cr-delete-confirmation-cancel-text-color)';
const cancelBackgroundColor = 'var(--cr-delete-confirmation-cancel-background-color)';
const confirmTextColor = 'var(--cr-delete-confirmation-confirm-text-color)';
const confirmBackgroundColor = 'var(--cr-delete-confirmation-confirm-background-color)';

const emit = defineEmits<{
    (name: 'confirm'): void
    (name: 'cancel'): void
}>();

const onConfirmed = async ()=>
{
    if(props.user)
    {
        if(alsoRemoveTeam.value)
        {
            await useTeamStore().delete(props.courseId, props.user.teamId);
        }
        else
        {
            await useUserStore().deleteCourseUser(props.courseId, props.user.id);
        }

        useRosterStore().clearCache();
        emit('confirm');
    }
}
function onCanceled()
{
    alsoRemoveTeam.value = true;
    emit('cancel');
}
</script>