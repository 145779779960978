<template>
    This is a placeholder.
    <br/>
    <cr-button @click="clicky">CLICK</cr-button>
</template>


<script lang="ts" setup>
import { useAnnouncementStore } from '@cyber-range/cyber-range-lib-ui';

const clicky = ()=>{
    useAnnouncementStore().announce('click');
}

</script>