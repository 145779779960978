<template>
    <read-control-item >
        <cr-tooltip :title="toolTipText" data-testing="edit-team-communication-tooltip">
            <cr-button outlined :disabled="disabled" @click="onClicked" data-testing="edit-team-communication-button">
                <cr-loading v-if="disabled" :message="t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_BUTTON')" data-testing="edit-team-communication-loading"/>
                <template v-else>
                    <cr-icon>bi-router</cr-icon>
                    {{ t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_BUTTON') }}
                </template>
            </cr-button>
        </cr-tooltip>
    </read-control-item>
    <cr-confirmation-dialog v-model="showControlUpdatedDialog" :title="t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_UPDATED_TITLE')" :cancel="false" :confirm-text="t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_UPDATED_CONFIRM')" data-testing="team-communication-updated-dialog">
        <p> 
            <cr-html :value="updatedDescriptionText" data-testing="inter-team-connectivity-updated-description"/>
        </p>
    </cr-confirmation-dialog>
    <cr-confirmation-dialog v-model="showConfirmationDialog" :title="t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_TITLE')" @confirm="onConfirm" :confirm-text="confirmText"  data-testing="edit-team-communication-dialog">
        <p  data-testing="edit-team-communication-dialog-description">
            {{ t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_DESCRIPTION') }}
        </p>
        <p>
            <cr-external-link class="kb-link centered-text" :href="kbStore.interTeamConnectivity()" data-testing="inter-team-connectivity-kb-link" >
                {{ t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_LEARN_ABOUT') }}
            </cr-external-link>
        </p>
        <p> 
            <cr-html :value="currentStateDescription" data-testing="edit-team-communication-dialog-current-state"/>
        </p>
        <p> 
            <cr-html :value="toggleQuestion" data-testing="edit-team-communication-dialog-toggle-text"/> 
        </p>
    </cr-confirmation-dialog>
</template>

<script setup lang="ts">
import ReadControlItem from '../layouts/sections/ReadControlItem.vue';
import { FeatureStatus, IExerciseGroup } from '@cyber-range/cyber-range-api-user-client';
import { computed, ref, toRefs, watch } from 'vue';
import { useInterTeamConnectivity } from '../../composables/useInterTeamConnectivity';
import { useI18n } from 'vue-i18n';
import { useKbStore } from '../../stores/kbStore';

const props = defineProps<{
    exerciseGroup:IExerciseGroup,
    courseId:string
}>();

const emit = defineEmits<{
    (name: 'toggle'): void,
}>();

const {exerciseGroup, courseId } = toRefs(props);
const { featureStatus, isPending, isEnabled } = useInterTeamConnectivity(courseId, exerciseGroup);

const { t } = useI18n();
const kbStore = useKbStore();

const showControlUpdatedDialog = ref(false);
const showConfirmationDialog = ref(false);

const onClicked = () => showConfirmationDialog.value = true;
const onConfirm = () => emit('toggle');

const disabled = computed(()=>
{
    return !!isPending.value || !exerciseGroup.value
})

const currentStateDescription = computed(()=>
{
    return !!isEnabled?.value
        ? t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_ENABLED_DESCRIPTION')
        : t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_DISABLED_DESCRIPTION')
});

const toggleQuestion = computed(()=>
{
    return !!isEnabled?.value
        ? t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_QUERY_DISABLE')
        : t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_QUERY_ENABLE')
});

const confirmText = computed(()=>
{
    return !!isEnabled?.value
        ? t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_DISABLE')
        : t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_ENABLE')
});

const updatedDescriptionText = computed(()=>
{
    return !!isEnabled?.value
        ? t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_NOW_ENABLED')
        : t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_NOW_DISABLED')
});


const toolTipText = computed(()=>
{
    if(!!disabled.value)
    {
        return featureStatus?.value === FeatureStatus.PendingEnabled
            ? t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_PENDING_ENABLED')
            : t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_PENDING_DISABLED')
    }
    return t('EXERCISE_GROUP_INTER_TEAM_CONNECTIVITY_TITLE')
});

watch(isPending, (newValue, oldValue) =>
{
    if (oldValue === true && newValue === false)
    {
        showControlUpdatedDialog.value = true;
    }
}, { immediate: true });
</script>

<style>
.centered-text
{
    text-align: center;
}
</style>