<template>
    <cr-filter icon="bi-person" :label="'Role'" :selectedValues="selectedValueDisplayNames" data-testing="role-filter">
        <template #crDropDownList>
            <role-picker v-model="selectedRoles" :roles="roles" tag="cr-dropdown-list-item" :childProps="{tag: 'div'}" />
        </template>
    </cr-filter>
</template>

<script setup lang="ts">
import { UserRole } from '@cyber-range/cyber-range-api-user-client';
import { useEnum } from '../../composables/useEnum';
import { ref, computed } from 'vue';
import RolePicker from '../pickers/RolePicker.vue';

const props = defineProps<
{
    roles: (UserRole|string)[];
    modelValue: (UserRole|string)[];
}>();

const emit = defineEmits(['update:modelValue']);

const { toDisplayEnumName } = useEnum();

const selectedRoles = computed(
    {
        get: () => props.modelValue,
        set: (roles: (UserRole|string)[]) => onSelectedRolesUpdated(roles)
    }
)

//On modelValues changed, update selectedValueDisplayNames so the filter's label is updated.
const selectedValueDisplayNames = ref<string[]>();

const onSelectedRolesUpdated = (roles: (UserRole|string)[]) => 
{
    selectedValueDisplayNames.value = roles.map(role => toDisplayEnumName(UserRole, role));
    emit('update:modelValue', roles);
}
</script>