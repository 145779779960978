import { IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import { IUsage, IUsageFilter } from '@cyber-range/cyber-range-api-usage-client';
import { defineStore } from 'pinia';
import { useApiClientStore } from './apiClientStore';

export const useUsagesStore = defineStore('usageStore', 
{
    actions: 
    {
        async list(filter?: Partial<IUsageFilter>, options?: {background?:boolean}): Promise<IApiPageResponse<IUsage>>
        {
            let usageApiClient = options?.background ? useApiClientStore().backgroundUsageApiClient : useApiClientStore().usageApiClient;
            return await usageApiClient.get(filter);
        },
    }
});
