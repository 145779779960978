import { Ref, computed, ref } from "vue";
import { useAuthorizationStore } from "../stores/authorizationStore";
import { useCourseStore } from "../stores/courseStore";
import { ICourse } from "@cyber-range/cyber-range-api-user-client";

export function useCourseApprovals(course?: Ref<ICourse|undefined>)
{
    const canApproveCourses = computed(() => useAuthorizationStore().canApproveCourses(course?.value?.organizationId));

    const showCourseApprovedDialog = ref(false);

    async function onApproveCourseClicked(eventOrCourse?: ICourse|Event)
    {
        const courseId = (eventOrCourse instanceof Event) ? course?.value?.id : eventOrCourse?.id;
        if (!courseId)
        {
            return
        }
        await useCourseStore().approve(courseId);
        showCourseApprovedDialog.value = true;
    }

    const showCourseRejectedDialog = ref(false);
    async function onRejectCourseClicked(eventOrCourse?: ICourse|Event)
    {
        const courseId = (eventOrCourse instanceof Event) ? course?.value?.id : eventOrCourse?.id;
        if (!courseId)
        {
            return
        }
        await useCourseStore().reject(courseId);
        showCourseRejectedDialog.value = true
    }

    return {
        canApproveCourses,
        showCourseApprovedDialog,
        onApproveCourseClicked,
        showCourseRejectedDialog,
        onRejectCourseClicked,
    };
}
