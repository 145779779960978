<template>
    <cr-error-dialog v-model="showNoAltIdDialog" :title="t('MAKE_PAYMENT_DIALOG_TITLE')" @confirm="closeDialog" data-testing="make-payment-no-alt-id-dialog">
        {{ t('MAKE_PAYMENT_DIALOG_NO_ALT_ID_TEXT') }}
    </cr-error-dialog>
    <cr-confirmation-dialog v-model="showMakePaymentDialog" :title="t('MAKE_PAYMENT_DIALOG_TITLE')"  @cancel="closeDialog" @confirm="onMakePaymentDialogConfirmed" @shown="onMakePaymentDialogShown" data-testing="make-payment-dialog">
        <form-section :key="key">
            <cr-radio v-model="selectedAmountOption" name="make-payment-amount-type">
                <cr-radio-item :value="PaymentAmountOptions.AmountDue" :disabled="amountDueDisabled">{{ t('MAKE_PAYMENT_DIALOG_AMOUNT_DUE', {amount: toCurrency(amountDue)} ) }}</cr-radio-item>
                <cr-radio-item :value="PaymentAmountOptions.CustomAmount">{{ t('MAKE_PAYMENT_DIALOG_CUSTOM_AMOUNT') }}</cr-radio-item>
            </cr-radio>
            <cr-text-field v-if="showCustomAmountTextField" v-model="customAmount" :label="t('MAKE_PAYMENT_AMOUNT_LABEL')" type="number" :step=.01 :min="1" :max="amountDue" prefix="$" required data-testing="make-payment-amount-field"/>
        </form-section>
    </cr-confirmation-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useNumberFormats } from '../../../composables/useNumberFormats';
import { watch } from 'vue';
import { computed } from 'vue';
import FormSection from '../../layouts/sections/FormSection.vue';
import { usePaymentStore } from '../../../stores/paymentStore';
import { Currency, Price } from '@cyber-range/cyber-range-api-payment-client';
import { PaymentAmountOptions } from '../../../interfaces/paymentAmountOptions';

const { t } = useI18n();
const { toCurrency } = useNumberFormats();

const model = defineModel<boolean>({required: true});
const props = defineProps<
{
    organizationId: string;
    alternateIds?: string[];
    balance?: number;
}>();

const key = ref<number>(0);

const selectedAmountOption = ref(PaymentAmountOptions.AmountDue);

const showNoAltIdDialog = ref(false);
const showMakePaymentDialog = ref(false);
const customAmount = ref<string>("");
const vendibleUrl = ref<string>(""); 
const hasAlternateId = computed(() => !!props.alternateIds?.length );
const amountDue = computed(() => (props.balance || 0) * -1);

const amountDueDisabled = computed(() => amountDue.value <= 0);
const showCustomAmountTextField = computed(() => selectedAmountOption.value === PaymentAmountOptions.CustomAmount);

const onMakePaymentDialogShown = () =>
{
    if (amountDueDisabled.value)
    {
        selectedAmountOption.value = PaymentAmountOptions.CustomAmount;
    }
    else
    {
        selectedAmountOption.value = PaymentAmountOptions.AmountDue;
    }
    key.value++;
};

watch(model, () => 
{
    if (!model.value)
    {
        return; // do nothing when closing the dialog
    }
    
    if (!hasAlternateId.value)
    {
        showNoAltIdDialog.value = true;
    }
    else
    {
        showMakePaymentDialog.value = true
    }
})

const closeDialog = () =>
{
    customAmount.value = "";
    vendibleUrl.value = "";
    model.value = false;
} 

const onMakePaymentDialogConfirmed = async () =>
{
    const amount = showCustomAmountTextField.value ? Number(customAmount.value) : amountDue.value;
    const result = await usePaymentStore().createOrganizationVendible(props.organizationId, new Price({ amount, currency: Currency.USD }));
    window.location.assign(result.paymentUrl);
    closeDialog();
}
</script>