export default {
    en: {
        RANGE_UNABLE_TO_JOIN_ERROR_MESSAGE: "An Error occurred while trying to join your exercise. Please try again later.",
        RANGE_EXERCISE_ENDED_MESSAGE: 'Your session has ended.',
        RANGE_CLOCK_OUT_OF_SYNC_MESSAGE: 'Your clock may be out of sync.',
        RANGE_ENVIRONMENT_TIME_REMAINING: 'Environment time remaining:',
        RANGE_EXTEND_SESSION_BUTTON: 'Extend Session',
        RANGE_EXERCISE_EXTENSION_DISABLED_NO_EXTENSIONS_REMAINING: "You have used all of your extensions",
        RANGE_EXERCISE_EXTENSION_DISABLED_NO_DURATION_REMAINING: "You have used all of your alloted time for the month",
        RANGE_EXTENDING_ENVIRONMENT_TIME: 'Extending...',
        RANGE_EXERCISE_EXTENSION_SUCCESS_MESSAGE: "Your session has been extended by {timePeriod}.",
        RANGE_EXERCISE_EARLY_TERMINATION_BUTTON: "Terminate",
        RANGE_EXERCISE_EARLY_TERMINATION_DIALOG_TITLE: "Are you sure you want to terminate this exercise environment?",
        RANGE_EXERCISE_EARLY_TERMINATION_DIALOG_CONFIRMATION_TEXT: "Upon termination, this exercise environment will be permanently deleted. A new and fresh environment will be provided to you upon your re-entry. If you do not take action, this environment will self-terminate when the time limit is reached.",
        RANGE_EXERCISE_EARLY_TERMINATION_DIALOG_UNDERSTANDING_LABEL: "I understand that a deleted exercise environment cannot be restored.",
        RANGE_EXERCISE_EARLY_TERMINATION_DIALOG_CONFIRM: "Terminate",
    }
}
