import { capitalCase } from "change-case";

function toDisplayEnumName(anEnum:object, enumValue:string|number|undefined, options?: {removePending:boolean}): string
{
    let result = capitalCase(Object.keys(anEnum).find(k => (<any>anEnum)[k] === enumValue) || '');

    if(options?.removePending)
    {
        result = result.replace(/^Pending /,'');
    }
    
    return result;
}

export type SelectItem = {id:string|number|undefined, name:string}

function toSelectItems(anEnum:object, enumValues?:(string|number|undefined)[]): SelectItem[]
{
    let result:SelectItem[] = [];

    let sort = (a:SelectItem,b:SelectItem)=>a.name.localeCompare(b.name);

    if(enumValues)
    {
        for(let value of enumValues)
        {
            result.push({id:value, name: toDisplayEnumName(anEnum, value)})
        }
    }
    else
    {
        for(let key of Object.keys(anEnum))
        {
            if(!isNaN(parseInt(key)))
            {
                //This is a Typescript enum hack for an enum without a value.  It can be safely skipped.
                continue;
            }

            let value = (<any>anEnum)[key];

            result.push({id:value, name: capitalCase(key)})
        }
    }

    return result.sort(sort);
}

let usableFunctions = 
{
    toDisplayEnumName,
    toSelectItems
}

export function useEnum(): typeof usableFunctions
{
    return usableFunctions;
}