<template>
    <cr-card :to="courseRoute" :toDisabled="!courseIsAccessible" data-testing="course-tile">
        <cr-card-image class="image cr-mb1 cr-p3" :src="(course as any)['image']" alt="" fill />
        <cr-card-body>
            <cr-card-title tag="h2" data-testing="course-name" class="cr-mb3 cr-font-bold">{{ course.name }}</cr-card-title>
            <cr-card-text v-if="course.status === CourseStatus.Ready">
                <tile-detail-section>
                    <tile-detail-section-item icon="bi-mortarboard-fill" :alt="t('COURSES_COURSE_INSTRUCTORS')" data-testing="course-instructors">
                        <template v-if="instructorName">
                            {{ instructorName }} 
                        </template> 
                        <template v-else>
                            {{ t('COURSES_COURSE_NO_INSTRUCTORS') }}
                        </template>
                    </tile-detail-section-item>
                    <tile-detail-section-item icon="bi-display" :alt="t('COURSES_COURSE_NUMBER_OF_ENVIRONMENTS')" data-testing="course-environments">
                        {{ t('COURSES_COURSE_NUMBER_OF_ENVIRONMENTS', {n: course.statistics.numberOfExercises}) }}
                    </tile-detail-section-item>
                    <tile-detail-section-item icon="bi-people-fill" :alt="t('COURSES_COURSE_NUMBER_OF_ENROLLMENTS')" data-testing="course-enrollments">
                        {{ t('COURSES_COURSE_USERS', {n: course.statistics.numberOfEnrollments} ) }}
                    </tile-detail-section-item>
                    <tile-detail-section-item :icon="courseExpirationIcon" :icon-color="courseExpirationIconColor" :alt="t('COURSES_COURSE_EXPIRATION')" data-testing="course-expiration">
                        <template v-if="expiredDuration">
                            {{ t('COURSES_COURSE_EXPIRED_ON', { expiredDuration }) }}
                        </template>
                        <template v-else-if="course.endTime">
                            {{ t('COURSES_COURSE_EXPIRES_ON') }} {{ new Date(course.endTime).toLocaleDateString() }} 
                        </template>
                        <template v-else>
                            {{ t('COURSES_COURSE_NEVER_EXPIRE') }}
                        </template>
                    </tile-detail-section-item>
                    <tile-detail-section-item icon="bi-person-badge" :alt="t('COURSES_COURSE_YOUR_ROLE')" data-testing="course-your-role">
                        {{  courseRoles }}
                    </tile-detail-section-item>
                </tile-detail-section>
            </cr-card-text>

            <cr-card-text v-else class="cr-text-center cr-ptb4">
                <cr-error-text v-if="course.status === CourseStatus.Error"  :message="t('COURSES_COURSE_ERROR')"/>
                <cr-loading v-else-if="course.status === CourseStatus.Pending_Deleting" :message="useEnum().toDisplayEnumName(CourseStatus, course.status, {removePending: true})" />
                <cr-warning-text v-else :message="useEnum().toDisplayEnumName(CourseStatus, course.status)" />
            </cr-card-text>
        </cr-card-body>
    </cr-card>
</template>

<style scoped>
.image
{
    background-color: var(--cr-tile-image-background-color);
    height: var(--cr-tile-image-height);
}
.cr-card-title
{
    font-size: var(--cr-tile-title-font-size);
}
</style>

<script setup lang="ts">
import { CourseStatus, ICourse, UserRole } from '@cyber-range/cyber-range-api-user-client';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import { useEnum } from '../../composables/useEnum';
import Route from '../../routers/route';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import TileDetailSection from '../layouts/sections/TileDetailSection.vue';
import TileDetailSectionItem from '../layouts/sections/TileDetailSectionItem.vue';
import { useCalendar } from '../../composables/useCalendar'

const { t } = useI18n();
const { getRoles } = useAuthorizationStore();
const { toDisplayEnumName } = useEnum();
const { toHumanDuration } = useCalendar();

const props = defineProps<
{
    course:ICourse
}>();

const courseIsAccessible = computed(()=>
{
    const isAccessibleStatus = ![CourseStatus.Pending_Deleting, CourseStatus.Error].includes(props.course.status);
    return isAccessibleStatus && useAuthorizationStore().canAccessCourse(props.course.id);
});

const courseRoute = computed(()=>
{
    return courseIsAccessible ? new BreadcrumbItem({...Route.Course, params: {courseId: props.course.id}}) : undefined;
});

const instructorName = computed(()=>
{
    return props.course.statistics.instructors?.map(i => i.name).join(', ') || '';
});

const courseRoles = computed(()=>
{
    return props.course && getRoles(props.course.id, props.course.organizationId)?.map(role => toDisplayEnumName(UserRole, role)).join(', ')
})
const expiredDuration = computed(() =>
{
    if (!props.course.endTime) return;
    const duration = new Date().getTime() -  new Date(props.course.endTime).getTime();
    return duration > 0 ? toHumanDuration(duration, { minimumResolution: 'day' }) : undefined;
})
const courseExpirationIcon = computed(() => !!expiredDuration.value ? 'bi-calendar-x' : 'bi-calendar')
const courseExpirationIconColor = computed(() => !!expiredDuration.value ? 'cr-course-expired-icon-color' : '')
</script>