import { IInvitation, IInvitationApiClient, InvitationFilter, InvitationStatus } from '@cyber-range/cyber-range-api-invitation-client';
import { defineStore } from 'pinia';
import { useApiClientStore } from './apiClientStore';
import { UserRole } from '@cyber-range/cyber-range-api-user-client';

export const useInvitationStore = defineStore('invitationStore', 
{
    state: () => 
    ({
       pendingInvitations: [] as IInvitation[]
    }),

    actions: 
    {
        async fetchPendingApproval(options?: {background?:boolean}): Promise<IInvitation[]>
        {
            let invitationApiClient = options?.background ? useApiClientStore().backgroundInvitationApiClient : useApiClientStore().invitationApiClient;
            const pendingInvitations: IInvitation[] = [];
            const filter = new InvitationFilter();
            do
            {
                const result = await invitationApiClient.get(filter);
                pendingInvitations.push(...result.items.filter(i => i.status === InvitationStatus.PendingApproval));
                filter.token = result.nextPageToken;
            }
            while (filter.token);
            this.pendingInvitations = pendingInvitations;
            return this.pendingInvitations;
        },

        async approve(invitationId: string, options?: { background: boolean }): Promise<void>
        {
            let invitationApiClient = options?.background ? useApiClientStore().backgroundInvitationApiClient : useApiClientStore().invitationApiClient;

            await invitationApiClient.approve(invitationId);
        },

        async reject(invitationId: string, options?: { background: boolean }): Promise<void>
        {
            let invitationApiClient = options?.background ? useApiClientStore().backgroundInvitationApiClient : useApiClientStore().invitationApiClient;

            await invitationApiClient.reject(invitationId);
            await invitationApiClient.delete(invitationId);
        },

        async create(invitations:IInvitation[], options?: {background?:boolean}): Promise<void>
        {
            let invitationApiClient = options?.background ? useApiClientStore().backgroundInvitationApiClient : useApiClientStore().invitationApiClient;

            await invitationApiClient.add(invitations);
        },

        async createWithRole(invitation:IInvitation, role: UserRole, options?: {background?:boolean}): Promise<void>
        {
            let invitationApiClient = options?.background ? useApiClientStore().backgroundInvitationApiClient : useApiClientStore().invitationApiClient;

            await invitationApiClient.addWithRole(invitation, role);
        },

        async resend(invitationId:string, options?: {background?:boolean}): Promise<void>
        {
            let invitationApiClient = options?.background ? useApiClientStore().backgroundInvitationApiClient : useApiClientStore().invitationApiClient;

            await invitationApiClient.resend(invitationId);
        },

        async update(invitationId:string, invitation: IInvitation, options?: {background?:boolean}): Promise<void>
        {
            let invitationApiClient = options?.background ? useApiClientStore().backgroundInvitationApiClient : useApiClientStore().invitationApiClient;

            await invitationApiClient.update(invitationId, invitation);
        },

        async delete(invitationId:string, options?: {background?:boolean, bulk?:boolean}): Promise<void>
        {
            let invitationApiClient: IInvitationApiClient = options?.background ? useApiClientStore().backgroundInvitationApiClient : useApiClientStore().invitationApiClient;

            invitationApiClient = options?.bulk ? useApiClientStore().bulkInvitationApiClient : invitationApiClient;

            await invitationApiClient.delete(invitationId);
        },
    }
})