import { IFeature } from "../interfaces/iFeature";
import { IPlan } from "../interfaces/iPlan";
import {FeatureCategory} from "../interfaces/featureCategory";
import { IProductLimit, ProductLimit } from "@cyber-range/cyber-range-api-subscription-client";

export class Plan implements IPlan
{
    productId: string | undefined;
    name: string | undefined;
    features: IFeature[];
    optionalFeatures: IFeature[];
    displayedPrice: string | undefined;
    basePrice: number | undefined;
    baseLimits: IProductLimit | undefined;

    constructor(plan?: Partial<IPlan>)
    {
        this.productId = plan?.productId;
        this.name = plan?.name;
        this.features = plan?.features || [];
        this.optionalFeatures = plan?.optionalFeatures || [];
        this.displayedPrice = plan?.displayedPrice;
        this.basePrice = plan?.basePrice;
        this.baseLimits = plan?.baseLimits;
    }

    get pricePerEnrollment(): number
    {
        const enrollmentPrices = this.features.filter(f => f.categories?.includes(FeatureCategory.Enrollment)).map(f => f.unitPrice || 0);
        return enrollmentPrices.reduce((acc, curr) => acc + curr, 0);
    }

    get isFlexRate(): boolean
    {
        return this.features.some(f => f.addonId === 'cloud_usage_product_id');
    }

    get isFederalRate(): boolean
    {
        return this.features.some(f => f.addonId?.includes('federal') || f.name?.toLocaleLowerCase().includes('federal'));
    }

    get provideCourseware(): boolean
    {
        return this.features.some(f => f.categories?.includes(FeatureCategory.Courseware));
    }

    get provideExerciseArea(): boolean
    {
        return this.features.some(f => f.categories?.includes(FeatureCategory.ExerciseArea));
    }

    get providePersistentEnvironment(): boolean
    {
        return this.features.some(f => f.addonId === 'feature_persistent_environments_product_id');
    }

    get provideNonPersistentEnvironment(): boolean
    {
        return this.features.some(f => f.addonId === 'feature_non_persistent_environments_product_id');
    }
    
    get provideCtf(): boolean
    {
        return this.features.some(f => f.categories?.includes(FeatureCategory.Ctf));
    }

    get effectiveLimits() : IProductLimit
    {
        let result = new ProductLimit(this.baseLimits || {})

        for(let feature of this.features)
        {
            for(let limit of Object.keys(result))
            {
                let value = feature?.limits?.[limit as keyof IProductLimit] || 0;
                
                if(limit in result && !isNaN(result[limit as keyof IProductLimit]))
                {
                    result[limit as keyof IProductLimit] += value
                }
                else
                {
                    result[limit as keyof IProductLimit] = value
                }
            }
        }

        return result;
    }
}