<template>
    <form-layout :title="t('SUBSCRIBE_PAGE_TITLE')" :breadcrumbs="breadcrumbs" @confirm="onConfirm" @cancel="onCancel" :ready="isReady">
        <template #form>
            <subscription-form v-model="subscription"/>
        </template>
    </form-layout>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import FormLayout from '../layouts/FormLayout.vue';
import SubscriptionForm from './SubscriptionForm.vue';
import { computed, onMounted, ref } from 'vue';
import Route from '../../routers/route';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { IOrganization } from '@cyber-range/cyber-range-api-organization-client';
import { usePlanStore } from '../../stores/planStore';
import { ISubscription, Recurrence, Subscription } from '@cyber-range/cyber-range-api-subscription-client';
import { useRawObject } from '../../composables/useRawObject';
import { useSubscriptionStore } from '../../stores/subscriptionStore';
import { useRouter } from 'vue-router';
import { useOrganizationStore } from '../../stores/organizationStore';

const props = defineProps<
{
    organizationId:string;
}>();

const { t } = useI18n();
const router = useRouter();
const organization = ref<IOrganization>();
const isReady = ref(false);
const subscription = ref(new Subscription({organizationId: props.organizationId, recurrence: Recurrence.Monthly}));

const breadcrumbs = computed(()=>[
                            new BreadcrumbItem(Route.Organizations),
                            new BreadcrumbItem({...Route.Organization, text: organization.value?.name, params: {organizationId: props.organizationId}}),
                            new BreadcrumbItem({...Route.Billing, params: {organizationId: props.organizationId}}),
                            new BreadcrumbItem({...Route.Subscribe, params: {organizationId: props.organizationId}})
                        ]);


onMounted(async () => 
{
    [organization.value] = await Promise.all([useOrganizationStore().getOrganization(props.organizationId), usePlanStore().fetchPlansAndFeatures()])
    isReady.value = true;
})

const onConfirm = async () =>
{
    let payload = useRawObject(subscription.value, {only: ['organizationId', 'productId', 'recurrence', 'addOns', 'startDate']});
    if (subscription.value.endDate)
    {
        payload.endDate = subscription.value.endDate;
    }

    await useSubscriptionStore().subscribe(payload);

    router.push({name: Route.Billing.name, params: { organizationId: props.organizationId}});
}

const onCancel = () =>
{
    router.push({name: Route.Billing.name, params: { organizationId: props.organizationId}});
}
</script>