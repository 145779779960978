<template>
    <cr-delete-confirmation-dialog id="deleteSubscriptionDialog" @confirm="onConfirmed" :disabled="isLoading" data-testing="delete-subscription-dialog">
        <span>{{ t('DELETE_SUBSCRIPTION_MESSAGE') }}</span>
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { ISubscription } from '@cyber-range/cyber-range-api-subscription-client';
import { useSubscriptionStore } from '../../../stores/subscriptionStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    subscription?: ISubscription;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    if(props.subscription)
    {
        await useSubscriptionStore().deleteSubscription(props.subscription.id);
        emit('confirm');
    }
}
</script>